import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom';

import Login from '../pages/auth/Login';
import OTP from '../pages/auth/OTP';
import SetPassword from '../pages/auth/SetPassword';
import ForgotPassword from '../pages/auth/ForgotPassword';
import ResetPassword from '../pages/auth/ResetPassword';
import Password from '../pages/auth/Password';
import SignUp from '../pages/Onboarding/SignUp';

import SomethingWentWrong from '../common/SomethingWentWrong';

import ProtectedLayout from './ProtectedLayout';
import SidebarLayout from './SidebarLayout';
import OnboardingLayout from './OnboardingLayout';
import HomeLayout from './HomeLayout';

import AuthLayout from './AuthLayout';
import { detailsPath, getDefaultRoute, getProtectedOnboardingRoutes, getProtectedSidebarRoutes, ticket, ticketTrackPath, trackSupportTicket } from './roleWisePageRoute';
import NotFound from '../pages/not-found';
import EmailVerification from '../pages/auth/EmailVerification';
import LoginWithOTP from '../pages/auth/LoginWithOtp';
import { UnderMaintainance } from '../pages/auth/UnderMaintainance';
import SupportTrack from '../pages/SupportTrack';
import Details from '../pages/Details';
import SupportTrackLayout from './SupportTrackLayout';
import Videomeet from '../pages/videomeet/Videomeet';

export const getRoutes = ({ loggedUser }) => {
    let protectedSidebarRoutes, protectedOnboardingRoutes, defaultRoute;
    if (loggedUser) {
        protectedSidebarRoutes = getProtectedSidebarRoutes(loggedUser.role.id, loggedUser.type, { frontend_controller: loggedUser.frontend_controller, status: loggedUser.status, grafana_url: loggedUser.grafana_url });
        protectedOnboardingRoutes = getProtectedOnboardingRoutes(loggedUser.role.id, loggedUser.type, { status: loggedUser.status });
        defaultRoute = getDefaultRoute(loggedUser.role.id, loggedUser.type, {
            frontend_controller: loggedUser.frontend_controller,
            status: loggedUser.status,
            spOnboardingStatus: loggedUser.spOnboardingStatus,
            customerOnboardingStatus: loggedUser.customerOnboardingStatus,
            email: loggedUser.email,
            pancard_number: loggedUser.pancard_number,
            feOnboardingStatus: loggedUser.feOnboardingStatus,
            address_proof_number: loggedUser.address_proof_number,
        });
    }
    return createBrowserRouter(
        createRoutesFromElements(
            <Route element={<AuthLayout />} errorElement={<SomethingWentWrong />}>
                {/* <Route path='*' element={<UnderMaintainance />} /> */}
                <Route element={<ProtectedLayout />}>
                    <Route element={<SidebarLayout />}>
                        {protectedSidebarRoutes?.map((route) => (
                            <Route key={route.path} path={route.path} element={route.component} />
                        ))}
                        <Route path='*' element={<Navigate to={defaultRoute ? defaultRoute : '/not-found'} />} />
                    </Route>
                    <Route path='/onboarding' element={<OnboardingLayout />}>
                        {protectedOnboardingRoutes?.map((route) => (
                            <Route key={route.path} path={route.path} element={route.component} />
                        ))}
                    </Route>
                    <Route element={<SupportTrackLayout />}>
                        <Route path={'/room/:room_id'} element={<Videomeet />} />
                    </Route>
                    <Route path='/not-found' element={<NotFound />} />
                </Route>

                <Route element={<SupportTrackLayout />}>
                    <Route path={trackSupportTicket.path} element={<SupportTrack />} />
                    <Route path={detailsPath.path} element={<Details />} />
                </Route>

                <Route element={<HomeLayout />}>
                    <Route path='/login' element={<Login />} />
                    <Route path='/admin/login-with-otp' element={<LoginWithOTP />} />
                    <Route path='/otp' element={<OTP />} />
                    <Route path='/verification/verify/:token' element={<EmailVerification />} />
                    <Route path='/password' element={<Password />} />
                    <Route path='/set-password' element={<SetPassword />} />
                    <Route path='/forgot-password' element={<ForgotPassword />} />
                    <Route path='/auth/password/reset/:token_id' element={<ResetPassword />} />
                    <Route path='/signUp' element={<SignUp />} />
                    <Route path='*' element={<Navigate to='/login' />} />
                </Route>
            </Route>
        )
    );
};

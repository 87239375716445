import { utils } from '../types';

export const searchModuleData = ({ module, tab, query, projectId, customerId, endUserId, siteId, warehouseId, limit, page }) => ({
    type: utils.SEARCH_MODULE_DATA_REQUEST,
    payload: { module, tab, query, projectId, customerId, endUserId, siteId, warehouseId, limit, page },
});

export const uploadFiles = ({ actionType, query = {}, files, formName }) => ({
    type: utils.UPLOAD_FILES_REQUEST,
    payload: { actionType, query, files, formName },
});

export const exportModuleCsvData = ({
    module,
    tab,
    projectId,
    customerId,
    endUserId,
    siteId,
    warehouseId,
    status = 'ALL',
    emails = [],
    download = true,
    data = {},
    type = 0,
    formName,
    filterData,
}) => ({
    type: utils.EXPORT_MODULE_DATA_CSV_REQUEST,
    payload: { module, tab, projectId, customerId, endUserId, siteId, warehouseId, status, emails, download, data, type, formName, filterData },
});

export const filterModuleData = ({
    module,
    tab,
    projectId,
    customerId,
    endUserId,
    siteId,
    warehouseId,
    data,
    limit,
    page,
    next = true,
    count = false,
    type = 0,
    query,
    loadingType,
}) => ({
    type: utils.FILTER_MODULE_DATA_REQUEST,
    payload: { module, tab, projectId, customerId, endUserId, siteId, warehouseId, data, limit, page, next, count, type, query, loadingType },
});

export const copyToClipboard = ({ data }) => ({
    type: utils.COPY_TO_CLIPBOARD_REQUEST,
    payload: { data },
});

export const downloadFiles = ({ files }) => ({
    type: utils.DOWNLOAD_FILE_REQUEST,
    payload: { files },
});

export const clearFilterModuleData = () => ({
    type: utils.CLEAR_FILTER_MODULE_DATA,
    payload: {},
});

export const setClearFilterClickAction = (action) => ({
    type: utils.SET_CLEAR_FILTER_BUTTON_ACTION,
    payload: action,
});

export const downloadRemoteData = ({ url, fileName, mimeType }) => ({
    type: utils.DOWNLOAD_REMOTE_DATA_REQUEST,
    payload: { url, fileName, mimeType },
});

export const updateLatLong = ({ latitude, longitude }) => ({
    type: utils.UPDATE_LAT_LONG_REQUEST,
    payload: { latitude, longitude },
});


export const setRoomListData = ({ isRoomListOpen, activeCallId }) => ({
    type: utils.SET_ROOM_LIST_DATA,
    payload: { isRoomListOpen, activeCallId },
});

export const setSelectedLocationOnMap = (data) => ({
    type: utils.SET_SELECTED_LOCATION_FROM_MAP,
    payload: data,
});

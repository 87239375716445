import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from '@mui/material/Skeleton';
import { guid, validatePAN } from '../../../utils/utils';
import BankCard from '../../../common/BankCard';
import MiniPopup from '../../../common/MiniPopup';
import { BankForm } from '../../Payments/Modals/PayPO/BankForm';
import { getLoginOTP } from '../../../actions/auth';
import { getWalletDetailsById, withdrawWalletAmount } from '../../../actions/wallet';
import { closeModalAction } from '../../../actions/modal';
import { wallet as walletTypes } from '../../../types';
import ButtonScogoPrimary from '../../../common/Buttons/ButtonScogoPrimary';
import Form from '../../../common/Form';
import Input from '../../../common/Form/Input';
import { useForm } from 'react-hook-form';
import { convertToLakhOrCrore, validateNumberValue } from '../../../utils/common';
import { isPayPoAllowed } from '../../../utils/access';
import { isScm } from '../../../utils/role';


export default function SendToBank({ transactionStatusQuery, defaultPage, defaultLimit, isShowDailyLimit, bankDetails, isBankDetailsLoading, wallet, totalWithdrawn, isUserSpPspSpisp, isUserIsIsp, directBankTransfer = false, vendorId, type, showTotalWithdrawn = true, userId, scogoBankAccountBalance, has_bank_details }) {

    const dispatch = useDispatch();

    const { loggedUser } = useSelector((state) => state.auth);
    const { banks } = useSelector((state) => state.user);
    const { isWithdrawAmountConfirmed } = useSelector((state) => state.wallet);
    const { formButtonLoading, isBankDetailsLoading: loggedUserBankDetailsLoading } = useSelector((state) => state.loading);

    const formName = 'sendToBank';

    const defaultValues = {
        amount: '',
        otp: '',
    };

    useEffect(() => {
        return () => dispatch({ type: walletTypes.SET_IS_WITHDRAWAL_AMOUNT_CONFIRMED, payload: false });
    }, [dispatch]);

    const handleFormSubmit = (formValues) => {
        if (directBankTransfer || (isWithdrawAmountConfirmed && formValues.otp)) {
            let data = {
                type: 'WITHDRAW',
                withdrawAmount: parseInt(formValues.amount),
            };
            if (formValues.otp) {
                data.otp = parseInt(formValues.otp)
            }
            if (formValues.email) {
                data.email = formValues.email;
            }
            if (formValues.pancard_number) {
                data.pancard_number = formValues.pancard_number;
            }
            if (directBankTransfer) {
                data.banking_partner = 'BANKOPEN';
                data.recepient = userId;
            }
            dispatch(
                withdrawWalletAmount({ data, refresh: true, formName, status: transactionStatusQuery, limit: defaultLimit, page: defaultPage, closeModal: directBankTransfer })
            );

        } else {
            dispatch(
                getLoginOTP({
                    mobile: loggedUser.mobile,
                    skip: true,
                    bank_otp: 1,
                    amount: formValues.amount,
                    confirmWithdrawAmountOnSuccess: true,
                    formName,
                })
            );
        }
    };

    const getBankDetailsCard = () => {
        let bank_account_number = isUserSpPspSpisp || isUserIsIsp ? 'sp_bank_account_number' : 'bank_account_number';
        let bank_name = isUserSpPspSpisp || isUserIsIsp ? 'sp_bank_name' : 'bank_name';
        let bank_ifsc_code = isUserSpPspSpisp || isUserIsIsp ? 'sp_bank_ifsc_code' : 'bank_ifsc_code';
        let bank_account_holder_name = isUserSpPspSpisp || isUserIsIsp ? 'sp_bank_account_holder_name' : 'bank_account_holder_name';
        let bankAccountNumber = bankDetails?.[bank_account_number];

        if (isBankDetailsLoading || loggedUserBankDetailsLoading)
            return (
                <div className='w-full'>
                    <SkeltonLoader />
                </div>
            );
        else if (bankDetails)
            return (
                <BankCard
                    bankName={bankDetails?.[bank_name]}
                    ifscCode={bankDetails?.[bank_ifsc_code]}
                    bankAccountHolderName={bankDetails?.[bank_account_holder_name]}
                    bankAccountNumber={bankAccountNumber}
                />
            );

        const bankFormPopup = (
            <MiniPopup
                handleClick={() => { }}
                popup={
                    <BankForm
                        banks={banks}
                        callPay={false}
                        vendorId={vendorId}
                        isSP={isUserSpPspSpisp || isUserIsIsp}
                        isRefresh={true}
                        type={type}
                    />
                }
                transition={false}
                className='fixed px-3 py-2 mt-2 z-10 rounded-md shadow-lg bg-white overflow-scroll left-0 top-0 border-2'
                menuStyle={{ left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}
            >
                <ButtonScogoPrimary textOrComponent={'Add Bank Details'} />
            </MiniPopup>
        );

        return (
            <div className='px-4 border-b-2 border-scogobgsky max-h-max py-2 mt-2 mx-2 rounded-lg gap-2'>
                <p className='text-font14 font-medium'>Bank Details Not Found</p>
                {bankFormPopup}
            </div>
        );
    };

    const sendToBankAmountCustomValidation = (amount) => {
        return parseFloat(amount) >= 400 && amount <= wallet?.wallet_amount;
    };

    const otpCustomValidation = (otp) => {
        return otp && otp.toString().length === 4;
    };

    const isUsrHaveEmail = loggedUser.email;
    const isUsrHavePancard = loggedUser.pancard_number;

    const methods = useForm({ defaultValues, mode: 'all' });
    const { watch } = methods;


    let isSubmitDisabled = parseInt(watch("amount")) > wallet?.withdraw_limit;
    if (directBankTransfer) {
        isSubmitDisabled = scogoBankAccountBalance < 400;
    }
    if (!has_bank_details && directBankTransfer) {
        return <p className='text-font18  text-scogo18 font-medium text-scogoclosed pb-10 p-6'>Bank details are not available for this user, please ask user to add the details and then retry bank transfer</p>
    }
    return (
        <>
            <div className='px-6 pt-4 '>
                <div className='flex gap-2'>
                    <div className='bg-tagbg py-2 text-font11 px-2 rounded-lg '>Current Balance: ₹ {wallet?.wallet_amount}</div>
                    {showTotalWithdrawn && <div className='bg-tagbg py-2 text-font11 px-2 rounded-lg '>Total Withdrawn Amount: ₹ {totalWithdrawn ? convertToLakhOrCrore(totalWithdrawn) : 0}</div>}
                    {directBankTransfer && <ScogoAmountBalance beneficiaryId={userId} />}
                </div>
                <div className='pt-6 px-2'>
                    <p className='text-font13 text-scogo18 font-medium md:mr-0 mr-2'>Amount will be credited to the following bank details</p>
                </div>
                <div>{getBankDetailsCard()}</div>
            </div>
            <Form
                methods={methods}
                onSubmit={handleFormSubmit}
                submitButtonText={directBankTransfer ? 'Transfer' : isWithdrawAmountConfirmed ? 'Withdraw' : 'Request OTP'}
                onCancel={() => dispatch(closeModalAction())}
                showSubmit
                submitContainerClassName='flex gap-2 justify-end py-4 px-4'
                isLoading={formButtonLoading[formName]}
                isSubmitDisabled={isSubmitDisabled}
            >
                <div className='px-3 relative'>
                    {!isUsrHaveEmail && !directBankTransfer && (
                        <Input
                            className='w-full'
                            label='Email'
                            type={'text'}
                            name='email'
                            required
                            validate={(email) => {
                                if (email === '') return true;
                                return (
                                    email.match(
                                        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                    ) || 'Invalid Email'
                                );
                            }}
                        />
                    )}
                    {!isUsrHavePancard && !directBankTransfer && (
                        <Input
                            className='w-full'
                            label='Pancard Number'
                            type={'text'}
                            name='pancard_number'
                            required
                            validate={(pan) => {
                                if (pan === '') return true;
                                return (validatePAN(pan) || 'Invalid Pancard');
                            }}
                        />
                    )}
                    <Input
                        allowFloats
                        className='w-full'
                        label='Amount'
                        type={'number'}
                        name='amount'
                        placeholder={'Amount'}
                        required
                        readOnly={isWithdrawAmountConfirmed}
                        validate={(amount) => {
                            if (amount === '') return true;
                            return (sendToBankAmountCustomValidation(amount) || 'Amount should be more than 400 and less than your wallet balance.');
                        }}

                    />

                    {isWithdrawAmountConfirmed && !directBankTransfer && (
                        <Input
                            className='w-full'
                            label='OTP'
                            type={'text'}
                            name='otp'
                            placeholder={'OTP'}
                            required
                            validate={(otp) => {
                                if (otp === '') return true;
                                return (otpCustomValidation(otp) || 'Invalid OTP');
                            }}
                        />
                    )}
                    {isShowDailyLimit && <p className='text-slate-700 text-font12 italic absolute -bottom-12 ml-4' > *Your daily withdrawal limit is ₹ {wallet?.withdraw_limit}</p>}

                </div>
            </Form >

        </>
    );
}

const SkeltonLoader = () => [1, 2, 3].map((item) => <Skeleton key={`skelton_${guid()}`} animation='wave' height={20} width={250} />);

export const ScogoAmountBalance = ({ beneficiaryId }) => {
    const { loggedUser } = useSelector((state) => state.auth);
    const role = loggedUser.role.id;
    const type = loggedUser.type;
    const frontend_controller = loggedUser.frontend_controller;
    const { walletDetailsById } = useSelector((state) => state.wallet);
    const { isWalletDetailsByIdLoading } = useSelector((state) => state.loading);
    const dispatch = useDispatch();
    const onRefreshClick = () => {
        dispatch(getWalletDetailsById({ updateBalance: false, beneficiary: beneficiaryId, accountBalance: 'BANKOPEN' }));
    };

    if (isScm(role, type) && isPayPoAllowed(frontend_controller) || beneficiaryId) {
        const scogoBankAccountBalance = validateNumberValue(walletDetailsById?.scogo_bankopen_account_balance);
        return <div className='bg-tagbg py-2 text-font11 px-2 rounded-lg flex'>Scogo Account Balance: ₹ {scogoBankAccountBalance}
            <span className={`material-icons text-font11 bg-transparent hover:text-scogoorange cursor-pointer max-w-max border-2 border-tagbg rounded-full ${isWalletDetailsByIdLoading ? 'animate-spin' : ''}`} onClick={onRefreshClick}>refresh</span>
        </div>
    }
    return <></>;
}
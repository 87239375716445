import React, { useEffect, useState, useCallback, useImperativeHandle, forwardRef } from 'react';
import moment from 'moment';
import Checkbox from '../../../common/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import DropdownMenu from '../../../common/MenuDropdown';
import { getCustomerIdOfLoginUser } from '../../../utils/utils';
import {
    getAllAssets,
    getAssetsFilterList,
    clearInventoryTableDataOnUnmount,
    setInventoryFilterAndPaginationData,
    clearSiteOrWarehouseIdFromStore,
    getAssetsFilteredData,
} from '../../../actions/inventory';
import { clearFilterModuleData, setClearFilterClickAction } from '../../../actions/utils';
import { copyToClipboard } from '../../../actions/utils';
import { GetInventoryActions, isDeviceConfigurationRequired, getAssetTitle } from '../InventoryController';
import IconToolTip from '../../../common/IconToolTip';
import OverflowTip from '../../../common/OverflowTip';
import AssetSerialNumber from '../../Modals/Inventory/AssetSerialNumber';
import { TeamItemsClassName, TeamItemClassName, TeamMenuButtonIcon } from '../../../common/Menuscommon';
import Menus from '../../../common/Menus';
import DetailsColumn from '../reusableComponents/AddOption/AssetDetails';
import { openChatInNewTab } from '../../../actions/conversations';
import APIFilterPopup from '../../../common/APIFilters/FilterPopup';
import { clickableTextClass } from '../../../common/Css';
import { validateDate, getDateWithISOFormat, validateFilterNumber, readFilterQueryParams, transformQueryFilters } from '../../../utils/filter';
import { useQuery, validateDateValue, validateResponseDate, validateStringValue } from '../../../utils/common';
import { DateComponent } from '../../../common/TableReusableColumns';
import VirtualizedTable from '../../../common/VirtualizedTable';
import { openCustomModal } from '../../../actions/modal';
import AccordionJSON from '../reusableComponents/AccordionJSON';
import { isCustomerGroup, isScm, isCluster } from '../../../utils/role';
import { RenderPRNumber, RenderPoNumber, RenderPoStatus } from '../../Payments/components/Po/PoTable';

const filterSchema = {
    customersFilter: {
        filterName: 'customers',
        title: 'Customer',
        typeOfFilter: 'checkbox',
        apiValueKey: 'customer_id',
        displayValueKey: 'customer_company_name',
        submitKey: 'customerId',
        queryParamKey: 'customerId',
        parse: ({ customerId }) => {
            let values = validateFilterNumber(customerId);
            if (values) return { customerId: values };
            return {};
        },
    },
    assetTypeFilter: {
        filterName: 'assets',
        title: 'Asset Type',
        typeOfFilter: 'checkbox',
        apiValueKey: 'id',
        displayValueKey: 'title',
        submitKey: 'asset_type_id',
        queryParamKey: 'asset_type_id',
        parse: ({ asset_type_id }) => {
            let values = validateFilterNumber(asset_type_id);
            if (values) return { asset_type_id: values };
            return {};
        },
    },
    descripenciesFilter: {
        filterName: 'descripencies',
        title: 'Descripencies',
        typeOfFilter: 'checkbox',
        apiValueKey: 'title',
        displayValueKey: 'title',
        submitKey: 'discrepancy',
        queryParamKey: 'discrepancy',
    },
    statusFilter: {
        filterName: 'status',
        title: 'Status',
        typeOfFilter: 'checkbox',
        apiValueKey: 'apiValueKey',
        displayValueKey: 'displayKey',
        submitKey: 'status',
        queryParamKey: 'status',
    },
    workingConditionFilter: {
        filterName: 'working_condition',
        title: 'Working Condition',
        typeOfFilter: 'checkbox',
        apiValueKey: 'apiValueKey',
        displayValueKey: 'displayKey',
        submitKey: 'working_condition',
        queryParamKey: 'working_condition',
    },
    added_on: {
        filterName: 'added_on',
        title: 'Added On',
        typeOfFilter: 'daterange',
        submitKey: ({ aFromDate, aToDate } = {}) => ({
            added_on: { fromDate: getDateWithISOFormat(aFromDate), toDate: getDateWithISOFormat(aToDate) },
        }),
        queryParamKey: {
            keyList: ['aFromDate', 'aToDate'],
            getQueryObject: ({ fromDate, toDate }) => {
                return { aFromDate: fromDate, aToDate: toDate };
            },
        },
        parse: ({ aFromDate, aToDate }) => {
            let validatedFrom = validateDate(aFromDate);
            let validatedTo = validateDate(aToDate);
            if (validatedFrom > validatedTo) return {};
            if (validatedFrom && validatedTo) {
                return { aFromDate: validatedFrom, aToDate: validatedTo };
            } else if (validatedFrom) {
                return { aFromDate: validatedFrom, aToDate: new Date() };
            }
            return {};
        },
        getSelectedFilterValues: ({ aFromDate, aToDate } = {}) => ({
            fromDate: getDateWithISOFormat(aFromDate),
            toDate: getDateWithISOFormat(aToDate),
        }),
        maxDateFromToday: 0,
    },
};

const AssetDetails = forwardRef(
    (
        {
            setCustomerIdOfSelectedSite,
            selectedSubTab,
            inventoryRowsPerPage,
            inventoryPage,
            setInventoryRowsAndPage,
            closeInventorySideDrawer,
            openInventorySideDrawer,
            selectedSiteId,
            defaultRowsPerPage,
            defaultPage,
            tab,
            setClearFilterButton,
            limitQueryParam,
            pageQueryParam,
            searchQueryParam,
            queryParams,
            updateQueryFilters,
            siteIdQueryParam,
            archiveQueryParam,
        },
        ref
    ) => {
        const dispatch = useDispatch();
        const { loggedUser } = useSelector((state) => state.auth);
        const customerId = getCustomerIdOfLoginUser(loggedUser);
        const siteId = selectedSiteId ? selectedSiteId : 0;
        const [checkedAssetsTracker, setCheckedAssetsTracker] = useState(() => new Set());
        const [filterQueryObject, setFilterObject] = useState({});
        const [filtersActiveStatus, setFiltersActiveStatus] = useState({});
        const query = useQuery();

        const { assets, assetsCount, assetsFiltersData, showInventorySideDrawer, inventorySideDrawerPayload } = useSelector((state) => state.inventory);

        const { assetsLoading, assetsForFilterLoading } = useSelector((state) => state.loading);
        const [filterList, setFilterList] = useState({});

        const getAssetData = useCallback(
            ({ isFilterApplied, searchTerm, limit, page, payload, siteId, archive }) => {
                if (!limit) limit = defaultRowsPerPage;
                if (!page) page = defaultPage;
                if (isFilterApplied || searchTerm?.length > 0) {
                    dispatch(getAssetsFilteredData({ customerId: customerId, siteId, count: true, limit, page, query: searchTerm, payload, archive, callPoList: true }));
                } else {
                    dispatch(getAllAssets({ customerId, siteId, count: true, limit, page, archive, callPoList: true }));
                }
            },
            [customerId, defaultPage, defaultRowsPerPage, dispatch]
        );

        useEffect(() => {
            let { queryObject, filterStatus } = readFilterQueryParams(filterSchema, query);
            setFilterObject(queryObject);
            setFiltersActiveStatus(filterStatus);
            let isToShowFilterButton = false;
            Object.values(filterStatus).forEach((value) => (value ? (isToShowFilterButton = value) : false));
            setClearFilterButton(isToShowFilterButton);
            let filterPayload = transformQueryFilters(filterSchema, queryObject);
            getAssetData({
                searchTerm: searchQueryParam,
                limit: limitQueryParam,
                page: pageQueryParam,
                payload: filterPayload,
                isFilterApplied: isToShowFilterButton,
                siteId: siteIdQueryParam,
                archive: archiveQueryParam,
            });
            dispatch(
                setInventoryFilterAndPaginationData({
                    payload: filterPayload,
                    isFilterActive: isToShowFilterButton,
                    limit: limitQueryParam,
                    page: pageQueryParam,
                    query: searchQueryParam,
                    tab,
                })
            );
        }, [queryParams, searchQueryParam, limitQueryParam, pageQueryParam, getAssetData, setClearFilterButton, query, dispatch, tab, siteIdQueryParam, archiveQueryParam]);

        useImperativeHandle(ref, () => ({
            getSelectedData: () => {
                let selectedData = assetsData.filter((value, index) => checkedAssetsTracker.has(index));
                return selectedData;
            },
        }));

        useEffect(() => {
            if (siteId === 0) setCustomerIdOfSelectedSite(0);
            closeInventorySideDrawer();
            return () => {
                dispatch(clearFilterModuleData());
                dispatch(setClearFilterClickAction(() => { }));
                dispatch(clearInventoryTableDataOnUnmount());
            };
        }, [siteId]);

        useEffect(() => {
            return () => dispatch(clearSiteOrWarehouseIdFromStore());
        }, [dispatch]);

        const isToShowCustomerDataInTable = GetInventoryActions('isToShowCustomerData');

        useEffect(() => {
            let payload = { customerId };
            if (isToShowCustomerDataInTable) payload = Object.assign(payload, { toFilter: 'customers' });
            dispatch(getAssetsFilterList(payload));
        }, [customerId, dispatch, isToShowCustomerDataInTable]);

        const filterComponent = ({ headName, keysToFilter, typeOfFilter, placement }) => (
            <div className='flex'>
                {headName}
                <APIFilterPopup
                    filterList={filterList}
                    setFilterList={setFilterList}
                    height={15}
                    keysToFilter={keysToFilter}
                    submit={({ payload, page, filterList }) => updateQueryFilters({ filterSchema, payload, page, filterList })}
                    typeOfFilter={typeOfFilter}
                    placement={placement}
                    filtersData={assetsFiltersData}
                    filterSchema={filterSchema}
                    isLoading={assetsForFilterLoading}
                    setPayloadAndFilterStatus={setInventoryFilterAndPaginationData}
                    filterQueryObject={filterQueryObject}
                    filtersActiveStatus={filtersActiveStatus}
                />
            </div>
        );


        const assetsData = assets?.map(transformAsset);

        const selectALLHandler = (checked) => {
            if (checked) {
                const allIndices = assetsData.map((_, index) => index);
                setCheckedAssetsTracker(new Set(allIndices));
            } else {
                setCheckedAssetsTracker(new Set());
            }
        };

        useEffect(() => {
            setCheckedAssetsTracker(new Set());
        }, [assetsLoading]);

        const handleOnChangeCheckbox = (position) => {
            if (checkedAssetsTracker.has(position)) {
                setCheckedAssetsTracker((prev) => {
                    const next = new Set(prev);
                    next.delete(position);
                    return next;
                });
            } else {
                setCheckedAssetsTracker((prev) => new Set(prev).add(position));
            }
        };



        const copyOnDoubleClick = (event, candString) => {
            const isDoubleClick = event.detail === 2;
            if (isDoubleClick) {
                dispatch(copyToClipboard({ data: candString }));
            }
        };

        const handleOpenLocation = (address) => {
            return window.open(`https://maps.google.com?q=${`${address}`}`, '_blank');
        };

        const handleCopyAddress = (address) => {
            let addressToCopy = `${address}`;
            dispatch(copyToClipboard({ data: addressToCopy }));
        };

        const handleAssetDetail = (title, id, serNumber) => {
            const sideBartitle = (
                <div className='cursor-pointer flex gap-1  truncate'>
                    <IconToolTip title={`Asset Type : ${title}`} placement='bottom'>
                        <p className='max-w-40% truncate'>{title}</p>
                    </IconToolTip>
                    {serNumber && (
                        <>
                            {' '}
                            <IconToolTip title={`Serial Number : ${serNumber}`} placement='bottom'>
                                <p className='max-w-79% truncate'>({serNumber})</p>
                            </IconToolTip>
                        </>
                    )}
                </div>
            );
            const inventorySideDrawerPayload = {
                title: sideBartitle,
                assetId: id,
                assetSource: 'asset',
            };
            openInventorySideDrawer({ inventorySideDrawerName: 'assetHistory', inventorySideDrawerPayload });
        };

        const isLoggedUserIsEligibleForFeasibility = loggedUser.is_feasibility_eligible === 1;

        const assetTableSchema = {
            table: [
                {
                    head: {
                        render: (headName) => {
                            return (
                                <div className='flex items-center'>
                                    <Checkbox
                                        checkColor='text-scogoorange mr-2'
                                        checked={assetsData?.length > 0 && checkedAssetsTracker?.size > 0 && assetsData?.length === checkedAssetsTracker?.size}
                                        onChange={(event) => {
                                            selectALLHandler(event);
                                        }}
                                        dynamicSize={'1.2rem'}
                                    />
                                    {filterComponent({
                                        headName,
                                        keysToFilter: [filterSchema.assetTypeFilter, filterSchema.added_on],
                                    })}
                                </div>
                            );
                        },
                        headName: 'Asset Type',
                    },
                    body: {
                        render: (item, rowIndex) => {
                            const createdAt = validateDateValue(item?.created_at) && moment(new Date(validateResponseDate(item?.created_at)));
                            return (
                                <div className='w-full flex items-start'>
                                    <Checkbox
                                        checkColor='text-scogoorange mr-2'
                                        checked={checkedAssetsTracker.has(rowIndex)}
                                        onChange={(event) => {
                                            handleOnChangeCheckbox(rowIndex);
                                        }}
                                        dynamicSize={'1.2rem'}
                                    />
                                    <div className='cursor-pointer w-full'>
                                        <p
                                            className={`text-scogo15 truncate w-11/12 ${clickableTextClass}`}
                                            onClick={() => handleAssetDetail(item?.assetType?.title, item?.id, item?.serialNo)}
                                        >
                                            {item?.assetType?.title}
                                        </p>

                                        {item?.assetId?.length > 0 ? <p className='text-font09 text-slate-600 font-medium ' >
                                            <OverflowTip someLongText={item?.assetId} textClass={" text-slate-600"} />
                                        </p> : <></>}

                                        <div className='flex items-center gap-2'>
                                            {createdAt && <DateComponent className='text-scogogray font-normal text-font10' date={createdAt} timeFormat='DD MMM YYYY' />}
                                            <RenderEditAssetConfig asset={item} />
                                        </div>
                                    </div>
                                </div>
                            );
                        },
                    },
                },
                {
                    head: {
                        render: (headName) => {
                            let showCustomerFilter = !selectedSiteId && isToShowCustomerDataInTable;
                            if (showCustomerFilter) {
                                return filterComponent({
                                    headName,
                                    keysToFilter: [filterSchema.customersFilter],
                                });
                            }
                            return headName;
                        },
                        headName: 'Asset Details',
                    },
                    body: {
                        render: (asset) => {
                            const customerName = asset?.customerCompanyName;
                            return (
                                <div className='w-full'>
                                    {isToShowCustomerDataInTable && customerName && (
                                        <IconToolTip title={`Customer Name : ${asset.customerCompanyName}`}>
                                            <p className='w-full truncate'>{asset.customerCompanyName}</p>
                                        </IconToolTip>
                                    )}
                                    <DetailsColumn
                                        onClick={copyOnDoubleClick}
                                        make={asset?.assetMake}
                                        model={asset?.assetModel}
                                        partcode={asset?.partcode}
                                        supplier={asset?.vendor_supplier_name}
                                    />
                                </div>
                            );
                        },
                    },
                },
                {
                    head: {
                        render: (headName) => {
                            return filterComponent({
                                headName,
                                keysToFilter: [filterSchema.statusFilter, filterSchema.workingConditionFilter],
                            });
                        },
                        headName: 'Serial No',
                    },
                    body: {
                        render: (item) => {
                            return (
                                <AssetSerialNumber
                                    serialNumber={item?.serialNo}
                                    status={item?.status}
                                    statusForDisplay={item?.statusForDisplay}
                                    workingCondition={item?.workingCondition}
                                    showCopyIcon={typeof item?.serialNo === 'string' && item?.serialNo !== ''}
                                />
                            );
                        },
                    },
                },
                {
                    head: {
                        render: (headName) => {
                            return headName;
                        },
                        headName: 'Support',
                    },
                    body: {
                        render: (item) => {
                            const isStartDate = moment(item?.startDate).isValid();
                            const isEndDate = moment(item?.endDate).isValid();

                            return (
                                <>
                                    {isStartDate ? (
                                        <>
                                            <p className='text-scogogray font-normal text-font11'>Start Date</p>
                                            {item?.startDate && <DateComponent date={item?.startDate} text={false} timeFormat='DD MMM YYYY' />}
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    {isEndDate ? (
                                        <>
                                            <p className='text-scogogray font-normal text-font11'>End Date</p>
                                            {item?.endDate && <DateComponent className='text-scogo15 font-medium' date={item?.endDate} text={false} timeFormat='DD MMM YYYY' />}
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </>
                            );
                        },
                    },
                },
                {
                    head: {
                        render: (headName) => {
                            return headName;
                        },
                        headName: 'Last Ticket',
                        width: 1.2,
                    },
                    body: {
                        render: (item) => {
                            const lastTIcketCreatedAt = validateDateValue(item?.lastTicket?.created_at) && moment(new Date(validateResponseDate(item?.lastTicket?.created_at)));
                            return (
                                <div className='w-full'>
                                    {item?.lastTicket?.job_ticket_number && (
                                        <p className='text-scogo15 font-medium w-full '>
                                            <div className='flex w-full gap-1'>
                                                <IconToolTip title={item?.lastTicket?.job_ticket_number} placement='bottom'>
                                                    <p
                                                        className={clickableTextClass + 'cursor-pointer hover:text-scogoorange truncate'}
                                                        onClick={() =>
                                                            dispatch(
                                                                openChatInNewTab({
                                                                    conversationId: item.lastTicket.id,
                                                                })
                                                            )
                                                        }
                                                    >
                                                        {item?.lastTicket?.job_ticket_number}{' '}
                                                    </p>
                                                </IconToolTip>
                                                <IconToolTip placement={'bottom'} title={`Copy Ticket Number`}>
                                                    <span
                                                        className='cursor-pointer material-icons text-scogobgsky text-lg'
                                                        onClick={() => dispatch(copyToClipboard({ data: item?.lastTicket?.job_ticket_number }))}
                                                    >
                                                        {' '}
                                                        content_copy{' '}
                                                    </span>
                                                </IconToolTip>
                                            </div>
                                        </p>
                                    )}
                                    {lastTIcketCreatedAt && (
                                        <DateComponent className='text-scogogray font-normal text-font10' date={lastTIcketCreatedAt} timeFormat='DD MMM YYYY HH:mm A' />
                                    )}

                                    <span className='px-2 bg-scogoprimary text-white flex gap-2 items-center rounded-2xl w-fit max-w-11/12' >
                                        <OverflowTip textClass={`text-inherit text-font10 text-black w-full font-normal`} someLongText={item?.lastTicket?.project?.project_name} />
                                    </span>
                                </div>
                            );
                        },
                    },
                },
                {
                    head: {
                        render: (headName) => {
                            return headName;
                        },
                        headName: 'Site Details',
                    },
                    body: {
                        render: (item) => {
                            const { site } = item;
                            const isAddressAvailable = site?.state?.state_name || site?.city?.city_name || site?.address;
                            const address = `${site?.complete_site_address ? site?.complete_site_address : ''}, ${site?.state?.state_name ? site?.state?.state_name : ''}, ${site?.city?.city_name ? site?.city?.city_name : ''
                                } ${site?.fk_pincode_id ? site?.fk_pincode_id : ''}`;
                            return (
                                <div className='w-full'>
                                    <p className='flex gap-1'>
                                        <OverflowTip someLongText={item?.siteId} />
                                        <IconToolTip placement={'bottom'} title={`Copy Site ID`}>
                                            <span
                                                className='cursor-pointer material-icons text-scogobgsky text-lg'
                                                onClick={() => dispatch(copyToClipboard({ data: item?.siteId }))}
                                            >
                                                {' '}
                                                content_copy{' '}
                                            </span>
                                        </IconToolTip>
                                    </p>
                                    {item?.siteBranch && item?.siteId !== item?.siteBranch && (
                                        <p className='w-full' onClick={(e) => copyOnDoubleClick(e, item?.siteBranch)}>
                                            <OverflowTip someLongText={item?.siteBranch} />
                                        </p>
                                    )}
                                    {item?.enduserName && <IconToolTip placement={'bottom'} title={`EndUser Name : ${item?.enduserName}`}>
                                        <div className='w-4/5 truncate mt-1'> {item?.enduserName} </div>
                                    </IconToolTip>}
                                    {isAddressAvailable && (
                                        <div className='flex flex-col'>
                                            <IconToolTip placement={'bottom'} title={`${address}`}>
                                                <span className='cursor-pointer text-scogobgsky text-lg'> View Address </span>
                                            </IconToolTip>
                                            <div className='flex'>
                                                <IconToolTip placement={'bottom'} title={`Open Location`}>
                                                    <span className='cursor-pointer material-icons text-scogobgsky text-lg' onClick={() => handleOpenLocation(address)}>
                                                        {' '}
                                                        place{' '}
                                                    </span>
                                                </IconToolTip>
                                                <IconToolTip placement={'bottom'} title={`Copy Address`}>
                                                    <span className='cursor-pointer material-icons text-scogobgsky text-lg' onClick={() => handleCopyAddress(address)}>
                                                        {' '}
                                                        content_copy{' '}
                                                    </span>
                                                </IconToolTip>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            );
                        },
                    },
                },
                {
                    head: {
                        render: (headName) => {
                            return headName;
                        },
                        headName: 'Contact Details',
                    },
                    body: {
                        render: (item) => {
                            return (
                                <div className='w-full'>
                                    {item?.usersDetails?.length > 0 ? (
                                        <RenderOwnersList owners={item?.usersDetails} />
                                    ) : (
                                        <>
                                            <p className='font-bold text-scogoprimary w-full' onClick={(e) => copyOnDoubleClick(e, item?.siteContactName)}>
                                                <OverflowTip someLongText={item?.siteContactName} />
                                            </p>
                                            <p className='text-scogo99 w-full' onClick={(e) => copyOnDoubleClick(e, item?.siteContactPhone)}>
                                                <OverflowTip someLongText={item?.siteContactPhone} />
                                            </p>
                                            <p className='text-scogo99 w-full' onClick={(e) => copyOnDoubleClick(e, item?.siteContactEmail)}>
                                                <OverflowTip someLongText={item?.siteContactEmail} />
                                            </p>
                                        </>
                                    )}
                                </div>
                            );
                        },
                    },
                },
                {
                    head: {
                        render: (headName) => {
                            return headName
                        },
                        headName: 'PR Number',
                        hide: !isLoggedUserIsEligibleForFeasibility
                    },
                    body: {
                        render: (asset) => {
                            let po;
                            if (Array.isArray(asset?.poList) && asset.poList.length > 0) {
                                po = asset.poList[0];
                            }
                            if (!po) return <></>;
                            const createdAt = validateDateValue(po.creationDate) && moment(new Date(validateResponseDate(po.creationDate)));
                            return <RenderPRNumber prNumber={po.ticketNumber} requested={validateStringValue(po?.po?.requester?.name)} createdAt={createdAt} poType={po?.po?.type === 'RENEWAL_DUE' ? 'RENEWAL DUE' : validateStringValue(po?.po?.type)} nextRenewalDate={po.nextRenewalDate} />
                        },
                    },
                },
                {
                    head: {
                        render: (headName) => {
                            return headName
                        },
                        headName: 'PO Number',
                        hide: !isLoggedUserIsEligibleForFeasibility
                    },
                    body: {
                        render: (asset) => {
                            let po;
                            if (Array.isArray(asset?.poList) && asset.poList.length > 0) {
                                po = asset.poList[0];
                            }
                            if (!po) return <></>;
                            const onPoNumberClick = () => {
                                const url = po?.po?.approvedpdf ? po.po.approvedpdf : po?.po?.quotationpdf;
                                window.open(url, '_blank');
                            };

                            return <RenderPoNumber poNmber={po?.po?.poNumber} createdAt={po.createdAt} approvals={po?.po?.approvals} _id={po?.po?._id} linkType={po?.linkType} vendorId={po?.po?.vendorId} vendorName={po?.po?.vendorName} onPoNumberClick={onPoNumberClick} source='assets' />;
                        },
                    },
                },
                {
                    head: {
                        render: (headName) => headName,
                        headName: 'PO Status',
                        width: 0.8,
                        hide: !isLoggedUserIsEligibleForFeasibility
                    },
                    body: {
                        render: (asset) => {
                            let po;
                            if (Array.isArray(asset?.poList) && asset.poList.length > 0) {
                                po = asset.poList[0];
                            }
                            if (!po) return <></>;
                            return <RenderPoStatus poType={po?.po?.type} nextRenewalDate={po.nextRenewalDate} />
                        }
                    },
                },
                {
                    head: {
                        render: (headName) => {
                            return headName;
                        },
                        headName: 'Scan Details',
                        hide: showInventorySideDrawer || isLoggedUserIsEligibleForFeasibility,
                    },
                    body: {
                        render: (item) => {
                            return (
                                <div className='break-all'>
                                    {item?.scanner ? (
                                        <IconToolTip title={`Scanned By: ${item?.scanner?.first_name} ${item?.scanner?.last_name}`} placement='bottom'>
                                            <p className='w-full truncate'>{item.scanner ? `${item.scanner.first_name} ${item.scanner.last_name}` : ''}</p>
                                        </IconToolTip>
                                    ) : (
                                        <></>
                                    )}
                                    {item?.scanned_time ? (
                                        <IconToolTip
                                            title={`Time: ${!['', undefined, null].includes(item.scanned_time) && moment(item.scanned_time).isValid()
                                                ? moment(item.scanned_time).format('DD-MM-YYYY')
                                                : ''
                                                }`}
                                            placement='bottom'
                                        >
                                            <p className='w-full truncate'>
                                                {!['', undefined, null].includes(item.scanned_time) && moment(item.scanned_time).isValid()
                                                    ? moment(item.scanned_time).format('DD-MM-YYYY')
                                                    : ''}
                                            </p>
                                        </IconToolTip>
                                    ) : (
                                        <></>
                                    )}
                                    {item?.scanned_location ? (
                                        <IconToolTip
                                            title={`Location: ${!['', undefined, null].includes(item.scanned_time) && moment(item.scanned_time).isValid()
                                                ? moment(item.scanned_time).format('DD-MM-YYYY')
                                                : ''
                                                }`}
                                            placement='bottom'
                                        >
                                            <p className='w-full truncate'>{item.scanned_location ? item.scanned_location : ''}</p>
                                        </IconToolTip>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            );
                        },
                    },
                },
                {
                    head: {
                        render: (headName) => {
                            return filterComponent({
                                headName,
                                keysToFilter: [filterSchema.descripenciesFilter],
                            });
                        },
                        headName: 'Discrepancy',
                        hide: showInventorySideDrawer || isLoggedUserIsEligibleForFeasibility,
                        columnRowCss: 'break-words',
                    },
                    body: {
                        render: (item) => {
                            return (
                                <div className='w-full'>
                                    {item?.descripencies && (
                                        <div className='text-scogo99 break-words w-full'>
                                            <span className='material-icons text-scogoToastDangerIcon text-font13 px-2'>warning</span>
                                            <span className='text-scogo99 break-words'>{item?.descripencies}</span>
                                        </div>
                                    )}
                                </div>
                            );
                        },
                    },
                },
                {
                    head: {
                        render: (headName) => {
                            return headName;
                        },
                        headName: 'Actions',
                        width: 0.5,
                    },
                    body: {
                        bodyClassName: 'w-24 max-w-24 ',
                        render: (item) => {
                            return (
                                <div className='flex justify-center'>
                                    <AssetActionColumn item={item} siteId={siteId} selectedSubTab={selectedSubTab} />
                                </div>
                            );
                        },
                    },
                },
            ],
        };
        return (
            <VirtualizedTable
                rowHeight={100}
                schema={assetTableSchema}
                rowData={assetsData}
                isLoading={assetsLoading}
                tablePagination={true}
                setRowsPerPage={setInventoryRowsAndPage}
                tablePaginationHandler={(limit, page) => updateQueryFilters({ limit, page, filterSchema, filterList })}
                count={assetsCount}
                tableError={'No Assets Found'}
                rowsPerPage={inventoryRowsPerPage}
                page={inventoryPage}
                checkRowShouldHighlight={(asset) => showInventorySideDrawer && asset.id === inventorySideDrawerPayload?.assetId}
                columnsGap='gap-x-4'
            />
        );
    }
);

export default AssetDetails;


export const AssetActionColumn = (props) => {
    const { item, siteId, selectedSubTab } = props;
    const assetsTableActions = (asset) => GetInventoryActions('assetsTableActions', { asset });
    const { loggedUser } = useSelector((state) => state.auth);
    const customerId = getCustomerIdOfLoginUser(loggedUser);
    let customerName = loggedUser?.customer_company_name;

    return (
        <DropdownMenu
            menuData={assetsTableActions(item)}
            componentOrIcon={'more_vert'}
            item={item}
            iconColor={'text-scogoprimary'}
            payload={{ siteId, customerId, customerName, tabName: selectedSubTab, role: loggedUser.role.id }}
        />
    );
};


export const RenderEditAssetConfig = ({ asset }) => {
    const { loggedUser } = useSelector((state) => state.auth);
    const role = loggedUser.role.id;
    const type = loggedUser.type;
    const dispatch = useDispatch();

    if (!isCustomerGroup(role, type) && !isScm(role, type) && !isCluster(role, type)) {
        return <></>
    }
    const onEditConfigClick = () => {
        dispatch(
            openCustomModal({
                heading: (
                    <span className='text-black'>
                        Edit Asset Config | <span className='text-scogobgsky text-font16'>{getAssetTitle(asset)}</span>
                    </span>
                ),
                modalWidth: '70rem',
                modalHeight: '84vh',
                maxHeight: '84vh',
                modalComponent: <AccordionJSON asset={asset} assetId={asset.id} serial_number={asset.serialNo} showJsonEditor={isDeviceConfigurationRequired(asset)} />,
            })
        );
    }

    let hovertitle = 'Asset Config';
    if (!isDeviceConfigurationRequired(asset)) {
        hovertitle = 'Device Files'
    }

    return <IconToolTip title={hovertitle}><span onClick={onEditConfigClick} className='material-icons hover:text-scogoorange text-scogogray text-3xl'>settings_applications</span></IconToolTip>
}


export const transformAsset = (asset) => {
    return {
        type: 'asset',
        id: asset?.id,
        assetId: asset?.asset_number,
        assetType: asset?.assetType,
        asset_type_id: asset?.asset_type_id,
        asset_details: asset?.asset_details,
        asset: asset?.assetType?.title,
        assetModel: asset?.model,
        serialNo: asset?.serial_number,
        extID: asset?.external_asset_id,
        startDate: asset?.warranty_start_date ? moment(asset?.warranty_start_date) : '',
        endDate: asset?.warranty_end_date ? moment(asset?.warranty_end_date) : '',
        ticketId: asset?.fk_ticket_id,
        warranty_duration: asset.warranty_duration,
        warranty_type: asset?.warranty_type,
        backlining: asset?.backlining,
        last_worked_date: asset?.last_worked_date,
        siteId: asset?.site?.external_site_id,
        service_support: asset?.service_support,
        is_boq_available: asset?.is_boq_available,
        fk_pincode_id: asset?.site?.fk_pincode_id,
        address: asset?.site?.complete_site_address,
        siteBranch: asset?.site?.site_branch_name,
        siteContactName: asset?.site?.site_contact_person_name,
        siteContactPhone: asset?.site?.site_contact_person_mobile,
        siteContactEmail: asset?.site?.site_contact_person_email,
        enduserId: asset?.site?.customer_end_user,
        externel_order_id: asset?.externel_order_id,
        assetMake: asset?.make,
        assetslist: [asset],
        source: { from: 'SITE', sourceId: asset.fk_site_id },
        fk_site_id: asset.fk_site_id,
        tagDetails: asset.tagDetails,
        is_rma_eligible: asset?.is_rma_eligible,
        status: asset?.status,
        statusForDisplay: asset?.status?.replace('_', ' '),
        partcode: asset?.partcode,
        po_amount: asset?.po_amount,
        oem_id: asset?.oem_id,
        oem_warranty_type: asset?.oem_warranty_type,
        oem_start_date: asset?.oem_start_date,
        oem_end_date: asset?.oem_end_date,
        oem_warranty_duration: asset?.oem_warranty_duration,
        vendor_supplier_name: asset?.vendor_supplier_name,
        oem_last_update: asset?.oem_last_update,
        preventive_maintenance_schedule: asset?.preventive_maintenance_schedule,
        is_categorised: asset?.is_categorised,
        customer_id: asset?.fk_customer_id,
        customerCompanyName: asset?.customer?.customer_company_name,
        created_at: asset?.created_at,
        descripencies: asset?.descripencies,
        scanner: asset?.scanner,
        scanned_time: asset?.scanned_time,
        scanned_location: asset?.scanned_location,
        workingCondition: asset?.working_condition,
        bill_date: asset?.bill_date,
        capex_date: asset?.capex_date,
        warranty_end_date: asset?.warranty_end_date,
        warranty_start_date: asset?.warranty_start_date,
        is_archived: asset?.is_archived,
        lastTicket: asset?.lastTicket,
        usersDetails: asset.usersDetails,
        po_number: asset.po_number,
        capex_type: asset.capex_type,
        per_item_value: asset.per_item_value,
        useful_life: asset.useful_life,
        site: asset.site,
        device_config: asset.device_config,
        poList: asset.poList,
        enduserName: asset?.site?.enduser?.end_user_name
    };
};


export const RenderOwnersList = ({ owners }) => {
    return (
        <>
            <div className='relative break-words w-full'>
                {Array.isArray(owners) && owners?.length > 0 && (
                    <>
                        {' '}
                        {owners.slice(0, 2).map((owner) => {
                            return (
                                <IconToolTip title={`${owner.user.first_name} ${owner.user.last_name}`} placement='bottom'>
                                    <p className='text-scogoprimary text-font11 truncate'>{`${owner.user.first_name} ${owner.user.last_name}`}</p>
                                </IconToolTip>
                            );
                        })}
                        {owners.length > 2 && (
                            <Menus
                                position='ml-auto'
                                activeStatus={false}
                                menuButtonIcon={TeamMenuButtonIcon(`View all`)}
                                itemHoverClassName='hover:bg-blue-100 '
                                itemsClassName={TeamItemsClassName}
                                itemLable={owners.slice(2, owners.length).map((owner) => `${owner.user.first_name} ${owner.user.last_name}`)}
                                itemClassName={TeamItemClassName}
                                activeStatusClass='bg-scogoclosed'
                                textColor={'text-white hover:text-black'}
                            />
                        )}
                    </>
                )}
            </div>
        </>
    );
};

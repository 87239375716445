import { partners, loading, modal, toast, customer, fe, sp, auth } from '../types';
import { takeLatest, put, call, all, select } from 'redux-saga/effects';
import api from '../apis';
import { CLUSTER, FE, PSP, SCM, SP } from '../utils/role';
import microservices from '../lambdas/microservices';
import lambdas from '../lambdas/apis';
import { getLoggedUser } from '../selectors/users';
import { getPartnerFilterDetails } from '../selectors/partners';
import { getCustomerIdOfLoginUser } from '../utils/utils';
import { allUserTab } from '../pages/team/TeamController';
import { uploadFileToS3WithSignedUrl } from '../utils/common';

export function* getPartnersList(action) {
    const { role, customer, userType, fk_cluster_id, stateIds, customer_id } = action.payload;
    try {
        let queryParams = {
            user_role: role === 'vp' ? [7, 9, 12] : role === 7 ? [7, 12] : role === 13 ? [12, 13] : role,
            status: role === 'vp' ? [2, 2.5] : 0,
        };
        if (stateIds) {
            queryParams.state = stateIds;
        }
        if (!['', undefined, null].includes(userType)) {
            queryParams.type = userType;
            if (userType === 'CLUSTER') {
                queryParams.user_role = [6, 12];
            }
            if (userType === 'ISP') {
                queryParams.user_role = [4, 7, 12];
            }
        }
        if (fk_cluster_id) {
            queryParams.fk_cluster_id = fk_cluster_id;
        }
        if ([7, 13].includes(role)) {
            queryParams.customer = customer;
        }
        if (customer_id) queryParams.customer_id = customer_id;
        yield put({ type: loading.PARTNERS_LIST_LOADING, payload: true });
        const response = yield call(api.invitations.getUsersList, {}, {}, queryParams);
        if (response.data.code === 200) {
            yield put({ type: partners.PARTNERS_LIST_SUCCESS, payload: response.data.data.user_list });
        } else {
            yield put({ type: partners.PARTNERS_LIST_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: partners.PARTNERS_LIST_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.PARTNERS_LIST_LOADING, payload: false });
}

export function* getPartnersListDropDown(action) {
    const { user_role, status, type, customerId, customer: customerName } = action.payload;

    let queryParams = {
        user_role: user_role,
        status: status,
        type: type,
        customer_id: customerId,
    };
    if (customerName) queryParams.customer = customerName;
    try {
        yield put({ type: loading.PARTNERS_DROPDOWN_LIST_LOADING, payload: true });
        const response = yield call(api.invitations.getUsersList, {}, {}, queryParams);
        if (response.data.code === 200) {
            const users = response.data.data.user_list?.map((item) => {
                return {
                    key: item.id,
                    value: item.id,
                    label: `${item?.first_name} ${item?.last_name} (${item?.mobile})`,
                    type: item?.type,
                    role: item?.role?.id,
                    user: item,
                };
            });
            yield put({ type: partners.PARTNERS_LIST_DROPDOWN_SUCCESS, payload: users });

            let customerDetailList = [];
            response.data.data.user_list.forEach((user) => {
                if (user.customer_detail) {
                    let customer = user.customer_detail;
                    customerDetailList.push({
                        key: customer.customer_id,
                        value: customer.customer_id,
                        label: customer.customer_company_name,
                        sp_name: customer.customer_company_name,
                        pancard_number: customer.customer_pan_card_number,
                        customer_gst_number: customer.customer_gst_number,
                        gst_pancard_number: customer.customer_gst_number ? customer.customer_gst_number : customer.customer_pan_card_number,
                        vendor_code: user?.vendor_code?.code,
                        user,
                    });
                }
            });
            yield put({ type: customer.RAISE_PO_VENDORS_LIST, payload: customerDetailList });
        } else {
            yield put({ type: partners.PARTNERS_LIST_DROPDOWN_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: partners.PARTNERS_LIST_DROPDOWN_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.PARTNERS_DROPDOWN_LIST_LOADING, payload: false });
}

export function* getGroupsUsersListDropdown(action) {
    try {
        yield put({ type: loading.GROUP_USERS_LIST_LOADING, payload: true });
        const scmList = yield call(api.invitations.getUsersList, {}, {}, { user_role: [SCM.role], status: 0, type: SCM.type });
        const partnerList = yield call(api.invitations.getUsersList, {}, {}, { user_role: [SCM.role, PSP.role], status: 0, type: CLUSTER.type });
        if (partnerList.data.code === 200 || scmList.data.code === 200) {
            let users = [];
            if (Array.isArray(partnerList.data.data.user_list) && Array.isArray(scmList.data.data.user_list)) {
                users = [...partnerList.data.data.user_list, ...scmList.data.data.user_list];
            }
            users = users.map((user) => {
                return {
                    key: user.id,
                    value: user.id,
                    label: `${user?.first_name} ${user?.last_name} (${user?.mobile})`,
                    type: user?.type,
                    role: user?.role,
                    is_enabled: user?.is_enabled
                };
            });
            yield put({ type: partners.GROUP_USERS_LIST_SUCCESS, payload: users });
        } else {
            yield put({ type: partners.GROUP_USERS_LIST_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: partners.PARTNERS_LIST_DROPDOWN_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.GROUP_USERS_LIST_LOADING, payload: false });
}

export function* deletePartner(action) {
    const { userId, role, customerId, formName, userName, tabName, customerName, userType, fk_cluster_id } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(api.invitations.deleteUser, {}, { userId });
        let message = '';
        if (response.data.code === 200) {
            message = `${userName} deleted Successfully`;
            yield all([
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message } }),
                yield put({ type: partners.DELETE_PARTNER_SUCCESS, payload: { userId } }),
            ]);
            if (tabName === 'customer_vendors') {
                yield put({ type: customer.GET_CUSTOMER_VENDORS_LIST_REQUEST, payload: { customer_id: customerId } });
            } else {
                yield put({ type: partners.REFRESH_PARTNER_FILTER_REQUEST });
            }
        } else {
            yield all([
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({ type: partners.DELETE_PARTNER_FAILURE, payload: { message: 'Oops Something went wrong' } }),
                yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } }),
            ]);
        }
    } catch (error) {
        yield put({ type: partners.DELETE_PARTNER_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* verifyPartnerDetails(action) {
    const { spId, bankId, data, role, formName } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(api.SP.verifyPayments, {}, { spId, bankId }, {}, data);
        let message, status;

        switch (response.data.code) {
            case 201:
                status = 'success';
                message = response.data.data.message;
                break;
            case 400:
                status = 'danger';
                message = response.data.message;
                break;
            default:
                if (response.data.hasOwnProperty('action')) {
                    status = 'danger';
                    message = response.data.action[0];
                } else {
                    status = 'danger';
                    message = `Something Went Wrong`;
                }
                break;
        }

        yield all([yield put({ type: modal.CLOSE_MODAL }), yield put({ type: toast.SEND_TOAST, payload: { status: status, message: message } })]);

        if (response.data.code === 201) {
            yield put({ type: partners.REFRESH_PARTNER_FILTER_REQUEST, payload: { role } });
        }
    } catch (error) {
        yield put({ type: partners.VERIFY_PARTNER_DETAILS_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* sendAndroidAppLink(action) {
    const { formValues } = action.payload;
    try {
        const response = yield call(api.invitations.sendAndroidAppLink, {}, {}, {}, formValues);
        if (response.data.code === 200) {
            if (typeof response.data.data === 'string') {
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: response.data.data } });
            }
            yield put({ type: partners.SEND_ANDROID_APP_LINK_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: partners.SEND_ANDROID_APP_LINK_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: partners.SEND_ANDROID_APP_LINK_FAILURE, payload: { message: error } });
    }
}

export function* getIwanDropdown(action) {
    try {
        yield put({ type: loading.PARTNERS_DROPDOWN_LIST_LOADING, payload: true });
        const partnerList = yield call(api.invitations.getUsersList, {}, {}, { user_role: [6], status: 0, type: 'SCM' });
        const scmList = yield call(api.invitations.getUsersList, {}, {}, { user_role: [6, 12], status: 0, type: 'CLUSTER' });
        if (partnerList.data.code === 200 || scmList.data.code === 200) {
            let users = [];
            if (Array.isArray(partnerList.data.data.user_list) && Array.isArray(scmList.data.data.user_list)) {
                users = [...partnerList.data.data.user_list, ...scmList.data.data.user_list];
            }
            users = users.map((user) => {
                return {
                    key: user.id,
                    value: user.id,
                    label: `${user?.first_name} ${user?.last_name} (${user?.mobile})`,
                    type: user?.type,
                    role: user?.role,
                };
            });
            yield put({ type: partners.IWAN_DROPDOWN_SUCCESS, payload: users });
        } else {
            yield put({ type: partners.IWAN_DROPDOWN_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: partners.IWAN_DROPDOWN_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.PARTNERS_DROPDOWN_LIST_LOADING, payload: false });
}



export function* giveOrRemoveHelpAccess(action) {
    const { userId, access, formName } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(microservices.user.removeOrGiveHelpAccess, {}, {}, {}, { userId, access });
        if (response.data.code === 200) {
            let message = response.data.data.message ? response.data.data.message : `${access ? 'Given Help Access' : 'Removed Help Access'}`;
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message } });
            yield put({ type: modal.CLOSE_MODAL });
            yield put({ type: partners.REFRESH_PARTNER_FILTER_REQUEST });
        } else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message ? response.data.data.message : 'Something went wrong' } });
            yield put({ type: partners.REMOVE_OR_GIVE_HELP_ACCESS_FAILURE, payload: response.data.data ? response.data.data : 'Oops Something went wrong' });
        }
    } catch (error) {
        yield put({ type: partners.REMOVE_OR_GIVE_HELP_ACCESS_FAILURE, payload: error });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });

}


export function* getPartnerDetails(action) {
    const { userIds, roles = [], status, execution_date, accepted_ticket_count, forSp } = action.payload;
    const payload = { userIds };
    if (typeof roles === 'string') payload.roles = roles.split(',');
    if (Array.isArray(status) && status.length > 0) payload.status = status;
    if (execution_date) payload.execution_date = execution_date;
    if (accepted_ticket_count) payload.accepted_ticket_count = accepted_ticket_count;

    try {
        yield put({ type: loading.PARTNERS_LIST_DETAILS_LOADING, payload: true });
        const response = yield call(microservices.user.getUserListDetails, {}, {}, {}, payload);
        if (response.data.code === 200) {
            if (forSp) {
                yield put({ type: sp.GET_PARTNER_DETAILS_LIST_SUCCESS_SP, payload: { accepted_ticket_count: response.data.data?.accepted_ticket_count, execution_date } });
            }
            yield put({ type: partners.GET_PARTNER_DETAILS_LIST_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: partners.GET_PARTNER_DETAILS_LIST_FAILURE, payload: 'Something went wrong' });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message || 'Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: partners.GET_PARTNER_DETAILS_LIST_FAILURE, payload: error });
    }
    yield put({ type: loading.PARTNERS_LIST_DETAILS_LOADING, payload: false });
}

export function* getPartnerList(action) {
    const { roles, status = [], limit, page, customer_id, tab } = action.payload;
    const callDetails = tab === allUserTab.path ? false : true
    const queryParams = {
        roles,
        status,
        limit,
        page,
        customer_id,
        count: true
    }
    try {
        yield put({ type: loading.PARTNERS_LIST_LOADING, payload: true });
        const response = yield call(microservices.user.getUserList, {}, {}, queryParams, {});
        if (response.data.code === 200) {
            if (callDetails) {
                const userIds = response?.data?.data?.map((user) => user.id);
                if (Array.isArray(userIds) && userIds.length > 0) {
                    yield put({ type: partners.GET_PARTNER_DETAILS_LIST_REQUEST, payload: { userIds, roles, status } });
                }
            }
            yield put({ type: partners.GET_PARTNER_LIST_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: partners.GET_PARTNER_LIST_FAILURE, payload: 'Something went wrong' });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message || 'Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: partners.GET_PARTNER_LIST_FAILURE, payload: error });
    }
    yield put({ type: loading.PARTNERS_LIST_LOADING, payload: false });
}

export function* getPartnerCount(action) {
    try {
        yield put({ type: loading.PARTNERS_COUNT_LOADING, payload: true });
        const response = yield call(microservices.user.usersCount, {}, {}, {}, {});
        if (response.data.code === 200) {
            yield put({ type: partners.GET_PARTNER_COUNT_SUCCESS, payload: response.data.data });
        }
    } catch (error) {
        yield put({ type: partners.GET_PARTNER_COUNT_FAILURE, payload: error });
    }
    yield put({ type: loading.PARTNERS_COUNT_LOADING, payload: false });
}


export function* getFilteredPartnerList(action) {
    let {
        module = 'USERS',
        roles,
        status,
        customerId,
        count = true,
        limit,
        page,
        payload,
        query,
        tab,
    } = action.payload;
    const callDetails = tab === allUserTab.path ? false : true
    try {
        let queryParams = {
            module,
            roles,
            limit,
            page,
            count,
            status
        };

        if (!['', 'all', undefined, null, 0].includes(customerId)) queryParams.customerId = customerId;
        if (!['', undefined, null].includes(query)) queryParams.query = query;
        yield put({ type: loading.PARTNERS_LIST_LOADING, payload: true });
        const response = yield call(lambdas.utils.filterModule, {}, {}, queryParams, payload);

        if (response.data.code === 200) {
            yield put({ type: partners.GET_FILTERED_PARTNER_LIST_SUCCESS, payload: { users: response.data.data, count: response.data.count, tab } });
            if (callDetails) {
                const userIds = response?.data?.data?.map((user) => user.id);
                if (Array.isArray(userIds) && userIds.length > 0) {
                    yield put({ type: partners.GET_PARTNER_DETAILS_LIST_REQUEST, payload: { userIds, roles, status } });
                }
            }
        } else if ([400, 500].includes(response.data.code)) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
            yield put({ type: partners.GET_FILTERED_PARTNER_LIST_FAILURE, payload: response.data.data.message });
        }
    } catch (err) {
        yield put({ type: partners.GET_FILTERED_PARTNER_LIST_FAILURE, payload: 'Something went wrong' });
    }
    yield put({ type: loading.PARTNERS_LIST_LOADING, payload: false });
}

export function* getPartnerFilterList(action) {
    const { status, roles } = action.payload;
    let queryParams = { module: 'USERS' };
    if (status) queryParams.status = status;
    if (roles) queryParams.roles = roles;

    try {
        yield put({ type: loading.PARTNERS_LIST_COUNT_LOADING, payload: true });
        const response = yield call(lambdas.user.userFilterList, {}, {}, queryParams, {});
        if (response.data.code === 200) {
            yield put({ type: partners.GET_PARTNER_FILTER_SUCCESS, payload: response.data.data });
        }
    } catch (error) {
        yield put({ type: partners.GET_PARTNER_FILTER_FAILURE, payload: error });
    }
    yield put({ type: loading.PARTNERS_LIST_COUNT_LOADING, payload: false });
}

export function* refreshTeamTable(action) {
    const { limit, page, filterPayload, query, tab, roles, status } = yield select(getPartnerFilterDetails);
    const loggedUser = yield select(getLoggedUser);
    const customerId = getCustomerIdOfLoginUser(loggedUser);
    let isFilterActive = filterPayload && Object.keys(filterPayload).length > 0;
    try {
        if (isFilterActive || query?.length > 0) {
            const payload = {
                customerId,
                limit,
                page,
                payload: filterPayload,
                query,
                roles,
                status,
                tab
            }
            yield put({ type: partners.GET_FILTERED_PARTNER_LIST_REQUEST, payload: payload });
        } else {
            const payload = {
                roles, status, limit, page, customer_id: customerId, tab
            }
            yield put({ type: partners.GET_PARTNER_LIST_REQUEST, payload: payload });

        }
    } catch (err) {
        yield put({ type: partners.REFRESH_PARTNER_FILTER_FAILURE, payload: err });
    }
}


export function* getDevelopersList(action) {
    try {
        yield put({ type: loading.SET_USER_LIST_LOADING, payload: true });
        const developersList = yield call(api.invitations.getUsersList, {}, {}, { user_role: SCM.role, type: 'DEVELOPER', status: 0 })

        if (developersList.data.code === 200) {

            yield put({ type: partners.GET_DEVELOPERS_LIST_SUCCESS, payload: developersList.data.data.user_list })
        } else {
            yield put({ type: partners.GET_DEVELOPERS_LIST_FAILURE, payload: { message: 'Oops Something went wrong' } })
        }
    }
    catch (error) {
        yield put({ type: partners.GET_DEVELOPERS_LIST_FAILURE, payload: { message: error } })

    }
    yield put({ type: loading.SET_USER_LIST_LOADING, payload: false })
}

export function* getGroupUserList(action) {
    const { roles, status = [], limit = 50, page = 1, customer_id, append } = action.payload;
    const queryParams = {
        roles,
        status,
        limit,
        page,
        customer_id,
    }
    try {
        if (append) {
            yield put({ type: loading.SET_NEXT_USER_LIST_LOADING, payload: true });
        } else {
            yield put({ type: loading.SET_USER_LIST_LOADING, payload: true });
        }
        const response = yield call(microservices.user.getUserList, {}, {}, queryParams, {});
        if (response.data.code === 200) {
            yield put({ type: partners.GET_GROUP_USER_LIST_SUCCESS, payload: { data: response.data.data, append } });
        } else {
            yield put({ type: partners.GET_GROUP_USER_LIST_FAILURE, payload: 'Something went wrong' });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message || 'Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: partners.GET_GROUP_USER_LIST_FAILURE, payload: error });
    }
    if (append) {
        yield put({ type: loading.SET_NEXT_USER_LIST_LOADING, payload: false });
    } else {
        yield put({ type: loading.SET_USER_LIST_LOADING, payload: false });
    }
}


export function* getFilteresUserList(action) {
    let {
        module = 'USERS',
        roles,
        status,
        limit,
        page,
        payload,
        query,
        append
    } = action.payload;
    try {
        let queryParams = {
            module,
            roles,
            limit,
            page,
            status
        };

        if (!['', undefined, null].includes(query)) queryParams.query = query;
        if (append) {
            yield put({ type: loading.SET_NEXT_USER_LIST_LOADING, payload: true });
        } else {
            yield put({ type: loading.SET_USER_LIST_LOADING, payload: true });

        }
        const response = yield call(lambdas.utils.filterModule, {}, {}, queryParams, payload);


        if (response.data.code === 200) {
            yield put({ type: partners.GET_FILTERED_GROUP_USER_LIST_SUCCESS, payload: { data: response.data.data, append } });
        } else if ([400, 500].includes(response.data.code)) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
            yield put({ type: partners.GET_FILTERED_GROUP_USER_LIST_FAILURE, payload: response.data.data.message });
        }
    } catch (err) {
        yield put({ type: partners.GET_FILTERED_GROUP_USER_LIST_FAILURE, payload: 'Something went wrong' });
    }
    if (append) {
        yield put({ type: loading.SET_NEXT_USER_LIST_LOADING, payload: false });
    } else {
        yield put({ type: loading.SET_USER_LIST_LOADING, payload: false });
    }
}


export function* uploadAddressPincodeFile(action) {
    const { file, formName, area } = action.payload;

    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const filesInRequestBody = {
            fileName: file.name,
            mimeType: file.type,
        };
        const response = yield call(lambdas.serviceability.uploadFile, {}, {}, {}, { reason: "SP_AND_TICKET_COUNT", files: [filesInRequestBody] });

        if (response.status === 200) {
            yield call(uploadFileToS3WithSignedUrl, response.data.data[0].signedUrl, file);
            yield put({ type: partners.GET_AREA_COUNT_REQUEST, payload: { fileUrl: response.data.data?.[0]?.fileUrl, area } });
        } else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message ? response.data.data.message : 'Something Went wrong' } })
        }
    } catch (error) {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* getAreaCount(action) {
    const { fileUrl, area } = action.payload;
    const payload = {}

    if (fileUrl) payload.fileUrl = fileUrl;
    else payload.area = area

    try {
        yield put({ type: loading.AREA_COUNT_LOADING, payload: true });
        const response = yield call(lambdas.serviceability.splist, {}, {}, {}, payload);
        if (response.data.code === 200) {
            yield put({ type: partners.GET_AREA_COUNT_SUCCESS, payload: response.data.data.count })
        } else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: fileUrl.data.data.message ? fileUrl.data.data.message : 'Something Went wrong' } })
        }
    } catch (error) {
        yield put({ type: partners.GET_AREA_COUNT_FAILURE, payload: { message: error } });

        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
    }
    yield put({ type: loading.AREA_COUNT_LOADING, payload: false });
}

export function* generateAffiliateLink(action) {
    const { userId, formName, invokeFrom } = action.payload;
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    try {
        const response = yield call(lambdas.user.generateAffiliateLink, {}, { userId }, {}, {});
        if (response.data.code === 200 && response?.data?.data) {
            const { userId, affiliate_id, store_ref_code, store_affiliate_link } = response?.data?.data;
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: "Affiliate Link Generated" } });
            if (invokeFrom === 'teams') {
                yield put({ type: partners.GENERATE_AFFILIATE_LINK_SUCCESS, payload: { userId, affiliate_id, store_ref_code, store_affiliate_link } });
            }
            else if (invokeFrom === 'userProfile') {
                yield put({ type: auth.GENERATE_AFFILIATE_LINK_SUCCESS_LOGGED_USER, payload: { userId, affiliate_id, store_ref_code, store_affiliate_link } });
            }
        }
        else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response?.data?.data?.message || "Something Went Wrong" } });
        }
    } catch (error) {
        console.log(error);
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: error.message } });
    }
    yield put({ type: modal.CLOSE_MODAL });
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}


export default function* partnersSaga() {
    yield takeLatest(partners.PARTNERS_LIST_REQUEST, getPartnersList);
    yield takeLatest(partners.PARTNERS_LIST_DROPDOWN_REQUEST, getPartnersListDropDown);
    yield takeLatest(partners.IWAN_DROPDOWN_REQUEST, getIwanDropdown);
    yield takeLatest(partners.DELETE_PARTNER_REQUEST, deletePartner);
    yield takeLatest(partners.VERIFY_PARTNER_DETAILS_REQUEST, verifyPartnerDetails);
    yield takeLatest(partners.SEND_ANDROID_APP_LINK_REQUEST, sendAndroidAppLink);
    yield takeLatest(partners.GROUP_USERS_LIST_REQUEST, getGroupsUsersListDropdown);
    yield takeLatest(partners.REMOVE_OR_GIVE_HELP_ACCESS_REQUEST, giveOrRemoveHelpAccess);
    yield takeLatest(partners.GET_PARTNER_LIST_REQUEST, getPartnerList);
    yield takeLatest(partners.GET_PARTNER_DETAILS_LIST_REQUEST, getPartnerDetails);
    yield takeLatest(partners.GET_PARTNER_COUNT_REQUEST, getPartnerCount);
    yield takeLatest(partners.GET_FILTERED_PARTNER_LIST_REQUEST, getFilteredPartnerList);
    yield takeLatest(partners.GET_PARTNER_FILTER_REQUEST, getPartnerFilterList);
    yield takeLatest(partners.REFRESH_PARTNER_FILTER_REQUEST, refreshTeamTable);

    yield takeLatest(partners.GET_DEVELOPERS_LIST_REQUEST, getDevelopersList)
    yield takeLatest(partners.GET_GROUP_USER_LIST_REQUEST, getGroupUserList)

    yield takeLatest(partners.GET_FILTERED_GROUP_USER_LIST_REQUEST, getFilteresUserList)
    yield takeLatest(partners.UPLOAD_ADDRESS_PINCODE_FILE_REQUEST, uploadAddressPincodeFile);
    yield takeLatest(partners.GET_AREA_COUNT_REQUEST, getAreaCount);
    yield takeLatest(partners.GENERATE_AFFILIATE_LINK, generateAffiliateLink)
}

import moment from 'moment';
import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFieldEngineersList } from '../../../../actions/fe';
import { closeModalAction } from '../../../../actions/modal';
import { clearEligibleSpDropdownListFromStore, getEligibleSPsList } from '../../../../actions/sp';
import { acceptTicket, ticketAssignToScogo, getExecutionDateChangeRemarks, bulkTicketAssignToScogo } from '../../../../actions/ticketAssignment';
import { assignSPManually, broadcastTicketManually, reAssignSPManually } from '../../../../actions/tickets';
import { actualValueForDropdown, validateStringValue, getFirstAndLastNameFromFullName, validateDateValue, validateNumberValue, filterExecutionDate } from '../../../../utils/common';
import { isCustomer, isScm, isSp, isCustomerAdmin, isCustomerUser, isCluster, isSuperVisor, isPsp } from '../../../../utils/role';
import { getIsshowReleaseTicketButton, isEligibleForEditExecutionDate } from '../../TicketController';
import { sendToastMessage } from '../../../../actions/toast';
import { RelaseButton } from './ReleaseButton';
import { clearFieldEngineersFromStore } from '../../../../actions/users';
import Form from '../../../../common/Form';
import Input from '../../../../common/Form/Input';
import Select from '../../../../common/Form/Select';
import DatePicker from '../../../../common/Form/DatePicker';
import { useForm, useFormContext } from 'react-hook-form';
import { FieldEngineerTable } from './FieldEngineerTable';
import ButtonScogoPrimary from '../../../../common/Buttons/ButtonScogoPrimary';
import ButtonScogoClosedOutlined from '../../../../common/Buttons/ButtonScogoClosedOutlined';
import GridTable from '../../../../common/GridTable';
import InputFiels from '../../../../common/InputFiels';
import IconToolTip from '../../../../common/IconToolTip';
import Checkbox from '../../../../common/Checkbox';
import FormCheckbox from '../../../../common/Form/Checkbox';
import OverflowTip from '../../../../common/OverflowTip';
import { getPartnerDetails } from '../../../../actions/partners';
import { isChangePlanAllowed } from '../../../../utils/access';

const formName = 'ticket_assign_sp';
const triggerToAll = 'triggerToAll';
export const transactional = 'transactional';
const assignToScogoValue = -1;

const actionProperty = {
    reassign: 'REASSIGN',
    accept: 'ACCEPT',
    transactional: 'TRANSACTIONAL',
    broadcast: 'BROADCAST',
    assign: 'ASSIGN',
};

export default function AssignSP({ ticket, isReassign, isBulk, ticketIds, callViewTicket }) {
    const ticketId = ticket.ticket_id;
    const defaultValues = { sp: '', fe: '', execution_date: '', ticket_instruction: '' };
    const dispatch = useDispatch();
    const { loggedUser } = useSelector((state) => state.auth);
    const { eligibleSPsDropdown } = useSelector((state) => state.sp);
    const { isSpDropdownListLoading, isPartnerListDetailsLoading } = useSelector((state) => state.loading);
    const role = loggedUser.role.id;
    const type = loggedUser.type;
    const loggedUserId = loggedUser.id
    const [spOptions, setSpOptions] = useState([]);
    const { fieldEngineers } = useSelector((state) => state.user);
    const { formButtonLoading } = useSelector((state) => state.loading);
    const isCustomerGroup = isCustomer(role, type) || isCustomerAdmin(role) || isCustomerUser(role);
    const submitRef = useRef(null);
    const is_service_partner = isSp(role, type);
    const [checkedTracker, setCheckedTracker] = useState({});

    useEffect(() => {
        if (!is_service_partner) dispatch(getEligibleSPsList({
            ticketId, dropdown: true, getdetails: true, execution_date: moment(ticket?.execution_date ? ticket?.execution_date : new Date()).format("YYYY-MM-DD HH:mm:ss")
        }));
        dispatch(getExecutionDateChangeRemarks());
        return () => dispatch(clearEligibleSpDropdownListFromStore());
    }, [dispatch, ticketId, is_service_partner]);

    useEffect(() => {
        if (!isCustomerGroup) {
            dispatch(getFieldEngineersList({ servicePartnerId: loggedUser.service_partner_id, }));
        }
        return () => dispatch(clearFieldEngineersFromStore());
    }, [loggedUser.service_partner_id, dispatch, isCustomerGroup]);

    useEffect(() => {
        if (Array.isArray(eligibleSPsDropdown)) {
            let mapIcon = { icon_name: 'place', color: 'scogoToastSuccess' };
            let activeIcon = { icon_name: 'lens', color: 'scogoToastSuccess' };
            let additionalList = [];
            if (!isCustomerGroup) {
                additionalList.push({ sp_full_name: 'All (Broadcast in your network)', value: triggerToAll, icons: [mapIcon, activeIcon] });
            }
            if (ticket.ticket_owned_by === 1 && isCustomerGroup) {
                additionalList.unshift({ sp_full_name: 'Assign To SCOGO', value: assignToScogoValue, icons: [mapIcon, activeIcon] });
            }
            if (isScm(role, type) || isCluster(role, type) || isSuperVisor(role, type) || isPsp(role, type) || isCustomerGroup) {
                additionalList.push({
                    dateOrCount: eligibleSPsDropdown.filter(e => e?.user?.id === loggedUserId)?.[0]?.dateOrCount,
                    sp_full_name: `${validateStringValue(loggedUser.first_name)} ${validateStringValue(loggedUser.last_name)} (Assign To Me)`,
                    value: loggedUser.id,
                    icons: [mapIcon, activeIcon],
                    user: {
                        id: loggedUser.id
                    }
                });
            }

            let eligibleSPs = eligibleSPsDropdown.filter(e => e.sp_full_name?.length > 0)
            setSpOptions([...additionalList, ...eligibleSPs]);
        }
    }, [eligibleSPsDropdown, role, type, loggedUser.id, ticket.assignToScogo, ticket.ticket_owned_by, loggedUser.last_name, loggedUser.first_name, isCustomerGroup, loggedUserId]);



    const callAcceptTicket = (formValues, isReassign) => {
        let tickets = isBulk ? ticketIds : [ticketId];
        let payload = {
            ticket_instruction: formValues.ticket_instruction,
            tickets,
            spId: checkedTracker?.value,
            feId: actualValueForDropdown(formValues.fe),
            property: isReassign ? actionProperty.reassign : actionProperty.accept,
            update_planned_date: formValues.update_planned_date,
        };
        if (formValues.execution_date) {
            payload.execution_date = moment(formValues.execution_date).format('YYYY-MM-DD HH:mm:ss');
        }
        if (formValues.execution_date_remark) {
            payload.execution_date_remark = actualValueForDropdown(formValues.execution_date_remark);
        }
        if (actualValueForDropdown(formValues.fe) === transactional) {
            let { first_name, last_name } = getFirstAndLastNameFromFullName(formValues.feName);
            payload.property = actionProperty.transactional;
            payload.transactionalFe = { first_name, last_name, mobile: formValues.feMobile };
            delete payload.feId;
        }
        dispatch(acceptTicket({ data: payload, formName, callViewTicket }));
    };

    const callReAssign = (formValues) => {
        let execution_date = '';
        if (formValues.execution_date) {
            execution_date = moment(formValues.execution_date).format('DD-MM-YYYY HH:mm:ss');
        }
        let payload = {
            execution_date, ticket_instruction: formValues.ticket_instruction, ticketList: [ticketId], sp: checkedTracker?.value, formName, callViewTicket, update_planned_date: formValues.update_planned_date,
        };
        dispatch(reAssignSPManually(payload));
    };

    const callTriggerToAll = (formValues) => {
        let execution_date = '';
        if (formValues.execution_date) {
            execution_date = moment(formValues.execution_date).format('DD-MM-YYYY HH:mm:ss');
        }
        let tickets = isBulk ? ticketIds : [ticketId];
        let payload = {
            execution_date,
            ticket_instruction: formValues.ticket_instruction,
            tickets,
            property: actionProperty.broadcast,
            update_planned_date: formValues.update_planned_date,
        };
        dispatch(broadcastTicketManually({ data: payload, formName, callViewTicket }));
    };


    const submit = (formValues) => {
        const isAssignToMe = checkedTracker?.value === loggedUser.id || isSp(role, type);
        let update_planned_date = formValues.update_planned_date ? 1 : 0;
        formValues.update_planned_date = update_planned_date;
        if (validateDateValue(formValues.execution_date)) {
            formValues.execution_date = new Date(moment(formValues.execution_date).subtract({ hours: 5.5 }));
        }
        if (!formValues.fe && isSp(role, type)) {
            dispatch(sendToastMessage({ message: 'Select FE', status: 'danger' }));
            return;
        }
        let execution_date = '';
        if (formValues.execution_date) {
            execution_date = moment(formValues.execution_date).format('DD-MM-YYYY HH:mm:ss');
        }

        const isTriggerToAll = checkedTracker?.value === triggerToAll;

        if (checkedTracker?.value === assignToScogoValue) {
            let payload = {
                execution_date,
                ticket_instruction: formValues.ticket_instruction,
            };
            if (isBulk) {
                payload.ticketIds = ticketIds;
                payload.formName = formName;
                dispatch(bulkTicketAssignToScogo(payload));
            } else {
                payload.ticket_id = ticketId;
                dispatch(ticketAssignToScogo({ payload, formName }));
            }

        } else if ((isReassign && isTriggerToAll) || isTriggerToAll) {
            callTriggerToAll(formValues);
        } else if (isReassign && isAssignToMe) {
            callAcceptTicket(formValues, isReassign);
        } else if (isReassign) {
            callReAssign(formValues);
        } else if (isAssignToMe) {
            callAcceptTicket(formValues);
        } else {
            let tickets = isBulk ? ticketIds : [ticketId];

            let payload = {
                execution_date,
                ticket_instruction: formValues.ticket_instruction,
                tickets: tickets,
                property: actionProperty.assign,
                sp: checkedTracker?.value,
                update_planned_date: formValues.update_planned_date,
            };
            dispatch(assignSPManually({ data: payload, formName, callViewTicket }));
        }
    };


    const showReleaseButton = getIsshowReleaseTicketButton({ role, type, ticket, loggedUserId });
    const methods = useForm({ defaultValues, mode: 'all' });

    return (
        <div className=''>
            <Form methods={methods} onSubmit={submit} formName={formName} submitRef={submitRef}>
                <AssignSpInputs spOptions={spOptions} isSpDropdownListLoading={isSpDropdownListLoading || isPartnerListDetailsLoading} fieldEngineers={fieldEngineers} isReassign={isReassign} ticket={ticket} checkedTracker={checkedTracker} setCheckedTracker={setCheckedTracker} />
                <FormButtons submitRef={submitRef} ticketId={ticketId} isLoading={formButtonLoading[formName]} showReleaseButton={showReleaseButton} />
            </Form>
        </div>
    );
}

const FormButtons = ({ isLoading, submitRef, showReleaseButton, ticketId }) => {
    const dispatch = useDispatch();
    return (
        <div className='flex justify-between items-center px-4 py-4'>
            <div className='px-2'>{showReleaseButton && <RelaseButton ticketId={ticketId} />}</div>
            <div className='flex gap-2'>
                <ButtonScogoPrimary
                    textOrComponent={'Submit'}
                    loading={isLoading}
                    onClick={(e) => {
                        e.preventDefault();
                        submitRef.current.click();
                    }}
                    type={"button"}
                />
                <ButtonScogoClosedOutlined textOrComponent='Cancel' type='button' onClick={() => dispatch(closeModalAction())} />
            </div>
        </div>
    );
};

const AssignSpInputs = ({ spOptions, isSpDropdownListLoading, fieldEngineers, isReassign, ticket, checkedTracker, setCheckedTracker }) => {
    const { loggedUser } = useSelector((state) => state.auth);
    const { remarks } = useSelector((state) => state.tickets);
    const role = loggedUser.role.id;
    const type = loggedUser.type;
    const [assignNew, setAssignNew] = useState(false);
    const [seachQuery, setSeachQuery] = useState("")
    const [list, setList] = useState(spOptions)
    const [ticketExecutionDate, setTicketExecutionDate] = useState(ticket?.current_execution_date)
    const dispatch = useDispatch();

    useEffect(() => {
        if (checkedTracker?.value) {
            setList(
                spOptions.filter(e => {
                    return e?.user?.id === checkedTracker?.value
                })
            )
        } else {
            setList(spOptions)
        }
    }, [spOptions])

    const handleOnChangeCheckbox = (data) => {
        if (checkedTracker?.value) {
            setCheckedTracker({});
            setList(spOptions)
        } else {
            setCheckedTracker({ value: data.value });
            setList([data])
        }
    };
    useEffect(() => {
        if (validateDateValue(ticket?.current_execution_date)) {
            setTicketExecutionDate(new Date(ticket.current_execution_date))
        } else {
            setTicketExecutionDate(new Date())
        }
    }, [ticket?.current_execution_date]);

    const { setValue, watch } = useFormContext();

    const execution_date = watch('execution_date');
    useEffect(() => {
        if (validateDateValue(ticket?.current_execution_date)) {
            setValue('execution_date', new Date(ticket.current_execution_date));
            setTicketExecutionDate(new Date(ticket.current_execution_date))
        }
    }, [ticket?.current_execution_date, setValue]);

    useEffect(() => {
        if ((execution_date && ticketExecutionDate !== ticket?.current_execution_date) && checkedTracker?.value !== triggerToAll && Array.isArray(spOptions)) {
            let options = spOptions
            options.shift()
            let userIds = options?.map(e => {
                return e?.user?.id
            })
            if (userIds?.length > 0) {
                dispatch(getPartnerDetails({
                    ticketId: ticket.ticket_id, dropdown: true, getdetails: true, execution_date: moment(execution_date).format("YYYY-MM-DD HH:mm:ss"), userIds,
                    accepted_ticket_count: true, forSp: true
                }));
            }
            setTicketExecutionDate(execution_date)
        }
    }, [execution_date])

    const isAssignToMe = checkedTracker.value === loggedUser.id || isSp(role, type);
    const executionDateRequired = (isReassign && isAssignToMe) || isAssignToMe;

    const renderOption = (spData) => {
        const { value, icons, employee_type, sp_full_name } = spData
        const showGreenName = (isScm(role, type) || isCluster(role, type)) && employee_type === 'EMP';

        return (
            <>
                <div div className='flex gap-2 items-center' >
                    <label className='flex items-center gap-3'>
                        <Checkbox
                            checkColor='text-scogoorange mr-2'
                            checked={checkedTracker.value === value}
                            onChange={() => {
                                handleOnChangeCheckbox(spData);
                            }}
                            dynamicSize={'1.2rem'}
                        />
                        <p className={`font-normal text-font12 ${showGreenName ? 'text-scogosuccess ' : ''} w-[95%] truncate`}>
                            <OverflowTip someLongText={sp_full_name} />

                            <p className='flex items-center gap-2' >
                                <span className='flex items-center'>
                                    {icons?.leftSide &&
                                        icons.leftSide.map((icon) => {
                                            return <span className={`text-lg material-icons text-${icon.color}`}>{icon.icon_name}</span>;
                                        })}
                                </span>
                                <span className='flex items-center gap-2'>
                                    {icons?.rightSide &&
                                        icons.rightSide.map((icon) => {
                                            return <span className={`text-sm text-${icon.color}`}>{icon.text}</span>;
                                        })}
                                </span>
                            </p>
                        </p>
                    </label>
                </div >
            </>
        );
    };

    useEffect(() => {
        if (seachQuery && !checkedTracker.value) {
            let searchData = spOptions?.filter(item => {

                if (item?.sp_full_name?.toUpperCase()?.includes(seachQuery) || item.sp_full_name?.toLowerCase()?.includes(seachQuery)) {
                    return true
                } else if (item?.user?.mobile?.includes(seachQuery)) {
                    return true
                }
                return false
            })
            setList(searchData)
        }
        else if (!checkedTracker.value) {
            setList(spOptions)
        }
    }, [seachQuery])

    const handleSelectfe = (fk_user_id) => {
        setValue('fe', fk_user_id);
    };


    const isExecutionDateReadOnly = validateDateValue(ticket.current_execution_date) && !isEligibleForEditExecutionDate({
        signOffAccept: ticket.signOffAccept, signOffRequest: ticket.signOffRequest, ticket_owned_by: ticket.ticket_owned_by, assignToScogo: ticket.assignToScogo,
        role, type, closedByNoc: ticket.closedByNoc, accepted_sp_id: ticket.accepted_sp_id, sign_off_lock: ticket.sign_off_lock
    });
    const isDateChanged = execution_date?.getTime?.() !== (new Date(ticket.current_execution_date)).getTime();
    let showCommentField = isDateChanged && !isExecutionDateReadOnly && ticket.current_execution_date

    const showUpdatePlannedDate = isDateChanged && isChangePlanAllowed(loggedUser.frontend_controller);

    return (
        <>
            <div className='px-3'>
                <div className='flex py-1 '>
                    <DatePicker
                        padding='px-2 py-2'
                        label='Execution Date & Time'
                        name='execution_date'
                        showTimeSelect
                        required={executionDateRequired}
                        className='w-6/12'
                        minDate={new Date()}
                        readOnly={isExecutionDateReadOnly}
                        isClearable={false}
                        filterTime={(date) => filterExecutionDate(date)}
                    />
                    {showCommentField && <Select label='Remark' className='w-6/12' name='execution_date_remark' options={remarks} required />}

                    {!isSp(role, type) && <Input className='w-6/12' label='Ticket Instruction' name='ticket_instruction' />}
                </div>
                {showUpdatePlannedDate && isScm(role, type) && <div>
                    <FormCheckbox label='Update Planned Date' id={'update_planned_date'} name='update_planned_date' />
                </div>}
                {!isSp(role, type) && (
                    <>
                        <div className='relative w-full h-16 shadow-sm py-2  border-b border-gray-200 bg-white' >
                            <InputFiels
                                type='text'
                                inputImg={process.env.PUBLIC_URL + '/img/search.png'}
                                inputClass={' loginput truncate text-xl flex items-center '}
                                placeholder={"Search"}
                                inputImgDiv='loginput-imgdiv top-3 left-4 h-6 w-6 mr-4 items-center '
                                inputDiv='text-gray-400 focus-within:text-gray-600 relative  flex  w-[40%] ml-auto '
                                value={seachQuery}
                                onChange={(event) => setSeachQuery(event.target.value.trimStart())}
                            />
                            {seachQuery?.length > 0 && <div className='absolute right-0 top-[25%]' >
                                <IconToolTip title="Cancel" onClick={() => {
                                    setSeachQuery('')
                                }}>
                                    <span className='text-scogoprimary hover:text-scogoorange cursor-pointer material-icons text-font18 mr-4'  >
                                        cancel
                                    </span>
                                </IconToolTip>
                            </div>
                            }

                        </div>
                        <ListSpTable execution_date={ticketExecutionDate} list={list} renderOption={renderOption} isSpDropdownListLoading={isSpDropdownListLoading} />
                    </>
                )}


            </div>
            {isAssignToMe &&
                <div className='mt-4' >
                    <FieldEngineerTable fieldEngineers={fieldEngineers} handleSelectfe={handleSelectfe} assignNew={assignNew} setAssignNew={setAssignNew} />
                </div>}
        </>
    );
};



const ListSpTable = ({ list, renderOption, isSpDropdownListLoading, execution_date }) => {
    const tableSchema = {
        table: [
            {
                head: {
                    headName: 'Name',
                    render: (headName) => headName,
                    width: 3,
                },

                body: {
                    render: (item) => {
                        return (
                            <>
                                {renderOption(item)}
                            </>
                        );
                    },
                },
            },
            {
                head: {
                    headName: 'Phone',
                    render: (headName) => headName,
                    width: 1,
                },
                body: {
                    render: (item) => {
                        return <p className='font-normal text-font11'>{item?.user?.mobile}</p>;
                    },
                },
            },

            {
                head: {
                    headName: moment(execution_date).format("DD-MMM-YY"),
                    render: (headName) => headName,
                    width: 1,
                },
                body: {
                    render: (item) => {
                        return <RenderDate sp_as_fe_count={item?.dateOrCount?.first_execution?.sp_as_fe_count} totalCount={item?.dateOrCount?.first_execution?.count} />
                    },
                },
            },

            {
                head: {
                    headName: moment(execution_date).add(1, 'days').format("DD-MMM-YY"),
                    render: (headName) => headName,
                    width: 1,
                },
                body: {
                    render: (item) => {
                        return <RenderDate sp_as_fe_count={item?.dateOrCount?.second_execution?.sp_as_fe_count} totalCount={item?.dateOrCount?.second_execution?.count} />
                    },
                },
            },

            {
                head: {
                    headName: moment(execution_date).add(2, 'days').format("DD-MMM-YY"),
                    render: (headName) => headName,
                    width: 1,
                },
                body: {
                    render: (item) => {
                        return <RenderDate sp_as_fe_count={item?.dateOrCount?.third_execution?.sp_as_fe_count} totalCount={item?.dateOrCount?.third_execution?.count} />
                    },
                },
            },

        ],
    };

    return <div className='' >
        <GridTable schema={tableSchema} tableError={list?.length === 0 ? "User Not Found" : ""} rowData={list} isLoading={isSpDropdownListLoading} tableBodyHeight={"max-h-64"} loadingMargin="18px" />
    </div>

}


const RenderDate = ({ sp_as_fe_count, totalCount }) => {
    if (validateNumberValue(totalCount) && validateNumberValue(sp_as_fe_count)) {
        return <p className='font-normal text-font13 pl-4'>
            <IconToolTip title="Accepted as FE / Total Accepted"><span>{sp_as_fe_count}/{totalCount}</span></IconToolTip>
        </p>
    }
    return <></>;
}
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModalAction } from '../actions/modal';
import IconToolTip from './IconToolTip';
import OverflowTip from './OverflowTip';
import { chatType, defaultSingleChatPic, showApprovalOptionsinMedia } from '../pages/chat/ChatController';
import { getFileType, getLookupWitHDefaultValue, modulo, validateStringValue } from '../utils/common';
import moment from 'moment';
import { downloadFiles } from '../actions/utils';
import ImageZoom from './ImageZoom';
import ApprovedRejectIcon from '../pages/chat/components/ApprovedRejectIcon';
import { getImageStyle } from '../pages/chat/messageBox/Messages/AttachmentMessage/Image';
import { getAttachmentWithPartialUrl } from '../actions/conversations';
import { GET_ATTACHMENT_WITH_PARTIAL_URL_SUCCESS } from '../types/conversations';

const MediaView = (props) => {
    let { sender, attachments, openedMediaIndex = 0, showSenderDetail, isTicketChat, isExecuteImage, currentConversationId } = useSelector((state) => state.modal);
    const [viewMediaIndex, setViewMediaIndex] = useState(openedMediaIndex);
    const dispatch = useDispatch();
    const mediaViewRef = useRef(null);
    const onDownloadClick = () => {
        const currentFile = attachments[viewMediaIndex];
        const file = { url: currentFile.fileUrl, fileName: currentFile.fileName, mimeType: currentFile.mimeType };
        dispatch(downloadFiles({ files: [file] }));
    };

    const handleArrowClick = useCallback(
        (arrowDirection) => {
            const increment = arrowDirection === 'right' ? 1 : -1;
            const attachmentsArray = attachments.length;
            const nextIndex = modulo(viewMediaIndex + increment, attachmentsArray);
            setViewMediaIndex(nextIndex);
        },
        [attachments?.length, viewMediaIndex]
    );

    const closeMedia = (event) => {
        if (event.keyCode === 27 && event.key === 'Escape') {
            dispatch(closeModalAction());
        } else if (event.keyCode === 39 && event.key === 'ArrowRight') {
            handleArrowClick('right');
        } else if (event.keyCode === 37 && event.key === 'ArrowLeft') {
            handleArrowClick('left');
        }
    };

    useEffect(() => {
        if (mediaViewRef?.current) {
            mediaViewRef.current.focus();
        }
    }, []);

    return (
        <div ref={mediaViewRef} tabIndex={-1} onKeyDown={closeMedia}>
            <div className='opacity-90 fixed inset-0 z-50 bg-white'></div>
            <div className='overflow-hidden fixed inset-0 z-50 outline-none focus:outline-none'>
                <HeaderComponent onDownloadClick={onDownloadClick} showSenderDetail={showSenderDetail} viewMediaIndex={viewMediaIndex} attachments={attachments} sender={sender} />
                <FilesComponent attachments={attachments} viewMediaIndex={viewMediaIndex} handleArrowClick={handleArrowClick} isTicketChat={isTicketChat} isExecuteImage={isExecuteImage} currentConversationId={currentConversationId} />
                <Footer attachments={attachments} viewMediaIndex={viewMediaIndex} setViewMediaIndex={setViewMediaIndex} />
            </div>
        </div>
    );
};

export default MediaView;

const Footer = ({ attachments, viewMediaIndex, setViewMediaIndex }) => {
    return (
        <div className='h-2/12 relative flex items-center justify-center px-6 bg-white opacity-90'>
            <div className='flex items-center overflow-x-auto gap-3  max-w-10/12 h-8/12 '>
                {attachments.map((item, index) => {
                    const mimeType = item.type;
                    const type = getFileType(mimeType);
                    let elem = <GetComponentByType type={type} url={item.fileUrl} />;

                    return (
                        <div key={item.id} className=' relative ml-1 rounded-md cursor-pointer bordr-2 border-scogoclosed' style={{ 'aspect-ratio': '1 / 1', height: '90%' }}>
                            <div
                                onClick={() => {
                                    setViewMediaIndex(index);
                                }}
                                className={index === viewMediaIndex ? 'border-2 rounded-md border-scogobgsky h-full' : 'h-full'}
                                style={{ 'aspect-ratio': '1 / 1' }}
                            >
                                {elem}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

const HeaderComponent = ({ onDownloadClick, attachments, viewMediaIndex, showSenderDetail, sender }) => {
    const dispatch = useDispatch();
    const { conversationDetails } = useSelector((state) => state.conversations);
    const getSenderDetails = () => {
        if (!sender) {
            sender = attachments?.[viewMediaIndex]?.message?.from;
        }
        const conversation = conversationDetails[attachments[viewMediaIndex].conversation];
        const messageTime = moment(attachments[viewMediaIndex].createdAt).format('DD/MM/YYYY hh:mm A');
        const messageTitle = `${validateStringValue(sender?.username)} @ ${validateStringValue(conversation?.conversationName)}`;
        const senderProfilePic = defaultSingleChatPic;

        return (
            <div className='flex items-center gap-4'>
                <img className='h-16 w-16 rounded-full object-cover' alt='profile' src={senderProfilePic} />
                <div className='flex flex-col'>
                    {messageTitle && <span className='text-2xl text-scogoprimary font-bold'>{messageTitle}</span>}
                    {messageTime && <span className='text-lg text-scogof8 font-medium'>{messageTime}</span>}
                </div>
            </div>
        );
    };
    const currentFileName = validateStringValue(attachments?.[viewMediaIndex]?.fileName);
    return (
        <div className='h-1/12 relative flex items-center justify-between px-6 bg-white'>
            <div className='flex items-center gap-4'>
                {showSenderDetail && getSenderDetails()}
                {!showSenderDetail && <span className='text-2xl text-scogoprimary font-bold'>{currentFileName}</span>}
            </div>
            <div className='flex gap-2 items-center'>
                <IconToolTip title='Download'>
                    <span onClick={onDownloadClick} className='material-icons text-scogoprimary font-medium align-middle text-4xl hover:text-scogoorange cursor-pointer'>
                        download
                    </span>
                </IconToolTip>
                <IconToolTip title='Close'>
                    <span
                        onClick={() => {
                            dispatch(closeModalAction());
                        }}
                        className='material-icons text-scogoprimary font-medium align-middle text-4xl hover:text-scogoorange cursor-pointer'
                    >
                        highlight_off
                    </span>
                </IconToolTip>
            </div>
        </div>
    );
};

export const GetComponentByType = ({ type, url, isSingle, isZoomable = false, className = ' h-11/12 m-auto max-w-full object-contain', onImageClick = () => { } }) => {
    const getImageComponent = (src) => <img onClick={onImageClick} src={process.env.PUBLIC_URL + src} className={`${className} rounded-lg `} alt='' />;
    const lookup = getLookupWitHDefaultValue({
        lookup: {
            image: isZoomable ? <ImageZoom imageUrl={url} /> : <img src={url} className={className} alt='' onClick={onImageClick} />,
            audio: (
                <div className=' h-full w-full '>
                    <img src={process.env.PUBLIC_URL + '/img/audio.png'} className={className} style={isSingle && { height: '80%' }} alt='' />
                    {isSingle && <audio className='w-4/5 m-auto h-1/5' src={url} controls />}
                </div>
            ),
            video: <video className={className} autoPlay loop src={url} muted controls />,
            pdf: <object data={url} aria-label='pdf' className={`${className} w-3/4`} />,
            text: getImageComponent('/img/txt-file.png'),
            xls: getImageComponent('/img/xls.png'),
        },
        defaultValue: <img src={process.env.PUBLIC_URL + '/img/unknownFileType.png'} className={`${className} rounded-lg `} alt='' onClick={onImageClick} />,
    });
    return lookup[type];
};

const FilesComponent = ({ attachments, viewMediaIndex, handleArrowClick, isTicketChat, isExecuteImage, currentConversationId }) => {
    let type = getFileType(attachments[viewMediaIndex].type);
    let fileUrl = attachments[viewMediaIndex].fileUrl;
    let caption = attachments[viewMediaIndex].caption;

    return (
        <div className='h-9/12'>
            <>
                <div className='h-11/12 relative flex'>
                    <div className='w-1/12 flex items-center justify-center'>
                        {attachments.length > 1 && <NavigationArrow handleArrowClick={handleArrowClick} direction='left' />}
                    </div>
                    <div className='w-10/12'>
                        <GetComponentByType type={type} url={fileUrl} isSingle={true} isZoomable />
                        <RenderApproveReject image={attachments[viewMediaIndex]} isTicketChat={isTicketChat} isExecuteImage={isExecuteImage} currentConversationId={currentConversationId} />
                    </div>
                    <div className='w-1/12 flex items-center justify-center'>
                        {attachments.length > 1 && <NavigationArrow handleArrowClick={handleArrowClick} direction='right' />}
                    </div>
                </div>
                <OverflowTip someLongText={caption} textClass='m-auto w-9/12 text-center mt-6 text-font14' />
            </>
        </div>
    );
};

const NavigationArrow = ({ handleArrowClick, direction }) => {
    const angleLookup = { left: '<', right: '>' };

    return (
        <div
            className={`select-none cursor-pointer backdrop-filter backdrop-blur-lg rounded-full leading-6 border border-scogoblack text-center bg-gray-300 shadow-primaryBtnShadow h-12 w-12 flex justify-center items-center`}
            onClick={() => handleArrowClick(direction)}
        >
            <span className='text-black text-2xl text-font25 font-bold'>{angleLookup[direction]}</span>
        </div>
    );
};

const RenderApproveReject = ({ image, isTicketChat, isExecuteImage, currentConversationId }) => {
    const { loggedUser } = useSelector((state) => state.auth);
    const { partialFileAttachment } = useSelector((state) => state.conversations);
    const dispatch = useDispatch();

    useEffect(() => {
        if (isExecuteImage && image?.fileUrl || !image?.message) {
            const partialFileUrl = new URL(image.fileUrl).pathname.substring(1);
            const _id = currentConversationId || image?.conversation;
            if (!partialFileAttachment[partialFileUrl]) {
                dispatch(getAttachmentWithPartialUrl({ partialFileUrl, conversationId: _id }));
            }
        }

    }, [isExecuteImage, dispatch, currentConversationId, image?.conversation, image?.fileUrl, image?.message]);

    const memoizedImage = useMemo(() => {
        const partialFileUrl = image.fileUrl && new URL(image.fileUrl).pathname.substring(1);
        if (partialFileUrl && partialFileAttachment[partialFileUrl]) {
            return partialFileAttachment[partialFileUrl];
        }
        return image;
    }, [image, partialFileAttachment]);

    let messageId = memoizedImage.message;
    if (typeof messageId === 'object') messageId = memoizedImage.message._id;

    if (memoizedImage._id && memoizedImage.conversation && messageId) {
        const { _id, isApproved, approver, rejecter, conversation, height } = memoizedImage;
        const showApprovalOptions = showApprovalOptionsinMedia({
            conversationType: isTicketChat ? chatType.ticket : '', isApproved, role: loggedUser.role.id, type: loggedUser.type
        });

        return <div className="absolute left-1/2 transform -translate-x-1/2 bottom-28 flex gap-2" style={{ maxHeight: getImageStyle(height) }}>
            < ApprovedRejectIcon showApprovalOptions={showApprovalOptions} isApproved={isApproved} approver={approver} rejecter={rejecter} conversationId={conversation} messageId={messageId} attachmentId={_id} callStepFields={isTicketChat} isCardMessage={false} />
        </div>
    }
    return <></>;
}
import { onboarding } from '../types';

export const getSPOnboardingStatus = () => ({ type: onboarding.SP_ONBOARDING_STATUS_REQUEST });

export const checkDomainAvailability = ({ domain }) => ({
    type: onboarding.CHECK_DOMAIN_AVAILABILITY_REQUEST,
    payload: { domain },
});

export const saveCustomerOnboardingDetails = ({ formValues, navigate }) => ({
    type: onboarding.SAVE_CUSTOMER_ONBOARDING_DETAILS_REQUEST,
    payload: { formValues, navigate },
});

export const createCustomerDomain = (domain) => ({
    type: onboarding.CREATE_CUSTOMER_DOMAIN_REQUEST,
    payload: { domain },
});

export const updateUsersEmail = (email, type) => ({
    type: onboarding.UPDATE_NEW_USER_EMAIL_REQUEST,
    payload: { email, type },
});

export const updateUserProfileDetails = (data) => ({
    type: onboarding.UPDATE_USER_PROFILE_DETAILS_REQUEST,
    payload: data,
});

export const updateUserBussinessDetails = ({ formValues, servicePartnerId, navigate, redirect }) => {
    let locationFormData = new FormData();
    let userFormData = new FormData();
    if (formValues?.cities) {
        formValues.cities.map((item, index) => {
            return locationFormData.append(`sp_city_ids[${index}]`, item.value);
        });
    }
    if (formValues.hasGST) {
        userFormData.append('sp_have_gst', 1);
        userFormData.append('sp_applied_as', 1);
    } else {
        userFormData.append('sp_have_gst', 0);
        userFormData.append('sp_applied_as', 2);
    }
    userFormData.append('sp_name', formValues.company_name ? formValues.company_name : '');
    userFormData.append('net_plan', formValues.net_plan ? formValues.net_plan : '');
    userFormData.append('net_provider', formValues.net_provider ? formValues.net_provider : '');
    userFormData.append('sp_address_line_1', formValues.sp_address_line_1 ? formValues.sp_address_line_1 : '');
    userFormData.append('sp_primary_pincode', formValues.sp_primary_pincode ? formValues.sp_primary_pincode : '');
    userFormData.append('sp_gst_no', formValues.sp_gst_no ? formValues.sp_gst_no : '');
    userFormData.append('sp_pan_card_number', formValues.sp_pan_card_number ? formValues.sp_pan_card_number : '');
    userFormData.append('pancard_number', formValues.pancard_number ? formValues.pancard_number : '');
    userFormData.append('sp_adhaar_number', formValues.sp_adhaar_number ? formValues.sp_adhaar_number : '');

    return {
        type: onboarding.UPDATE_USER_BUSSINESS_DETAILS_REQUEST,
        payload: { locationFormData, userFormData, servicePartnerId, cities: formValues?.cities, navigate, redirect },
    };
};

export const updateUserBankDetails = ({ formValues, servicePartnerId, navigate, isRedirect, }) => {
    let bankFormData = new FormData();
    const { bank_name, bank_ifsc_code, bank_account_holder_name, bank_account_type, bank_account_number } = formValues;
    bankFormData.append('sp_id', parseInt(servicePartnerId));
    bankFormData.append('sp_bank_name', bank_name);
    bankFormData.append('sp_bank_ifsc_code', bank_ifsc_code);
    bankFormData.append('sp_bank_account_holder_name', bank_account_holder_name);
    bankFormData.append('sp_bank_account_type', bank_account_type);
    bankFormData.append('sp_bank_account_number', bank_account_number);

    let bankVerificationPayload = {
        account_number: bank_account_number,
        ifsc: bank_ifsc_code,
    };
    return {
        type: onboarding.UPDATE_USER_BANK_DETAILS_REQUEST,
        payload: { bankFormData, servicePartnerId, navigate, isRedirect, bankVerificationPayload },
    };
};

export const skipBankDetails = ({ navigate }) => ({
    type: onboarding.ON_SKIP_BANK_DETAILS_REQUEST,
    payload: { navigate },
});

export const updateUserProfileBussinessDetails = ({ formValues, servicePartnerId, formName }) => {
    let locationFormData = new FormData();
    let stateLocationFormData = new FormData();
    let userFormData = new FormData();
    if (formValues?.cities) {
        formValues.cities.map((item, index) => {
            return locationFormData.append(`sp_city_ids[${index}]`, item.value);
        });
    }
    if (formValues.hasGST) {
        userFormData.append('sp_have_gst', 1);
        userFormData.append('sp_applied_as', 1);
    } else {
        userFormData.append('sp_have_gst', 0);
        userFormData.append('sp_applied_as', 2);
    }
    userFormData.append('sp_name', formValues.sp_name ? formValues.sp_name : '');
    userFormData.append('net_plan', formValues.net_plan ? formValues.net_plan : '');
    userFormData.append('net_provider', formValues.net_provider ? formValues.net_provider : '');
    userFormData.append('sp_address_line_1', formValues.sp_address_line_1 ? formValues.sp_address_line_1 : '');
    userFormData.append('sp_primary_pincode', formValues.sp_primary_pincode ? formValues.sp_primary_pincode : '');
    userFormData.append('sp_gst_no', formValues.sp_gst_no ? formValues.sp_gst_no : '');
    userFormData.append('sp_pan_card_number', formValues.sp_pan_card_number ? formValues.sp_pan_card_number : '');
    userFormData.append('pancard_number', formValues.pancard_number ? formValues.pancard_number : '');
    userFormData.append('sp_adhaar_number', formValues.sp_adhaar_number ? formValues.sp_adhaar_number : '');

    return {
        type: onboarding.UPDATE_USER_PROFILE_BUSSINESS_DETAILS_REQUEST,
        payload: {
            locationFormData,
            userFormData,
            stateLocationFormData,
            servicePartnerId,
            cities: formValues?.cities,
            formName,
        },
    };
};


export const saveFEOnboardingDetails = ({ aadhar_number, driving_license, id_proof_type, fe_document_image, dob, navigate, formName, addressProofType, addressProofNumber, proofImage, fileUrlBack }) => {
    let form = new FormData();
    if (aadhar_number) form.append("adhar_number", aadhar_number);
    if (driving_license) form.append("driving_license", driving_license);
    form.append("id_proof_type", id_proof_type ? id_proof_type : '');
    form.append("dob", dob);
    if (Array.isArray(fe_document_image) && fe_document_image.length > 0) {
        form.append('fe_document_image', fe_document_image[0])
    }
    return {
        type: onboarding.SAVE_FE_ONBOARDING_DETAILS_REQUEST,
        payload: { form, navigate, formName, addressProofType, addressProofNumber, proofImage, fileUrlBack }
    }
};
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updatePartnersProfile } from '../../../actions/users';
import { closeModalAction } from '../../../actions/modal';
import Form from '../../../common/Form';
import Input from '../../../common/Form/Input';
import { useForm } from 'react-hook-form';
import { validateStringValue } from '../../../utils/common';

const formName = 'updateUserDetails';

const getComponyName = (userDetails) => {
    let componyName = '';
    if (userDetails?.customer_detail?.customer_company_name) {
        componyName = userDetails?.customer_detail?.customer_company_name;
    } else if (userDetails?.service_partner?.sp_name) {
        componyName = userDetails?.service_partner?.sp_name;
    } else if (userDetails?.sp_name) {
        componyName = userDetails?.sp_name;
    }
    return componyName;
};

export default function UpdateVendorsDetails(props) {
    const dispatch = useDispatch();
    const { formButtonLoading } = useSelector((state) => state.loading);
    const { userDetails, role, userType, customer, customerId, tabName, fk_cluster_id } = props;

    const defaultValues = {
        first_name: validateStringValue(userDetails?.first_name),
        last_name: validateStringValue(userDetails?.last_name),
        email: validateStringValue(userDetails?.email),
        sp_name: validateStringValue(getComponyName(userDetails)),
        net_plan: validateStringValue(userDetails?.service_partner?.net_plan),
        net_provider: validateStringValue(userDetails?.service_partner?.net_provider),
    };

    const isIspType = userDetails.type === 'ISP';

    const submit = (form) => {
        let payload = {
            first_name: form.first_name,
            last_name: form.last_name,
            email: form.email,
            sp_name: form.sp_name,
            user_id: userDetails.id,
        };
        if (isIspType) {
            payload = {
                ...payload,
                net_plan: form?.net_plan,
                net_provider: form?.net_provider,
            };
        }
        dispatch(updatePartnersProfile({ data: payload, role, userType, customer, customerId, formName, tabName, fk_cluster_id }));
    };

    const methods = useForm({ defaultValues, mode: 'all' });

    return (
        <Form
            methods={methods}
            onSubmit={submit}
            formName={formName}
            submitButtonText={'Update'}
            onCancel={() => dispatch(closeModalAction())}
            showSubmit
            submitContainerClassName='flex gap-2 justify-end py-4 px-4'
            isLoading={formButtonLoading[formName]}
        >
            <div className='px-3 grid grid-cols-2 rows-fr grid-flow-row-dense'>
                <Input label='First Name' name='first_name' required type={'text'} />
                <Input label='Last Name' name='last_name' required type={'text'} />
                <Input
                    label='Email Address'
                    name='email'
                    required
                    validate={(email) => {
                        return (
                            email.match(
                                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            ) || 'Invalid Email'
                        );
                    }}
                />
                <Input label='Company Name' name='sp_name' required />
                {isIspType && (
                    <>
                        <Input label='Net Plan' name='net_plan' required shouldUnregister />
                        <Input label='Net Provider' name='net_provider' required shouldUnregister />
                    </>
                )}
            </div>
        </Form>
    );
}

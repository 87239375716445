import { wallet } from '../types';

export const getWalletDetails = ({ updateBalance = false, showSuccessToast } = {}) => ({
    type: wallet.GET_WALLET_DETAILS_REQUEST,
    payload: { updateBalance, showSuccessToast },
});

export const withdrawWalletAmount = ({ data, refresh = true, formName, limit, page, status }) => ({
    type: wallet.WITHDRAW_WALLET_AMOUNT_REQUEST,
    payload: { data, refresh, formName, limit, page, status },
});

export const getPromisedAmount = () => ({ type: wallet.GET_PROMISED_AMOUNT_REQUEST });

export const getWalletPaymentStatus = ({ scogo_transaction_no, id }) => ({
    type: wallet.GET_WALLET_PAYMENTS_STATUS_REQUEST,
    payload: { scogo_transaction_no, id },
});

export const getWalletOrderStatus = ({ orderId }) => ({
    type: wallet.GET_WALLET_PAYMENTS_STATUS_REQUEST,
    payload: { orderId },
});
export const getWalletTransactions = ({ transactionType, limit, page, customerId, status, allusers, fromDate, toDate }) => ({
    type: wallet.GET_WALLET_TRANSACTIONS_REQUEST,
    payload: { transactionType, limit, page, customerId, status, allusers, fromDate, toDate },
});

export const getNextWalletTransactions = ({ transactionType, limit, page, customerId, status, fromDate, toDate, email, allusers }) => ({
    type: wallet.GET_WALLET_NEXT_TRANSACTIONS_REQUEST,
    payload: { transactionType, limit, page, customerId, status, fromDate, toDate, email, allusers },
});

export const addAmountToWallet = ({ orderAmount, securityDeposit, formName }) => ({
    type: wallet.ADD_AMOUNT_TO_WALLET_REQUEST,
    payload: { orderAmount, securityDeposit, formName },
});

export const payPoManually = ({ ticketId, fileData, reason = 'PO_MANUAL_PAYMENT', formName, data, isRefresh, callViewTicket, onSuccess, enduserId }) => ({
    type: wallet.PAY_PO_MANUALLY_REQUEST,
    payload: { ticketId, fileData, reason, formName, data, isRefresh, callViewTicket, onSuccess, enduserId },
});

export const payPO = ({ data, formName, isRefresh, callViewTicket, onSuccess, enduserId }) => ({
    type: wallet.PAY_PO_REQUEST,
    payload: { data, formName, isRefresh, callViewTicket, onSuccess, enduserId },
});

export const requestStatement = ({
    userId,
    count,
    limit,
    page,
    customerId,
    status,
    requestStatement,
    emails,
    allusers,
    fromDate,
    toDate,
    formName,
}) => ({
    type: wallet.REQUEST_STATEMENT_REQUEST,
    payload: { userId, count, limit, page, customerId, status, requestStatement, emails, allusers, fromDate, toDate, formName },
});

export const getRecepientsFilterdData = ({ limit, page, next, count, payload }) => ({
    type: wallet.GET_RECEPIENTS_FILTERED_DATA_REQUEST,
    payload: { limit, page, next, count, payload },
});

export const updateWalletAfterPaymentGatewaySuccess = ({ orderId, status, transactionType, customerId, limit, page, walletType }) => ({
    type: wallet.UPDATE_WALLET_AFTER_PAYMENT_GATEWAY_SUCCESS_REQUEST,
    payload: { orderId, status, customerId, transactionType, limit, page, walletType },
});

export const clearOnGoingTransactionFromStore = () => ({
    type: wallet.CLEAR_ONGOING_TRANSACTIONS_FROM_STORE,
});

export const approveWalletPayment = ({ data, instantpay = false, isRefresh, navigateBack, navigate }) => ({
    type: wallet.APPROVE_WALLET_PAYMENT_REQUEST,
    payload: { data, instantpay, isRefresh, navigateBack, navigate },
});

export const getWalletTransactionsDetails = ({ data, copyToClipboard = false }) => ({
    type: wallet.GET_WALLET_TRANSACTIONS_DETAILS_REQUEST,
    payload: { data, copyToClipboard },
});

export const paySecurityDeposit = () => ({
    type: wallet.PAY_SECURITY_DEPOSIT_REQUEST,
});

export const clearOnGoingSecurityDepositTransactionFromStore = () => ({
    type: wallet.CLEAR_ONGOING_SECURITY_DEPOSIT_TRANSACTIONS_FROM_STORE,
});

export const getWalletDetailsById = ({ updateBalance = false, showSuccessToast, beneficiary, accountBalance } = {}) => ({
    type: wallet.GET_WALLET_DETAILS_BY_ID_REQUEST,
    payload: { updateBalance, showSuccessToast, beneficiary, accountBalance },
});

export const removeWalletDetailsFromStore = () => ({
    type: wallet.REMOVE_WALLET_DETAILS_FROM_STORE,
});

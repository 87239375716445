import { takeEvery, put, call, takeLatest, all, select, delay } from 'redux-saga/effects';
import { modal, tickets, toast, loading, project, utils, sp, conversations, fm } from '../../types';
import api from '../../apis';
import { generateNocExecutionForm, getCustomerIdOfLoginUser, guid } from '../../utils/utils';

import { generateaddSitesform, generateEditTicketForm, generateIndependentTicketform } from '../../utils/form';
import microservices from '../../lambdas/microservices';
import lambdas from '../../lambdas/apis';
import { getFileType, uploadFileToS3WithSignedUrl } from '../../utils/common';
import { getLoggedUser } from '../../selectors/users';
import { getFilterDetails, getTicketCountList } from '../../selectors/tickets';
import { isFe, isIsp, isPsp, isScm, isSp, isCustomerGroup } from '../../utils/role';
import { feTypeLookup, getTicketTabNameOrSortBy, spTypeLookup } from '../../pages/Ticket/TicketTable';
import { newMainTab } from '../../pages/Ticket/TicketController';
import { customerBilling, ticket as ticketPath, todayStatus, trackSupportTicket } from '../../router/roleWisePageRoute';
import { DuplicateSite } from '../../pages/Ticket/Modal/DuplicateSite';
import moment from 'moment';
import { take } from 'lodash';
import RaisePo from '../../pages/raise-po';
import config from '../../config';

export function* getBOQList(action) {
    const { customerId, genre = '' } = action.payload;
    const queryParams = {};
    if (customerId !== undefined) queryParams.customer_id = customerId;
    if (genre) queryParams.genre = genre;
    yield put({ type: loading.GET_BOQ_ASSETS_LOADING, payload: true });
    try {
        const response = yield call(api.tickets.boqList, {}, {}, queryParams);
        if (response.data.code === 200) {
            let boqList = [];
            response.data.data.map((boq) => {
                return boqList.push({
                    key: `${guid()}_${boq.id}`,
                    value: boq.id,
                    label: boq.title,
                    inputFields: boq.input_fields,
                    requiredFields: boq.required_fields,
                });
            });
            yield put({ type: tickets.BOQ_LIST_SUCCESS, payload: boqList });
        } else {
            yield put({ type: tickets.BOQ_LIST_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.BOQ_LIST_FAILURE, payload: error });
    }
    yield put({ type: loading.GET_BOQ_ASSETS_LOADING, payload: false });
}

export function* createTickets(action) {
    const { data, existing, addNew, formName } = action.payload;
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    try {
        const form = yield call(generateIndependentTicketform, { ...data }, existing, addNew);
        if (form) {
            const response = yield call(microservices.tickets.createTickets, {}, {}, {}, form);
            if (response.data.code === 201) {
                let message = response.data.data && response.data.data.message ? response.data.data.message : `Ticket Created Successfully!!!`;
                yield all([
                    yield put({ type: modal.CLOSE_MODAL }),
                    yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message } }),
                    yield put({ type: tickets.CREATE_SUPPORT_TICKETS_SUCCESS, payload: response.data }),
                ]);
            } else {
                let message =
                    response.data.data && response.data.data.message
                        ? response.data.data.message
                        : response.data.errorMessage
                            ? response.data.errorMessage
                            : 'Something Went Wrong';
                yield all([
                    yield put({ type: modal.CLOSE_MODAL }),
                    yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message } }),
                    yield put({ type: tickets.CREATE_SUPPORT_TICKETS_FAILURE, payload: { message: 'Oops Something went wrong' } }),
                ]);
            }
        }
    } catch (error) {
        yield put({ type: tickets.CREATE_SUPPORT_TICKETS_FAILURE, payload: error });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* createIndependentTickets(action) {
    const { data, existing, addNew, formName, onSuccess = () => { }, refresh, onFailure = () => { } } = action.payload;
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    try {
        const form = yield call(generateIndependentTicketform, { ...data }, existing, addNew);
        if (form) {
            const response = yield call(lambdas.tickets.createIndependentTickets, {}, {}, {}, form);
            if ([200, 201].includes(response.data.code)) {
                let message = `Ticket Created Successfully!!!`;
                let status = 'success';
                if (response.data.data.duplicate_sites && data.location_data.length === response.data.data.duplicate_sites.length) {
                } else {
                    yield put({ type: toast.SEND_TOAST, payload: { message, status } });
                }
                yield all([yield put({ type: modal.CLOSE_MODAL }), yield put({ type: tickets.CREATE_INDEPENDENT_TICKETS_SUCCESS, payload: response.data })]);
                onSuccess();
                if (refresh) {
                    if (window.location.pathname === ticketPath.path) {
                        yield put({ type: tickets.REFRESH_TICKET_TABLE_REQUEST });
                    }
                }
            } else {
                let message = response.data.data.message;
                let status = 'danger';
                yield all([
                    yield put({ type: toast.SEND_TOAST, payload: { message, status } }),
                    yield put({ type: tickets.CREATE_INDEPENDENT_TICKETS_FAILURE, payload: { message: 'Oops Something went wrong' } }),
                ]);
                onFailure();
            }
        }
    } catch (error) {
        yield put({ type: tickets.CREATE_INDEPENDENT_TICKETS_FAILURE, payload: error });
        onFailure();
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* updateTicketProperties(action) {
    const { data, formName, closeModal = true, updateTicketList = false } = action.payload;
    const hardCopy = data.data.hard_copy_required;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.tickets.updateTicketProperties, {}, {}, {}, data);
        if (response.data.code === 200) {
            let message = hardCopy === 1 ? 'Hard copy required is set to Enable' : hardCopy === 0 ? 'Hard copy required is set to Disable' : 'Project details updated successfully';
            if (response.data.data.message) message = response.data.data.message;
            const promises = [
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message } }),
                yield put({ type: project.GET_ALL_PROJECT_LIST_REQUEST, payload: {} }),
            ]
            if (closeModal) promises.push(yield put({ type: modal.CLOSE_MODAL }));
            if (updateTicketList) {
                promises.push(yield put({ type: tickets.UPDATE_TICKET_PROPERTIES_SUCCESS, payload: { ...response.data.data, input: data.data.remark } }));
            }
            yield all(promises);
        } else {
            yield all([
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({ type: tickets.UPDATE_TICKET_PROPERTIES_FAILURE, payload: 'Something Went Wrong' }),
                yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something Went Wrong' } }),
            ]);
        }
    } catch (error) {
        yield put({ type: tickets.UPDATE_TICKET_PROPERTIES_FAILURE, payload: error.message || 'Something Went Wrong' });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* updateTodaysStatusTicketProperties(action) {
    const { data, formName, intention, refresh } = action.payload;
    const ticketId = data.ticketId;
    if (intention) {
        yield put({ type: loading.REACHED_SITE_STATUS_LOADING, payload: { intention, ticketId, formName, isLoading: true } });
        yield put({ type: loading.SITE_COMPLETE_STATUS_LOADING, payload: { intention, ticketId, formName, isLoading: true } });
    }
    try {
        const response = yield call(lambdas.tickets.updateTicketProperties, {}, {}, {}, data);
        if (response.data.code === 200) {
            if (refresh) {
                if (window.location.pathname === todayStatus.path) {
                    yield put({ type: tickets.REFRESH_TODAY_STATUS_TABLE_REQUEST });
                }
            }
        }
    } catch (error) {
        yield put({ type: tickets.UPDATE_TODAY_STATUS_TICKET_PROPERTIES_FAILURE, payload: error });
    }
    if (intention) {
        yield put({ type: loading.REACHED_SITE_STATUS_LOADING, payload: { intention, ticketId, formName, isLoading: false } });
        yield put({ type: loading.SITE_COMPLETE_STATUS_LOADING, payload: { intention, ticketId, formName, isLoading: false } });
    }
}

export function* notifyIsp(action) {
    const { data, whatsappUrl, formName } = action.payload;

    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        let response = yield call(microservices.tickets.notifyIsp, {}, {}, {}, data);
        if (response.data.code === 200) {
            let status = 'success';
            let message = response.data.data.message;
            if (data.whatsapp && Array.isArray(data.isps) && data.isps.length === 1 && !['', undefined, null].includes(whatsappUrl)) {
                window.open(whatsappUrl, '_blank');
            }
            yield all([
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({ type: tickets.NOTIFY_TICKETS_TO_ISP_SUCCESS, payload: response.data.data }),
                yield put({ type: toast.SEND_TOAST, payload: { status, message } }),
            ]);
        } else {
            let status = 'danger';
            let message = response.data.data.message;
            yield all([
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({ type: tickets.NOTIFY_TICKETS_TO_ISP_FAILURE, payload: { message: 'Oops Something went wrong' } }),
                yield put({ type: toast.SEND_TOAST, payload: { status, message } }),
            ]);
        }
    } catch (error) {
        yield put({ type: tickets.NOTIFY_TICKETS_TO_ISP_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* respondFeasibility(action) {
    const { data, closeModal = true, refresh = false, ticketId, ispId, formName, isGetSpList, isGetAssignedNewTicketList } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        let response = yield call(microservices.tickets.respondFeasibility, {}, {}, {}, data);
        if (response.data.code === 200) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: response.data.data.message } });
            if (refresh) {
                if (isGetSpList) {
                    yield put({ type: sp.GET_ELIGIBLE_SP_LIST_REQUEST, payload: { ticketId, dropdown: false, type: 'ISP' } });
                    // yield put({ type: sp.UPDATE_ELIGIBLE_SP_LIST, payload: { ticketId, dropdown: false, type: 'ISP' } });
                }
                if (isGetAssignedNewTicketList)
                    yield put({
                        type: tickets.SP_ASSIGNED_NEW_TICKETS_LIST_REQUEST,
                        payload: {
                            sp_id: ispId,
                            user_type: 'ISP',
                        },
                    });
            }

            if (closeModal) {
                yield put({ type: modal.CLOSE_MODAL });
            }
        } else {
            yield put({ type: tickets.RESPOND_FEASIBILITY_TICKET_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.RESPOND_FEASIBILITY_TICKET_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* viewTicketById(action) {
    const { ticketId, externalTicketId } = action.payload;
    const queryParams = {};
    if (!isNaN(ticketId) && parseInt(ticketId) > 0) queryParams.ticket_id = ticketId;
    if (!['', undefined, null].includes(externalTicketId)) queryParams.external_ticket_id = externalTicketId;
    try {
        yield put({ type: loading.VIEW_TICKET_BY_ID_LOADING, payload: true });
        const response = yield call(api.tickets.viewTicket, {}, {}, queryParams);

        if (response.data.code === 200) {
            yield put({ type: tickets.VIEW_TICKET_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: tickets.VIEW_TICKET_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.VIEW_TICKET_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.VIEW_TICKET_BY_ID_LOADING, payload: false });
}

export function* updateCourierStatus(action) {
    const { trackingId, type, ticketId } = action.payload;
    try {
        yield put({ type: loading.IS_UPDATE_COURIER_STATUS_LOADING, payload: true });
        const response = yield call(api.courier.trackCourier, {}, {}, { ticket_id: trackingId });
        if (response.data.code === 200) {
            if (response.data.data.tracking_output) {
                if (window.location.pathname === ticketPath.path) {
                    yield put({ type: tickets.UPDATE_TICKET_TABLE_COURIER_STATUS_SUCCESS, payload: { data: response.data.data.tracking_output.response, type, ticketId } });
                } else {
                    yield put({ type: tickets.UPDATE_TICKET_COURIER_STATUS_SUCCESS, payload: { data: response.data.data.tracking_output.response, type } });
                }
            } else {
                yield put({ type: tickets.UPDATE_TICKET_COURIER_STATUS_SUCCESS, payload: response.data.data });
            }
            if (response.data.data.error) {
                yield put({ type: toast.SEND_TOAST, payload: { status: 'warning', message: response.data.data.error } });
            }
        } else {
            yield put({ type: tickets.UPDATE_TICKET_COURIER_STATUS_FAILURE, payload: { message: 'Oops Something went wrong' } });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: response.data.data.error } });
        }
    } catch (error) {
        yield put({ type: tickets.UPDATE_TICKET_COURIER_STATUS_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.IS_UPDATE_COURIER_STATUS_LOADING, payload: false });
}

export function* getChildTickets(action) {
    const { ticketId } = action.payload;
    try {
        const response = yield call(lambdas.tickets.getChildTickets, {}, { ticketId });
        if (response.data.code === 200) {
            yield put({ type: tickets.GET_CHILD_TICKETS_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: tickets.GET_CHILD_TICKETS_SUCCESS, payload: [] });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message || 'Something Went Wrong' } });
        }
    } catch (error) {
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: error.message || 'Something Went Wrong' } });
    }
}

export function* getSOWDocument(action) {
    const { ticketId, download } = action.payload;
    try {
        yield put({ type: loading.IS_SOW_DOCUMENT_NOT_FOUND_LOADING, payload: true });
        let response = yield call(api.project.getSOWDocument, {}, { ticketId });
        if (response.data.code === 200) {
            if (response.data.data.document !== '') {
                if (!download) {
                    window.open(response.data.data.document, '_blank');
                }
            } else {
                yield put({ type: toast.SEND_TOAST, payload: { status: 'warning', message: 'File Not Found' } });
                yield put({ type: tickets.SOW_DOCUMENT_NOT_FOUND, payload: 'File Not Found' });
            }
        } else {
            yield put({ type: tickets.SOW_DOCUMENT_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.SOW_DOCUMENT_FAILURE, payload: error });
    }
    yield put({ type: loading.IS_SOW_DOCUMENT_NOT_FOUND_LOADING, payload: false });
}

export function* getAssetConfigTemplate(action) {
    const { ticketId } = action.payload;
    try {
        yield put({ type: loading.GET_ASSET_CONFIG_TEMPLATE_LOADING, payload: true });
        const response = yield call(api.tickets.getAssetConfigTemplate, {}, { ticketId });
        if (response.data.code === 200) {
            if (response.data.data && Array.isArray(response.data.data) && response.data.data.length > 0) {
                const files = [];
                for (const file of response.data.data) {
                    files.push({ url: file.file_path, fileName: file.file_name, mimeType: 'application/pdf' });
                }
                if (files.length > 0) {
                    yield put({ type: utils.DOWNLOAD_FILE_REQUEST, payload: { files } });
                }
            } else {
                yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Config Templates Not found!!!' } });
            }
            yield put({ type: tickets.GET_ASSETS_CONFIG_TEMPLATE_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: tickets.GET_ASSETS_CONFIG_TEMPLATE_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.GET_ASSETS_CONFIG_TEMPLATE_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.GET_ASSET_CONFIG_TEMPLATE_LOADING, payload: false });
}

export function* getSignOffSampleReport(action) {
    const { ticketId, download = false } = action.payload;
    try {
        yield put({ type: loading.IS_SIGN_OFF_SAMPLE_REPORT_NOT_FOUND_LOADING, payload: true });
        let response = yield call(api.project.getSignOffSampleReport, {}, { ticketId });
        if (response.data.code === 200) {
            if (response.data.data.document !== '') {
                if (!download) {
                    window.open(response.data.data.document, '_blank');
                }
            } else {
                yield put({ type: tickets.SIGN_OFF_SAMPLE_REPORT_NOT_FOUND, payload: 'File Not Found' });
            }
        } else {
            yield put({ type: tickets.SIGN_OFF_SAMPLE_REPORT_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.SIGN_OFF_SAMPLE_REPORT_FAILURE, payload: error });
    }
    yield put({ type: loading.IS_SIGN_OFF_SAMPLE_REPORT_NOT_FOUND_LOADING, payload: false });
}
export function* getTicketDetails(action) {
    const { ticketId, payment_transaction } = action.payload;
    yield put({ type: loading.EDIT_PAYOUT_FORM_LOADING, payload: true });
    yield put({ type: loading.VIEW_TICKET_BY_ID_LOADING, payload: true });
    try {
        const response = yield call(api.tickets.getTicketDetails, {}, {}, { ticket_id: ticketId, payment_transaction: payment_transaction });
        if (response.data.code === 200) {
            yield put({ type: tickets.GET_TICKET_DETAILS_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: tickets.GET_TICKET_DETAILS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.GET_TICKET_DETAILS_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.EDIT_PAYOUT_FORM_LOADING, payload: false });
    yield put({ type: loading.VIEW_TICKET_BY_ID_LOADING, payload: false });
}

export function* getTicketReportLink(action) {
    const { ticketId, download, popError, loading: isLoading = true } = action.payload;
    try {
        if (isLoading) yield put({ type: loading.IS_DOWNLOAD_REPORT_LOADING, payload: true });
        const response = yield call(api.tickets.generateReport, {}, { ticketId });
        if (response.data.code === 200) {
            if (response.data.data && response.data.data.document) {
                if (!download) {
                    window.open(response.data.data.document, '_blank');
                } else {
                }
                yield put({ type: tickets.TICKET_REPORT_LINK_SUCCESS, payload: response.data.data.document });
            } else {
                if (popError) {
                    yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'File Not found' } });
                } else {
                    yield put({ type: tickets.TICKET_REPORT_LINK_NOT_FOUND, payload: 'File Not Found' });
                }
            }
        } else {
            yield put({ type: tickets.TICKET_REPORT_LINK_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.TICKET_REPORT_LINK_FAILURE, payload: error });
    }
    if (isLoading) yield put({ type: loading.IS_DOWNLOAD_REPORT_LOADING, payload: false });
}

export function* getTicketPayments(action) {
    try {
        const { ticketId, incentive = false, user_type = 'ALL' } = action.payload;
        yield put({ type: loading.IS_TICKET_PAYMENT_LOADING, payload: true });
        let response = yield call(microservices.tickets.ticketPayments, {}, { ticketId }, { incentive, user_type });
        if (response.data.code === 200) {
            yield put({ type: tickets.GET_TICKET_PAYMENTS_SUCCESS, payload: response.data.data });
            if (!['', undefined, null].includes(response.data.basePayment)) {
                yield put({ type: tickets.GET_TICKET_BASE_PAYMENTS_SUCCESS, payload: response.data.basePayment });
            }
        } else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
            yield put({ type: tickets.GET_TICKET_PAYMENTS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.GET_TICKET_PAYMENTS_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.IS_TICKET_PAYMENT_LOADING, payload: false });
}

export function* saveRequestAdditionalAmount(action) {
    const { payload, closeModal, refreshPayments, refreshViewTicket, ticketId, idx, formName, onSuccess = () => { } } = action.payload;
    try {
        const response = yield call(lambdas.tickets.requestAdditionalAmount, {}, {}, {}, payload);
        if ([200, 400, 404, 403, 500].includes(response.data.code)) {
            let status = 'danger';
            if (response.data.code === 200) {
                onSuccess()
                status = 'success';
                yield put({ type: tickets.REQUEST_ADDITIONAL_AMOUNT_SUCCESS, payload: { idx, status: true } });
                yield put({ type: tickets.GET_TICKET_PAYMENTS_REQUEST, payload: { ticketId: ticketId, incentive: false } });
            }
            yield put({ type: toast.SEND_TOAST, payload: { status, message: response.data.data.message } });
            if (closeModal) {
                yield put({ type: modal.CLOSE_MODAL });
            }

            if (refreshPayments) {
                yield put({ type: tickets.GET_TICKET_PAYMENTS_REQUEST, payload: { ticketId: ticketId } });
            }
            if (refreshViewTicket) {
                yield put({ type: tickets.VIEW_TICKET_REQUEST, payload: { ticketId: ticketId, paymentTransaction: true } });
            }
        } else {
            yield put({ type: tickets.REQUEST_ADDITIONAL_AMOUNT_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.REQUEST_ADDITIONAL_AMOUNT_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* requestAdditionalAmount(action) {
    const { data, proofs, closeModal = true, refreshPayments = false, refreshViewTicket = false, idx, formName, onSuccess } = action.payload;
    const ticketId = data.ticketId;

    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    try {
        if (Array.isArray(proofs) && proofs.length > 0) {
            const proofImage = proofs[0];
            const filesInRequestBody = {
                fileName: proofImage.name,
                mimeType: proofImage.type,
            };
            const signedUrlRes = yield call(lambdas.user.uploadProof, {}, {}, {}, { files: [filesInRequestBody], ticketId, reason: 'ADDITIONAL_AMOUNT_PROOF' });
            if (signedUrlRes.data.code === 200) {
                yield call(uploadFileToS3WithSignedUrl, signedUrlRes.data.data.signedUrl, proofImage);
                const payload = { ...data };
                payload.payments[0].proof = signedUrlRes.data.data.fileUrl;
                payload.payments[0].proofFileName = proofImage.name;
                payload.payments[0].proofType = getFileType(proofImage.type);
                payload.payments[0].proofMimeType = proofImage.type;
                yield put({ type: tickets.SAVE_REQUEST_ADDITIONAL_AMOUNT_REQUEST, payload: { payload, closeModal, refreshPayments, refreshViewTicket, ticketId, idx, formName, onSuccess } });
            }
        } else {
            yield put({
                type: tickets.SAVE_REQUEST_ADDITIONAL_AMOUNT_REQUEST,
                payload: { payload: data, closeModal, refreshPayments, refreshViewTicket, ticketId, idx, formName, onSuccess },
            });
        }
    } catch (error) {
        yield put({ type: tickets.REQUEST_ADDITIONAL_AMOUNT_FAILURE, payload: { message: error } });
    }
}

export function* processAdditionalAmount(action) {
    const { data, refreshPayments = false, closeModal = true, activeTab, projectId, customerId, limit = 10, page = 1, idx, formName } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.tickets.processAdditionalAmount, {}, {}, {}, data);
        let status = '',
            rejected = false,
            approved = false;
        if ([200, 400, 500].includes(response.data.code)) {
            status = 'danger';
            if (response.data.code === 200) {
                status = 'success';
                if (data.action === 'REJECT') rejected = true;
                if (data.action === 'APPROVE') approved = true;
            }

            yield put({ type: toast.SEND_TOAST, payload: { status, message: response.data.data.message } });
            yield put({ type: tickets.PROCESS_ADDITIONAL_AMOUNT_SUCCESS, payload: { rejected, approved, idx } });
            if (refreshPayments) {
                yield put({ type: tickets.GET_TICKET_PAYMENTS_REQUEST, payload: { ticketId: data.ticketId } });
                yield put({ type: tickets.GET_TICKET_DETAILS_REQUEST, payload: { ticketId: data.ticketId } });
            } else {
                yield put({ type: tickets.TICKETS_LIST_REQUEST, payload: { activeTab, projectId, customerId, limit, page } });
            }
        } else {
            yield put({ type: tickets.PROCESS_ADDITIONAL_AMOUNT_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
        if (closeModal) {
            yield put({ type: modal.CLOSE_MODAL });
        }
    } catch (error) {
        yield put({ type: tickets.PROCESS_ADDITIONAL_AMOUNT_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* getTicketsList(action) {
    const {
        count = false,
        type,
        next = true,
        offset,
        limit = 10,
        page = 1,
        customerId,
        tab = 'alltickets',
        view = 'TICKET_VIEW',
        lastScannedIndex = 0,
        sortBy = 'created_at',
    } = action.payload;
    try {
        let queryParams = { count, type, next, offset, limit, page, tab, view, lastScannedIndex, sortBy };
        if (customerId) queryParams.customerId = customerId;
        if (lastScannedIndex) queryParams.lastScannedIndex = lastScannedIndex;
        if (!count) {
            const ticketsCount = yield select(getTicketCountList);
            if (Object.keys(ticketsCount).length === 0) queryParams.count = true;
        }

        yield put({ type: loading.TICKET_LIST_LOADING, payload: true });
        const response = yield call(lambdas.tickets.getTickets, {}, {}, queryParams);
        if (response.data.code === 200) {
            const loggedUser = yield select(getLoggedUser);
            const ticketList = response.data.data;
            yield put({
                type: tickets.GET_TICKET_LIST_SUCCESS,
                payload: {
                    ticketList,
                    ticketsCount: response.data.count,
                    loggedUserType: loggedUser.type,
                    loggedUserRole: loggedUser.role.id,
                    openedTab: tab,
                },
            });
            const ticketIds = Array.isArray(ticketList) && ticketList.map((ticket) => ticket.id);
            if (ticketIds.length > 0) yield put({ type: tickets.GET_TICKET_ASSETS_REMARKS_REQUEST, payload: { ticketIds } });
        } else {
            yield put({ type: tickets.GET_TICKET_LIST_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.GET_TICKET_LIST_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.TICKET_LIST_LOADING, payload: false });
}

export function* getTicketsTodayStatus(action) {
    yield put({ type: loading.TODAY_STATUS_LOADING, payload: true });
    try {
        const { limit = 10, page = 1, count = false, projectId, searchQuery, stateId, customerId } = action.payload;
        let queryParams = { limit, page, count };

        if (searchQuery) queryParams.searchQuery = searchQuery;

        if (customerId) {
            queryParams.customerId = customerId;
        }

        if (![0, '', 'all', undefined, null].includes(projectId) && !isNaN(projectId)) queryParams.projectId = projectId;
        if (![0, '', 'all', undefined, null].includes(stateId) && !isNaN(stateId)) queryParams.stateId = stateId;
        let response = yield call(lambdas.tickets.getTicketsTodayStatus, {}, {}, queryParams);
        if (response.data.code === 200) {
            yield put({ type: tickets.TICKETS_TODAY_STATUS_LIST_SUCCESS, payload: { todayStatusTicketList: response.data.data } });
            // yield put({ type: admin.LIST_ALL_STATE_REQUEST })
        }
        if (count) {
            yield put({ type: tickets.TICKETS_TODAY_STATUS_COUNT_SUCCESS, payload: response.data.count });
        } else {
            yield put({ type: tickets.TICKETS_TODAY_STATUS_LIST_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.TICKETS_TODAY_STATUS_LIST_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.TODAY_STATUS_LOADING, payload: false });
}

export function* addfeIntentionStatus(action) {
    const { ticketId, intention, formName, refresh } = action.payload;
    yield put({ type: loading.FE_INTENTION_LOADING, payload: { intention, ticketId, formName, isLoading: true } });
    try {
        if (ticketId && intention) {
            const response = yield call(lambdas.tickets.addfeIntentionStatus, {}, {}, {}, { ticketId, intention });
            if (response.data.code === 200) {
                if (refresh) {
                    if (window.location.pathname === todayStatus.path) {
                        yield put({ type: tickets.REFRESH_TODAY_STATUS_TABLE_REQUEST });
                    }
                }
            }
        }
    } catch (error) {
        yield put({ type: tickets.ADD_FE_INTENTION_STATUS_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FE_INTENTION_LOADING, payload: { intention, formName, ticketId, isLoading: false } });
}

export function* updateNocExecutionData(action) {
    const { formValues, ticket, formName, intention, refresh } = action.payload;
    yield put({ type: loading.SITE_COMPLETE_STATUS_LOADING, payload: { intention, formName, ticketId: ticket.id, isLoading: true } });
    try {
        const data = yield call(generateNocExecutionForm, formValues, ticket);
        const response = yield call(api.noc.updateNocData, {}, {}, {}, data);
        if (response.data.code === 200) {
            if (refresh) {
                if (window.location.pathname === todayStatus.path) {
                    yield put({ type: tickets.REFRESH_TODAY_STATUS_TABLE_REQUEST });
                }
            }
        } else {
            yield put({ type: tickets.UPDATE_NOC_EXECUTION_DATA_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.UPDATE_NOC_EXECUTION_DATA_FAILURE, payload: error });
    }
    yield put({ type: loading.SITE_COMPLETE_STATUS_LOADING, payload: { intention, formName, ticketId: ticket.id, isLoading: false } });
}

export function* getSPAcceptedTickets(action) {
    const { type, order_by = 'broadcast_timestamp', limit = 10, page = 1 } = action.payload;
    let queryParams = { order_by, limit, page };
    queryParams[type] = true;
    try {
        yield put({ type: loading.TICKET_LIST_LOADING, payload: true });
        const response = yield call(api.tickets.listSPTickets, {}, {}, queryParams);
        if (response.data.code === 200) {
            let ticketList = response.data.data;
            yield put({ type: tickets.GET_SP_ACCEPTED_TICKETS_SUCCESS, payload: { ticketList } });
            const ticketIds = Array.isArray(ticketList) && ticketList.map((ticket) => ticket.id);
            if (ticketIds.length > 0) yield put({ type: tickets.GET_TICKET_ASSETS_REMARKS_REQUEST, payload: { ticketIds } });
        } else {
            yield put({ type: tickets.GET_SP_ACCEPTED_TICKETS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.GET_SP_ACCEPTED_TICKETS_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.TICKET_LIST_LOADING, payload: false });
}

export function* getFEAcceptedTickets(action) {
    const { type, order_by = 'broadcast_timestamp', limit = 10, page = 1 } = action.payload;
    let queryParams = { order_by, limit, page };
    if (type) queryParams[type] = true;
    try {
        yield put({ type: loading.TICKET_LIST_LOADING, payload: true });
        const response = yield call(api.tickets.listFETickets, {}, {}, queryParams);
        if (response.data.code === 200) {
            let ticketList = response.data.data;
            yield put({ type: tickets.GET_FE_ACCEPTED_TICKETS_SUCCESS, payload: { ticketList } });
            const ticketIds = Array.isArray(ticketList) && ticketList.map((ticket) => ticket.id);
            if (ticketIds.length > 0) yield put({ type: tickets.GET_TICKET_ASSETS_REMARKS_REQUEST, payload: { ticketIds } });
        } else {
            yield put({ type: tickets.GET_FE_ACCEPTED_TICKETS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.GET_FE_ACCEPTED_TICKETS_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.TICKET_LIST_LOADING, payload: false });
}

export function* getStatusWiseTicketCount(action) {
    try {
        const response = yield call(api.tickets.statusWiseTicketCount, {}, {}, {});
        if (response.data.code === 200) {
            yield put({ type: tickets.GET_SP_TICKETS_COUNT_SUCCESS, payload: { ticketsCount: response.data.data } });
        } else {
            yield put({ type: tickets.GET_SP_TICKETS_COUNT_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.GET_SP_TICKETS_COUNT_FAILURE, payload: { message: error } });
    }
}

export function* getFETicketsCount(action) {
    try {
        const response = yield call(api.tickets.feTicketCount, {}, {}, {});
        if (response.data.code === 200) {
            yield put({ type: tickets.GET_FE_TICKETS_COUNT_SUCCESS, payload: { ticketsCount: response.data.data } });
        } else {
            yield put({ type: tickets.GET_FE_TICKETS_COUNT_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.GET_FE_TICKETS_COUNT_FAILURE, payload: { message: error } });
    }
}

export function* getTicketFilterList(action) {
    const { toFilter, projectIds } = action.payload;
    yield put({ type: loading.TICKET_FILTER_LIST_LOADING, payload: true });
    const queryParams = { toFilter };
    if (projectIds) queryParams.projectIds = projectIds;
    const loggedUser = yield select(getLoggedUser);
    const role = loggedUser.role.id;
    const type = loggedUser.type;
    try {
        let agentResponse
        if (isScm(role, type) || isCustomerGroup(role, type)) {
            agentResponse = yield call(lambdas.user.getTechSupportUsers, {});
        }
        const response = yield call(microservices.tickets.ticketFilterList, {}, {}, queryParams);
        if (response.data.code === 200) {
            if (window.location.pathname === customerBilling.path) {
                yield put({ type: fm.GET_CB_TICKET_FILTER_LIST_SUCCESS, payload: response.data.data });
            } else {
                let data = Object.assign(response.data.data, {});
                if ((isScm(role, type) || isCustomerGroup(role, type)) && agentResponse?.data?.code === 200) {
                    data.agent = agentResponse.data.data.techSupport.map((user) => {
                        return {
                            key: user.id,
                            value: user.id,
                            label: `${user.first_name}  ${user.last_name}`,
                        };
                    });
                }
                yield put({ type: tickets.GET_TICKET_FILTER_LIST_SUCCESS, payload: response.data.data });
            }
        } else {
            yield put({ type: tickets.GET_TICKET_FILTER_LIST_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.GET_TICKET_FILTER_LIST_FAILURE, payload: error });
    }
    yield put({ type: loading.TICKET_FILTER_LIST_LOADING, payload: false });
}

export function* getSPTickets(action) {
    const { order_by = 'broadcast_timestamp', limit, page } = action.payload;
    const queryParams = { order_by };

    if (limit) queryParams.limit = limit;
    if (page) queryParams.page = page;

    try {
        yield put({ type: loading.TICKET_LIST_LOADING, payload: true });
        const response = yield call(api.tickets.listSPEligibleTickets, {}, {}, queryParams);
        if (response.data.code === 200) {
            let ticketList = response.data.data;
            yield put({ type: tickets.GET_SP_NEW_TICKETS_LIST_SUCCESS, payload: ticketList });
        } else {
            yield put({ type: tickets.GET_SP_NEW_TICKETS_LIST_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.GET_SP_NEW_TICKETS_LIST_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.TICKET_LIST_LOADING, payload: false });
}

export function* getTicketFilteredList(action) {
    let { customerId, next = true, count = true, type = 0, limit, page, payload, query, tab, isSupportPath = false, navigate, closeModal } = action.payload;
    try {
        const loggedUser = yield select(getLoggedUser);
        customerId = getCustomerIdOfLoginUser(loggedUser);
        yield put({ type: loading.TICKET_LIST_LOADING, payload: true });
        let queryParams = { limit, page, next, count, type, tab };
        if (!['', 'all', undefined, null, 0].includes(customerId)) queryParams.customerId = customerId;

        const response = yield call(lambdas.tickets.ticketFilter, {}, {}, queryParams, { ...payload, query });
        if (response.data.code === 200) {
            let ticketList = response.data.data;
            yield put({
                type: tickets.GET_TICKET_FILTERED_LIST_SUCCESS,
                payload: { ticketList, count: response.data.count },
            });
            const ticketIds = Array.isArray(ticketList) && ticketList.map((ticket) => ticket.id);
            if (ticketIds.length > 0) yield put({ type: tickets.GET_TICKET_ASSETS_REMARKS_REQUEST, payload: { ticketIds } });
            if (isSupportPath) {
                if (ticketIds.length === 0) {
                    yield put({ type: tickets.NO_TICKET_FOUND, payload: 'No Tickets Found' });
                    yield put({ type: toast.SEND_TOAST, payload: { status: 'warning', message: 'Ticket not found' } });
                }
                if (ticketIds.length > 0) {
                    if (closeModal) yield put({ type: modal.CLOSE_MODAL });
                    navigate(`${trackSupportTicket.path}?ticketId=${ticketIds[0]}`);
                }
            }
        } else if ([400, 500].includes(response.data.code)) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
            yield put({ type: tickets.GET_TICKET_FILTERED_LIST_SUCCESS, payload: 'Something went wrong' });
        }
    } catch (err) {
        yield put({ type: tickets.GET_TICKET_FILTERED_LIST_SUCCESS, payload: 'Something went wrong' });
    }
    yield put({ type: loading.TICKET_LIST_LOADING, payload: false });
}

export function* getTodayStatusFilterData(action) {
    let { customerId, next = true, count = true, type = 0, limit, page, payload, query } = action.payload;
    try {
        yield put({ type: loading.TODAY_STATUS_LOADING, payload: true });
        let queryParams = { limit, page, next, count, type };
        if (!['', 'all', undefined, null, 0].includes(customerId)) queryParams.customerId = customerId;
        if (!['', undefined, null].includes(query)) queryParams.query = query;
        const response = yield call(lambdas.tickets.todayStatusFilter, {}, {}, queryParams, payload);
        if (response.data.code === 200) {
            yield put({
                type: tickets.GET_TODAY_STATUS_FILTER_DATA_SUCCESS,
                payload: { filterData: response.data.data, filterCount: response.data.filterCount },
            });
        } else if ([400, 500].includes(response.data.code)) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
        }
    } catch (err) {
        yield put({ type: tickets.GET_TODAY_STATUS_FILTER_DATA_FAILURE, payload: 'Something went wrong' });
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
    }
    yield put({ type: loading.TODAY_STATUS_LOADING, payload: false });
}

export function* getTicketAssetsOrRemarks(action) {
    let { ticketIds } = action.payload;
    try {
        yield put({ type: loading.TICKET_ASSET_REMARK_LOADING, payload: true });
        const response = yield call(microservices.tickets.ticketAssets, {}, {}, {}, { ticketIds });
        if (response.data.code === 200) {
            yield put({
                type: tickets.GET_TICKET_ASSETS_REMARKS_SUCCESS,
                payload: { ticketAssets: response.data.data },
            });
        } else if ([400, 500].includes(response.data.code)) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
        }
    } catch (err) {
        yield put({ type: tickets.GET_TICKET_ASSETS_REMARKS_FAILURE, payload: 'Something went wrong' });
    }
    yield put({ type: loading.TICKET_ASSET_REMARK_LOADING, payload: false });
}
export function* getTicketDetailsById(action) {
    let { ticketId } = action.payload
    try {

        const response = yield call(microservices.tickets.ticketAssets, {}, {}, {}, { ticketIds: [ticketId] });
        if (response.data.code === 200) {
            yield put({
                type: tickets.GET_TICKET_DETAILS_BY_ID_SUCCESS,
                payload: response.data.data
            })
        } else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message ? response.data.data.message : 'Something Went Wrong!!!' } });
        }
    }
    catch (err) {
        yield put({ type: tickets.GET_TICKET_DETAILS_BY_ID_FAILURE, payload: 'Something went wrong' })
    }
}

export function* getTicketRemarks(action) {
    let { ticketIds } = action.payload;
    try {
        const response = yield call(microservices.tickets.ticketAssets, {}, {}, {}, { ticketIds });
        if (response.data.code === 200) {
            yield put({
                type: tickets.GET_TICKET_REMARKS_SUCCESS,
                payload: response.data.data,
            });
        } else if ([400, 500].includes(response.data.code)) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
        }
    } catch (err) {
        yield put({ type: tickets.GET_TICKET_REMARKS_FAILURE, payload: 'Something went wrong' });
    }
}

function* refreshTicketList(action) {
    const { limit, page, tab, isFilterActive, payload, query } = action.payload;
    try {
        if (isFilterActive || query?.length > 0) {
            yield put({ type: tickets.GET_TICKET_FILTERED_LIST_REQUEST, payload: { limit, page, tab, payload, query } });
        } else {
            const loggedUser = yield select(getLoggedUser);
            const type = loggedUser.type;
            const role = loggedUser.role.id;
            const { openedTab, sortBy } = getTicketTabNameOrSortBy(role, type, tab);
            yield put({ type: tickets.GET_TICKET_LIST_REQUEST, payload: { limit, page, tab: openedTab, count: true, sortBy } });
        }
    } catch (err) {
        yield put({ type: tickets.GET_TICKET_LIST_FAILURE, payload: { message: err } });
    }
}

function* refreshSPTicketList(action) {
    const { limit, page, tab, isFilterActive, payload, query } = action.payload;
    const type = spTypeLookup[tab];
    try {
        if (isFilterActive || query?.length > 0) {
            yield put({ type: tickets.GET_TICKET_FILTERED_LIST_REQUEST, payload: { limit, page, tab, payload, query } });
        } else {
            yield put({ type: tickets.GET_SP_ACCEPTED_TICKETS_REQUEST, payload: { limit, page, type } });
            yield put({ type: tickets.GET_SP_TICKETS_COUNT_REQUEST });
        }
    } catch (err) {
        yield put({ type: tickets.GET_TICKET_LIST_FAILURE, payload: { message: err } });
    }
}

function* refreshFETicketList(action) {
    const { limit, page, tab, isFilterActive, payload, query } = action.payload;
    const type = feTypeLookup[tab];
    try {
        if (isFilterActive || query?.length > 0) {
            yield put({ type: tickets.GET_TICKET_FILTERED_LIST_REQUEST, payload: { limit, page, tab, payload, query } });
        } else {
            yield put({ type: tickets.GET_FE_ACCEPTED_TICKETS_REQUEST, payload: { limit, page, type } });
            yield put({ type: tickets.GET_FE_TICKETS_COUNT_REQUEST });
        }
    } catch (err) {
        yield put({ type: tickets.GET_TICKET_LIST_FAILURE, payload: { message: err } });
    }
}

export function* refreshTicketTable(action) {
    try {
        yield put({ type: loading.TICKET_LIST_LOADING, payload: true });
        const { limit, page, filterPayload, query, tab, mainTab } = yield select(getFilterDetails);
        let isFilterActive = filterPayload && Object.keys(filterPayload).length > 0;
        const loggedUser = yield select(getLoggedUser);
        const type = loggedUser.type;
        const role = loggedUser.role.id;
        if (mainTab === newMainTab.path) {
            yield put({
                type: tickets.GET_SP_NEW_TICKETS_LIST_REQUEST,
                payload: { limit, page },
            });
        } else if (isFe(role, type)) {
            yield put({
                type: tickets.REFRESH_FE_TICKET_REQUEST,
                payload: { limit, page, tab, isFilterActive, payload: filterPayload, query },
            });
        } else if (isPsp(role, type) || isSp(role, type) || isIsp(role, type)) {
            yield put({ type: tickets.REFRESH_SP_TICKET_REQUEST, payload: { limit, page, tab, isFilterActive, payload: filterPayload, query } });
        } else {
            yield put({ type: tickets.REFRESH_TICKET_REQUEST, payload: { limit, page, tab, isFilterActive, payload: filterPayload, query } });
        }
    } catch (error) {
        yield put({ type: tickets.REFRESH_TICKET_TABLE_FAILURE, payload: 'Something went wrong' });
    }
    yield put({ type: loading.TICKET_LIST_LOADING, payload: false });
}

export function* closeTicket(action) {
    const { data, ticket, formName, ticketExecution = false, navigate, refresh, closeModal = true, isSupportTicket = false } = action.payload;
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    try {
        const response = yield call(api.ticketExecution.closeTicket, {}, {}, {}, data);
        if (response.data.code === 200) {
            yield all([
                yield put({ type: tickets.CLOSE_TICKET_SUCCESS, payload: { message: 'ticket closed', timestamp: Date.now() } }),
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'Sign Off Requested Successfully!!!' } }),
            ]);
            if (closeModal) yield put({ type: modal.CLOSE_MODAL });
            if (refresh) yield put({ type: tickets.REFRESH_TICKET_TABLE_REQUEST });
            if (isSupportTicket) {
                yield put({ type: tickets.GET_TICKET_DETAILS_REQUEST, payload: { ticketId: data.ticket_id } });
            }
            if (!isSupportTicket && ticketExecution && navigate) {
                navigate('/tickets');
            }

        } else {
            if (response.data && !['', undefined, null].includes(response.data.message)) {
                yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.message } });
            }
            yield all([yield put({ type: modal.CLOSE_MODAL }), yield put({ type: tickets.CLOSE_TICKET_FAILURE, payload: { message: 'Oops Something went wrong' } })]);
        }
    } catch (error) {
        yield put({ type: tickets.CLOSE_TICKET_FAILURE, payload: error });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* editExecutionDate(action) {
    const { form, formName, refresh } = action.payload;
    if (formName) {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    }
    try {
        const response = yield call(api.tickets.editExecutionDate, { 'Content-Type': 'multipart/form-data' }, {}, {}, form);
        if (response.data.code === 200) {
            yield all([
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'Ticket Execution Date Changed Successfully!!!' } }),
                yield put({ type: modal.CLOSE_MODAL }),
            ]);
            if (refresh) {
                if (window.location.pathname === todayStatus.path) {
                    yield put({ type: tickets.REFRESH_TODAY_STATUS_TABLE_REQUEST });
                } else {
                    yield put({ type: tickets.REFRESH_TICKET_TABLE_REQUEST });
                }
            }
        } else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
            yield put({ type: tickets.EDIT_EXECUTION_DATE_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.EDIT_EXECUTION_DATE_FAILURE, payload: { message: error } });
    }
    if (formName) {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
    }
}

export function* changeTicketWarehouse(action) {
    const { bulk = false, data, formName, ticketId, refresh = false, refreshViewTicket = false } = action.payload;
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    try {
        let payload = {
            bulk,
            data,
            ticketId,
            property: 'CHANGE_WAREHOUSE',
        };
        const response = yield call(lambdas.tickets.updateTicketProperties, {}, {}, {}, payload);
        if (response.data.code === 200) {
            let message = 'Warehouse Updated Successfully';
            if (response.data.data.message) message = response.data.data.message;
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message } });
            if (refresh) {
                yield put({ type: tickets.REFRESH_TICKET_TABLE_REQUEST });
            }
            if (refreshViewTicket) {
                yield put({ type: tickets.VIEW_TICKET_REQUEST, payload: { ticketId } });
            }
        } else {
            yield put({ type: tickets.CHANGE_TICKET_WAREHOUSE_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.CHANGE_TICKET_WAREHOUSE_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* updateTicketRMADetails(action) {
    const { data, formName, ticketId, refresh = false } = action.payload;
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    try {
        const response = yield call(microservices.tickets.updateTicketRma, {}, { ticketId }, {}, data);
        if (response.data.code === 200) {
            let message = 'Warehouse Updated Successfully';
            if (response.data.data.message) message = response.data.data.message;
            yield all([yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message } }), yield put({ type: modal.CLOSE_FULL_SCREEN_MODAL })]);
            if (refresh) yield put({ type: tickets.REFRESH_TICKET_TABLE_REQUEST });
        } else {
            let message = 'Something went wrong';
            if (response.data.data.message) message = response.data.data.message;
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message } });
            yield put({ type: tickets.UPDATE_TICKET_RMA_DETAILS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.UPDATE_TICKET_RMA_DETAILS_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* acceptRejectAsset(action) {
    const { job_sign_off_notes, site_completed_on, actionType, formName, ticketId, refresh = true } = action.payload;

    const payload = {
        action: actionType,
        job_sign_off_notes,
    };

    if (site_completed_on) payload.site_completed_on = moment(site_completed_on).format('YYYY-MM-DD HH:mm:ss');

    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        let response = yield call(microservices.tickets.closeTicket, {}, { ticketId }, {}, payload);
        if (response.data.code === 200) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: response.data.data.message } });
            yield put({ type: modal.CLOSE_MODAL });
            if (refresh) yield put({ type: tickets.REFRESH_TICKET_TABLE_REQUEST });
        } else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
            yield put({ type: tickets.ACCEPT_REJECT_TICKET_ASSET_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.ACCEPT_REJECT_TICKET_ASSET_FAILURE, payload: { message: error } });
    }

    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* closeFeasibiblity(action) {
    const { data, formName, ticketId, refresh = true, openRaisePoModal = false, job_ticket_number } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        let response = yield call(microservices.tickets.closeTicket, {}, { ticketId }, {}, data);
        if (response.data.code === 200) {
            yield all([
                yield put({ type: modal.CLOSE_FULL_SCREEN_MODAL }),
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: response.data.data.message } })
            ])
            if (openRaisePoModal) {
                const heading = (
                    <>
                        <span className='text-black'>
                            Raise PO |&nbsp;<span className='text-scogobgsky text-font16'>{job_ticket_number}</span>
                        </span>
                    </>
                );
                let modalComponent = <RaisePo ticketId={ticketId} isRefresh heading={heading} />
                yield put({ type: modal.OPEN_FULLSCREEN_MODAL, payload: { heading, modalComponent } });
            }
            else if (refresh) yield put({ type: tickets.REFRESH_TICKET_TABLE_REQUEST });
        } else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
            yield put({ type: tickets.CLOSE_FEASIBILITY_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.CLOSE_FEASIBILITY_FAILURE, payload: { message: error } });
    }

    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* updateDocketDetails(action) {
    const { data, formName, callCloseTicket = true } = action.payload;
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    try {
        const response = yield call(lambdas.tickets.updateDocketDetais, {}, {}, {}, data);
        if ([200, 400, 404, 500].includes(response.data.code)) {
            let status = 'danger';
            if (response.data.code === 200) {
                status = 'success';
                if (data.action === 'HARD_COPY_RECEIVED' && callCloseTicket) {
                    yield put({
                        type: tickets.CLOSE_TICKET_REQUEST,
                        payload: {
                            data: {
                                ticket_id: data.ticket_id,
                                job_sign_off_notes: 'Hard Copy Received',
                            },
                            refresh: true,
                            formName,
                        },
                    });
                } else {
                    yield put({ type: modal.CLOSE_MODAL });
                    yield put({ type: tickets.REFRESH_TICKET_TABLE_REQUEST });
                    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
                }
            }
            yield put({ type: toast.SEND_TOAST, payload: { status, message: response.data.data.message } });
        } else {
            yield put({ type: tickets.UPDATE_TICKET_DOCKET_DETAILS_FAILURE, payload: { message: 'Oops Something went wrong' } });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
        }
    } catch (error) {
        yield put({ type: tickets.UPDATE_TICKET_DOCKET_DETAILS_FAILURE, payload: { message: error } });
    }
}

export function* editTicket(action) {
    const { form, formName, onSuccess = () => { }, updateTicketDetails, toastSuccessMessage = '', supportAsset = {}, redirectTo, navigate, ticketId, closeModal = false } = action.payload;
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    try {
        const response = yield call(api.tickets.editTicket, { 'Content-Type': 'multipart/form-data' }, {}, {}, form);
        if (response.data.code === 200) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: toastSuccessMessage ? toastSuccessMessage : 'TICKET EDIT SUCCESS' } });
            if (updateTicketDetails && ticketId) {
                // yield put({ type: tickets.EDIT_TICKET_SUCCESS, payload: { ...response.data.data, supportAsset } });
                yield put({ type: tickets.GET_TICKET_DETAILS_REQUEST, payload: { ticketId } });
            }
            if (redirectTo && navigate) {
                navigate(redirectTo);
            }
            if (closeModal) {
                yield put({ type: modal.CLOSE_MODAL });
            }
            onSuccess();
        } else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'TICKET EDIT FAIL' } });
            yield put({ type: tickets.EDIT_TICKET_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.EDIT_TICKET_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* createRmaTicket(action) {
    const { data, formName, refresh, onSuccess = () => { }, onFailure = () => { }, addRemark = {} } = action.payload;
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    try {
        const response = yield call(microservices.tickets.createTickets, {}, {}, {}, { location_data: data });
        if ([200, 201].includes(response.data.code)) {
            if (addRemark.ticketId) {
                yield put({
                    type: tickets.UPDATE_TICKET_PROPERTIES_REQUEST, payload: {
                        data: {
                            ticketId: addRemark.ticketId,
                            property: "ADD_REMARK",
                            data: {
                                remark: addRemark.remark
                            }
                        }, formName: 'no_required', closeModal: false, updateTicketList: false
                    }
                });
            }
            let message = response.data.data.message ? response.data.data.message : 'Ticket Create Successfully!!!';
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message } });
            if (refresh) yield put({ type: tickets.REFRESH_TICKET_TABLE_REQUEST });
            onSuccess();
        } else {
            let message = response.data.data.message ? response.data.data.message : 'Something went wrong!!!';
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message } });
            yield put({ type: tickets.CREATE_RMA_TICKET_FAILURE, payload: { message: 'Oops Something went wrong' } });
            onFailure();
        }
    } catch (error) {
        yield put({ type: tickets.CREATE_RMA_TICKET_FAILURE, payload: { message: error } });
        onFailure();
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* cloneTickets(action) {
    const { formData: form, refresh, formName } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(api.tickets.bulkCloneTickets, {}, {}, {}, form);
        if (response.data.code === 200) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'Tickets Cloned Successfully!!!' } });
            yield put({ type: modal.CLOSE_MODAL });
            if (refresh) yield put({ type: tickets.REFRESH_TICKET_TABLE_REQUEST });
        } else {
            yield put({ type: tickets.CLONE_TICKETS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.CLONE_TICKETS_FAILURE, payload: error });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* assignTechSupportUser(action) {
    const { userIds, refresh = true, formName, ticketId, ticketIds } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.tickets.assignTechSupportUser, {}, { ticketId }, {}, { userIds, ticketIds });
        if (response.data.code === 200) {
            yield put({
                type: toast.SEND_TOAST,
                payload: { status: 'success', message: response.data.data.message ? response.data.data.message : 'User Added Successfully' },
            });
            yield put({ type: modal.CLOSE_MODAL });
            if (refresh) {
                const { ticketList } = yield select((state) => state.tickets);
                const ticketIds = Array.isArray(ticketList) && ticketList.map((ticket) => ticket.ticket_id);
                if (ticketIds.length > 0) yield put({ type: tickets.GET_TICKET_ASSETS_REMARKS_REQUEST, payload: { ticketIds } });
            }
        } else {
            yield put({
                type: toast.SEND_TOAST,
                payload: { status: 'danger', message: response.data.data.message ? response.data.data.message : 'Something went wrong' },
            });
            yield put({ type: tickets.ASSIGN_TECH_SUPPORT_USERS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.ASSIGN_TECH_SUPPORT_USERS_FAILURE, payload: error });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* updateCustomerApprovalRecieved(action) {
    const { data, formName, refresh } = action.payload;
    const payload = {
        ...data,
        property: 'EDIT',
    };
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.tickets.updateTicketProperties, {}, {}, {}, payload);
        if (response.data.code === 200) {
            yield put({
                type: toast.SEND_TOAST,
                payload: { status: 'success', message: response.data.data.message ? response.data.data.message : 'Ticket details updated successfully' },
            });
            yield put({ type: modal.CLOSE_MODAL });
            if (refresh) yield put({ type: tickets.REFRESH_TICKET_TABLE_REQUEST });
        } else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something Went Wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.UPDATE_CUSTOMER_APPROVAL_RECIEVED_FAILURE, payload: error.message || 'Something Went Wrong' });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* bulkEditExecutionDate(action) {
    const { form, formName, refresh } = action.payload;
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    try {
        const response = yield call(api.tickets.bulkEditExecutionDate, {}, {}, {}, form);
        if (response.data.code === 200) {
            yield all([
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'Ticket Execution Date Changed Successfully!!!' } }),
                yield put({ type: modal.CLOSE_MODAL }),
            ]);
            if (refresh) yield put({ type: tickets.REFRESH_TICKET_TABLE_REQUEST });
        } else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
            yield put({ type: tickets.BULK_EDIT_EXECUTION_DATE_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.BULK_EDIT_EXECUTION_DATE_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* approveOrRejectPo(action) {
    const { data, formName, ticketId, navigate, refresh = false } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        let response = yield call(microservices.tickets.closeTicket, {}, { ticketId }, {}, data);
        if (response.data.code === 200) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: response.data.data.message } });
            yield put({ type: modal.CLOSE_FULL_SCREEN_MODAL });
            if (refresh) yield put({ type: tickets.REFRESH_TICKET_TABLE_REQUEST });
        } else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
            yield put({ type: tickets.APPROVE_OR_REJECT_PO_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.APPROVE_OR_REJECT_PO_FAILURE, payload: { message: error } });
    }

    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* removePOApprover(action) {
    const { teamId, userId, ticketId, formName, updateReducer } = action.payload;
    const data = {
        property: 'REMOVE_PO_APPROVER',
        ticketId,
        data: {
            userId,
            teamId,
        },
    };
    try {
        if (formName) yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.tickets.updateTicketProperties, {}, {}, {}, data);
        if (response.data.code === 200) {
            yield all([yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: response.data.data.message } })]);
            if (updateReducer) {
                yield put({ type: tickets.REMOVE_PO_APPROVER_SUCCESS, payload: { teamId, userId } });
            }
        } else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something Went Wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.REMOVE_PO_APPROVER_FAILURE, payload: error.message || 'Something Went Wrong' });
    }
    if (formName) yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* getTicketStatsByCustomerId(action) {
    yield put({ type: loading.GET_TICKET_STATS_LOADING, payload: true });
    const { customerId } = action.payload;
    try {
        const response = yield call(microservices.tickets.ticketStats, {}, { customerId });
        if (response.data.code === 200) {
            yield put({ type: tickets.GET_TICKET_STATS_SUCCESS, payload: response.data.data.ticketStatus });
        } else {
            yield put({ type: tickets.GET_TICKET_STATS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.GET_TICKET_STATS_FAILURE, payload: error });
    }
    yield put({ type: loading.GET_TICKET_STATS_LOADING, payload: false });
}

export function* exportTicketCsvData(action) {
    let { module = 'TICKETS', tab, projectId, customerId, endUserId, status = 'ALL', emails = [], data = {}, type = 0, formName, filterData } = action.payload;
    const { filterPayload, tab: storeTab, query } = yield select(getFilterDetails);
    if (!tab) tab = storeTab;
    if (filterPayload) filterData = Object.assign(filterPayload, filterData);

    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        let queryParams = { module, tab, type, status };
        if (query) queryParams.query = query;
        if (!['', 'all', undefined, null].includes(projectId)) queryParams.projectId = projectId;
        if (!['', 'all', undefined, null, 0].includes(customerId)) queryParams.customerId = customerId;
        if (!['', 'all', undefined, null].includes(endUserId)) queryParams.endUserId = endUserId;
        const response = yield call(lambdas.utils.exportCsv, {}, {}, queryParams, { emails, filterData, data });
        if (response.data.code === 200) {
            yield all([yield put({ type: modal.CLOSE_MODAL }), yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: response.data.data.message } })]);
        } else if ([400, 500].includes(response.data.code)) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
            yield put({ type: modal.CLOSE_MODAL });
        }
    } catch (err) {
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* bulkUploadSites(action) {
    const { project_id, formName, file, refresh = false } = action.payload;

    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(api.project.bulkUploadSite, { 'Content-Type': 'multipart/form-data' }, {}, {}, { project_id, file });
        if (response.data.code === 201) {
            let status = 'success';
            let message = 'Sites Added Successfully!!!';
            yield all([
                yield put({ type: toast.SEND_TOAST, payload: { status, message } }),
                yield put({ type: modal.CLOSE_MODAL }),
            ]);

            let bulk = false;
            let duplicateSites = false;
            if (response.data.data.duplicate_sites.length > 0) {
                duplicateSites = true;
            }
            if (
                response.data.data.duplicate_serial_number.length > 0 ||
                response.data.data.error_location_courier.length > 0 ||
                response.data.data.error_pincode.length > 0 ||
                response.data.data.asset_type_error.length > 0
            ) {
                bulk = true;
            }
            if (duplicateSites || bulk) {
                let modalData = {
                    modalComponent: (
                        <DuplicateSite
                            siteDetails={response.data.data}
                            projectId={project_id}
                            bulk={bulk}
                            count={response.data.data.duplicate_sites.length}
                            duplicateSites={duplicateSites}
                        />
                    ),
                    heading: <span className='text-scogoclosed'>Duplicate Sites</span>,
                    modalWidth: '40rem',
                    modalHeight: 'auto',
                };
                yield put({ type: modal.OPEN_CUSTOM_MODAL, payload: modalData });
            } else {
                if (refresh) yield put({ type: tickets.REFRESH_TICKET_TABLE_REQUEST });
            }
        } else {
            yield put({ type: tickets.BULK_UPLOAD_SITE_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.BULK_UPLOAD_SITE_FAILURE, payload: error });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* addNewSites(action) {
    const { projectId, data, existing, addNew, formName } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const form = yield call(generateaddSitesform, { projectId, data, existing, addNew });
        const response = yield call(api.tickets.addSites, { 'Content-Type': 'multipart/form-data' }, {}, {}, form);
        if (response.data.code === 201) {
            let message = `Sites Added Successfully!!!`;
            let status = 'success';
            yield all([
                yield put({ type: toast.SEND_TOAST, payload: { status, message } }),
                yield put({ type: modal.CLOSE_MODAL }),
            ]);
        } else {
            yield put({ type: tickets.ADD_NEW_SITE_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.ADD_NEW_SITE_FAILURE, payload: error });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* raisePO(action) {
    const { formValues, existing, addNew, formName, navigate, refresh } = action.payload;
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    try {
        const form = yield call(generateIndependentTicketform, { ...formValues }, existing, addNew);
        if (form) {
            const response = yield call(microservices.tickets.createTickets, {}, {}, {}, form);
            if (response.data.code === 201) {
                let message = response.data.data && response.data.data.message ? response.data.data.message : `Ticket Created Successfully!!!`;
                yield all([
                    yield put({ type: modal.CLOSE_FULL_SCREEN_MODAL }),
                    yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message } }),
                    yield put({ type: tickets.CREATE_SUPPORT_TICKETS_SUCCESS, payload: response.data }),
                ]);

                if (refresh) navigate('/tickets?tab=new');
            } else {
                let message =
                    response.data.data && response.data.data.message
                        ? response.data.data.message
                        : response.data.errorMessage
                            ? response.data.errorMessage
                            : 'Something Went Wrong';
                yield all([
                    yield put({ type: modal.CLOSE_MODAL }),
                    yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message } }),
                    yield put({ type: tickets.RAISE_PO_FAILURE, payload: { message: 'Oops Something went wrong' } }),
                ]);
            }
        }
    } catch (error) {
        yield put({ type: tickets.RAISE_PO_FAILURE, payload: error });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* getTicketsByIds(action) {
    const { ids } = action.payload;
    try {
        yield put({ type: loading.TICKET_LIST_LOADING, payload: true });
        const response = yield call(lambdas.tickets.getTickets, {}, {}, { ids: ids.join(',') });
        if (response.data.code === 200) {
            yield put({
                type: tickets.GET_TICKETS_BY_IDS_SUCCESS,
                payload: response.data.data,
            });
        } else {
            yield put({ type: tickets.GET_TICKETS_BY_IDS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.GET_TICKETS_BY_IDS_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.TICKET_LIST_LOADING, payload: false });
}

export function* updateTicketPaymentAllowance(action) {
    const { customer_additional_expenses, ticket_id } = action.payload;
    try {
        yield put({ type: loading.UPDATE_TICKET_PAYMENT_ALLOWANCE_LOADING, payload: true });
        const response = yield call(api.tickets.updateTicketAllowance, {}, {}, {}, { customer_additional_expenses, ticket_id });
        if (response.data.code === 200) {
            const message = 'Ticket Allowance Updated Successfull';
            const status = 'success';
            yield put({ type: toast.SEND_TOAST, payload: { status, message } });
        } else {
            yield put({ type: tickets.UPDATE_TICKET_PAYMENT_ALLOWANCE_FAILURE, payload: { message: 'Oops Something went wrong' } });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.UPDATE_TICKET_PAYMENT_ALLOWANCE_FAILURE, payload: { message: error } });
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
    }
    yield put({ type: loading.UPDATE_TICKET_PAYMENT_ALLOWANCE_LOADING, payload: false });
}

export function* resumeTicket(action) {
    const { data, formName } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(api.ticketExecution.resumeTicket, {}, {}, {}, data);
        if (response.data.code === 200) {
            let message = 'Ticket Resumed Successfully!!!';
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message } });
            yield put({ type: modal.CLOSE_MODAL });
            yield put({ type: tickets.REFRESH_TICKET_TABLE_REQUEST });
        } else {
            yield put({ type: tickets.GET_TICKETS_RESUME_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.GET_TICKETS_RESUME_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}
export function* bulkResumeTicket(action) {
    const { data, formName } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(microservices.tickets.bulkResumeTicket, {}, {}, {}, data);
        if (response.data.code === 200) {
            let message = 'Ticket Resumed Successfully!!!';
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message } });
            yield put({ type: modal.CLOSE_MODAL });
            yield put({ type: tickets.REFRESH_TICKET_TABLE_REQUEST });
        } else {
            yield put({ type: tickets.BULK_RESUME_TICKET_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } })

    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}
export function* deleteTicket(action) {
    const { data, formName } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.tickets.deleteTicket, {}, {}, {}, data);
        if (response.data.code === 200) {
            let message = 'Ticket Deleted Successfully!!!';
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message } });
            yield put({ type: modal.CLOSE_MODAL });
            yield put({ type: tickets.REFRESH_TICKET_TABLE_REQUEST });
        } else {
            yield put({ type: tickets.TICKETS_DELETE_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.TICKETS_DELETE_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}
export function* hardCloseTicket(action) {
    const { data, formName } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(api.ticketExecution.hardCloseTicket, {}, {}, {}, data);
        if (response.data.code === 200 || 201) {
            let message = 'Ticket Hard Closed Successfully!!!';
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message } });
            yield put({ type: modal.CLOSE_MODAL });
            yield put({ type: tickets.REFRESH_TICKET_TABLE_REQUEST });
        } else {
            yield put({ type: tickets.TICKETS_HARD_CLOSE_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.TICKETS_HARD_CLOSE_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}
export function* getPoDetails(action) {
    const { ticketId } = action.payload;

    const _id = ticketId.toString();

    let queryParams = { useConversationId: true };

    try {
        yield put({ type: loading.PO_DETAILS_LOADING, payload: true });

        const response = yield call(lambdas.conversations.getConversationDetails, {}, { _id }, queryParams);

        if (response.data.code === 200 || 201) {
            yield put({ type: tickets.PO_DETAILS_SUCCESS, payload: { data: response.data.data } });
        } else {
            yield put({ type: tickets.PO_DETAILS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.PO_DETAILS_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.PO_DETAILS_LOADING, payload: false });
}

export function* requestTrackFE(action) {
    const { ticketId, formName } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        let response = yield call(microservices.tickets.requestTrackFE, {}, {}, {}, { ticketId });
        if (response.data.code === 200) {
            let message = response.data.data.message;
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message } });
            yield put({ type: modal.CLOSE_MODAL });
        } else {
            yield put({ type: tickets.REQUEST_FE_TRACK_IN_TICKET_FAILURE, payload: { message: 'Oops Something went wrong' } });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: response.data.data.message ? response.data.data.message : 'Something went wrong' } });
        }
    } catch (error) {
        let message = 'Request to track failed';
        yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message } });
        yield put({ type: tickets.REQUEST_FE_TRACK_IN_TICKET_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* zipTicketReports(action) {
    const { ticketsData, reportType, emails = [], formName } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });

        const response = yield call(microservices.tickets.requestSignoffZip, {}, {}, { type: reportType }, { tickets: ticketsData, type: reportType, emails });
        if (response.status === 200) {
            let message = response.data.data.message;
            yield put({ type: tickets.ZIP_TICKET_REPORTS_SUCCESS, payload: response.data.data });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message } });
            yield put({ type: modal.CLOSE_MODAL });
        } else {
            yield put({ type: tickets.ZIP_TICKET_REPORTS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.ZIP_TICKET_REPORTS_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* updateSignOffNote(action) {
    const { data, property = 'EDIT', ticketId, formName } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.tickets.updateTicketProperties, {}, {}, {}, { data: data, property, ticketId });
        if (response.data.code === 200) {
            let message = 'Ticket details updated successfully';
            yield all([yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message } }), yield put({ type: modal.CLOSE_MODAL })]);
            yield put({ type: tickets.REFRESH_TICKET_TABLE_REQUEST });
        } else {
            yield all([yield put({ type: modal.CLOSE_MODAL }), yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something Went Wrong' } })]);
        }
    } catch (error) {
        yield put({ type: tickets.UPDATE_TICKET_PROPERTIES_FAILURE, payload: error.message || 'Something Went Wrong' });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* getTicketCount(action) {
    const { customerId, projectIds } = action.payload;
    let queryParam = {};
    if (customerId) queryParam.customerId = customerId;
    if (projectIds) queryParam.projectIds = projectIds.join(',');

    try {
        const loggedUser = yield select(getLoggedUser);
        const type = loggedUser.type;
        const role = loggedUser.role.id;
        let response;
        if (isPsp(role, type) || isSp(role, type)) {
            response = yield call(api.tickets.statusWiseTicketCount, {}, {}, {});
        } else if (isFe(role, type)) {
            response = yield call(api.tickets.feTicketCount, {}, {}, {});
        } else {
            response = yield call(lambdas.tickets.ticketCount, {}, {}, queryParam, {});
        }
        if (response.data.code === 200) {
            yield put({
                type: tickets.GET_TICKETS_COUNT_SUCCESS,
                payload: {
                    ticketsCount: response.data.data,
                    type,
                    role,
                },
            });
        } else {
            yield put({ type: tickets.GET_TICKETS_COUNT_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.GET_TICKETS_COUNT_FAILURE, payload: { message: error } });
    }
}

export function* addMigrationAssets(action) {
    const { ticketId, assets, formName, onSuccess = () => { } } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.tickets.updateTicketProperties, {}, {}, {}, { data: { assets }, property: 'ADD_MIGRATION_ASSET', ticketId });
        if (response.data.code === 200) {
            yield put({ type: tickets.ADD_MIGRATION_ASSETS_SUCCESS, payload: response.data.data });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'Added Assets' } });
            onSuccess();
            yield put({ type: tickets.REFRESH_TICKET_TABLE_REQUEST });
        } else {
            yield put({ type: tickets.ADD_MIGRATION_ASSETS_FAILURE, payload: { message: 'Oops Something went wrong' } });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data?.message || 'Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.ADD_MIGRATION_ASSETS_FAILURE, payload: { message: error } });
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* bulkUploadTicketFile(action) {
    const { property = 'TICKETS', customerId, projectId, ispId, files, formName, refresh = false } = action.payload;
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    try {
        let response = yield call(microservices.tickets.uploadBulkTickets, {}, {}, {}, { property, customerId, projectId, ispId });
        if (response.data.code === 200) {
            if (response.data.data && response.data.data.signedUrl && files && files.length > 0) {
                yield call(uploadFileToS3WithSignedUrl, response.data.data.signedUrl, files[0]);
            }
            yield all([
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: response.data.data.message } }),
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({ type: tickets.BULK_UPLOAD_TICKET_FILE_SUCCESS, payload: response.data.data }),
            ]);
            if (refresh) yield put({ type: tickets.REFRESH_TICKET_TABLE_REQUEST });
        } else {
            yield put({ type: tickets.BULK_UPLOAD_TICKET_FILE_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.BULK_UPLOAD_TICKET_FILE_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* engineerRatingAndComment(action) {
    const { userId, sign_off_quality_comment, sign_off_quality_rating, work_quality_comment, work_quality_rating, fk_ticket_id, closeModal = true, formName } = action.payload;

    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    try {
        let response = yield call(
            lambdas.rating.postFeRating,
            {},
            { userId },
            {},
            { fk_ticket_id, sign_off_quality_comment, sign_off_quality_rating, work_quality_comment, work_quality_rating }
        );
        if (response.data.code === 200) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: response.data.data.message } });
            yield put({ type: tickets.REFRESH_TICKET_TABLE_REQUEST });
            if (closeModal) {
                yield put({ type: modal.CLOSE_MODAL });
            }
        } else {
            yield put({ type: tickets.ENGINEER_RATING_AND_COMMENT_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.ENGINEER_RATING_AND_COMMENT_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}
export function* siteCompleted(action) {
    const {
        ticketId,
        formName,
        refresh = true,
        callViewTicket = false,
        sign_off_quality_comment,
        sign_off_quality_rating,
        work_quality_comment,
        work_quality_rating,
    } = action.payload;

    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    try {
        const response = yield call(
            api.noc.updateNocData,
            {},
            {},
            {},
            { ticket_id: ticketId, sign_off_quality_comment, sign_off_quality_rating, work_quality_comment, work_quality_rating }
        );

        if (response.data.code === 200) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'Site Complete Updated' } });
            yield put({ type: modal.CLOSE_MODAL });
            if (refresh) yield put({ type: tickets.REFRESH_TICKET_TABLE_REQUEST });
            if (callViewTicket) yield put({ type: tickets.VIEW_TICKET_REQUEST, payload: { ticketId } });
        } else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
            yield put({ type: modal.CLOSE_MODAL });
        }
    } catch (error) {
        yield put({ type: tickets.UPDATE_NOC_EXECUTION_DATA_FAILURE, payload: error });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* refreshTodayStatusTable(action) {
    const { limit, page, filterPayload, query } = yield select(getFilterDetails);
    let isFilterActive = filterPayload && Object.keys(filterPayload).length > 0;
    try {
        let payload = {};
        if (isFilterActive || query?.length > 0) {
            payload = { limit, page, payload: filterPayload, query };
            yield put({ type: tickets.GET_TODAY_STATUS_FILTER_DATA_REQUEST, payload });
        } else {
            payload = { limit, page, count: true };
            yield put({ type: tickets.TICKETS_TODAY_STATUS_LIST_REQUEST, payload });
        }
    } catch (error) {
        yield put({ type: tickets.UPDATE_NOC_EXECUTION_DATA_FAILURE, payload: error });
    }
}
export function* askAppointment(action) {
    const { ticketId, email, formName, mobile, cc } = action.payload

    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    try {
        const response = yield call(lambdas.appointment.askAppointment, {}, {}, {}, { ticketId, email, mobile, cc })

        if (response.data.code === 200) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: response.data.data.message } });
            yield put({ type: modal.CLOSE_MODAL });
        }
        else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
            yield put({ type: modal.CLOSE_MODAL });
        }

    } catch (error) {
        yield put({ type: tickets.ASK_APPOINTMENT_FAILURE, payload: error });

    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}


export function* unlockSignoff(action) {
    const { formName, ticketId, remark = '' } = action.payload;
    const data = { "sign_off_lock": 0, remark };
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.tickets.updateTicketProperties, {}, {}, {}, { property: 'UNLOCK_SIGN_OFF', ticketId, data });
        if (response.data.code === 200) {
            yield put({ type: tickets.REFRESH_TICKET_TABLE_REQUEST });
            yield put({ type: modal.CLOSE_MODAL });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: response.data.data?.message || 'Signoff Unlocked' } });
        } else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data?.message || 'Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.UNLOCK_SIGNOFF_FAILURE, payload: { message: error } });
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* getIssueCategoriesFilterList(action) {
    const { customerId, dropdown = true } = action.payload;
    let queryParams = { dropdown };
    if (!isNaN(customerId) && customerId > 0) queryParams.customerId = customerId;
    yield put({ type: loading.LIST_ISSUE_CATEGORIES_LOADING, payload: true });
    try {
        const response = yield call(microservices.admin.getIssueCategories, {}, {}, queryParams);
        if (response.data.code === 200) {
            let issueCategories = response.data.data || [];
            issueCategories = response.data.data.map((issue) => {
                return { key: issue.id, id: issue.id, label: issue.title };
            });
            yield put({ type: tickets.GET_ISSUE_CATEGORY_FILTER_SUCCESS, payload: issueCategories });
        } else {
            yield put({
                type: toast.SEND_TOAST,
                payload: {
                    status: 'danger',
                    message: response.data.data.message,
                },
            });
        }
    } catch (error) {
        yield put({ type: tickets.GET_ISSUE_CATEGORY_FILTER_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.LIST_ISSUE_CATEGORIES_LOADING, payload: false });
}

export function* getActiveVoiceCall(action) {
    try {
        yield put({ type: loading.IS_ACTIVE_VOICE_CALL_TICKET_LIST_LOADING, payload: true });
        const response = yield call(lambdas.tickets.activeVoiceCall, {}, {}, {});
        if (response.data.code === 200) {
            const activeVoiceCallList = response.data.data.tickets;
            yield put({
                type: tickets.GET_ACTIVE_VOICE_CALL_SUCCESS,
                payload: { activeVoiceCallList },
            });
        } else {
            yield put({ type: tickets.GET_ACTIVE_VOICE_CALL_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.GET_ACTIVE_VOICE_CALL_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.IS_ACTIVE_VOICE_CALL_TICKET_LIST_LOADING, payload: false });
}

export function* createSupportTrackTickets(action) {
    const { data, existing, addNew, formName, onSuccess = () => { }, onFailure = () => { }, navigate, redirectToSupportTicket = false, additional_documents, tagId = '' } = action.payload;
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    try {
        const form = yield call(generateIndependentTicketform, { ...data }, existing, addNew);
        if (form) {
            const response = yield call(lambdas.tickets.createIndependentTickets, {}, {}, {}, form);
            if ([200, 201].includes(response.data.code)) {
                let message = `Ticket Created Successfully!!!`;
                let status = 'success';
                yield put({ type: tickets.CREATE_INDEPENDENT_TICKETS_SUCCESS, payload: response.data });
                if (response?.data?.data?.id) {
                    yield put({ type: toast.SEND_TOAST, payload: { message, status } });
                    yield delay(2000);
                    yield put({ type: conversations.GET_CONVERSTAION_DETAILS_REQUEST, payload: { _id: response.data.data.id, useConversationId: true } });
                    const waitForApi = yield take([conversations.GET_CONVERSTAION_DETAILS_SUCCESS, conversations.GET_CONVERSTAION_DETAILS_FAILURE]);
                    if (waitForApi.includes(conversations.GET_CONVERSTAION_DETAILS_FAILURE)) {
                        yield delay(5000);
                        yield put({ type: conversations.GET_CONVERSTAION_DETAILS_REQUEST, payload: { _id: response.data.data.id, useConversationId: true } });

                    }
                    yield put({ type: conversations.PUT_TO_CONVERSATIONS_STORE, payload: { storedDocumentsToSend: additional_documents } });
                    if (redirectToSupportTicket && navigate) {
                        let to = `${trackSupportTicket.path}?ticketId=${response.data.data.id}`;
                        if (tagId) to = `${to}&tag=${tagId}`;
                        navigate(to);
                    }
                }
                if (response?.data?.data?.alreadyExists && data.location_data.length > 0) {
                    onSuccess({ fileData: additional_documents, conversationId: response?.data?.data?.conversationId, isAddToQueue: true, ticketId: response?.data?.data?.ticketId, projectId: response?.data?.data?.projectId });
                    const passedTicketData = data.location_data[0];
                    const payloadData = {
                        formName,
                        siteAddress: passedTicketData.job_site_address_1,
                        issueCategory: passedTicketData.issue_category,
                        description: passedTicketData.description,
                        pincode: passedTicketData.job_fk_pincode_id,
                        executionDate: passedTicketData.execution_date,
                        ticketId: response?.data?.data?.ticketId,
                        siteId: response?.data?.data?.siteId,
                        hardCopyReceived: "dont_send",
                        assetPickupRequired: 'dont_send',
                        hardCopyRequired: 'dont_send',
                        revisitRequired: 'dont_send',
                        externalTicketId: passedTicketData.external_site_id,
                        isRequestSupport: true,
                        updateTicketDetails: true,
                        toastSuccessMessage: 'Ticket Updated Successfully',
                    }
                    const boq = passedTicketData.boq_data.length > 0 ? passedTicketData.boq_data[0] : undefined;
                    if (boq) {
                        payloadData.supportAsset = {
                            serial_number: boq.serial_number,
                            asset_type_id: boq.asset_type_id,
                            quantity: boq.quantity,
                            partcode: boq.partcode,
                        }
                    }
                    let to = `${trackSupportTicket.path}?ticketId=${response?.data?.data?.ticketId}`
                    if (tagId) to = `${to}&tag=${tagId}`;
                    yield put({
                        type: tickets.EDIT_TICKET_REQUEST, payload: {
                            form: generateEditTicketForm(payloadData), formName, updateTicketDetails: true, toastSuccessMessage: 'Ticket Updated Successfully',
                            navigate,
                            redirectTo: to
                        }
                    });
                }
                yield put({ type: modal.CLOSE_MODAL });
            } else {
                let message = response.data.data.message;
                let status = 'danger';
                yield all([
                    yield put({ type: toast.SEND_TOAST, payload: { message, status } }),
                    yield put({ type: tickets.CREATE_INDEPENDENT_TICKETS_FAILURE, payload: { message: 'Oops Something went wrong' } }),
                ]);
                onFailure();
            }
        }
    } catch (error) {
        yield put({ type: tickets.CREATE_INDEPENDENT_TICKETS_FAILURE, payload: error });
        onFailure();
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* moveTORma(action) {
    const { form, formName, onSuccess = () => { }, refresh = true } = action.payload;
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    try {
        const response = yield call(api.tickets.editTicket, { 'Content-Type': 'multipart/form-data' }, {}, {}, form);
        if (response.data.code === 200) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'TICKET MOVED TO RMA' } });
            if (refresh) yield put({ type: tickets.REFRESH_TICKET_TABLE_REQUEST });
            onSuccess();
        } else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.MOVE_TO_RMA_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}
export function* getTicketDetailForDetailPage(action) {
    const { ticketId, shared_by } = action.payload;
    const query = { ticketNumber: ticketId, product: 'superapp' };
    if (shared_by) query.shared_by = shared_by;
    try {
        yield put({ type: loading.VIEW_TICKET_BY_ID_LOADING, payload: true });
        const response = yield call(lambdas.tickets.ticketDetails, {}, {}, query);
        if (response.data.code === 200) {
            yield put({ type: tickets.GET_TICKET_DETAILS_FOR_DETAIL_PAGE_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: tickets.GET_TICKET_DETAILS_FOR_DETAIL_PAGE_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.GET_TICKET_DETAILS_FOR_DETAIL_PAGE_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.VIEW_TICKET_BY_ID_LOADING, payload: false });
}

export function* bulkHardCopyRecievedUpdate(action) {
    const { ticketIds, formName } = action.payload
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.tickets.updateTicketProperties, {}, {}, {}, {
            bulk: true,
            data: { hard_copy_required: 1, hard_copy_received: 1 },
            property: 'BULK_HARDCOPY_RECEIVED',
            tickets: ticketIds
        });
        if (response.data.code === 200) {
            yield all([
                yield put({ type: tickets.REFRESH_TICKET_TABLE_REQUEST }),
                yield put({ type: modal.CLOSE_MODAL }),
            ])
        } else {
            yield put({ type: tickets.BULK_HARDCOPY_RECIEVED_UPDATE_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: tickets.BULK_HARDCOPY_RECIEVED_UPDATE_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } })
};

export function* callOnTicketDetailsCopy(action) {
    const { ticketId } = action.payload;
    const { loggedUser } = yield select((state) => state.auth);
    const url = `https://${config.ui4Domain}${trackSupportTicket.path}?ticketId=${ticketId}&shared_by=${loggedUser.id}`;
    try {
        const response = yield call(lambdas.tickets.callOnTicketDetailsCopy, {}, {}, {}, { ticketId, url });
        if (response.data.code === 200) {

        } else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong, Please Try Again' } });
        }
    } catch (error) {
        console.log('error:', error);
    }
};

export default function* coreTicketSaga() {
    yield takeLatest(tickets.BOQ_LIST_REQUEST, getBOQList);
    yield takeLatest(tickets.RESPOND_FEASIBILITY_TICKET_REQUEST, respondFeasibility);
    yield takeEvery(tickets.VIEW_TICKET_REQUEST, viewTicketById);
    yield takeEvery(tickets.UPDATE_TICKET_COURIER_STATUS_REQUEST, updateCourierStatus);
    yield takeEvery(tickets.GET_CHILD_TICKETS_REQUEST, getChildTickets);
    yield takeEvery(tickets.SOW_DOCUMENT_REQUEST, getSOWDocument);
    yield takeEvery(tickets.SIGN_OFF_SAMPLE_REPORT_REQUEST, getSignOffSampleReport);
    yield takeEvery(tickets.GET_TICKET_DETAILS_REQUEST, getTicketDetails);
    yield takeEvery(tickets.TICKET_REPORT_LINK_REQUEST, getTicketReportLink);
    yield takeEvery(tickets.GET_TICKET_PAYMENTS_REQUEST, getTicketPayments);
    yield takeLatest(tickets.REQUEST_ADDITIONAL_AMOUNT_REQUEST, requestAdditionalAmount);
    yield takeLatest(tickets.PROCESS_ADDITIONAL_AMOUNT_REQUEST, processAdditionalAmount);
    yield takeLatest(tickets.GET_TICKET_LIST_REQUEST, getTicketsList);
    yield takeLatest(tickets.TICKETS_TODAY_STATUS_LIST_REQUEST, getTicketsTodayStatus);
    yield takeLatest(tickets.ADD_FE_INTENTION_STATUS_REQUEST, addfeIntentionStatus);
    yield takeLatest(tickets.UPDATE_NOC_EXECUTION_DATA_REQUEST, updateNocExecutionData);
    yield takeLatest(tickets.UPDATE_TODAY_STATUS_TICKET_PROPERTIES_REQUEST, updateTodaysStatusTicketProperties);
    yield takeLatest(tickets.GET_TICKET_FILTER_LIST_REQUEST, getTicketFilterList);
    yield takeLatest(tickets.GET_TODAY_STATUS_FILTER_DATA_REQUEST, getTodayStatusFilterData);
    yield takeLatest(tickets.GET_SP_ACCEPTED_TICKETS_REQUEST, getSPAcceptedTickets);
    yield takeLatest(tickets.GET_FE_ACCEPTED_TICKETS_REQUEST, getFEAcceptedTickets);
    yield takeLatest(tickets.GET_SP_TICKETS_COUNT_REQUEST, getStatusWiseTicketCount);
    yield takeLatest(tickets.GET_FE_TICKETS_COUNT_REQUEST, getFETicketsCount);
    yield takeLatest(tickets.GET_SP_NEW_TICKETS_LIST_REQUEST, getSPTickets);
    yield takeEvery(tickets.GET_ASSETS_CONFIG_TEMPLATE_REQUEST, getAssetConfigTemplate);
    yield takeEvery(tickets.GET_TICKET_FILTERED_LIST_REQUEST, getTicketFilteredList);
    yield takeEvery(tickets.GET_TICKET_ASSETS_REMARKS_REQUEST, getTicketAssetsOrRemarks);
    yield takeEvery(tickets.REFRESH_TICKET_TABLE_REQUEST, refreshTicketTable);
    yield takeEvery(tickets.REFRESH_TICKET_REQUEST, refreshTicketList);
    yield takeEvery(tickets.REFRESH_SP_TICKET_REQUEST, refreshSPTicketList);
    yield takeEvery(tickets.REFRESH_FE_TICKET_REQUEST, refreshFETicketList);
    yield takeLatest(tickets.CREATE_SUPPORT_TICKETS_REQUEST, createTickets);
    yield takeLatest(tickets.CREATE_INDEPENDENT_TICKETS_REQUEST, createIndependentTickets);
    yield takeEvery(tickets.UPDATE_TICKET_PROPERTIES_REQUEST, updateTicketProperties);
    yield takeLatest(tickets.NOTIFY_TICKETS_TO_ISP_REQUEST, notifyIsp);
    yield takeLatest(tickets.CLOSE_TICKET_REQUEST, closeTicket);
    yield takeLatest(tickets.EDIT_EXECUTION_DATE_REQUEST, editExecutionDate);
    yield takeLatest(tickets.CHANGE_TICKET_WAREHOUSE_REQUEST, changeTicketWarehouse);
    yield takeLatest(tickets.UPDATE_TICKET_RMA_DETAILS_REQUEST, updateTicketRMADetails);
    yield takeLatest(tickets.ACCEPT_REJECT_TICKET_ASSET_REQUEST, acceptRejectAsset);
    yield takeLatest(tickets.UPDATE_TICKET_DOCKET_DETAILS_REQUEST, updateDocketDetails);
    yield takeLatest(tickets.CLOSE_FEASIBILITY_REQUEST, closeFeasibiblity);
    yield takeLatest(tickets.CLONE_TICKETS_REQUEST, cloneTickets);
    yield takeLatest(tickets.ASSIGN_TECH_SUPPORT_USERS_REQUEST, assignTechSupportUser);
    yield takeLatest(tickets.UPDATE_CUSTOMER_APPROVAL_RECIEVED_REQUEST, updateCustomerApprovalRecieved);
    yield takeLatest(tickets.BULK_EDIT_EXECUTION_DATE_REQUEST, bulkEditExecutionDate);
    yield takeLatest(tickets.APPROVE_OR_REJECT_PO_REQUEST, approveOrRejectPo);
    yield takeLatest(tickets.REMOVE_PO_APPROVER_REQUEST, removePOApprover);
    yield takeLatest(tickets.EDIT_TICKET_REQUEST, editTicket);
    yield takeLatest(tickets.CREATE_RMA_TICKET_REQUEST, createRmaTicket);
    yield takeLatest(tickets.GET_TICKET_STATS_REQUEST, getTicketStatsByCustomerId);
    yield takeLatest(tickets.EXPORT_TICKET_CSV_DATA_REQUEST, exportTicketCsvData);
    yield takeLatest(tickets.BULK_UPLOAD_SITE_REQUEST, bulkUploadSites);
    yield takeLatest(tickets.ADD_NEW_SITE_REQUEST, addNewSites);
    yield takeLatest(tickets.RAISE_PO_REQUEST, raisePO);
    yield takeEvery(tickets.GET_TICKETS_BY_IDS_REQUEST, getTicketsByIds);
    yield takeLatest(tickets.UPDATE_TICKET_PAYMENT_ALLOWANCE_REQUEST, updateTicketPaymentAllowance);
    yield takeLatest(tickets.RESUME_TICKET_REQUEST, resumeTicket);
    yield takeLatest(tickets.TICKET_DELETE_REQUEST, deleteTicket);
    yield takeLatest(tickets.HARD_CLOSE_TICKET_REQUEST, hardCloseTicket);
    yield takeLatest(tickets.GET_PO_DETAILS, getPoDetails);
    yield takeLatest(tickets.REQUEST_TRACK_FE, requestTrackFE);
    yield takeEvery(tickets.ZIP_TICKET_REPORTS_REQUEST, zipTicketReports);
    yield takeEvery(tickets.UPDATE_SIGN_OFF_NOTES_REQUEST, updateSignOffNote);
    yield takeEvery(tickets.GET_TICKET_REMARKS, getTicketRemarks);
    yield takeEvery(tickets.GET_TICKETS_COUNT_REQUEST, getTicketCount);
    yield takeEvery(tickets.ADD_MIGRATION_ASSETS_REQUEST, addMigrationAssets);
    yield takeLatest(tickets.SITE_COMPLETED_REQUEST, siteCompleted);
    yield takeLatest(tickets.BULK_UPLOAD_TICKET_FILE_REQUEST, bulkUploadTicketFile);
    yield takeLatest(tickets.SAVE_REQUEST_ADDITIONAL_AMOUNT_REQUEST, saveRequestAdditionalAmount);
    yield takeLatest(tickets.REFRESH_TODAY_STATUS_TABLE_REQUEST, refreshTodayStatusTable);
    yield takeLatest(tickets.ENGINEER_RATING_AND_COMMENT_REQUEST, engineerRatingAndComment);
    yield takeLatest(tickets.GET_TICKET_DETAILS_BY_ID_REQUEST, getTicketDetailsById);
    yield takeLatest(tickets.ASK_APPOINTMENT_REQUEST, askAppointment);
    yield takeLatest(tickets.UNLOCK_SIGNOFF_REQUEST, unlockSignoff);
    yield takeLatest(tickets.BULK_RESUME_TICKET_REQUEST, bulkResumeTicket);
    yield takeLatest(tickets.GET_ACTIVE_VOICE_CALL_REQUEST, getActiveVoiceCall);
    yield takeLatest(tickets.GET_ISSUE_CATEGORY_FILTER_REQUEST, getIssueCategoriesFilterList);
    yield takeLatest(tickets.CREATE_SUPPORT_TRACK_TICKETS_REQUEST, createSupportTrackTickets);
    yield takeLatest(tickets.MOVE_TO_RMA_REQUEST, moveTORma);
    yield takeLatest(tickets.GET_TICKET_DETAILS_FOR_DETAIL_PAGE_REQUEST, getTicketDetailForDetailPage);
    yield takeLatest(tickets.BULK_HARDCOPY_RECIEVED_UPDATE_REQUEST, bulkHardCopyRecievedUpdate);
    yield takeLatest(tickets.CALL_ON_COPY_TICKET_DETAILS_REQUEST, callOnTicketDetailsCopy);

}

import React, { useState } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import MaterialIconToolTip from "./MaterialIconToolTip";
import { modulo } from "../utils/common";

const directionClass = {
    0: 'rotate-0',
    1: 'rotate-90',
    2: 'rotate-180',
    3: '-rotate-90',
}

const ImageZoom = ({ imageUrl }) => {
    const [rotateDegree, setRotateDegree] = useState(0);

    const rotate = (arrowDirection) => {
        const increment = arrowDirection === 'right' ? 1 : -1;
        const nextIndex = modulo(rotateDegree + increment, Object.keys(directionClass).length);
        setRotateDegree(nextIndex);
    }

    return (
        <TransformWrapper
            initialScale={1}
            minScale={0.7}
            maxScale={8}
            centerOnInit={true}
        >
            {({ zoomIn, zoomOut, resetTransform }) => {
                return (
                    <React.Fragment>
                        <div className="h-full w-full">
                            <div className='h-11/12 w-full overflow-hidden flex items-center justify-between'>
                                <TransformComponent contentStyle={{
                                    maxWidth: '100%',
                                    height: '100%',
                                }}
                                    wrapperStyle={{
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                    }}>
                                    <img
                                        src={imageUrl}
                                        alt="Zoomable "
                                        className={`h-11/12 w-11/12 m-auto max-w-max object-contain ${directionClass[rotateDegree]}`}
                                    />
                                </TransformComponent>
                            </div>
                            <div className='h-1/12 w-full flex items-center justify-center'>
                                <div className="bg-scogoprimary flex gap-2 items-center justify-center h-10/12 max-w-max opacity-90">
                                    <MaterialIconToolTip materialIconClass="m-auto px-1 material-icons text-white w-8 cursor-pointer text-2xl" title='Rotate Left' materialIconName='rotate_left' onClick={() => rotate('left')} />
                                    <MaterialIconToolTip materialIconClass="m-auto px-1 material-icons text-white w-8 cursor-pointer text-2xl" title='Zoom Out' materialIconName='remove' onClick={() => zoomOut()} />
                                    <MaterialIconToolTip materialIconClass="m-auto px-1 material-icons text-scogogray opacity-90 w-8 cursor-pointer text-2xl" title='Reset' materialIconName='zoom_out' onClick={() => {
                                        resetTransform();
                                        setRotateDegree(0);
                                    }} />
                                    <MaterialIconToolTip materialIconClass="m-auto px-1 material-icons text-white w-8 cursor-pointer text-2xl" title='Zoom In' materialIconName='add' onClick={() => zoomIn()} />
                                    <MaterialIconToolTip materialIconClass="m-auto px-1 material-icons text-white w-8 cursor-pointer text-2xl font-normal" title='Rotate Right' materialIconName='rotate_right' onClick={() => rotate('right')} />

                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )
            }
            }
        </TransformWrapper >
    )
};

export default ImageZoom;



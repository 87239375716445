import { takeEvery, takeLatest, all, put, call, select } from 'redux-saga/effects';
import { wallet, loading, utils, toast, modal, payments, conversations, tickets } from '../types';
import lambdas from '../lambdas/apis';
import { getPaymentFilterData } from '../selectors/payments';
import { refreshPage } from '../actions/payments';
import api from '../apis';
import { uploadFileToS3WithSignedUrl } from '../utils/common';
import moment from 'moment';

export function* getWalletDetails(action) {
    yield put({ type: loading.GET_WALLET_BALANCE_LOADING, payload: true });
    try {
        const { updateBalance, showSuccessToast } = action.payload;
        const response = yield call(lambdas.wallet.getWalletDetails, {}, {}, { updateBalance });
        if (response.data.code === 200) {
            yield put({ type: wallet.GET_WALLET_DETAILS_SUCCESS, payload: response.data.data });
            if (showSuccessToast) {
                yield put({ type: toast.SEND_TOAST, payload: { message: `Latest Balance Amount will be updated in 5 mins`, status: 'success' } });
            }
        } else {
            yield put({ type: wallet.GET_WALLET_DETAILS_FAILURE, payload: { message: 'Oops Something went wrong' } });
            yield put({ type: toast.SEND_TOAST, payload: { message: response.data.data?.message || 'Something went Wrong!', status: 'danger' } });
        }
    } catch (error) {
        yield put({ type: wallet.GET_WALLET_DETAILS_FAILURE, payload: { message: error } });
        yield put({ type: toast.SEND_TOAST, payload: { message: 'Something went Wrong!', status: 'danger' } });
    }
    yield put({ type: loading.GET_WALLET_BALANCE_LOADING, payload: false });
}

export function* withdrawWalletAmount(action) {
    const { data, refresh = true, formName, limit, page, status } = action.payload;

    let message = '';
    if (data.type === 'WITHDRAW') {
        message = 'Withdraw Successfull!!';
    }
    if (data.type === 'TRANSFER') {
        message = 'Transfered Successfully!!';
    }
    if (data.type === 'PAY_PO_ITEM') {
        message = 'Paid Successfully!!';
    }
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.wallet.withdraw, {}, {}, {}, data);
        if (response.data.code === 200) {
            yield put({ type: toast.SEND_TOAST, payload: { message: response.data.data.message ? response.data.data.message : message, status: 'success' } });
            yield put({ type: modal.CLOSE_MODAL });
            if (refresh) {
                yield put({ type: wallet.GET_WALLET_DETAILS_REQUEST, payload: { updateBalance: true } });
                yield put({ type: wallet.GET_PROMISED_AMOUNT_REQUEST, payload: {} });
                yield put({ type: wallet.GET_WALLET_TRANSACTIONS_REQUEST, payload: { limit, page, status } });
            }
        } else {
            if (response.data.data && ['', undefined, null].includes(response.data.data.message)) {
                message = 'Withdraw Failure!!!';
            }
            yield put({ type: toast.SEND_TOAST, payload: { message: response.data.data.message, status: 'danger' } });
        }
    } catch (error) {
        message = error.message || `Something went Wrong!!!`;
        yield put({ type: toast.SEND_TOAST, payload: { message: message, status: 'danger' } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* getPromisedAmount(action) {
    try {
        const response = yield call(lambdas.tickets.getPromisedAmount, {});
        if (response.data.code === 200) {
            yield put({ type: wallet.GET_PROMISED_AMOUNT_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: toast.SEND_TOAST, payload: { message: response.data.data.message, status: 'danger' } });
        }
    } catch (error) {
        yield put({ type: wallet.GET_PROMISED_AMOUNT_FAILURE, payload: { message: error } });
    }
}

export function* getWalletPaymentStatus(action) {
    const { scogo_transaction_no } = action.payload;
    let status = 'danger',
        message = 'Something went Wrong!!!';
    try {
        const response = yield call(lambdas.wallet.getWalletPaymentStatus, {}, {}, { scogo_transaction_no });
        if (response.data.code === 200) {
            if (response.data.data && response.data.data.message) {
                status = 'success';
                message = response.data.data.message;
                yield put({ type: toast.SEND_TOAST, payload: { status, message } });
            } else {
                if (window.location.pathname === '/payments') {
                    yield put({ type: payments.UPDATE_WALLET_PAYMENT_STATUS_SUCCESS, payload: response.data.data });
                }
            }
        } else {
            message = response.data.data?.message;
            yield put({ type: toast.SEND_TOAST, payload: { status, message } });
        }
    } catch (error) {
        message = error.message || `Something went Wrong!!!`;
        yield put({ type: toast.SEND_TOAST, payload: { status, message } });
    }
}

export function* getWalletOrderStatus(action) {
    const { orderId } = action.payload;
    let status = 'danger',
        message = 'Something went Wrong!!!';
    try {
        const response = yield call(lambdas.wallet.getWalletOrderStatus, {}, {}, { orderId });
        if (response.data.code === 200) {
            yield put({ type: payments.REFRESH_WALLET_ORDER_STATUS_SUCCESS, payload: response.data.data });
            if (response.data.data?.message) {
                status = 'success';
                message = response.data.data.message;
                yield put({ type: toast.SEND_TOAST, payload: { status, message } });
            }
        } else {
            message = response.data.data?.message;
            yield put({ type: toast.SEND_TOAST, payload: { status, message } });
            yield put({ type: payments.REFRESH_WALLET_ORDER_STATUS_FAILURE, payload: response.data.data?.message });
        }
    } catch (error) {
        message = error.message || `Something went Wrong!!!`;
        yield put({ type: toast.SEND_TOAST, payload: { status, message } });
        yield put({ type: payments.REFRESH_WALLET_ORDER_STATUS_FAILURE, payload: error });
    }
    yield put({ type: loading.GET_WALLET_TRANSACTIONS_LOADING, payload: false });
}

export function* getWalletTransactions(action) {
    const {
        transactionType = 'WALLET',
        limit = 10,
        page = 1,
        customerId,
        status = 'all',
        requestStatement = false,
        fromDate,
        toDate,
        allusers = 'false',
    } = action.payload;
    let queryParams = { transactionType, limit, page, status, requestStatement, allusers };
    if (!['', undefined, null].includes(customerId)) queryParams.customerId = customerId;
    if (!['', undefined, null].includes(fromDate)) queryParams.fromDate = fromDate;
    if (!['', undefined, null].includes(toDate)) queryParams.toDate = toDate;

    try {
        yield put({ type: loading.GET_WALLET_TRANSACTIONS_LOADING, payload: true });
        const response = yield call(lambdas.wallet.getTransactions, {}, {}, queryParams);
        if (response.data.code === 200) {
            yield put({ type: wallet.GET_WALLET_TRANSACTIONS_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: wallet.GET_WALLET_TRANSACTIONS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: wallet.GET_WALLET_TRANSACTIONS_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.GET_WALLET_TRANSACTIONS_LOADING, payload: false });
}

export function* getNextWalletTransactions(action) {
    const {
        transactionType = 'WALLET',
        limit = 10,
        page = 1,
        customerId,
        status = 'all',
        requestStatement = false,
        fromDate,
        toDate,
        email,
        allusers = 'false',
    } = action.payload;
    let queryParams = { transactionType, limit, page, status, requestStatement, allusers };
    if (!['', undefined, null].includes(customerId)) queryParams.customerId = customerId;
    if (!['', undefined, null].includes(fromDate)) queryParams.fromDate = fromDate;
    if (!['', undefined, null].includes(toDate)) queryParams.toDate = toDate;
    if (!['', undefined, null].includes(email)) queryParams.email = email;
    try {
        yield put({ type: loading.GET_WALLET_NEXT_TRANSACTIONS_LOADING, payload: true });
        const response = yield call(lambdas.wallet.getTransactions, {}, {}, queryParams);
        if (response.data.code === 200) {
            yield put({ type: wallet.GET_WALLET_NEXT_TRANSACTIONS_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: wallet.GET_WALLET_NEXT_TRANSACTIONS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: wallet.GET_WALLET_NEXT_TRANSACTIONS_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.GET_WALLET_NEXT_TRANSACTIONS_LOADING, payload: false });
}

export function* addAmountToWallet(action) {
    const { orderAmount, securityDeposit = 0, formName } = action.payload;
    let message = 'Something went Wrong!!!';
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.wallet.addAmount, {}, {}, {}, { orderAmount, securityDeposit });
        if (response.data.code === 200) {
            yield put({ type: wallet.ADD_AMOUNT_TO_WALLET_SUCCESS, payload: response.data });
            yield put({ type: modal.CLOSE_MODAL });
        } else {
            message = response.data.data.message;
            yield put({ type: wallet.ADD_AMOUNT_TO_WALLET_SUCCESS, payload: null });
            yield put({ type: toast.SEND_TOAST, payload: { message: message, status: 'danger' } });
        }
    } catch (error) {
        message = error.message || `Something went Wrong!!!`;
        yield put({ type: toast.SEND_TOAST, payload: { message: message, status: 'danger' } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* payPoManually(action) {
    let { ticketId, fileData, reason = 'PO_MANUAL_PAYMENT', formName, data, isRefresh, callViewTicket, onSuccess, enduserId } = action.payload;

    const files = new Array(...fileData.map((item) => item.file));
    const filesInRequestBody = fileData.map(({ file }) => ({
        fileName: file.name,
        mimeType: file.type,
    }));

    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.user.newResourceFile, {}, {}, {}, { files: filesInRequestBody, ticketId, reason });
        if (response.data.code === 200) {
            yield* response.data.data.map(({ signedUrl, fileName }) => {
                const file = files.find((f) => f.name === fileName);
                return call(uploadFileToS3WithSignedUrl, signedUrl, file);
            });
            const attachments = [];
            response.data.data.map(({ fileUrl, mimeType, fileName }) => {
                attachments.push({
                    name: fileName,
                    url: fileUrl,
                    mimeType,
                });
            });
            data.attachments = attachments;
            yield put({ type: wallet.PAY_PO_REQUEST, payload: { data, formName, isRefresh, callViewTicket, onSuccess, enduserId } });
        } else {
            yield all([
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something Went wrong' } }),
                yield put({ type: wallet.PAY_PO_MANUALLY_FAILURE, payload: { message: 'Could not upload files' } }),
            ]);
        }
    } catch (error) {
        yield put({ type: wallet.PAY_PO_MANUALLY_FAILURE, payload: { message: error } });
    }
}

export function* payPO(action) {
    const { data, formName, isRefresh, callViewTicket, onSuccess = () => { }, enduserId } = action.payload;
    const { tab } = yield select(getPaymentFilterData);
    let message = '';
    if (data.type === 'WITHDRAW') message = 'Withdraw Successfull!!';
    if (data.type === 'TRANSFER') message = 'Transfered Successfully!!';
    if (data.type === 'PAY_PO_ITEM') message = 'Paid Successfully!!';
    if (data.type === 'BANK_TRANSFER') message = 'Paid Successfully!!';
    if (data.type === 'PAY_PO_ITEM_MANUALLY') message = 'Paid Successfully!!';

    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.wallet.withdraw, {}, {}, {}, data);
        if (response.data.code === 200) {
            if (response?.data?.data?.message) message = response.data.data.message;
            yield put({ type: toast.SEND_TOAST, payload: { message: message, status: 'success' } });
            yield put({ type: conversations.GET_CONVERSTAION_DETAILS_REQUEST, payload: { _id: data.ticketId, useConversationId: true } });
            if (isRefresh) {
                yield put(refreshPage({ tab, enduserId }));
            }
            if (callViewTicket) {
                onSuccess();
                yield put({ type: tickets.VIEW_TICKET_REQUEST, payload: { ticketId: data.ticketId } });
            }
        } else {
            if (response.data.data && ['', undefined, null].includes(response.data.data.message)) {
                message = 'Withdraw Failure!!!';
            }
            yield put({ type: toast.SEND_TOAST, payload: { message: response.data.data.message, status: 'danger' } });
        }
    } catch (error) {
        message = error.message || `Something went Wrong!!!`;
        yield put({ type: toast.SEND_TOAST, payload: { message: message, status: 'danger' } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* requestStatement(action) {
    const {
        userId,
        transactionType = 'WALLET',
        count = false,
        limit = 10,
        page = 1,
        customerId,
        status = 'all',
        requestStatement = false,
        fromDate,
        toDate,
        emails,
        allusers = 'false',
        formName,
    } = action.payload;
    let queryParams = { transactionType, count, limit, page, status, requestStatement, allusers, userId };
    if (!['', 0, undefined, null].includes(customerId)) queryParams.customerId = customerId;
    if (!['', undefined, null].includes(fromDate)) queryParams.fromDate = fromDate;
    if (!['', undefined, null].includes(toDate)) queryParams.toDate = toDate;
    if (!['', undefined, null].includes(emails)) queryParams.emails = emails;

    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.wallet.getTransactions, {}, {}, queryParams);
        if (response.data.code === 200) {
            let status = 'success';
            let message = response.data.data.message;
            yield put({ type: toast.SEND_TOAST, payload: { status, message } });
            yield put({ type: modal.CLOSE_MODAL });
        } else {
            yield put({ type: wallet.REQUEST_STATEMENT_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: wallet.REQUEST_STATEMENT_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* getRecepientsFilterdData(action) {
    let { module = 'RECEPIENTS', next = true, count = false, type = 0, limit, page, payload } = action.payload;
    try {
        yield put({ type: loading.SET_RECEPIENTS_FILTERED_DATA_LOADING, payload: true });
        let queryParams = { module, limit, page, next, count, type, lastScannedIndex: 0 };
        const response = yield call(lambdas.utils.filterModule, {}, {}, queryParams, payload);
        if (response.data.code === 200) {
            yield put({ type: wallet.GET_RECEPIENTS_FILTERED_DATA_SUCCESS, payload: response.data.data });
        } else if ([400, 500].includes(response.data.code)) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
        }
    } catch (err) {
        yield put({ type: wallet.GET_RECEPIENTS_FILTERED_DATA_FAILURE, payload: 'Something went wrong' });
    }
    yield put({ type: loading.SET_RECEPIENTS_FILTERED_DATA_LOADING, payload: false });
}

export function* updateWalletAfterPaymentGatewaySuccess(action) {
    const { orderId, status, customerId, transactionType = 'WALLET', limit, page, walletType } = action.payload;
    try {
        const response = yield call(lambdas.wallet.updateWallet, {}, {}, {}, { orderId, status });
        if (response.data.code === 200) {
            yield put({
                type: wallet.GET_WALLET_TRANSACTIONS_REQUEST,
                payload: { transactionType, count: true, limit, page, customerId, status: walletType },
            });
            yield put({ type: wallet.GET_WALLET_DETAILS_REQUEST, payload: { updateBalance: true } });
        } else {
            yield put({ type: wallet.UPDATE_WALLET_AFTER_PAYMENT_GATEWAY_SUCCESS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: wallet.UPDATE_WALLET_AFTER_PAYMENT_GATEWAY_SUCCESS_FAILURE, payload: { message: error } });
    }
}

export function* approveWalletPayment(action) {
    const { data, isRefresh, instantpay = false, navigateBack, navigate } = action.payload;
    let status = 'success',
        message = '';
    const { tab } = yield select(getPaymentFilterData);
    try {
        if (data.action === 'Approve' && !instantpay) {
            yield put({ type: loading.APPROVE_WALLET_LOADING, payload: true });
        }

        if (instantpay) {
            yield put({ type: loading.INSTANT_PAY_WALLET_LOADING, payload: true });
        }

        const response = yield call(api.wallet.approvePayment, {}, {}, {}, data);
        message = response.data.message;
        if (response.data.code === 200) {
            message = 'Payment Approved Successfully';
            if (data.action === 'Approve' && instantpay) {
                message = 'Payment was Successful!!!';
            }
            if (data.action === 'Reject') {
                status = 'danger';
                message = 'Payment Rejected!!!!';
            }
            if (isRefresh) {
                yield put(refreshPage({ tab }));
            }
            if (navigateBack) {
                navigate(-1)
            }
        } else {
            status = 'danger';
        }
    } catch (error) {
        status = 'danger';
        message = error.message || `Something went Wrong!!!`;
    } finally {
        yield put({ type: toast.SEND_TOAST, payload: { status, message } });
        yield put({ type: modal.CLOSE_MODAL });
        yield put({ type: loading.APPROVE_WALLET_LOADING, payload: false });
        yield put({ type: loading.INSTANT_PAY_WALLET_LOADING, payload: false });
    }
}

export function* getWalletTransactionsDetails(action) {
    const { data, copyToClipboard = false } = action.payload;
    try {
        if (copyToClipboard) {
            yield put({ type: loading.COPY_TRANSACTIONS_DETAILS_LOADING, payload: true });
        }
        const response = yield call(lambdas.wallet.getTransactionsDetails, {}, {}, {}, data);
        if (response.data.code === 200) {
            yield put({ type: wallet.GET_WALLET_TRANSACTIONS_DETAILS_SUCCESS, payload: response.data.data });
            if (copyToClipboard && response.data.data.length > 0) {
                const txnDetails = response.data.data[0];
                let content = '';
                if (txnDetails.paymentType === 'WALLET_TRANSFER') {
                    content = '';
                    content += `Mode of Payment: Scogo Wallet\n`;
                    content += `Amount: ${txnDetails.orderAmount}\n`;
                    content += `Scogo Transaction No: ${txnDetails.scogo_tx_no}\n`;
                    content += `Transaction Time: ${moment(txnDetails.txTime).add(5.50, 'hours').format('DD-MM-YYYY HH:mm:ss')}`;
                } else if (['BANK_TRANSFER_DEBIT', 'BANK_TRANSFER_CREDIT'].includes(txnDetails.paymentType)) {
                    content = '';
                    content += `Mode of Payment: Bank Transfer\n`;
                    if (txnDetails.user) {
                        content += `Recepient Name: ${txnDetails.user.first_name} ${txnDetails.user.last_name}\n`;
                    }
                    content += `Transaction No: ${txnDetails.referenceId}\n`;
                    content += `Account Number: ${txnDetails.cardNumber}\n`;
                    content += `Amount: ${txnDetails.orderAmount}\n`;
                    content += `Transaction Time: ${moment(txnDetails.txTime).add(5.50, 'hours').format('DD-MM-YYYY HH:mm:ss')}`;
                }
                if (!['', undefined, null].includes(content)) {
                    yield put({ type: utils.COPY_TO_CLIPBOARD_REQUEST, payload: { data: content } });
                }
            }
        } else {
            yield put({ type: wallet.GET_WALLET_TRANSACTIONS_DETAILS_FAILURE, payload: 'Something went wrong' });
        }
    } catch (error) {
        yield put({ type: wallet.GET_WALLET_TRANSACTIONS_DETAILS_FAILURE, payload: 'Something went wrong' });
    }
    if (copyToClipboard) {
        yield put({ type: loading.COPY_TRANSACTIONS_DETAILS_LOADING, payload: false });
    }
}

export function* paySecurityDeposit(action) {
    yield put({ type: loading.PAY_SECURITY_DEPOSIT_LOADING, payload: true });
    try {
        const response = yield call(lambdas.wallet.addAmount, {}, {}, {}, { securityDeposit: 1 });
        if (response.data.code === 200) {
            if (response.data.data && response.data.data.message) {
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: response.data.data.message } });
            } else {
                yield put({ type: wallet.PAY_SECURITY_DEPOSIT_SUCCESS, payload: response.data });
            }
        } else {
            yield put({ type: wallet.PAY_SECURITY_DEPOSIT_FAILURE, payload: { message: 'Oops Something went wrong' } });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
        }
    } catch (error) {
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Oops Something went wrong' } });
    }
    yield put({ type: loading.PAY_SECURITY_DEPOSIT_LOADING, payload: false });
}

export function* getWalletDetailsById(action) {
    yield put({ type: loading.GET_WALLET_BALANCE_BY_ID_LOADING, payload: true });
    try {
        const { updateBalance, showSuccessToast, beneficiary, accountBalance } = action.payload;
        const queryParams = { updateBalance, beneficiary };
        if (accountBalance) queryParams.accountBalance = accountBalance;
        const response = yield call(lambdas.wallet.getWalletDetails, {}, {}, queryParams);
        if (response.data.code === 200) {
            yield put({ type: wallet.GET_WALLET_DETAILS_BY_ID_SUCCESS, payload: response.data.data });
            if (showSuccessToast) {
                yield put({ type: toast.SEND_TOAST, payload: { message: `Latest Balance Amount will be updated in 5 mins`, status: 'success' } });
            }
        } else {
            yield put({ type: wallet.GET_WALLET_DETAILS_BY_ID_FAILURE, payload: { message: 'Oops Something went wrong' } });
            yield put({ type: toast.SEND_TOAST, payload: { message: response.data.data?.message || 'Something went Wrong!', status: 'danger' } });
        }
    } catch (error) {
        yield put({ type: wallet.GET_WALLET_DETAILS_BY_ID_FAILURE, payload: { message: error } });
        yield put({ type: toast.SEND_TOAST, payload: { message: 'Something went Wrong!', status: 'danger' } });
    }
    yield put({ type: loading.GET_WALLET_BALANCE_BY_ID_LOADING, payload: false });
}


export default function* walletSaga() {
    yield takeEvery(wallet.GET_WALLET_DETAILS_REQUEST, getWalletDetails);
    yield takeEvery(wallet.WITHDRAW_WALLET_AMOUNT_REQUEST, withdrawWalletAmount);
    yield takeEvery(wallet.GET_PROMISED_AMOUNT_REQUEST, getPromisedAmount);
    yield takeEvery(wallet.GET_WALLET_PAYMENTS_STATUS_REQUEST, getWalletPaymentStatus);
    yield takeEvery(wallet.GET_WALLET_TRANSACTIONS_REQUEST, getWalletTransactions);
    yield takeEvery(wallet.GET_WALLET_NEXT_TRANSACTIONS_REQUEST, getNextWalletTransactions);
    yield takeEvery(wallet.ADD_AMOUNT_TO_WALLET_REQUEST, addAmountToWallet);
    yield takeLatest(wallet.PAY_PO_MANUALLY_REQUEST, payPoManually);
    yield takeLatest(wallet.PAY_PO_REQUEST, payPO);
    yield takeEvery(wallet.REQUEST_STATEMENT_REQUEST, requestStatement);
    yield takeEvery(wallet.GET_RECEPIENTS_FILTERED_DATA_REQUEST, getRecepientsFilterdData);
    yield takeLatest(wallet.UPDATE_WALLET_AFTER_PAYMENT_GATEWAY_SUCCESS_REQUEST, updateWalletAfterPaymentGatewaySuccess);
    yield takeLatest(wallet.APPROVE_WALLET_PAYMENT_REQUEST, approveWalletPayment);
    yield takeLatest(wallet.GET_WALLET_TRANSACTIONS_DETAILS_REQUEST, getWalletTransactionsDetails);
    yield takeLatest(wallet.PAY_SECURITY_DEPOSIT_REQUEST, paySecurityDeposit);
    yield takeLatest(wallet.GET_WALLET_DETAILS_BY_ID_REQUEST, getWalletDetailsById);
}

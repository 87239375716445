import React, { useEffect, useState, useCallback, forwardRef, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import AssetSerialNumber from '../../Modals/Inventory/AssetSerialNumber';
import SpareAssetTicketTrackingColumn from '../reusableComponents/AddOption/SpareAssetTicketTrackingColumn';
import OverflowTip from '../../../common/OverflowTip';
import Checkbox from '../../../common/Checkbox';
import DropdownMenu from '../../../common/MenuDropdown';
import { getCustomerIdOfLoginUser } from '../../../utils/utils';
import {
    getAllSpareAssets,
    removeSpareAssetDataFromStore,
    getSpareAssetFilterList,
    clearInventoryTableDataOnUnmount,
    setInventoryFilterAndPaginationData,
    clearSiteOrWarehouseIdFromStore,
    getSpareAssetFilteredData,
} from '../../../actions/inventory';
import { copyToClipboard, setClearFilterClickAction } from '../../../actions/utils';
import { actionHead } from '../../../common/tabeHelpers';
import { GetInventoryActions } from '../InventoryController';
import IconToolTip from '../../../common/IconToolTip';
import { clearFilterModuleData } from '../../../actions/utils';
import APIFilterPopup from '../../../common/APIFilters/FilterPopup';
import { clickableTextClass } from '../../../common/Css';
import { useQuery } from '../../../utils/common';
import { validateFilterNumber, readFilterQueryParams, transformQueryFilters } from '../../../utils/filter';
import { DateComponent } from '../../../common/TableReusableColumns';
import VirtualizedTable from '../../../common/VirtualizedTable';
import { RenderEditAssetConfig } from '../sites/AssetDetails';

const filterSchema = {
    customersFilter: {
        filterName: 'customers',
        title: 'Customer',
        typeOfFilter: 'checkbox',
        apiValueKey: 'customer_id',
        displayValueKey: 'customer_company_name',
        submitKey: 'customerId',
        queryParamKey: 'customerId',
        parse: ({ customerId }) => {
            let values = validateFilterNumber(customerId);
            if (values) return { customerId: values };
            return {};
        },
    },
    assetTypeFilter: {
        filterName: 'assets',
        title: 'Asset Type',
        typeOfFilter: 'checkbox',
        apiValueKey: 'id',
        displayValueKey: 'title',
        submitKey: 'title',
        queryParamKey: 'title',
        parse: ({ title }) => {
            let values = validateFilterNumber(title);
            if (values) return { title: values };
            return {};
        },
    },
    categoryFilter: {
        filterName: 'spareCategory',
        title: 'Spare Category',
        typeOfFilter: 'checkbox',
        apiValueKey: 'id',
        displayValueKey: 'title',
        submitKey: 'category',
        queryParamKey: 'category',
        parse: ({ category }) => {
            let values = validateFilterNumber(category);
            if (values) return { category: values };
            return {};
        },
    },
    warehouseFilter: {
        filterName: 'warehouses',
        title: 'Warehouse',
        typeOfFilter: 'checkbox',
        apiValueKey: 'id',
        displayValueKey: 'name',
        submitKey: 'warehouseId',
        queryParamKey: 'warehouseId',
        parse: ({ warehouseId }) => {
            let values = validateFilterNumber(warehouseId);
            if (values) return { warehouseId: values };
            return {};
        },
    },
    descripenciesFilter: {
        filterName: 'descripencies',
        title: 'Descripencies',
        typeOfFilter: 'checkbox',
        apiValueKey: 'title',
        displayValueKey: 'title',
        submitKey: 'discrepancy',
        queryParamKey: 'discrepancy',
    },
    statusFilter: {
        filterName: 'status',
        title: 'Status',
        typeOfFilter: 'checkbox',
        apiValueKey: 'apiValueKey',
        displayValueKey: 'displayKey',
        submitKey: 'status',
        queryParamKey: 'status',
    },
    workingConditionFilter: {
        filterName: 'working_condition',
        title: 'Working Condition',
        typeOfFilter: 'checkbox',
        apiValueKey: 'apiValueKey',
        displayValueKey: 'displayKey',
        submitKey: 'working_condition',
        queryParamKey: 'working_condition',
    },
    serialNumberFilter: {
        filterName: 'serialNumberSort',
        title: 'Serial Number Sort',
        typeOfFilter: 'checkbox',
        apiValueKey: 'apiValueKey',
        displayValueKey: 'displayKey',
        submitKey: 'serialNumberSort',
        queryParamKey: 'serialNumberSort',
    },
};

export const SpareAssetDetails = forwardRef(
    (
        {
            selectedWarehouseId,
            inventoryRowsPerPage,
            inventoryPage,
            setInventoryRowsAndPage,
            openInventorySideDrawer,
            closeInventorySideDrawer,
            selectedSubTab,
            role,
            type,
            defaultRowsPerPage,
            defaultPage,
            setClearFilterButton,
            limitQueryParam,
            pageQueryParam,
            searchQueryParam,
            queryParams,
            updateQueryFilters,
            warehouseIdQueryParam,
        },
        ref
    ) => {
        const dispatch = useDispatch();

        const warehouseId = selectedWarehouseId ? selectedWarehouseId : 0;

        const { spareAssets, spareAssetsCount, spareAssetFiltersData, showInventorySideDrawer, inventorySideDrawerPayload } = useSelector((state) => state.inventory);
        const { spareAssetLoading, spareAssetFilterLoading } = useSelector((state) => state.loading);
        const { loggedUser } = useSelector((state) => state?.auth);
        const { filteredData, filterDataCount } = useSelector((state) => state.utils);

        const customerId = loggedUser && getCustomerIdOfLoginUser(loggedUser);

        const [isViewingMore, setIsViewingMore] = useState({});
        const [inventoryAssets, setInventoryAssets] = useState(spareAssets);
        const [checkedSpareAssetsTracker, setCheckedSpareAssetsTracker] = useState(() => new Set());

        const toShowCustomer = GetInventoryActions('isToShowCustomerData');
        const [filterList, setFilterList] = useState({});
        const [filterQueryObject, setFilterObject] = useState({});
        const [filtersActiveStatus, setFiltersActiveStatus] = useState({});
        const query = useQuery();

        const getAssetData = useCallback(
            ({ isFilterApplied, searchTerm, limit, page, payload, warehouseId }) => {
                if (!limit) limit = defaultRowsPerPage;
                if (!page) page = defaultPage;
                if (isFilterApplied || searchTerm?.length > 0) {
                    dispatch(getSpareAssetFilteredData({ customerId: customerId, count: true, limit, page, query: searchTerm, payload, warehouseId }));
                } else {
                    dispatch(getAllSpareAssets({ warehouseId, customerId, count: true, limit, page }));
                }
            },
            [customerId, defaultPage, defaultRowsPerPage, dispatch]
        );

        useEffect(() => {
            let { queryObject, filterStatus } = readFilterQueryParams(filterSchema, query);
            setFilterObject(queryObject);
            setFiltersActiveStatus(filterStatus);
            let isToShowFilterButton = false;
            Object.values(filterStatus).forEach((value) => (value ? (isToShowFilterButton = value) : false));
            setClearFilterButton(isToShowFilterButton);
            let filterPayload = transformQueryFilters(filterSchema, queryObject);
            getAssetData({
                searchTerm: searchQueryParam,
                limit: limitQueryParam,
                page: pageQueryParam,
                payload: filterPayload,
                isFilterApplied: isToShowFilterButton,
                warehouseId: warehouseIdQueryParam,
            });
            dispatch(
                setInventoryFilterAndPaginationData({
                    payload: filterPayload,
                    isFilterActive: isToShowFilterButton,
                    limit: limitQueryParam,
                    page: pageQueryParam,
                    query: searchQueryParam,
                })
            );
        }, [queryParams, searchQueryParam, limitQueryParam, pageQueryParam, getAssetData, setClearFilterButton, query, dispatch, warehouseIdQueryParam]);

        useImperativeHandle(ref, () => ({
            getSelectedData: () => {
                let selectedData = inventoryAssets.filter((value, index) => checkedSpareAssetsTracker.has(index));
                return selectedData;
            },
        }));

        useEffect(() => {
            setCheckedSpareAssetsTracker(new Set());
        }, [inventoryAssets]);

        const transformSpareAsset = (spareDataArray) => {
            const getTruthValue = (item) => {
                if (!['', undefined, null].includes(item)) return item;
                return '';
            };

            let transformedData = spareDataArray?.map((spareData) => {
                let addingKey = {
                    spareCategoryTitle: getTruthValue(spareData?.spareCategory?.title),
                    assetTypeTitle: getTruthValue(spareData?.assetType?.title),
                    warehouseName: getTruthValue(spareData?.warehouse?.name),
                    warehouseId: spareData?.warehouse?.id,
                    customerCompanyName: getTruthValue(spareData?.customer?.customer_company_name),
                    statusForDisplay: spareData?.status?.replace('_', ' '),
                    workingCondition: spareData?.working_condition,
                    serialNo: spareData?.serial_number,
                };
                return Object.assign(spareData, addingKey);
            });
            return transformedData;
        };

        useEffect(() => {
            let transformedSpare = transformSpareAsset(spareAssets);
            setInventoryAssets(transformedSpare);
        }, [spareAssets]);

        useEffect(() => {
            if (Array.isArray(filteredData)) {
                const transformedSpare = transformSpareAsset(filteredData);
                setInventoryAssets(transformedSpare);
            }
        }, [filteredData]);

        useEffect(() => {
            let filterPayload = { customerId };
            if (toShowCustomer) filterPayload = Object.assign(filterPayload, { toFilter: 'customers' });
            dispatch(getSpareAssetFilterList(filterPayload));
            return () => {
                dispatch(removeSpareAssetDataFromStore());
                dispatch(clearFilterModuleData());
                dispatch(setClearFilterClickAction(() => { }));
                dispatch(clearInventoryTableDataOnUnmount());
            };
        }, [customerId, dispatch, toShowCustomer]);

        useEffect(() => {
            return () => dispatch(clearSiteOrWarehouseIdFromStore());
        }, [dispatch]);

        const selectALLHandler = (checked) => {
            if (checked) {
                const allIndices = inventoryAssets.map((_, index) => index);
                setCheckedSpareAssetsTracker(new Set(allIndices));
            } else {
                setCheckedSpareAssetsTracker(new Set());
            }
        };

        const filterComponent = ({ headName, keysToFilter, typeOfFilter, placement }) => (
            <div className='flex'>
                {headName}
                <APIFilterPopup
                    filterList={filterList}
                    setFilterList={setFilterList}
                    height={15}
                    keysToFilter={keysToFilter}
                    submit={({ payload, page, filterList }) => updateQueryFilters({ filterSchema, payload, page, filterList })}
                    typeOfFilter={typeOfFilter}
                    placement={placement}
                    filtersData={spareAssetFiltersData}
                    filterSchema={filterSchema}
                    isLoading={spareAssetFilterLoading}
                    setPayloadAndFilterStatus={setInventoryFilterAndPaginationData}
                    filterQueryObject={filterQueryObject}
                    filtersActiveStatus={filtersActiveStatus}
                />
            </div>
        );

        const copyOnDoubleClick = (event, candString) => {
            const isDoubleClick = event.detail === 2;
            if (isDoubleClick) {
                dispatch(copyToClipboard({ data: candString }));
            }
        };

        const handleAssetDetail = (title, id, serNumber) => {
            const sideBartitle = (
                <div className='cursor-pointer flex gap-1 truncate w-11/12'>
                    <IconToolTip title={`Asset Number : ${title}`} placement='bottom'>
                        <p className='truncate'>{title}</p>
                    </IconToolTip>
                    {serNumber && (
                        <>
                            {' '}
                            <IconToolTip title={`Serial Number : ${serNumber}`} placement='bottom'>
                                <p className='max-w-79% truncate'>({serNumber})</p>
                            </IconToolTip>
                        </>
                    )}
                </div>
            );
            const inventorySideDrawerPayload = {
                title: sideBartitle,
                assetId: id,
                assetSource: 'spare',
            };
            openInventorySideDrawer({ inventorySideDrawerName: 'assetHistory', inventorySideDrawerPayload });
        };

        const handleOnChangeCheckbox = (position) => {
            if (checkedSpareAssetsTracker.has(position)) {
                setCheckedSpareAssetsTracker((prev) => {
                    const next = new Set(prev);
                    next.delete(position);
                    return next;
                });
            } else {
                setCheckedSpareAssetsTracker((prev) => new Set(prev).add(position));
            }
        };

        const spareAssetTableSchema = {
            table: [
                {
                    head: {
                        render: (headName) => {
                            return (
                                <div className='flex items-center'>
                                    <Checkbox
                                        checkColor='text-scogoorange mr-2 '
                                        checked={inventoryAssets?.length > 0 && checkedSpareAssetsTracker?.size > 0 && inventoryAssets?.length === checkedSpareAssetsTracker?.size}
                                        onChange={(event) => {
                                            selectALLHandler(event);
                                        }}
                                        dynamicSize={'1.2rem'}
                                    />
                                    {headName}
                                </div>
                            );
                        },
                        headName: 'Asset Number',
                        width: 1.4,
                    },
                    body: {
                        render: (item, rowIndex) => {
                            return (
                                <div className='flex w-full cursor-pointer'>
                                    <div className='flex items-center'>
                                        <Checkbox
                                            onChange={() => handleOnChangeCheckbox(rowIndex)}
                                            checked={checkedSpareAssetsTracker.has(rowIndex)}
                                            dynamicSize={'1.2rem'}
                                            checkColor='text-scogoorange mr-2 '
                                        />
                                    </div>
                                    <div className='w-10/12'>
                                        <div className='flex'>
                                            <p
                                                className={`w-10/12 truncate font-bold ${clickableTextClass}`}
                                                onClick={() => {
                                                    handleAssetDetail(item?.spare_number, item?.id, item?.serial_number);
                                                }}
                                            >
                                                <OverflowTip someLongText={item?.spare_number} />
                                            </p>
                                            {item?.spare_number && (
                                                <IconToolTip placement={'bottom'} title={`Copy Asset Number`}>
                                                    <span
                                                        className='cursor-pointer material-icons text-scogobgsky text-lg pl-1'
                                                        onClick={() => dispatch(copyToClipboard({ data: item?.spare_number }))}
                                                    >
                                                        {' '}
                                                        content_copy{' '}
                                                    </span>
                                                </IconToolTip>
                                            )}
                                        </div>
                                        {item?.reconcile_status === 1 ? (
                                            <p className='flex items-center bg-scogobgsky max-w-max text-white px-1 rounded-md text-font11'>Reconciled</p>
                                        ) : (
                                            <></>
                                        )}
                                        <RenderEditAssetConfig asset={item} />
                                    </div>
                                </div>
                            );
                        },
                    },
                },
                {
                    head: {
                        render: (headName) => {
                            const customerFilter = filterSchema.customersFilter;
                            const warehouseFilter = filterSchema.warehouseFilter;
                            const isWarehouseIdAvailable = !warehouseId || isNaN(warehouseId) || warehouseId === 0;
                            const keysToFilter = [];
                            if (toShowCustomer) keysToFilter.unshift(customerFilter);
                            if (isWarehouseIdAvailable) keysToFilter.push(warehouseFilter);
                            return filterComponent({ headName, keysToFilter });
                        },
                        headName: 'Warehouse',
                    },
                    body: {
                        render: (item) => {
                            const endUserName = item?.enduser?.end_user_name;
                            return (
                                <div className='w-full'>
                                    <IconToolTip title={`Warehouse Name : ${item?.warehouseName}`}>
                                        <p className='w-full truncate text-scogogray font-bold'>{item?.warehouseName}</p>
                                    </IconToolTip>
                                    <IconToolTip title={`Customer Name : ${item?.customerCompanyName}`}>
                                        <p className='w-full truncate'>{item?.customerCompanyName}</p>
                                    </IconToolTip>
                                    {endUserName && <p className={' font-bold text-scogo99 w-full truncate'}>
                                        <IconToolTip placement={'bottom'} title={`End User : ${endUserName}`}>
                                            <span className='font-bold text-scogo99 max-w-max truncate'>{endUserName}</span>
                                        </IconToolTip>
                                    </p>}
                                </div>
                            );
                        },
                    },
                },
                {
                    head: {
                        render: (headName) => {
                            return filterComponent({
                                headName,
                                keysToFilter: [filterSchema.assetTypeFilter, filterSchema.categoryFilter],
                            });
                        },
                        headName: 'Asset Type',
                    },
                    body: {
                        render: (item) => {
                            return (
                                <div className='break-all'>
                                    {item?.assetTypeTitle ? (
                                        <p className='truncate w-11/12'>
                                            <span className='text-font11 text-scogogray font-medium'> Title: </span>
                                            <span className='text-font11 '>{`${item?.assetTypeTitle}`} </span>
                                        </p>
                                    ) : (
                                        <></>
                                    )}
                                    {item?.spareCategoryTitle ? (
                                        <p className='truncate w-11/12'>
                                            <span className='text-font11 text-scogogray font-medium'> Category: </span>
                                            <span className='text-font11 '>{`${item?.spareCategoryTitle}`} </span>
                                        </p>
                                    ) : (
                                        <></>
                                    )}
                                    <p>
                                        <span className='text-font11 text-scogogray font-medium'>Added On: </span>
                                        <span className='text-font11 '>{moment(item?.added_on).format('DD/MM/YYYY')}</span>
                                    </p>
                                </div>
                            );
                        },
                    },
                },
                {
                    head: {
                        render: (headName) => {
                            return headName;
                        },
                        headName: 'Asset Details',
                        hide: showInventorySideDrawer,
                    },
                    body: {
                        render: (item) => {
                            return (
                                <div className='flex flex-col'>
                                    {item?.make ? (
                                        <IconToolTip title={`Make : ${item?.make}`} placement='bottom'>
                                            <span className='w-11/12 truncate' onClick={(e) => copyOnDoubleClick(e, item?.make)}>
                                                {item?.make ? item?.make : ''}
                                            </span>
                                        </IconToolTip>
                                    ) : (
                                        <></>
                                    )}
                                    {item?.model ? (
                                        <IconToolTip title={`Model : ${item?.model}`} placement='bottom'>
                                            <span className='w-11/12 truncate' onClick={(e) => copyOnDoubleClick(e, item?.model)}>
                                                {item?.model ? item?.model : ''}
                                            </span>
                                        </IconToolTip>
                                    ) : (
                                        <></>
                                    )}
                                    {item?.partcode ? (
                                        <IconToolTip title={`Partcode : ${item?.partcode}`} placement='bottom'>
                                            <span className='w-11/12 truncate' onClick={(e) => copyOnDoubleClick(e, item?.partcode)}>
                                                {item?.partcode ? item?.partcode : ''}
                                            </span>
                                        </IconToolTip>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            );
                        },
                    },
                },
                {
                    head: {
                        render: (headName) => {
                            return filterComponent({
                                headName,
                                keysToFilter: [filterSchema.statusFilter, filterSchema.workingConditionFilter, filterSchema.serialNumberFilter],
                            });
                        },
                        headName: 'Serial No',
                    },
                    body: {
                        render: (item) => {
                            return (
                                <AssetSerialNumber
                                    showCopyIcon={false}
                                    serialNumber={item?.serial_number}
                                    status={item?.status}
                                    statusForDisplay={item?.statusForDisplay}
                                    workingCondition={item?.working_condition}
                                    delivered_to={item.delivered_to}
                                    received_from={item.received_from}
                                />
                            );
                        },
                    },
                },
                {
                    head: {
                        render: (headName) => {
                            return <div className='pl-3'>{headName}</div>;
                        },
                        headName: 'Price',
                        hide: showInventorySideDrawer,
                        width: 0.7,
                    },
                    body: {
                        render: (item) => {
                            return (
                                <p className='font-medium pl-4'>
                                    <OverflowTip someLongText={`₹ ${item?.value || 0}`} />
                                </p>
                            );
                        },
                    },
                },
                {
                    head: {
                        render: (headName) => {
                            return headName;
                        },
                        headName: 'Scan Details',
                        hide: showInventorySideDrawer,
                    },
                    body: {
                        render: (item) => {
                            return (
                                <div className='break-all'>
                                    {item?.scanner ? (
                                        <IconToolTip title={`Scanned By: ${item?.scanner?.first_name} ${item?.scanner?.last_name}`} placement='bottom'>
                                            <p className='w-full truncate'>{`${item.scanner.first_name} ${item.scanner.last_name}`}</p>
                                        </IconToolTip>
                                    ) : (
                                        <></>
                                    )}

                                    {item?.scanned_time && (
                                        <IconToolTip title={`Scanned By: ${item?.scanner?.first_name} ${item?.scanner?.last_name}`} placement='bottom'>
                                            <p>
                                                <DateComponent className='text-scogogray font-normal text-font10' date={item?.scanned_time} timeFormat='DD MMM YYYY' />
                                            </p>
                                        </IconToolTip>
                                    )}

                                    {item?.scanned_location ? (
                                        <IconToolTip title={`Location: ${item.scanned_location}`} placement='bottom'>
                                            <p className='w-full truncate'>{item.scanned_location}</p>
                                        </IconToolTip>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            );
                        },
                    },
                },
                {
                    head: actionHead('Tracking '),
                    body: {
                        render: (item) => {
                            const tracking =
                                Array.isArray(item?.ticket_asset_relationships) && item?.ticket_asset_relationships?.length > 0 && item?.ticket_asset_relationships.at(-1);

                            if (!tracking) {
                                return <span></span>;
                            }
                            const toggleViewMore = (val) => setIsViewingMore((prev) => ({ ...prev, [item.id]: val }));
                            return (
                                <>
                                    <SpareAssetTicketTrackingColumn tracking={tracking} toggleViewMore={toggleViewMore} isViewingMore={isViewingMore[item.id]} />
                                </>
                            );
                        },
                    },
                },
                {
                    head: {
                        render: (headName) => {
                            return filterComponent({
                                headName,
                                keysToFilter: [filterSchema.descripenciesFilter],
                            });
                        },
                        headName: 'Discrepancy',
                        hide: showInventorySideDrawer,
                        columnRowCss: 'break-words',
                    },
                    body: {
                        render: (item) => {
                            return (
                                <div className='w-full block'>
                                    {item?.descripencies ? (
                                        <>
                                            <span className='material-icons text-scogoToastDangerIcon text-font10 px-2'>warning</span>
                                            <span className='text-scogo99 text-font09'>{item?.descripencies}</span>
                                        </>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            );
                        },
                    },
                },
                {
                    head: {
                        headName: 'Actions',
                        render: (headName) => headName,
                        width: 0.5,
                    },
                    body: {
                        render: (item) => {
                            return (
                                <div className='flex justify-center'>
                                    <SpareAssetActionColumn item={item} selectedWarehouseId={selectedWarehouseId} warehouseId={warehouseId} selectedSubTab={selectedSubTab} />
                                </div>
                            );
                        },
                    },
                },
            ],
        };

        useEffect(() => {
            closeInventorySideDrawer();
            const transformed = transformSpareAsset(spareAssets);
            setInventoryAssets(transformed);
        }, [spareAssets]);

        return (
            <VirtualizedTable
                rowHeight={100}
                schema={spareAssetTableSchema}
                rowData={inventoryAssets}
                isLoading={spareAssetLoading}
                setRowsPerPage={setInventoryRowsAndPage}
                rowsPerPage={inventoryRowsPerPage}
                tablePagination
                tablePaginationHandler={(limit, page) => updateQueryFilters({ limit, page, filterSchema, filterList })}
                count={spareAssetsCount}
                tableError={'No Spare Assets Found'}
                page={inventoryPage}
                checkRowShouldHighlight={(spareAsset) => showInventorySideDrawer && spareAsset.id === inventorySideDrawerPayload?.assetId}
                columnsGap='gap-x-6'
            />
        );
    }
);

export default SpareAssetDetails;



const SpareAssetActionColumn = (props) => {
    const { item: spareAsset, selectedWarehouseId, warehouseId, selectedSubTab } = props;
    const spareTableActions = GetInventoryActions('spareTableActions', { spareAsset });
    const { loggedUser } = useSelector((state) => state.auth);
    const role = loggedUser?.role?.id;
    const type = loggedUser?.type;
    const customerId = getCustomerIdOfLoginUser(loggedUser);

    return (
        <DropdownMenu
            menuData={spareTableActions}
            item={spareAsset}
            componentOrIcon={'more_vert'}
            iconColor={'text-scogoprimary'}
            payload={{ selectedWarehouseId, warehouseId, customerId, userId: loggedUser?.id, tabName: selectedSubTab, role, type }}
        />
    );
};
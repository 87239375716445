import { isScm, isCustomer, isCustomerAdmin, isCustomerUser, isCluster, isSuperVisor, isSp, isPsp, isIsp, isFe, isSpIsp, isFM, isCustomerGroup } from '../../utils/role';

import { getPaymentsMainTabs } from '../../pages/Payments/PaymentController';
import { ticket, inventory, projects, payments, teamWork, chats, wallet, customerBilling } from '../../router/roleWisePageRoute';
import { isToShowCustomerBillingTab } from '../../utils/access';

const projectsSidebarTab = {
    key: 'projects',
    name: 'Projects',
    imgSrc: '/img/projects.svg',
    Linkto: projects.path,
};

const ticketSidebarTab = {
    key: 'ticket',
    name: 'Ticket',
    imgSrc: '/img/ticket.svg',
    Linkto: ticket.path,
};

const inventorySidebarTab = {
    key: 'inventory',
    name: 'Inventory',
    imgSrc: '/img/sites.svg',
    Linkto: inventory.path,
};

const teamSidebarTab = {
    key: 'team',
    name: 'Team',
    imgSrc: '/img/groups.svg',
    Linkto: teamWork.path,
};

export const chatSidebarTab = {
    key: 'chats',
    name: 'Chats',
    imgSrc: '/img/sidebar_chat.png',
    Linkto: chats.path,
    countKey: 'chatNotificationCount',
};

export const paymentsSidebarTab = {
    key: 'payments',
    name: (role, type) => {
        if (isCustomer(role, type) || isCustomerAdmin(role, type) || isCustomerUser(role, type) || isIsp(role, type)) {
            return 'Finance';
        }
        return 'Payments';
    },
    imgSrc: '/img/payments_scogo.svg',
    Linkto: payments.path,
};

const walletSidebarTab = {
    key: 'wallet',
    name: 'Wallet',
    imgSrc: '/img/payments.svg',
    Linkto: wallet.path,
};

const customerBillingSidebarTab = {
    key: 'customerBilling',
    name: 'Customer Billing',
    imgSrc: '/img/customer_billing.svg',
    Linkto: customerBilling.path,
};

const clientsSideBarTab = {
    key: 'clientsTab',
    name: 'Clients',
    imgSrc: '/img/clients.svg',
    Linkto: '/clients',
};

const dashboardTab = {
    key: 'dashboardTab',
    name: 'Dashboard',
    imgSrc: '/img/dashboard.svg',
    Linkto: '/dashboard',
};

export const getSidebar = (role, type, frontend_controller, grafana_url) => {
    let tabs = [];
    if (isScm(role, type)) {
        tabs = [
            projectsSidebarTab,
            ticketSidebarTab,
            teamSidebarTab,
            inventorySidebarTab,
            paymentsSidebarTab,
            walletSidebarTab,
            chatSidebarTab,
            clientsSideBarTab,
            customerBillingSidebarTab
        ];
    }
    else if (isCustomer(role, type)) tabs = [projectsSidebarTab, ticketSidebarTab, teamSidebarTab, inventorySidebarTab, paymentsSidebarTab, walletSidebarTab, chatSidebarTab];
    else if (isCustomerAdmin(role, type))
        tabs = [projectsSidebarTab, ticketSidebarTab, teamSidebarTab, inventorySidebarTab, paymentsSidebarTab, walletSidebarTab, chatSidebarTab];
    else if (isCustomerUser(role, type))
        tabs = [projectsSidebarTab, ticketSidebarTab, teamSidebarTab, inventorySidebarTab, paymentsSidebarTab, walletSidebarTab, chatSidebarTab];
    else if (isCluster(role, type)) tabs = [ticketSidebarTab, teamSidebarTab, inventorySidebarTab, paymentsSidebarTab, walletSidebarTab, chatSidebarTab];
    else if (isSuperVisor(role, type)) tabs = [ticketSidebarTab, teamSidebarTab, inventorySidebarTab, paymentsSidebarTab, chatSidebarTab, walletSidebarTab];
    else if (isSp(role, type) || isIsp(role, type) || isSpIsp(role, type))
        tabs = [ticketSidebarTab, teamSidebarTab, inventorySidebarTab, paymentsSidebarTab, walletSidebarTab, chatSidebarTab];
    else if (isPsp(role, type)) tabs = [ticketSidebarTab, teamSidebarTab, inventorySidebarTab, paymentsSidebarTab, walletSidebarTab, chatSidebarTab];
    else if (isFe(role, type)) tabs = [ticketSidebarTab, inventorySidebarTab, paymentsSidebarTab, walletSidebarTab, chatSidebarTab];
    else if (isFM(role)) {
        tabs = [customerBillingSidebarTab];
    }
    tabs = tabs.filter((tab) => {
        if (tab.key === paymentsSidebarTab.key) {
            const paymentTabs = getPaymentsMainTabs(role, type, frontend_controller);
            return paymentTabs.length > 0;
        } else if (tab.key === customerBillingSidebarTab.key) {
            return isToShowCustomerBillingTab(frontend_controller)
        }
        return true;
    });

    if (isCustomerGroup(role, type) && grafana_url) {
        tabs = [dashboardTab, ...tabs];
    }
    return tabs;
};

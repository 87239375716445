export const SEARCH_MODULE_DATA_REQUEST = 'SEARCH_MODULE_DATA_REQUEST';
export const SEARCH_MODULE_DATA_SUCCESS = 'SEARCH_MODULE_DATA_SUCCESS';
export const CANCEL_SEARCH_MODULE_DATA_REQUEST = 'CANCEL_SEARCH_MODULE_DATA_REQUEST';
export const SEARCH_MODULE_DATA_COUNT_SUCCESS = 'SEARCH_MODULE_DATA_COUNT_SUCCESS';

export const UPLOAD_FILES_REQUEST = 'UPLOAD_FILES_REQUEST';
export const UPLOAD_FILES_SUCCESS = 'UPLOAD_FILES_SUCCESS';

export const EXPORT_MODULE_DATA_CSV_REQUEST = 'EXPORT_MODULE_DATA_CSV_REQUEST';

export const FILTER_MODULE_DATA_REQUEST = 'FILTER_MODULE_DATA_REQUEST';
export const CLEAR_FILTER_MODULE_DATA = 'CLEAR_FILTER_MODULE_DATA';
export const FILTER_MODULE_DATA_SUCCESS = 'FILTER_MODULE_DATA_SUCCESS';
export const FILTER_MODULE_DATA_FAILURE = 'FILTER_MODULE_DATA_FAILURE';

export const UPDATE_FILTER_MODULE_LAST_SCANNED_INDEX_SUCCESS = 'UPDATE_FILTER_MODULE_LAST_SCANNED_INDEX_SUCCESS';

export const FILTER_MODULE_DATA_COUNT_SUCCESS = 'FILTER_MODULE_DATA_COUNT_SUCCESS';
export const FILTER_MODULE_DATA_COUNT_FAILURE = 'FILTER_MODULE_DATA_COUNT_FAILURE';

export const SET_INITIAL_FORM = 'SET_INITIAL_FORM';

export const COPY_TO_CLIPBOARD_REQUEST = 'COPY_TO_CLIPBOARD_REQUEST';
export const COPY_TO_CLIPBOARD_FAILURE = 'COPY_TO_CLIPBOARD_FAILURE';

export const DOWNLOAD_FILE_REQUEST = 'DOWNLOAD_FILE_REQUEST';
export const DOWNLOAD_FILE_FAILURE = 'DOWNLOAD_FILE_FAILURE';

export const SET_CLEAR_FILTER_BUTTON = 'SET_CLEAR_FILTER_BUTTON';
export const SET_CLEAR_FILTER_BUTTON_ACTION = 'SET_CLEAR_FILTER_BUTTON_ACTION';

export const BULK_UPLOAD_FILES_REQUEST = 'BULK_UPLOAD_FILES_REQUEST';
export const BULK_UPLOAD_FILES_SUCCESS = 'BULK_UPLOAD_FILES_SUCCESS';
export const BULK_UPLOAD_FILES_FAILURE = 'BULK_UPLOAD_FILES_FAILURE';

export const UPDATE_LAT_LONG_REQUEST = 'UPDATE_LAT_LONG_REQUEST';
export const UPDATE_LAT_LONG_SUCCESS = 'UPDATE_LAT_LONG_SUCCESS';

export const DOWNLOAD_REMOTE_DATA_REQUEST = 'DOWNLOAD_REMOTE_DATA_REQUEST';
export const SET_ROOM_LIST_DATA = 'SET_ROOM_LIST_DATA';
export const SET_SELECTED_LOCATION_FROM_MAP = 'SET_SELECTED_LOCATION_FROM_MAP';


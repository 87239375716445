import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getWalletDetailsById, removeWalletDetailsFromStore } from '../../../actions/wallet';
import { isIsp, isPsp, isSp, isSpIsp, isFe, isCluster, isSuperVisor, isScm } from '../../../utils/role';
import SendToBank from '../../Wallet/Modals/SendToBank';
import { getUserBankDetailsById } from '../../../actions/auth';
import { isShowDailyLimit } from '../../Wallet/components/Passbook';
import { getBanksList } from '../../../actions/users';
import { validateNumberValue } from '../../../utils/common';

export const BankTransfer = ({ user }) => {
    const dispatch = useDispatch();
    const { bankDetailsById } = useSelector((state) => state.auth);
    const role = user.role, type = user.type;
    const { walletDetailsById } = useSelector((state) => state.wallet);
    const { isBankDetailsLoading } = useSelector((state) => state.loading);

    const isUserSpPspSpisp = isSp(role, type) || isPsp(role, type) || isSpIsp(role, type);
    const isUserIsIsp = isIsp(role, type);

    let vendorId = user.id;
    let vendorType = '';
    if (isUserSpPspSpisp) vendorId = user.service_partner.service_partner_id;
    if (isUserIsIsp) {
        vendorId = user?.customer_details?.customer_id;
        vendorType = 'ISP';
    }

    useEffect(() => {
        const bankPayload = { type: vendorType, id: vendorId };
        if (isFe(role, type) || isCluster(role, type) || isSuperVisor(role, type) || isScm(role, type)) {
            bankPayload.check_user = false
        }
        if (user.id) {
            dispatch(getWalletDetailsById({ updateBalance: false, beneficiary: user.id, accountBalance: 'BANKOPEN' }));
            dispatch(getUserBankDetailsById(bankPayload));
        }
        return () => {
            dispatch(removeWalletDetailsFromStore());
        }
    }, [user?.id, dispatch, vendorType, vendorId, role, type]);

    useEffect(() => {
        if (!bankDetailsById) {
            dispatch(getBanksList());
        }
    }, [bankDetailsById, dispatch]);

    return <>
        <SendToBank isShowDailyLimit={isShowDailyLimit(walletDetailsById?.withdraw_limit, user.role, user.type)} wallet={walletDetailsById} totalWithdrawn={100000} isBankDetailsLoading={isBankDetailsLoading} bankDetails={bankDetailsById} isUserSpPspSpisp={isUserSpPspSpisp} isUserIsIsp={isUserIsIsp} directBankTransfer vendorId={vendorId} type={vendorType} showTotalWithdrawn={false} userId={user.id} scogoBankAccountBalance={validateNumberValue(walletDetailsById?.scogo_bankopen_account_balance)} has_bank_details={user.has_bank_details === 1} />
    </>
}

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserBankDetailsAndPay, cleanupBankUddateDetailsFromStore } from '../../../../actions/users';
import { actualValueForDropdown } from '../../../../utils/common';
import { buttonClass, textCss } from '../../../../common/Css';
import Select from 'react-select';
import ButtonScogoPrimary from '../../../../common/Buttons/ButtonScogoPrimary';
import ButtonScogoClosedOutlined from '../../../../common/Buttons/ButtonScogoClosedOutlined';

const accountTypes = [
    { key: 1, value: 1, label: 'Savings Account' },
    { key: 2, value: 2, label: 'Current Account' },
];

export const BankForm = ({ banks, vendorId, closeMenu, isRefresh, payPoData, callPay, isSP, type }) => {
    const formName = 'updateBankDetails';
    const dispatch = useDispatch();
    const { formButtonLoading } = useSelector((state) => state.loading);
    const [form, setForm] = useState({});
    const [formValidationError, setFormValidationError] = useState({});

    const handleInputChange = (event) => {
        event.preventDefault();
        const { value, name } = event.target;
        if (!value) setFormValidationError((prevState) => ({ ...prevState, [name]: { error: true, message: 'This field is required' } }));
        else setFormValidationError((prevState) => ({ ...prevState, [name]: { error: false, message: '' } }));
        setForm((prevState) => ({ ...prevState, [name]: value }));
    };

    const stopPropagation = (e) => {
        switch (e.key) {
            case 'ArrowDown':
            case 'ArrowUp':
            case 'Home':
            case 'End':
                break;
            default:
                e.stopPropagation();
        }
    };

    const isOtherBankSelected = actualValueForDropdown(form?.sp_bank_name) === 'other';

    const submit = (formValues) => {
        let isValidFormData = true;
        let bankName = formValues?.bank_name_text;
        if (!bankName) bankName = formValues?.bank_name?.label;

        let data = {};

        if (isSP) {
            data.sp_id = vendorId;
        }

        if (type) data.type = type;

        const getFormValue = (value, name, errorMsg = 'This field is required') => {
            if (value) {
                data = Object.assign(data, { [name]: value });
                setFormValidationError((prevState) => ({ ...prevState, [name]: { error: false, message: '' } }));
            } else {
                isValidFormData = false;
                setFormValidationError((prevState) => ({ ...prevState, [name]: { error: true, message: errorMsg } }));
            }
        };

        getFormValue(bankName, 'bank_name');
        getFormValue(formValues.bank_ifsc_code, 'bank_ifsc_code');
        getFormValue(formValues.bank_account_holder_name, 'bank_account_holder_name');
        getFormValue(actualValueForDropdown(formValues.bank_account_type), 'bank_account_type');
        getFormValue(formValues.bank_account_number, 'bank_account_number');

        if (isValidFormData)
            dispatch(
                updateUserBankDetailsAndPay({
                    data,
                    id: vendorId,
                    formName,
                    callPay,
                    isRefresh,
                    payPoData,
                    isSP,
                    callAfterBankSubmit: closeMenu,
                    type,
                })
            );
    };

    const getInput = (name, label, className = '', required = true) => {
        return (
            <div className={'flex flex-col block border-b-2 border-scogoddd ' + className}>
                {label && required && (
                    <p className={`${textCss?.inputLabel}`}>
                        {label} {<span className='text-scogoorange'>*</span>}
                    </p>
                )}
                <input
                    value={form?.[name]}
                    className='w-full border-none focus:ring-0 '
                    onChange={handleInputChange}
                    name={name}
                    required
                    type={'text'}
                    onKeyDown={stopPropagation}
                />
                {formValidationError?.[name]?.error && (
                    <span className='text-scogoclosed text-font10 font-normal'>{formValidationError[name].message}</span>
                )}
            </div>
        );
    };

    const getDropDownField = ({ name, options, label, required = true, className = '', menuHeight = '10rem' }) => {
        const customStyles = {
            singleValue: (base) => ({
                ...base,
                fontSize: '1.2rem',
                paddingBottom: '1rem',
            }),
            placeholder: (base) => ({
                ...base,
                fontSize: '1.2rem',
                padding: 0,
                paddingTop: 0,
                paddingBottom: 0,
            }),
            control: (provided, state) => ({
                ...provided,
                minHeight: '3rem',
                border: state.isFocused ? 'none' : 'none',
                outline: state.isFocused ? 'none' : 'none',
                borderRadius: 0,
            }),
            menuList: (provided, state) => ({
                ...provided,
                maxHeight: menuHeight,
            }),
        };
        return (
            <div className={'relative ' + className}>
                <label className='relative block border-b-2 border-scogoddd'>
                    {label && (
                        <span className={textCss?.inputLabel}>
                            {label} {required && <span className='text-scogoorange'>*</span>}{' '}
                        </span>
                    )}
                    <Select
                        name={name}
                        options={options}
                        isSearchable
                        className='w-full text-font11 basic-multi-select'
                        classNamePrefix='select'
                        onChange={(value) => setForm((prevState) => ({ ...prevState, [name]: value }))}
                        isClearable
                        position='fixed'
                        styles={customStyles}
                        menuPlacement={'bottom'}
                    />
                </label>
                {formValidationError?.[name]?.error && (
                    <span className='text-scogoclosed text-font10 font-normal'>{formValidationError[name].message}</span>
                )}
            </div>
        );
    };

    return (
        <>
            <div className='grid grid-cols-2 rows-fr grid-flow-row-dense w-40vw gap-4 py-4 relative'>
                {getDropDownField({ name: 'bank_name', options: banks, label: 'Select Bank' })}
                {isOtherBankSelected && getInput('bank_name_text', 'Enter Bank Name')}
                {getDropDownField({ name: 'bank_account_type', options: accountTypes, label: 'Select Account Type' })}
                {getInput('bank_account_holder_name', 'Account Holder Name')}
                {getInput('bank_account_number', 'Account Number')}
                {getInput('bank_ifsc_code', 'IFSC Code')}
            </div>
            <div className={`px-4 gap-4 flex justify-end`}>
                <ButtonScogoPrimary textOrComponent='Submit' onClick={() => submit(form)} loading={formButtonLoading?.[formName]} />
                <ButtonScogoClosedOutlined textOrComponent='Cancel' onClick={closeMenu} />
            </div>
        </>
    );
};

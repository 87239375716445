import React, { useEffect } from 'react';
import { projectCss, textCss } from '../../../common/Css';
import UpdatePo from './UpdatePO';
import { useDispatch } from 'react-redux';
import { viewTicketById } from '../../../actions/tickets';
import { getConversationDetails } from '../../../actions/conversations';
import { useSelector } from 'react-redux';
import { getPoAssetsCategoriesDropdown } from '../../../actions/payments';
import { getBanksList } from '../../../actions/users';
import { getUserBankDetails } from '../../../actions/auth';
import { SkeltonCardTwo } from '../../../common/SkeltonCardTwo';
import { ScogoAmountBalance } from '../../Wallet/Modals/SendToBank';
import { DetailsToShow } from '../../Ticket/CloseFeasibility/Details';

export default function RenderUpdatePo({ ticketId, isRefresh, ticket }) {
    const dispatch = useDispatch();
    const { isViewTicketByIdLoading, isConversationDetailsLoading } = useSelector((state) => state.loading);
    const { viewTicket } = useSelector((state) => state.tickets);
    const { conversationDetails } = useSelector((state) => state.conversations);
    let ticketConvo;
    if (conversationDetails && conversationDetails[ticketId]) ticketConvo = conversationDetails[ticketId];
    if (!ticketConvo && ticket) ticketConvo = { ticket };

    useEffect(() => {
        dispatch(viewTicketById({ ticketId }));
        dispatch(getConversationDetails({ _id: ticketId, useConversationId: true }));
        dispatch(getPoAssetsCategoriesDropdown());
        dispatch(getBanksList());
    }, [dispatch, ticketId]);

    useEffect(() => {
        if (ticketConvo?.ticket?.po?.vendorId) {
            dispatch(getUserBankDetails({ id: ticketConvo?.ticket?.po?.vendorId, type: 'ISP' }));
        }
    }, [ticketConvo?.ticket?.po?.vendorId, dispatch]);

    const title = ticketConvo?.po?.status === 'CANCELLED' ? 'Reopen PO' : 'Update PO';
    const identification = ticketConvo?.prNumber || viewTicket?.job_ticket_number;
    return <>
        <div className={`subContainer title-4 p-5`}>
            <div className={`titleContainer`}>
                <div className='flex justify-between'>
                    <h1 className='text-scogo21 text-font16 font-medium font-rubik '>{title} : {<span className='text-scogobgsky text-font16'>{identification}</span>}</h1>
                    <ScogoAmountBalance />
                </div>
            </div>
            <div className='bg-white relative mb-4 border-gray-200 shadow-md w-full rounded-lg' style={{ height: '15vh' }}>
                <DetailsToShow ticketDetails={viewTicket} />
            </div>
            <div className='flex-auto'>
                <div className='flex-auto '>
                    <div className='tab-content tab-space bg-white shadow-xl rounded-lg'>
                        <div className={`${projectCss?.scmAddProjectForm} lg:pb-0 md:pb-168 pb-230 overflow-auto mb-24 md:mb-24 `} style={{ height: '63vh' }}>
                            {(isViewTicketByIdLoading || isConversationDetailsLoading) && <div className='px-6 w-full h-full bg-white overflow-hidden'> <SkeltonCardTwo arr={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16]} height={20} className={'my-4'} /></div>}

                            {ticketConvo?.ticket ? <UpdatePo ticket={ticketConvo?.ticket} isRefresh={isRefresh} /> : <></>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
};
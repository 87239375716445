const urls = {
    conversations: {
        getConversations: { method: 'get', url: 'ccs/conversations' },
        getConversationDetails: { method: 'get', url: 'ccs/conversations/{_id}' },
        getConversationMessages: { method: 'post', url: 'ccs/messages' },
        getConversationCount: { method: 'get', url: 'ccs/conversations/count' },
        createChat: { method: 'post', url: 'ccs/conversations/create' },
        createSupport: { method: 'post', url: 'ccs/support/create' },
        closeConversation: { method: 'post', url: 'ccs/conversations/{_id}/close' },
        modifyNotificationSettings: { method: 'post', url: 'ccs/conversations/{_id}/mute' },
        uploadFiles: { method: 'post', url: 'ccs/conversations/{_id}/files' },
        saveAndBroadcastMessage: { method: 'post', url: 'ccs/conversations/{_id}/broadcast' },
        leaveConversation: { method: 'post', url: 'ccs/conversations/{_id}/leave' },
        whitelistProjectTeamUser: { method: 'post', url: 'ccs/conversations/{projectId}/join' },
        blackListProjectteamUser: { method: 'post', url: 'ccs/conversations/leave' },
        getConversationMedia: { method: 'get', url: 'ccs/conversations/{_id}/files' },
        tagProject: { method: 'post', url: 'ccs/conversations/projects' },
        updateGroupChatDetail: { method: 'put', url: 'ccs/conversations/{_id}' },
        addUserConversation: { method: 'post', url: 'ccs/conversations/{_id}/join' },
        addMembers: { method: 'post', url: 'ccs/conversations/{_id}/add-members' },
        getConversationInfo: { method: 'get', url: 'ccs/conversations/{_id}/info' },
        grantOrRevokeAdminAccess: { method: 'post', url: 'ccs/conversations/{_id}/admin' },
        approveOrRejectImage: { method: 'post', url: 'ccs/conversations/{_id}/media/approve' },
        downloadCustomerReport: { method: 'get', url: 'tickets/signoff/pdf' },
        sendScogoGptMessage: { method: 'post', url: 'scogo-gpt' },
        notifyViaWhatsappMessage: { method: 'post', url: 'whatsappIntegration/notifyViaWhatsappMessage' },
    },
    superdesk: {
        getCustomerTeams: { method: 'get', url: 'superdesk/teams' },
        getprojectListName: { method: 'get', url: 'projects/names' },
        getCustomerCapexTypes: { method: 'get', url: 'capex' },
        addTeams: { method: 'post', url: 'superdesk/teams' },
        updateAgentsProperty: { method: 'post', url: 'superdesk/agents' },
        addorRemoveTeamsAgent: { method: 'post', url: 'superdesk/agents' },
        addUsersInTeam: { method: 'put', url: 'superdesk/teams/{teamId}' },
        getTeamsById: { method: 'get', url: 'superdesk/teams/{teamId}' },
        deleteTeam: { method: 'delete', url: 'superdesk/teams/{teamId}' },
        editTeam: { method: 'put', url: 'superdesk/teams/{teamId}' },
    },
    wallet: {
        getTransactions: { method: 'get', url: 'wallet/transactions' },
        getWalletDetails: { method: 'get', url: 'wallet' },
        withdraw: { method: 'post', url: 'wallet/withdraw' },
        getWalletPaymentStatus: { method: 'get', url: 'wallet/payments/status' },
        getWalletOrderStatus: { method: 'get', url: 'wallet/order/status' },
        addAmount: { method: 'post', url: 'wallet/order' },
        updateWallet: { method: 'put', url: 'wallet' },
        getTransactionsDetails: { method: 'post', url: 'wallet/transactions' },
    },
    projects: {
        updatePmDetails: { method: 'post', url: 'projects/tagpm' },
        emailTomorrowsPlannedTicketsCsv: { method: 'post', url: 'csv/plannedTickets/tomorrow' },
        emailTodaysConfigCompleteTicketsCsv: { method: 'post', url: 'csv/configTickets/today' },
        addorRemoveTeamProject: { method: 'post', url: 'projects/{projectId}/teams' },
        createPost: { method: 'post', url: 'projects/{projectId}/chat-post' },
        uploadPostAttachment: { method: 'post', url: 'files' },
        addMultipleProjectInTeams: { method: 'post', url: 'projects/addTeams' },
        addSlaInProject: { method: 'post', url: 'projects/{projectId}/sla' },
        getProjectsSla: { method: 'get', url: 'projects/{projectId}/sla' },
    },
    serviceability: {
        uploadFile: { method: 'post', url: 'files' },
        splist: { method: "post", url: "admin/domain/checkServiceability" }
    },

    scm: {
        getSiteType: { method: 'get', url: 'sitetypes' },
        updateSiteType: { method: 'put', url: 'users/sitetypes' },
    },
    policies: {
        getAllSlas: { method: 'get', url: 'policies/sla' },
    },
    operation: {
        getAssetsTypes: { method: 'get', url: 'assetsTypes' },
        addAssetsMaster: { method: 'post', url: 'assetsTypes' },
    },
    workflow: {
        signOffTemplates: { method: 'get', url: 'templates/signoff' },
        createWorkflow: { method: 'post', url: 'workflow/{templateId}' },
    },
    template: {
        createTemplate: { method: 'post', url: 'templates' },
    },
    tickets: {
        updateTicketProperties: { method: 'put', url: 'tickets/properties' },
        createIndependentTickets: { method: 'post', url: 'tickets/independent' },
        acceptTicket: { method: 'post', url: 'tickets/accept' },
        getPromisedAmount: { method: 'get', url: 'tickets/promise/amount' },
        cancelPo: { method: 'put', url: 'tickets/properties' },
        getChildTickets: { method: 'get', url: 'tickets/{ticketId}/children' },
        requestAdditionalAmount: { method: 'post', url: 'tickets/additional/amount/request' },
        processAdditionalAmount: { method: 'post', url: 'tickets/additional/amount/status' },
        deleteTicket: { method: 'delete', url: 'tickets' },
        getTickets: { method: 'get', url: 'tickets' },
        ticketFilter: { method: 'post', url: 'filter/tickets' },
        getTicketsTodayStatus: { method: 'get', url: 'tickets/todaystatus' },
        addfeIntentionStatus: { method: 'post', url: 'tickets/feNotify' },
        todayStatusFilter: { method: 'post', url: 'filter/todayStatus' },
        assignSP: { method: 'post', url: 'tickets/assign' },
        updateDocketDetais: { method: 'put', url: 'tickets/docket' },
        assignTechSupportUser: { method: 'post', url: 'tickets/{ticketId}/assign' },
        hardCloseTicket: { method: 'post', url: 'tickets/hardCloseTicket' },
        getSignOffSteps: { method: 'get', url: 'tickets/signoff/steps' },
        updateSignOffSteps: { method: 'post', url: 'tickets/signoff/steps' },
        ticketCount: { method: 'get', url: 'tickets/count' },
        uploadSignOff: { method: 'post', url: 'tickets/signoff/upload' },
        signedUrlsForSignoffUpload: { method: 'post', url: 'tickets/signoff/upload/signedUrl' },
        activeVoiceCall: { method: 'get', url: 'tickets/activeVoiceCall' },
        ticketDetails: { method: 'get', url: 'tickets/track' },
        callOnTicketDetailsCopy: { method: 'post', url: 'tickets/clipboard' },
    },
    utils: {
        uploadFiles: { method: 'post', url: 'files/upload' },
        searchModule: { method: 'get', url: 'search' },
        exportCsv: { method: 'post', url: 'csv/export' },
        filterModule: { method: 'post', url: 'filter' },
        getFilterList: { method: 'get', url: 'filter/list' },
    },
    notifications: {
        getNotification: { method: 'get', url: 'users/notifications' },
        readNotification: { method: 'post', url: 'users/notifications' },
        getChatNotifications: { method: 'get', url: 'ccs/conversations/notifications' },
        countChatNotifications: { method: 'get', url: 'ccs/conversations/notifications/count' },
        markReadChatNotifications: { method: 'post', url: 'ccs/conversations/notifications/{notificationId}/read' },
    },
    locations: {
        states: {
            listAllState: { method: 'get', url: 'states' },
        },
        cities: {
            listAllCity: { method: 'get', url: 'states/0/city' },
        },
        pincodes: {
            listAll: { method: 'get', url: 'pincode' },
            addPincode: { method: 'post', url: 'pincode' },
        },
    },
    user: {
        updateUserTerritories: { method: 'put', url: 'users/territory' },
        removeUserTerritory: { method: 'post', url: 'users/territory/remove' },
        updateTechSupportStatusOfUser: { method: 'put', url: 'users/techSupport' },
        getTechSupportUsers: { method: 'get', url: 'users/techSupport' },
        getTechEngineers: { method: 'get', url: 'users/techSupport' },
        getUserDetails: { method: 'get', url: 'users/{userId}/chat' },
        newResourceFile: { method: 'post', url: 'files' },
        createEndUser: { method: 'post', url: 'endUser' },
        getUsersRatecard: { method: 'get', url: 'users/ratecard' },
        onboardUser: { method: 'post', url: 'onboarding/{role}' },
        userProfileDetails: { method: 'get', url: 'users/{userId}/profile/minimal' },
        uploadProof: { method: 'post', url: 'files' },
        updateProofAddress: { method: 'post', url: 'users/address-proof' },
        verifyAddress: { method: 'post', url: 'users/address-proof/verify' },
        uploadAdditionalAmountProof: { method: 'post', url: 'files' },
        userFilterList: { method: 'get', url: 'filter/list' },
        generateAffiliateLink: { method: 'get', url: 'users/{userId}/generateAffiliateId' },
        bulkInveteFileUpload: { method: 'get', url: 'bulkInvites/fileUpload' },
        getEndUser: { method: 'get', url: 'endusers' },
    },
    sp: {
        sharePublicProfile: { method: 'get', url: 'users/{userId}/profile' },
        getidCard: { method: 'get', url: 'ccs/users/{userId}' },
    },
    payment: {
        payoutFilter: { method: 'post', url: 'filter/payouts' },
        openTicketFilter: { method: 'post', url: 'filter/openTickets' },
        rewardsFilter: { method: 'post', url: 'filter/reward' },
        withdrawalFilter: { method: 'post', url: 'filter/withdraw' },
        poFilter: { method: 'post', url: 'filter/po' },
    },
    admin: {
        checkDomainAvailability: { method: 'post', url: 'admin/domain/availability' },
        createDomain: { method: 'post', url: 'admin/domain' },
    },
    customer: {
        getDealIds: { method: 'get', url: 'customer/deals' },
    },
    whatsapp: {
        updateWhatsappNumber: { method: 'put', url: 'whatsappIntegration/checkWhatsAppVerificationStatus' },
        getWhatsappQr: { method: 'get', url: 'whatsappIntegration/startSessionAndScanQR' },
        listOfWhatsAppGroup: { method: 'get', url: 'whatsappIntegration/whatsAppGroupList' },
        forwardMessageToWhatsApp: { method: 'post', url: 'whatsappIntegration/forwardMessageToWhatsApp' }
    },
    rating: {
        postFeRating: { method: 'post', url: 'users/{userId}/rate' },
    },
    appointment: {
        askAppointment: { method: 'post', url: 'appointment/generateAppointmentLink' }
    },
    inventory: {
        uploadAttachmentsDeviceConfig: { method: 'post', url: 'webhooks/assets/config' }
    }

};

export default urls;

import { payments, tickets } from '../types';
import { validateStringValue, validateNumberValue, validateDateValue } from '../utils/common';
const INITIAL_STATE = {
    error: undefined,
    page: 1,
    isFilterActive: false,
    rowsPerPage: 10,
    filterPayload: {},
    searchQuery: '',
    showPaymentSideDrawer: false,
    paymentsExportCsvData: {},
    paymentsFilteredData: [],
    paymentsCount: '',
    handleDownloadZipPoClick: () => { },
    handleExportCsvClick: () => { },
    groupPoListByEnduser: {},
    poCountByEnduserList: {},
    hasEndUserGroupedPoListEnd: {}
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case payments.GET_ALL_PAYMENTS_SUCCESS: {
            const { tickets_list, paymentsCount } = action.payload;
            return {
                ...state,
                payments: transformPaymentsData(tickets_list),
                paymentsCount,
            };
        }

        case payments.UPDATE_WALLET_PAYMENT_STATUS_SUCCESS: {
            const { id, response } = action.payload;
            let payments = [...state.payments].map((payment) => {
                if (parseFloat(id) === parseFloat(payment.id)) {
                    return {
                        ...payment,
                        ticket_basic_payment_status: {
                            ...payment.ticket_basic_payment_status,
                            paymate_transaction_message: response.paymate_transaction_message,
                            is_request_raised: response.is_request_raised,
                        },
                    };
                }
                return payment;
            });

            return {
                ...state,
                payments,
            };
        }

        case payments.GET_PO_LIST_SUCCESS: {
            let poList = transformPOList(action.payload);
            return {
                ...state,
                poList: poList,
            };
        }
        case payments.GET_PO_LIST_COUNT_SUCCESS:
            return {
                ...state,
                poCount: action.payload,
            };

        case payments.GET_OPEN_TICKET_PAYMENTS_SUCCESS: {
            const { tickets_list, paymentsCount } = action.payload;
            return {
                ...state,
                openTicketPayments: transformPaymentsData(tickets_list),
                openTicketPaymentCount: paymentsCount,
            };
        }
        case payments.GET_ALL_PAYOUTS_FILTERLIST_SUCCESS: {
            const data = action.payload || {};
            data['requested_amount'] = [
                { label: "Yes", value: 1 },
                { label: "No", value: 0 },
            ]
            return {
                ...state,
                payoutsFilterData: action.payload,
            };
        }
        case payments.GET_ALL_WITHDRAWAL_FILTERLIST_SUCCESS: {
            return {
                ...state,
                withdrawalFilterData: action.payload,
            };
        }
        case payments.GET_ALL_REWARDS_AND_PENALTIES_FILTERLIST_SUCCESS: {
            return {
                ...state,
                rewardsAndPenaltiesFilterData: action.payload,
            };
        }
        case payments.GET_ALL_PO_FILTERLIST_SUCCESS: {
            return {
                ...state,
                poFilterList: action.payload,
            };
        }

        case payments.GET_WALLET_WITHDRAWAL_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                walletWithdrawlTransactions: transformWithdrawalData(action.payload),
            };

        case payments.ALL_WITHDRAWAL_TRANSACTIONS_COUNT_SUCCESS:
            return {
                ...state,
                walletWithdrawalCount: action.payload.wallet,
            };

        case payments.GET_WALLET_REWARDS_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                walletTransactionsRewards: transformRewardsData(action.payload),
            };

        case payments.ALL_REWARDS_TRANSACTIONS_COUNT_SUCCESS:
            return {
                ...state,
                walletRewardsCount: action.payload.wallet,
            };

        case payments.SET_PAYMENTS_SIDEDRAWER:
            return {
                ...state,
                showPaymentSideDrawer: action.payload.showPaymentSideDrawer,
                paymentSideDrawerName: action.payload.sideBarName,
                sideDrawerPayload: action.payload.paymentSideDrawerPayload,
            };

        case payments.GET_PAYOUTS_FILTER_DATA_SUCCESS: {
            const { filterData, filterCount } = action.payload;
            return {
                ...state,
                payments: transformPaymentsData(filterData),
                paymentsCount: filterCount,
            };
        }

        case payments.GET_OPEN_TICKET_FILTER_DATA_SUCCESS: {
            const { filterData, filterCount } = action.payload;
            return {
                ...state,
                openTicketPayments: transformPaymentsData(filterData),
                openTicketPaymentCount: filterCount,
            };
        }
        case payments.GET_REWARDS_FILTER_DATA_SUCCESS: {
            const { filterData, filterCount } = action.payload;
            return {
                ...state,
                walletTransactionsRewards: transformRewardsData(filterData),
                walletRewardsCount: filterCount,
            };
        }

        case payments.GET_WITHDRAWAL_FILTER_DATA_SUCCESS: {
            const { filterData, filterCount } = action.payload;
            return {
                ...state,
                walletWithdrawlTransactions: transformWithdrawalData(filterData),
                walletWithdrawalCount: filterCount,
            };
        }
        case payments.GET_PO_FILTER_DATA_SUCCESS: {
            const { filterData, filterCount } = action.payload;
            return {
                ...state,
                poList: transformPOList(filterData),
                poCount: filterCount,
            };
        }
        case payments.GET_ENDUSER_GROUPED_PO_FILTER_DATA_SUCCESS: {
            const { filterData, filterCount, enduserId, page } = action.payload;
            const groupPoListByEnduser = state.groupPoListByEnduser;
            const poCountByEnduserList = state.poCountByEnduserList;
            const hasEndUserGroupedPoListEnd = state.hasEndUserGroupedPoListEnd;

            if (page > 1 && filterData) {
                groupPoListByEnduser[enduserId] = [...groupPoListByEnduser[enduserId], ...transformPOList(filterData)];
            } else {
                groupPoListByEnduser[enduserId] = transformPOList(filterData);
            }
            poCountByEnduserList[enduserId] = filterCount;
            hasEndUserGroupedPoListEnd[enduserId] = filterData.length === 0;

            return {
                ...state,
                groupPoListByEnduser,
                poCountByEnduserList,
            };
        }
        case payments.CLEAR_PAYEMNT_TABS_DATA_ON_UNMOUNT: {
            return {
                ...state,
                searchQuery: '',
                rowsPerPage: INITIAL_STATE.rowsPerPage,
                page: INITIAL_STATE.page,
                filterPayload: {},
                isFilterActive: false,
                tab: '',
            };
        }
        case payments.SET_PAYMENT_FILTER_AND_PAGINATION_DATA: {
            const { payload, isFilterActive, limit, page, query, tab } = action.payload;
            let updatedPayload = {};
            if (isFilterActive !== undefined) updatedPayload.isFilterActive = isFilterActive;
            if (payload) updatedPayload.filterPayload = payload;
            if (limit) updatedPayload.rowsPerPage = limit;
            if (page) updatedPayload.page = page;
            if (typeof query === 'string') updatedPayload.searchQuery = query;
            if (tab) updatedPayload.tab = tab;
            return {
                ...state,
                ...updatedPayload,
            };
        }

        case payments.CANCEL_PO_SUCCESS: {
            const { id } = action.payload;
            let newList = state.poList?.map((item) => {
                if (item?.ticketId === id) {
                    let updateValue = {
                        ...item,
                        status: 'CANCELLED',
                        updatedAt: new Date(),
                    };
                    if (item?.po) {
                        updateValue.po = { ...item.po, status: 'CANCELLED' };
                    }
                    return updateValue;
                } else return item;
            });
            return {
                ...state,
                poList: newList,
            };
        }
        case payments.CANCEL_ENDUSER_GROUPED_PO_SUCCESS: {
            const { id, enduserId } = action.payload;
            const groupPoListByEnduser = state.groupPoListByEnduser;
            if (groupPoListByEnduser[enduserId]) {
                groupPoListByEnduser[enduserId] = groupPoListByEnduser[enduserId].map((item) => {
                    if (item?.ticketId === id) {
                        let updateValue = {
                            ...item,
                            status: 'CANCELLED',
                            updatedAt: new Date(),
                        };
                        if (item?.po) {
                            updateValue.po = { ...item.po, status: 'CANCELLED' };
                        }
                        return updateValue;
                    } else return item;
                })
            }
            return {
                ...state,
                groupPoListByEnduser,
            };
        }

        case payments.UPDATE_PO_INVOICE_SUCCESS: {
            const { invoices, updatedAt, id } = action.payload;
            let newList = state.poList?.map((item) => {
                if (item?.ticketId === id) {
                    let updateValue = {
                        ...item,
                        invoices,
                        updatedAt: updatedAt,
                    };
                    if (item?.po) {
                        updateValue.po = { ...item.po, invoices };
                    }
                    return updateValue;
                } else return item;
            });
            return {
                ...state,
                poList: newList,
            };
        }
        case payments.UPDATE_GROUP_BY_ENDUSER_PO_INVOICE_SUCCESS: {
            const { invoices, updatedAt, id, enduserId } = action.payload;
            const groupPoListByEnduser = state.groupPoListByEnduser;
            if (groupPoListByEnduser[enduserId]) {
                groupPoListByEnduser[enduserId] = groupPoListByEnduser[enduserId].map((item) => {
                    if (item?.ticketId === id) {
                        let updateValue = {
                            ...item,
                            invoices,
                            updatedAt: updatedAt,
                        };
                        if (item?.po) {
                            updateValue.po = { ...item.po, invoices };
                        }
                        return updateValue;
                    } else return item;
                })
            }
            return {
                ...state,
                groupPoListByEnduser
            };
        }

        case payments.GENERATE_INVOICE_SUCCESS: {
            const { invoices, updatedAt, id } = action.payload;
            let newList = state.poList?.map((item) => {
                if (item?.ticketId === id) {
                    let updateValue = {
                        ...item,
                        invoices,
                        updatedAt: updatedAt,
                    };
                    if (item?.po) {
                        updateValue.po = { ...item.po, invoices };
                    }
                    return updateValue;
                } else return item;
            });
            return {
                ...state,
                poList: newList,
            };
        }
        case payments.GROUP_BY_ENDUSER_PO_GENERATE_INVOICE_SUCCESS: {
            const { invoices, updatedAt, id, enduserId } = action.payload;
            const groupPoListByEnduser = state.groupPoListByEnduser;
            if (groupPoListByEnduser[enduserId]) {
                groupPoListByEnduser[enduserId] = groupPoListByEnduser[enduserId].map((item) => {
                    if (item?.ticketId === id) {
                        let updateValue = {
                            ...item,
                            invoices,
                            updatedAt: updatedAt,
                        };
                        if (item?.po) {
                            updateValue.po = { ...item.po, invoices };
                        }
                        return updateValue;
                    } else return item;
                })
            }

            return {
                ...state,
                groupPoListByEnduser
            };
        }

        case payments.PAY_PO_TAX_SUCCESS: {
            const { id, paymentDetails, totalAmount, paidAmount, totalTax, paidTax, updatedAt } = action.payload;
            let newList = state.poList?.map((item) => {
                if (item?.ticketId === id) {
                    let updateValue = {
                        ...item,
                        paymentDetails,
                        totalAmount,
                        paidAmount,
                        totalTax,
                        paidTax,
                        updatedAt: updatedAt,
                    };
                    if (item?.po) {
                        updateValue.po = { ...item.po, paymentDetails };
                    }
                    return updateValue;
                } else return item;
            });
            return {
                ...state,
                poList: newList,
            };
        }
        case payments.PAY_GROUP_BY_ENDUSER_PO_TAX_SUCCESS: {
            const { id, paymentDetails, totalAmount, paidAmount, totalTax, paidTax, updatedAt, enduserId } = action.payload;
            const groupPoListByEnduser = state.groupPoListByEnduser;
            if (groupPoListByEnduser[enduserId]) {
                groupPoListByEnduser[enduserId] = groupPoListByEnduser[enduserId].map((item) => {
                    if (item?.ticketId === id) {
                        let updateValue = {
                            ...item,
                            paymentDetails,
                            totalAmount,
                            paidAmount,
                            totalTax,
                            paidTax,
                            updatedAt: updatedAt,
                        };
                        if (item?.po) {
                            updateValue.po = { ...item.po, paymentDetails };
                        }
                        return updateValue;
                    } else return item;
                })
            };
            return {
                ...state,
                groupPoListByEnduser
            };
        }
        case payments.GET_TICKET_PO_DETAILS_SUCCESS: {
            return {
                ...state,
                ticketPODetails: transformTicketPODetails(action.payload),
            };
        }
        case payments.SET_EXPORT_CSV_PO_FUNCTION_IN_STORE: {
            return {
                ...state,
                handleExportCsvClick: action.payload,
            };
        }
        case payments.SET_DOWNLOAD_ZIP_PO_FUNCTION_IN_STORE: {
            return {
                ...state,
                handleDownloadZipPoClick: action.payload,
            };
        }
        case payments.GET_PO_CATEGORIES_SUCCESS: {
            return {
                ...state,
                poCategories: action.payload,
            };
        }

        case payments.GET_PO_ASSET_CATEGORIES_DROPDOWN_SUCCESS:
            return {
                ...state,
                poassetcategories: action.payload,
            };
        case payments.CLEANUP_FOR_UPDATE_PO: {
            return {
                ...state,
                poCategories: undefined,
            };
        }
        case payments.GET_PO_GST_DETAILS_SUCCESS: {
            return {
                ...state,
                poGstDetails: action.payload,
            };
        }
        case payments.CLEAR_PO_GST_DETAILS_FROM_STORE: {
            return {
                ...state,
                poGstDetails: undefined,
            };
        }

        case tickets.REMOVE_PO_APPROVER_SUCCESS: {
            const { teamId, userId } = action.payload;
            let poDetails = state.ticketPODetails;

            poDetails.approvalTeams = poDetails.approvalTeams.map((team) => {
                if (team.team.id === teamId) {
                    let users = team.users.filter((user) => {
                        return user.id !== userId;
                    });
                    return { ...team, users };
                }
                return team;
            });

            return {
                ...state,
                ticketPODetails: poDetails,
            };
        }
        case payments.GET_PO_GST_DETAILS_FAILURE:
        case payments.GET_WALLET_WITHDRAWAL_TRANSACTIONS_FAILURE:
        case payments.GET_PO_ASSET_CATEGORIES_DROPDOWN_FAILURE:
        case payments.GET_PO_FILTER_DATA_FAILURE:
        case payments.GET_PAYOUTS_FILTER_DATA_FAILURE:
        case payments.GET_OPEN_TICKET_FILTER_DATA_FAILURE:
        case payments.GET_ALL_PAYMENTS_FAILURE:
        case payments.GET_OPEN_TICKET_PAYMENTS_FAILURE:
        case payments.GET_PO_LIST_FAILURE:
        case payments.GET_ALL_PAYOUTS_FILTERLIST_FAILURE:
        case payments.GET_ALL_REWARDS_AND_PENALTIES_FILTERLIST_FAILURE:
        case payments.GET_ALL_WITHDRAWAL_FILTERLIST_FAILURE:
        case payments.GET_WALLET_REWARDS_TRANSACTIONS_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return {
                ...state,
            };
    }
};

const transformPOList = (poLists) => {
    let newPoList = poLists?.map((item) => {
        let obj = {
            approvedpdf: item?.po?.approvedpdf,
            approvals: item?.po?.approvals,
            ticketId: validateNumberValue(item?.ticketId),
            prNumber: validateStringValue(item?.ticketNumber),
            poNmber: validateStringValue(item?.po?.poNumber),
            createdAt: validateDateValue(item?.createdAt),
            creationDate: validateDateValue(item?.creationDate),
            nextRenewalDate: validateDateValue(item?.nextRenewalDate),
            customerName: validateStringValue(item?.customerName),
            invoices: item?.po?.invoices,
            requested: validateStringValue(item?.po?.requester?.name),
            vendorName: validateStringValue(item?.po?.vendorName),
            vendorId: validateNumberValue(item?.po?.vendorId),
            poType: item?.po?.type === 'RENEWAL_DUE' ? 'RENEWAL DUE' : validateStringValue(item?.po?.type),
            siteId: validateNumberValue(item?.siteId),
            siteName: validateStringValue(item?.siteName),
            enduserName: validateStringValue(item?.enduserName),
            status: validateStringValue(item?.po?.status),
            paymentDetails: item?.po?.paymentDetails,
            totalAmount: parseFloat(item?.po?.paymentDetails?.totalPrice),
            paidAmount: parseFloat(item?.po?.paymentDetails?.totalPrice) - parseFloat(item?.po?.paymentDetails?.totalUnpaid),
            totalTax: parseFloat(item?.po?.paymentDetails?.totalTax),
            paidTax: parseFloat(item?.po?.paymentDetails?.totalTax) - parseFloat(item?.po?.paymentDetails?.totalUnpaidTax),
            quotationpdf: item?.po?.quotationpdf,
            id: item?.id,
            linkType: item.linkType
        };
        return Object.assign(item, obj);
    });
    return newPoList;
};

const transformWithdrawalData = (withdrawalList) => {
    let newWithdrawalList = [];
    if (Array.isArray(withdrawalList) && withdrawalList.length > 0) {
        newWithdrawalList = withdrawalList.map((withdraw) => {
            let obj = {
                trnasactionId: validateStringValue(withdraw?.scogo_tx_no),
                userId: withdraw?.user?.id,
                name: `${validateStringValue(withdraw?.user?.first_name)} ${validateStringValue(withdraw?.user?.last_name)}`,
                amount: validateNumberValue(withdraw?.orderAmount),
                status: validateStringValue(withdraw?.txStatus),
                date: validateDateValue(withdraw?.created_at),
                intitatedBy: `${validateStringValue(withdraw?.createdBy?.first_name)} ${validateStringValue(withdraw?.createdBy?.last_name)}`,
                openingBalance: validateNumberValue(withdraw.opening_balance),
                closingBalance: validateNumberValue(withdraw.closing_balance),
            };
            return Object.assign(withdraw, obj);
        });
    }
    return newWithdrawalList;
};

const transformRewardsData = (rewardsList) => {
    let newRewardsList = [];
    if (Array.isArray(rewardsList) && rewardsList.length > 0) {
        newRewardsList = rewardsList.map((reward) => {
            let obj = {
                transactionId: validateStringValue(reward?.scogo_tx_no),
                name: `${validateStringValue(reward?.user?.first_name)} ${validateStringValue(reward?.user?.last_name)}`,
                userId: reward?.user?.id,
                type: validateStringValue(reward?.paymentType),
                amount: validateNumberValue(reward?.orderAmount),
                status: validateStringValue(reward?.txStatus),
                date: validateDateValue(reward?.created_at),
            };
            return Object.assign(reward, obj);
        });
    }
    return newRewardsList;
};

export const transformPaymentsData = (payments) => {
    let newPaymentList = [];
    if (Array.isArray(payments) && payments.length > 0) {
        newPaymentList = payments.map((payment) => {
            let obj = {
                ticketNo: validateStringValue(payment?.job_ticket_number),
                sp: `${validateStringValue(payment?.accepted_s_p_detail?.first_name)} ${validateStringValue(
                    payment?.accepted_s_p_detail?.last_name
                )}`,
                sp_id: validateNumberValue(payment?.accepted_s_p_detail?.id),
                city: validateStringValue(payment?.job_city?.city_name),
                city_id: validateNumberValue(payment?.job_city?.city_id),
                signOffDate: validateDateValue(payment?.sign_off_approval_date),
                GstorPan: payment?.accepted_s_p_company_detail?.sp_gst_no,
                paymentAmount: validateNumberValue(payment?.ticket_basic_payment_status?.amount),
                dueDate: validateDateValue(payment?.ticket_basic_payment_status?.due_date),
                scogo_transaction_no: validateStringValue(payment?.ticket_basic_payment_status?.scogo_transaction_no),
                state_name: validateStringValue(payment?.job_state?.state_name),
                state_id: validateNumberValue(payment?.job_state?.state_id),
                project_name: validateStringValue(payment?.project_detail?.project_name),
                project_id: validateNumberValue(payment?.project_detail?.id),
            };
            return Object.assign(payment, obj);
        });
    }
    return newPaymentList;
};

const transformTicketPODetails = (ticket) => {
    const poDetails = ticket?.ticket?.po ? ticket?.ticket?.po : {};
    return {
        quotes: poDetails.quotes,
        poitems: poDetails.poitems,
        vendorName: poDetails.vendorName,
        vendorId: poDetails.vendorId,
        expenseType: poDetails.expenseType,
        poNumber: poDetails.poNumber,
        approvers: poDetails.approvers,
        approvals: poDetails.approvals,
        approvalTeams: ticket.approvalTeams,
        approvalUsers: ticket.approvalUsers
    };
};

export default reducer;

import { put, delay, call, takeLatest, takeEvery, all, race, take, select } from 'redux-saga/effects';

import lambdas from '../lambdas/apis';
import microservices from '../lambdas/microservices';

import { utils, toast, loading, modal, auth } from '../types';
import { uploadFileToS3WithSignedUrl, getRemoteData } from '../utils/common';
import { getInventoryFilterData } from '../selectors/inventory';
import {
    sites as sitesTab,
    assets as assetsTab,
    warehouses as warehousesTab,
    spareAssets as spareAssetsTab,
} from '../pages/Inventory/InventoryController';

export function* searchModuleData(action) {
    const { cancel } = yield race({
        task: delay(1000),
        cancel: take(utils.CANCEL_SEARCH_MODULE_DATA_REQUEST),
    });
    if (!cancel) {
        try {
            yield put({ type: loading.SEARCH_MODULE_DATA_LOADING, payload: true });
            const { module, tab, query, projectId, customerId, endUserId, siteId, warehouseId, limit, page } = action.payload;
            let queryParams = { module, tab, query, giveLastTickets: true, giveAssetTypes: true, giveSpareAssetsStats: true };
            if (!['', 'all', undefined, null].includes(projectId)) queryParams.projectId = projectId;
            if (!['', 'all', undefined, null].includes(customerId)) queryParams.customerId = customerId;
            if (!['', 'all', undefined, null].includes(endUserId)) queryParams.endUserId = endUserId;
            if (!['', 'all', undefined, null].includes(siteId)) queryParams.siteId = siteId;
            if (!['', 'all', undefined, null].includes(warehouseId)) queryParams.warehouseId = warehouseId;
            if (!['', 'all', undefined, null].includes(limit)) queryParams.limit = limit;
            if (!['', 'all', undefined, null].includes(page)) queryParams.page = page;
            const response = yield call(lambdas.utils.searchModule, {}, {}, queryParams);
            if (response.data.code === 200) {
                yield all([
                    yield put({ type: utils.SEARCH_MODULE_DATA_SUCCESS, payload: response.data.data }),
                    yield put({ type: utils.SEARCH_MODULE_DATA_COUNT_SUCCESS, payload: response.data.count }),
                ]);
            }
        } catch (err) { }
        yield put({ type: loading.SEARCH_MODULE_DATA_LOADING, payload: false });
    }
}

export function* uploadFiles(action) {
    const { actionType, query = {}, files = [], formName } = action.payload;
    try {
        const queryParams = { action: actionType, ...query };
        let filesName = [];
        Array.prototype.forEach.call(files, (file) => filesName.push(file.name));

        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.utils.uploadFiles, {}, {}, queryParams, { files: filesName });
        if (response.data.code === 200) {
            yield* response.data.data.map(({ signedUrl, fileName }) => {
                const file = Array.prototype.forEach.call(files, (f) => f.name === fileName);
                return call(uploadFileToS3WithSignedUrl, signedUrl, file);
            });
            yield put({ type: modal.CLOSE_MODAL });
            let message = `file uploaded successfully!!!`;
            if (files.length > 1) {
                message = `files uploaded successfully!!!`;
            }
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: message } });
        } else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something Went Wrong' } });
        }
    } catch (err) { }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* copyToClipboard(action) {
    const { data } = action.payload;
    try {
        navigator.clipboard.writeText(data);
        let message = 'Successfully Copied To Clipboard';
        let status = 'success';
        yield put({ type: toast.SEND_TOAST, payload: { status, message } });
    } catch (error) {
        yield put({ type: utils.COPY_TO_CLIPBOARD_FAILURE, payload: { message: error } });
    }
}

export function* downloadFiles(action) {
    const { files } = action.payload;
    try {
        let status = 'success';
        let message = files.length === 1 ? 'File Downloaded Successfully' : 'Files Downloaded Successfully';
        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            const { url, fileName, mimeType } = file;
            let blob = yield call(getRemoteData, url);
            const a = document.createElement('a');
            a.style.display = 'none';
            document.body.appendChild(a);

            // Set the HREF to a Blob representation of the data to be downloaded
            a.href = window.URL.createObjectURL(new Blob([blob], { type: mimeType }));

            // Use download attribute to set set desired file name
            a.setAttribute('download', fileName);

            // Trigger the download by simulating click
            a.click();

            // Cleanup
            window.URL.revokeObjectURL(a.href);
            document.body.removeChild(a);
        }
        yield put({ type: toast.SEND_TOAST, payload: { status, message } });
    } catch (error) {
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something Went Wrong' } });
    }
}

export function* exportModuleCsvData(action) {
    const {
        module,
        tab,
        projectId,
        customerId,
        endUserId,
        siteId,
        warehouseId,
        status = 'ALL',
        emails = [],
        download = true,
        data = {},
        type = 0,
        formName,
        filterData,
    } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        let queryParams = { module, tab, type, status };
        if (tab === sitesTab.id) queryParams.tab = 'sites';
        else if (tab === assetsTab.id) queryParams.tab = 'assets';
        else if (tab === warehousesTab.id) queryParams.tab = 'warehouses';
        else if (tab === spareAssetsTab.id) queryParams.tab = 'spareassets';
        if (!['', 'all', undefined, null].includes(projectId)) queryParams.projectId = projectId;
        if (!['', 'all', undefined, null].includes(customerId)) queryParams.customerId = customerId;
        if (!['', 'all', undefined, null].includes(endUserId)) queryParams.endUserId = endUserId;
        if (!['', 'all', undefined, null].includes(siteId) && parseInt(siteId) > 0) queryParams.siteId = siteId;
        if (!['', 'all', undefined, null].includes(warehouseId) && parseInt(warehouseId) > 0) queryParams.warehouseId = warehouseId;
        const response = yield call(lambdas.utils.exportCsv, {}, {}, queryParams, { emails, filterData, data });
        if (response.data.code === 200) {
            yield all([
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: response.data.data.message } }),
            ]);
        } else if ([400, 500].includes(response.data.code)) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
            yield put({ type: modal.CLOSE_MODAL });
        }
    } catch (err) {
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* filterModuleData(action) {
    let {
        module,
        tab,
        projectId,
        customerId,
        endUserId,
        next = true,
        count = false,
        type = 0,
        loadingType,
        limit: givenLimit,
        page: givenPage,
    } = action.payload;
    let { limit = 10, page = 1, query, filterPayload = {}, warehouseId, siteId, archive } = yield select(getInventoryFilterData);
    if (givenLimit) limit = givenLimit;
    if (givenPage) page = givenPage;
    try {
        if (loadingType) yield put({ type: loadingType, payload: true });
        let queryParams = {
            module,
            tab,
            limit,
            page,
            next,
            count,
            type,
            giveLastTickets: true,
            giveAssetTypes: true,
            giveSpareAssetsStats: true,
            archive,
        };
        if (tab === sitesTab.id) queryParams.tab = 'sites';
        else if (tab === assetsTab.id) queryParams.tab = 'assets';
        else if (tab === warehousesTab.id) queryParams.tab = 'warehouses';
        else if (tab === spareAssetsTab.id) queryParams.tab = 'spareassets';
        if (!['', 'all', undefined, null].includes(projectId)) queryParams.projectId = projectId;
        if (!['', 'all', undefined, null, 0].includes(customerId)) queryParams.customerId = customerId;
        if (!['', 'all', undefined, null, 0].includes(warehouseId)) queryParams.warehouseId = warehouseId;
        if (!['', 'all', undefined, null, 0].includes(siteId)) queryParams.siteId = siteId;
        if (!['', 'all', undefined, null].includes(endUserId)) queryParams.endUserId = endUserId;
        if (!['', 'all', undefined, null].includes(filterPayload?.status))
            filterPayload.status = filterPayload.status.map((s) => s.split(' ').join('_'));
        if (!['', undefined, null].includes(query)) queryParams.query = query;
        const response = yield call(lambdas.utils.filterModule, {}, {}, queryParams, filterPayload);
        if (response.data.code === 200) {
            if (response.data.lastScannedIndex)
                yield put({ type: utils.UPDATE_FILTER_MODULE_LAST_SCANNED_INDEX_SUCCESS, payload: response.data.lastScannedIndex });
            if (response.data) yield put({ type: utils.FILTER_MODULE_DATA_COUNT_SUCCESS, payload: response.data.count });
            yield put({ type: utils.FILTER_MODULE_DATA_SUCCESS, payload: response.data.data });
        } else if ([400, 500].includes(response.data.code)) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
        }
    } catch (err) {
        yield put({ type: utils.FILTER_MODULE_DATA_FAILURE, payload: 'Something went wrong' });
    }
    yield put({ type: loadingType, payload: false });
}

export function* bulkUpload(action) {
    let { property, file, formName, toCloseModal, ...payload } = action.payload;
    try {
        if (formName) yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(microservices.utils.bulkUpload, {}, {}, {}, { property, ...payload });
        if (response.data.code === 200) {
            yield call(uploadFileToS3WithSignedUrl, response.data.data.signedUrl, file);
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: response.data.data.message } });
            yield put({ type: utils.BULK_UPLOAD_FILES_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
        }
    } catch (err) {
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
        yield put({ type: utils.BULK_UPLOAD_FILES_FAILURE, payload: 'Something went wrong' });
    }
    if (toCloseModal) yield put({ type: modal.CLOSE_MODAL });
    if (formName) yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* downloadRemoteData(action) {
    try {
        const { url, fileName, mimeType } = action.payload;
        let blob = yield call(getRemoteData, url);
        const a = document.createElement('a');
        a.style.display = 'none';
        document.body.appendChild(a);

        // Set the HREF to a Blob representation of the data to be downloaded
        a.href = window.URL.createObjectURL(new Blob([blob], { type: mimeType }));

        // Use download attribute to set set desired file name
        a.setAttribute('download', fileName);

        // Trigger the download by simulating click
        a.click();

        // Cleanup
        window.URL.revokeObjectURL(a.href);
        document.body.removeChild(a);
    } catch (error) {
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: error.message || 'Something Went Wrong!!!' } });
    }
}

export function* updateLatLong(action) {
    const { latitude, longitude } = action.payload;
    yield put({ type: utils.UPDATE_LAT_LONG_SUCCESS, payload: { latitude, longitude } });
}






export default function* utilsSaga() {
    yield takeLatest(utils.UPLOAD_FILES_REQUEST, uploadFiles);
    yield takeLatest(utils.SEARCH_MODULE_DATA_REQUEST, searchModuleData);
    yield takeLatest(utils.EXPORT_MODULE_DATA_CSV_REQUEST, exportModuleCsvData);
    yield takeLatest(utils.FILTER_MODULE_DATA_REQUEST, filterModuleData);
    yield takeLatest(utils.COPY_TO_CLIPBOARD_REQUEST, copyToClipboard);
    yield takeLatest(utils.DOWNLOAD_FILE_REQUEST, downloadFiles);
    yield takeEvery(utils.BULK_UPLOAD_FILES_REQUEST, bulkUpload);
    yield takeEvery(utils.DOWNLOAD_REMOTE_DATA_REQUEST, downloadRemoteData);
    yield takeEvery(utils.UPDATE_LAT_LONG_REQUEST, updateLatLong);
}

import { wallet } from '../types';

const INITIAL_STATE = {
    error: undefined,
    walletTransactions: [],
    hasReachedWalletListEnd: false,
    ongoingTransaction: null,
    isWithdrawAmountConfirmed: false,
    securityTransaction: null,
    securityTransactionFail: null,
};

const walletReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case wallet.GET_PROMISED_AMOUNT_SUCCESS:
            return {
                ...state,
                promisedAmount: action.payload.promisedAmount,
                totalEarning: action.payload.totalEarning,
                totalWithdrawn: action.payload.totalWithdrawn,
                totalPaid: action.payload.totalPaid,
            };
        case wallet.GET_WALLET_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                walletTransactions: action.payload,
                hasReachedWalletListEnd: false,
            };

        case wallet.GET_WALLET_NEXT_TRANSACTIONS_SUCCESS:
            const removeDuplicates = (arr) => {
                const { res } = arr.reduce(
                    ({ encountered, res }, el) => {
                        if (!encountered.has(el.scogo_tx_no)) {
                            encountered.add(el.scogo_tx_no);
                            res.push(el);
                        }
                        return { encountered, res };
                    },
                    { encountered: new Set(), res: [] }
                );
                return res;
            };
            const filteredWalletList = removeDuplicates([...state.walletTransactions, ...action.payload]);
            const hasReachedWalletListEnd = action.payload.length === 0;
            return {
                ...state,
                walletTransactions: filteredWalletList,
                hasReachedWalletListEnd: hasReachedWalletListEnd,
            };
        case wallet.WITHDRAW_WALLET_AMOUNT_WEBHOOK_SUCCESS:
            return {
                ...state,
                walletTransactions:
                    state.walletTransactions &&
                    state.walletTransactions.length > 0 &&
                    state.walletTransactions.map((item) => {
                        if (item.scogo_tx_no === action.payload.scogo_tx_no) {
                            item.status = action.payload.status;
                            item.reason = action.payload.reason;
                            item.txStatus = action.payload.txStatus;
                            item.orderStatus = action.payload.orderStatus;
                            return item;
                        } else {
                            return item;
                        }
                    }),
            };
        case wallet.GET_WALLET_DETAILS_SUCCESS:
            return {
                ...state,
                wallet: action.payload,
            };
        case wallet.GET_WALLET_DETAILS_BY_ID_SUCCESS:
            return {
                ...state,
                walletDetailsById: action.payload,
            };
        case wallet.REMOVE_WALLET_DETAILS_FROM_STORE:
            return {
                ...state,
                walletDetailsById: undefined,
            };

        case wallet.ADD_AMOUNT_TO_WALLET_SUCCESS:
            return {
                ...state,
                ongoingTransaction: action.payload,
            };
        case wallet.CLEAR_ONGOING_TRANSACTIONS_FROM_STORE:
            return {
                ...state,
                ongoingTransaction: INITIAL_STATE.ongoingTransaction,
            };
        case wallet.GET_RECEPIENTS_FILTERED_DATA_SUCCESS:
            return {
                ...state,
                recepientsFilteredData: action.payload,
            };
        case wallet.SET_IS_WITHDRAWAL_AMOUNT_CONFIRMED:
            return {
                ...state,
                isWithdrawAmountConfirmed: action.payload,
            };
        case wallet.PAY_SECURITY_DEPOSIT_SUCCESS:
            return {
                ...state,
                securityTransaction: action.payload,
            };
        case wallet.PAY_SECURITY_DEPOSIT_FAILURE:
            return {
                ...state,
                securityTransactionFail: action.payload,
            };

        case wallet.CLEAR_ONGOING_SECURITY_DEPOSIT_TRANSACTIONS_FROM_STORE:
            return {
                ...state,
                securityTransaction: INITIAL_STATE.securityTransaction,
                securityTransactionFail: INITIAL_STATE.securityTransactionFail,
            };

        case wallet.PAY_PO_MANUALLY_FAILURE:
        case wallet.ADD_AMOUNT_TO_WALLET_FAILURE:
        case wallet.GET_WALLET_DETAILS_FAILURE:
        case wallet.GET_WALLET_NEXT_TRANSACTIONS_FAILURE:
        case wallet.GET_WALLET_TRANSACTIONS_FAILURE:
        case wallet.GET_PROMISED_AMOUNT_FAILURE:
        case wallet.REQUEST_STATEMENT_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        default:
            return {
                ...state,
            };
    }
};

export default walletReducer;

import moment from 'moment';
import { takeEvery, call, put, all, select, takeLatest } from 'redux-saga/effects';
import { payments, loading, toast, modal, conversations } from '../types';
import microservices from '../lambdas/microservices';
import api from '../apis';
import lambdas from '../lambdas/apis';
import { getLoggedUser } from '../selectors/users';
import { getCustomerIdOfLoginUser } from '../utils/utils';
import { getPaymentFilterData } from '../selectors/payments';
import { refreshPage } from '../actions/payments';
import { uploadFileToS3WithSignedUrl, isURL, GetQueryParam } from '../utils/common';

export function* getPaymentsList(action) {
    const { limit = 10, page = 1, fromDate, toDate } = action.payload;

    const currentDate = moment();
    const todayMinus1Year = moment(currentDate).subtract(1, 'year');
    let queryParams = {
        closed: 1,
        'return-ticket-list': 1,
        'from-date': todayMinus1Year.format('DD-MM-YYYY'),
        'to-date': currentDate.format('DD-MM-YYYY'),
        limit,
        page,
        count: true,
    };
    if (fromDate && toDate) {
        queryParams['from-date'] = fromDate;
        queryParams['to-date'] = toDate;
    }

    try {
        yield put({ type: loading.SET_ALL_PAYMENTS_LOADING, payload: true });
        const response = yield call(api.payments.listAll, {}, {}, queryParams);
        if (response.data.code === 200) {
            yield put({
                type: payments.GET_ALL_PAYMENTS_SUCCESS,
                payload: { tickets_list: response.data.data.tickets_list.data, paymentsCount: response.data.data.tickets_list.total },
            });
        } else {
            yield put({ type: payments.GET_ALL_PAYMENTS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: payments.GET_ALL_PAYMENTS_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.SET_ALL_PAYMENTS_LOADING, payload: false });
}

export function* getPaymentPos(action) {
    try {
        const { customerId, limit = 10, page = 1, count = true } = action.payload;
        const queryParams = { limit, page, count };
        yield put({ type: loading.SET_PO_TABLE_LOADING, payload: true });
        if (!isNaN(customerId) && parseInt(customerId) > 0) queryParams.customerId = customerId;
        const response = yield call(microservices.payments.getPOs, {}, {}, queryParams);
        if (response.data.code === 200) {
            yield put({ type: payments.GET_PO_LIST_SUCCESS, payload: response.data.data });
            if (count) {
                yield put({ type: payments.GET_PO_LIST_COUNT_SUCCESS, payload: response.data.count });
            }
        } else {
            yield put({ type: payments.GET_PO_LIST_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: payments.GET_PO_LIST_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.SET_PO_TABLE_LOADING, payload: false });
}

export function* cancelPoRequest(action) {
    const { data, ticketId, formName, isRefresh, enduserId } = action.payload;
    let payload = { data, property: 'CANCEL_PO', ticketId };
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.tickets.updateTicketProperties, {}, {}, {}, payload);
        const poGroupView = new URLSearchParams(window.location.search).get('poGroupView');
        if (response.data.code === 200) {
            if (isRefresh) {
                if (enduserId && poGroupView) {
                    yield put({ type: payments.CANCEL_ENDUSER_GROUPED_PO_SUCCESS, payload: { id: ticketId, enduserId } });
                } else {
                    yield put({ type: payments.CANCEL_PO_SUCCESS, payload: { id: ticketId } });
                }
            }
            yield all([
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'Ticket details updated successfully' } }),
            ]);
        } else {
            yield put({ type: payments.CANCEL_PO_FAILURE, payload: { message: 'Oops Something went wrong' } });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
        }
    } catch (error) {
        yield put({ type: payments.CANCEL_PO_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* deletePoRequest(action) {
    const { ticketIds, formName, isRefresh, enduserId } = action.payload;
    const { tab = 'po' } = yield select(getPaymentFilterData);

    let payload = { ticketIds };
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.tickets.deleteTicket, {}, {}, {}, payload);
        if (response.data.code === 200) {
            yield all([yield put({ type: modal.CLOSE_MODAL }), yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'Ticket Deleted Successfully' } })]);
            if (isRefresh) {
                yield put(refreshPage({ tab, enduserId }));
            }
        } else {
            yield put({ type: payments.DELETE_PO_FAILURE, payload: { message: 'Oops Something went wrong' } });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
        }
    } catch (error) {
        yield put({ type: payments.DELETE_PO_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* updatePoInvoiceRequest(action) {
    const { data, ticketId, formName, isRefresh, files, enduserId } = action.payload;
    let payload = { data, property: 'UPDATE_PO_INVOICE', ticketId };
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.tickets.updateTicketProperties, {}, {}, {}, payload);
        const poGroupView = new URLSearchParams(window.location.search).get('poGroupView')
        if (response.data.code === 200) {
            if (response.data.pofiles) {
                yield* response.data.pofiles.map(({ signedUrl, fileName }) => {
                    const file = files.find((f) => f.name === fileName);
                    return call(uploadFileToS3WithSignedUrl, signedUrl, file);
                });
            }
            if (isRefresh) {
                if (enduserId && poGroupView) {
                    yield put({
                        type: payments.UPDATE_GROUP_BY_ENDUSER_PO_INVOICE_SUCCESS,
                        payload: { invoices: response?.data?.data?.po?.invoices, updatedAt: response.data.data.updatedAt, id: ticketId, enduserId },
                    });
                } else {
                    yield put({
                        type: payments.UPDATE_PO_INVOICE_SUCCESS,
                        payload: { invoices: response?.data?.data?.po?.invoices, updatedAt: response.data.data.updatedAt, id: ticketId },
                    });
                }
            }
            yield all([
                yield put({ type: modal.CLOSE_MODAL }),
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'Ticket details updated successfully' } }),
            ]);
        } else {
            yield put({ type: payments.UPDATE_PO_INVOICE_FAILURE, payload: { message: 'Oops Something went wrong' } });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
        }
    } catch (error) {
        yield put({ type: payments.UPDATE_PO_INVOICE_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* updatePo(action) {
    const { data, ticketId, formName, isRefresh, enduserId } = action.payload;
    let payload = { data, property: 'UPDATE_PO', ticketId };
    const { tab } = yield select(getPaymentFilterData);
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.tickets.updateTicketProperties, {}, {}, {}, payload);
        if (response.data.code === 200) {
            if (isRefresh) {
                yield put(refreshPage({ tab, enduserId }));
            }
            yield all([
                yield put({ type: modal.CLOSE_FULL_SCREEN_MODAL }),
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'Ticket details updated successfully' } }),
            ]);
        } else {
            yield put({ type: payments.UPDATE_PO_FAILURE, payload: { message: 'Oops Something went wrong' } });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
        }
    } catch (error) {
        yield put({ type: payments.UPDATE_PO_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* getOpenTicketPaymentList(action) {
    const { limit, page, fromDate, toDate } = action.payload;
    let queryParams = {
        only_payment: 1,
        'return-ticket-list': 1,
        limit,
        page,
    };

    if (fromDate && toDate) {
        queryParams['from-date'] = fromDate;
        queryParams['to-date'] = toDate;
    }

    try {
        yield put({ type: loading.SET_OPEN_TICKETS_PAYMENTS_LOADING, payload: true });
        const response = yield call(api.payments.listAll, {}, {}, queryParams);
        if (response.data.code === 200) {
            yield put({
                type: payments.GET_OPEN_TICKET_PAYMENTS_SUCCESS,
                payload: { tickets_list: response.data.data.tickets_list.data, paymentsCount: response.data.data.tickets_list.total },
            });
        } else {
            yield put({ type: payments.GET_OPEN_TICKET_PAYMENTS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: payments.GET_OPEN_TICKET_PAYMENTS_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.SET_OPEN_TICKETS_PAYMENTS_LOADING, payload: false });
}

export function* getAllPayoutsFilterList(action) {
    try {
        yield put({ type: loading.SET_IS_PAYOUTS_FILTERLIST_LOADING, payload: true });
        const response = yield call(microservices.payments.getPayoutsFilterList, {}, {}, {});
        if (response) {
            let filterList = response.data.data;
            yield put({ type: payments.GET_ALL_PAYOUTS_FILTERLIST_SUCCESS, payload: filterList });
        } else {
            yield put({ type: payments.GET_ALL_PAYOUTS_FILTERLIST_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: payments.GET_ALL_PAYOUTS_FILTERLIST_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.SET_IS_PAYOUTS_FILTERLIST_LOADING, payload: false });
}

export function* getAllRewardsAndPenaltiesFilterList(action) {
    try {
        yield put({ type: loading.SET_IS_REWARDS_AND_PENALTIES_FILTERLIST_LOADING, payload: true });
        const response = yield call(microservices.payments.getRewardsFilterList, {}, {}, {});
        if (response) {
            let filterList = response.data.data;
            yield put({ type: payments.GET_ALL_REWARDS_AND_PENALTIES_FILTERLIST_SUCCESS, payload: filterList });
        } else {
            yield put({ type: payments.GET_ALL_REWARDS_AND_PENALTIES_FILTERLIST_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: payments.GET_ALL_REWARDS_AND_PENALTIES_FILTERLIST_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.SET_IS_REWARDS_AND_PENALTIES_FILTERLIST_LOADING, payload: false });
}

export function* getAllPOFilterList(action) {
    const { toFilter } = action.payload;
    try {
        yield put({ type: loading.SET_PO_FILTERLIST_LOADING, payload: true });
        const response = yield call(microservices.payments.getPoFilterList, {}, {}, { toFilter });
        if (response) {
            let filterList = response.data.data;
            yield put({ type: payments.GET_ALL_PO_FILTERLIST_SUCCESS, payload: filterList });
        } else {
            yield put({ type: payments.GET_ALL_PO_FILTERLIST_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: payments.GET_ALL_PO_FILTERLIST_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.SET_PO_FILTERLIST_LOADING, payload: false });
}

export function* getAllWithdrwalFilterList(action) {
    try {
        yield put({ type: loading.SET_IS_WITHDRAWAL_FILTERLIST_LOADING, payload: true });
        const response = yield call(microservices.payments.getWithDrawalFilterList, {}, {}, {});
        if (response) {
            let filterList = response.data.data;
            yield put({ type: payments.GET_ALL_WITHDRAWAL_FILTERLIST_SUCCESS, payload: filterList });
        } else {
            yield put({ type: payments.GET_ALL_WITHDRAWAL_FILTERLIST_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: payments.GET_ALL_WITHDRAWAL_FILTERLIST_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.SET_IS_WITHDRAWAL_FILTERLIST_LOADING, payload: false });
}

export function* getWalletWithdrawlTransactions(action) {
    const { transactionType = 'WALLET', limit, page, status = 'withdraw', count = true, allusers = true } = action.payload;
    let queryParams = { transactionType, limit, page, status, allusers, count, requestStatement: false };

    try {
        yield put({ type: loading.SET_WALLET_WITHDRAWAL_TRANSACTIONS_LOADING, payload: true });
        const response = yield call(lambdas.wallet.getTransactions, {}, {}, queryParams);
        if (response.data.code === 200) {
            if (count) {
                yield put({ type: payments.ALL_WITHDRAWAL_TRANSACTIONS_COUNT_SUCCESS, payload: response.data.count });
            }
            yield put({ type: payments.GET_WALLET_WITHDRAWAL_TRANSACTIONS_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: payments.GET_WALLET_WITHDRAWAL_TRANSACTIONS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: payments.GET_WALLET_WITHDRAWAL_TRANSACTIONS_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.SET_WALLET_WITHDRAWAL_TRANSACTIONS_LOADING, payload: false });
}

export function* getWalletRewardsTransactions(action) {
    const { transactionType = 'WALLET', limit, page, status = 'rewardspenalties', allusers = true, count = true } = action.payload;
    let queryParams = { transactionType, limit, page, status, allusers, count, requestStatement: false };

    try {
        yield put({ type: loading.SET_WALLET_REWARDS_TRANSACTIONS_LOADING, payload: true });
        const response = yield call(lambdas.wallet.getTransactions, {}, {}, queryParams);
        if (response.data.code === 200) {
            if (count) {
                yield put({ type: payments.ALL_REWARDS_TRANSACTIONS_COUNT_SUCCESS, payload: response.data.count });
            }
            yield put({ type: payments.GET_WALLET_REWARDS_TRANSACTIONS_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: payments.GET_WALLET_REWARDS_TRANSACTIONS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: payments.GET_WALLET_REWARDS_TRANSACTIONS_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.SET_WALLET_REWARDS_TRANSACTIONS_LOADING, payload: false });
}

export function* payPoTax(action) {
    const { formName, ticketId, withdrawAmount, vendorId, isRefresh, type = 'PAY_PO_GST', banking_partner, updateConvo = false, enduserId } = action.payload;
    const data = {
        type,
        ticketId,
        withdrawAmount,
        vendorId,
    };
    if (banking_partner) {
        data.banking_partner = banking_partner;
    }
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(lambdas.wallet.withdraw, {}, {}, {}, data);
        if (response.data.code === 200) {
            const poGroupView = new URLSearchParams(window.location.search).get('poGroupView');

            if (isRefresh) {
                const data = {
                    id: ticketId,
                    paymentDetails: response.data.data.po.paymentDetails,
                    totalAmount: parseFloat(response.data.data.po?.paymentDetails?.totalPrice),
                    paidAmount: parseFloat(response.data.data.po?.paymentDetails?.totalPrice) - parseFloat(response.data.data.po?.paymentDetails?.totalUnpaid),
                    totalTax: parseFloat(response.data.data.po?.paymentDetails?.totalTax),
                    paidTax: parseFloat(response.data.data.po?.paymentDetails?.totalTax) - parseFloat(response.data.data.po?.paymentDetails?.totalUnpaidTax),
                    updatedAt: response.data.data.updatedAt,
                }
                if (poGroupView && enduserId) {
                    data.enduserId = enduserId
                    yield put({
                        type: payments.PAY_GROUP_BY_ENDUSER_PO_TAX_SUCCESS,
                        payload: data,
                    });
                } else {
                    yield put({
                        type: payments.PAY_PO_TAX_SUCCESS,
                        payload: data,
                    });
                }
            }
            if (updateConvo) {
                yield put({
                    type: conversations.UPDATE_CONVO_ON_PAY_PO_TAX_SUCCESS,
                    payload: {
                        _id: ticketId,
                        paymentDetails: response.data.data.po.paymentDetails,
                    },
                });
            }
            yield all([yield put({ type: modal.CLOSE_MODAL }), yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'GST Paid Successfully!!' } })]);
        } else {
            yield put({ type: payments.PAY_PO_TAX_FAILURE, payload: { message: 'Oops Something went wrong' } });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data?.data?.message || 'Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: payments.PAY_PO_TAX_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* generateInvoice(action) {
    const { data, files, formName, isRefresh, enduserId } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(microservices.payments.generateInvoice, {}, {}, {}, data);
        if (response.data.code === 200) {
            const poGroupView = new URLSearchParams(window.location.search).get('poGroupView')
            if (Array.isArray(response.data.pofiles) && response.data.pofiles.length > 0 && Array.isArray(files) && files.length > 0) {
                yield* response.data.pofiles.map(({ signedUrl, fileName }) => {
                    const file = files.find((f) => f.name === fileName);
                    return call(uploadFileToS3WithSignedUrl, signedUrl, file);
                });
            }
            if (isRefresh) {
                if (enduserId && poGroupView) {
                    yield put({
                        type: payments.GROUP_BY_ENDUSER_PO_GENERATE_INVOICE_SUCCESS,
                        payload: { invoices: response?.data?.data?.po?.invoices, updatedAt: response.data.data.updatedAt, id: data?.ticketId, enduserId },
                    });
                } else {
                    yield put({
                        type: payments.GENERATE_INVOICE_SUCCESS,
                        payload: { invoices: response?.data?.data?.po?.invoices, updatedAt: response.data.data.updatedAt, id: data?.ticketId },
                    });
                }
            }
            yield all([yield put({ type: modal.CLOSE_MODAL }), yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: response.data.data.message } })]);

            if (!['', undefined, null].includes(response.data.invoiceLink)) {
                window.open(response.data.invoiceLink, '_blank');
            }
        } else {
            yield put({ type: payments.GENERATE_INVOICE_FAILURE, payload: { message: 'Oops Something went wrong' } });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data?.data?.message || 'Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: payments.GENERATE_INVOICE_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* getPayoutsFilterData(action) {
    let { customerId, next = true, count = true, type = 0, limit, page, payload, query } = action.payload;

    try {
        yield put({ type: loading.SET_ALL_PAYMENTS_LOADING, payload: true });
        let queryParams = { limit, page, next, count, type };
        if (!['', 'all', undefined, null, 0].includes(customerId)) queryParams.customerId = customerId;
        if (!['', undefined, null].includes(query)) queryParams.query = query;
        const response = yield call(lambdas.payment.payoutFilter, {}, {}, queryParams, payload);
        if (response.data.code === 200) {
            yield put({
                type: payments.GET_PAYOUTS_FILTER_DATA_SUCCESS,
                payload: { filterData: response.data.data, filterCount: response.data.filterCount },
            });
        } else if ([400, 500].includes(response.data.code)) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
        }
    } catch (err) {
        yield put({ type: payments.GET_PAYOUTS_FILTER_DATA_FAILURE, payload: 'Something went wrong' });
    }
    yield put({ type: loading.SET_ALL_PAYMENTS_LOADING, payload: false });
}

export function* getOpenTicketFilterData(action) {
    let { customerId, next = true, count = true, type = 0, limit, page, query, payload } = action.payload;

    try {
        yield put({ type: loading.SET_OPEN_TICKETS_PAYMENTS_LOADING, payload: true });
        let queryParams = { limit, page, next, count, type };
        if (!['', 'all', undefined, null, 0].includes(customerId)) queryParams.customerId = customerId;
        if (!['', undefined, null].includes(query)) queryParams.query = query;
        const response = yield call(lambdas.payment.openTicketFilter, {}, {}, queryParams, payload);
        if (response.data.code === 200) {
            yield put({
                type: payments.GET_OPEN_TICKET_FILTER_DATA_SUCCESS,
                payload: { filterData: response.data.data, filterCount: response.data.filterCount },
            });
        } else if ([400, 500].includes(response.data.code)) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
        }
    } catch (err) {
        yield put({ type: payments.GET_OPEN_TICKET_FILTER_DATA_FAILURE, payload: 'Something went wrong' });
    }
    yield put({ type: loading.SET_OPEN_TICKETS_PAYMENTS_LOADING, payload: false });
}

export function* getRewardsFilterData(action) {
    let { customerId, next = true, count = true, type = 0, limit, page, payload, query } = action.payload;
    try {
        yield put({ type: loading.SET_WALLET_REWARDS_TRANSACTIONS_LOADING, payload: true });
        let queryParams = { limit, page, next, count, type };
        if (!['', 'all', undefined, null, 0].includes(customerId)) queryParams.customerId = customerId;
        if (!['', undefined, null].includes(query)) queryParams.query = query;
        const response = yield call(lambdas.payment.rewardsFilter, {}, {}, queryParams, payload);
        if (response.data.code === 200) {
            yield put({
                type: payments.GET_REWARDS_FILTER_DATA_SUCCESS,
                payload: { filterData: response.data.data, filterCount: response.data.filterCount },
            });
        } else if ([400, 500].includes(response.data.code)) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
        }
    } catch (err) {
        yield put({ type: payments.GET_REWARDS_FILTER_DATA_FAILURE, payload: 'Something went wrong' });
    }
    yield put({ type: loading.SET_WALLET_REWARDS_TRANSACTIONS_LOADING, payload: false });
}

export function* getWithdrawlFilterData(action) {
    let { customerId, next = true, count = true, type = 0, limit, page, query, payload } = action.payload;

    try {
        yield put({ type: loading.SET_WALLET_WITHDRAWAL_TRANSACTIONS_LOADING, payload: true });
        let queryParams = { limit, page, next, count, type };
        if (!['', 'all', undefined, null, 0].includes(customerId)) queryParams.customerId = customerId;
        if (!['', undefined, null].includes(query)) queryParams.query = query;
        const response = yield call(lambdas.payment.withdrawalFilter, {}, {}, queryParams, payload);
        if (response.data.code === 200) {
            yield put({
                type: payments.GET_WITHDRAWAL_FILTER_DATA_SUCCESS,
                payload: { filterData: response.data.data, filterCount: response.data.filterCount },
            });
        } else if ([400, 500].includes(response.data.code)) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
        }
    } catch (err) {
        yield put({ type: payments.GET_WITHDRAWAL_FILTER_DATA_FAILURE, payload: 'Something went wrong' });
    }
    yield put({ type: loading.SET_WALLET_WITHDRAWAL_TRANSACTIONS_LOADING, payload: false });
}

export function* getPoFilterData(action) {
    let { customerId, next = true, count = true, type = 0, limit, page, query, payload, source, enduserId } = action.payload;
    try {
        if (source === 'enduser_view') {
            if (page > 1) {
                yield put({ type: loading.SET_NEXT_ENDUSER_GROUPED_PO_FILTER_DATA_LOADING, payload: { id: enduserId, isLoading: true } });
            } else {
                yield put({ type: loading.SET_ENDUSER_GROUPED_PO_FILTER_DATA_LOADING, payload: { id: enduserId, isLoading: true } });
            }
        } else {
            yield put({ type: loading.SET_PO_TABLE_LOADING, payload: true });
        }
        let queryParams = { limit, page, next, count, type };
        if (!['', 'all', undefined, null, 0].includes(customerId)) queryParams.customerId = customerId;
        if (!['', undefined, null].includes(query)) queryParams.query = query;
        const response = yield call(lambdas.payment.poFilter, {}, {}, queryParams, payload);
        if (response.data.code === 200) {
            if (source === 'enduser_view') {
                yield put({
                    type: payments.GET_ENDUSER_GROUPED_PO_FILTER_DATA_SUCCESS,
                    payload: { filterData: response.data.data, filterCount: response.data.filterCount, enduserId, page },
                });
            } else {
                yield put({
                    type: payments.GET_PO_FILTER_DATA_SUCCESS,
                    payload: { filterData: response.data.data, filterCount: response.data.filterCount },
                });
            }
        } else if ([400, 500].includes(response.data.code)) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
        }
    } catch (err) {
        yield put({ type: payments.GET_PO_FILTER_DATA_FAILURE, payload: 'Something went wrong' });
    }
    if (source === 'enduser_view') {
        if (page > 1) {
            yield put({ type: loading.SET_NEXT_ENDUSER_GROUPED_PO_FILTER_DATA_LOADING, payload: { id: enduserId, isLoading: false } });
        } else {
            yield put({ type: loading.SET_ENDUSER_GROUPED_PO_FILTER_DATA_LOADING, payload: { id: enduserId, isLoading: false } });
        }
    } else {
        yield put({ type: loading.SET_PO_TABLE_LOADING, payload: false });
    }
}

export function* refreshPoPage(action) {
    const { enduserId } = action.payload;
    let { limit = 10, page = 1, query, next = true, count = true, type = 0, filterPayload = {}, isFilterActive } = yield select(getPaymentFilterData);
    const loggedUser = yield select(getLoggedUser);
    const customerId = getCustomerIdOfLoginUser(loggedUser);
    let isFilterOrSearchActive = isFilterActive || query.length > 0;
    const poGroupView = new URLSearchParams(window.location.search).get('poGroupView');
    if (poGroupView && enduserId) {
        filterPayload = { enduserIds: [enduserId] };
        isFilterOrSearchActive = true;
    };

    yield put({ type: loading.SET_PO_TABLE_LOADING, payload: true });
    try {
        let response;
        if (isFilterOrSearchActive) {
            yield put({ type: loading.SET_PO_TABLE_LOADING, payload: true });
            let queryParams = { limit, page, next, count, type };
            if (!['', 'all', undefined, null, 0].includes(customerId)) queryParams.customerId = customerId;
            if (!['', undefined, null].includes(query)) queryParams.query = query;
            response = yield call(lambdas.payment.poFilter, {}, {}, queryParams, filterPayload);
        } else {
            const queryParams = { customerId, limit, page, count };
            if (!isNaN(customerId) && parseInt(customerId) > 0) queryParams.customerId = customerId;
            response = yield call(microservices.payments.getPOs, {}, {}, queryParams);
        }
        if (response.data.code === 200) {
            if (isFilterOrSearchActive) {
                yield put({
                    type: payments.GET_PO_FILTER_DATA_SUCCESS,
                    payload: { filterData: response.data.data, filterCount: response.data.filterCount },
                });
            } else {
                yield put({ type: payments.GET_PO_LIST_SUCCESS, payload: response.data.data });
                if (count) {
                    yield put({ type: payments.GET_PO_LIST_COUNT_SUCCESS, payload: response.data.count });
                }
            }
        } else if ([400, 500].includes(response.data.code)) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
        }
    } catch (err) { }
    yield put({ type: loading.SET_PO_TABLE_LOADING, payload: false });
}

export function* refreshPayoutsPage(action) {
    const { limit = 10, page = 1, query, next = true, count = true, type = 0, filterPayload = {}, isFilterActive } = yield select(getPaymentFilterData);
    const loggedUser = yield select(getLoggedUser);
    const customerId = getCustomerIdOfLoginUser(loggedUser);
    const isFilterOrSearchActive = isFilterActive || query.length > 0;
    yield put({ type: loading.SET_ALL_PAYMENTS_LOADING, payload: true });
    try {
        let response;
        if (isFilterOrSearchActive) {
            let queryParams = { limit, page, next, count, type };
            if (!['', 'all', undefined, null, 0].includes(customerId)) queryParams.customerId = customerId;
            if (!['', undefined, null].includes(query)) queryParams.query = query;
            response = yield call(lambdas.payment.payoutFilter, {}, {}, queryParams, filterPayload);
        } else {
            const queryParams = { customerId, limit, page, count };
            if (!isNaN(customerId) && parseInt(customerId) > 0) queryParams.customerId = customerId;
            response = yield call(api.payments.listAll, {}, {}, queryParams);
        }
        if (response.data.code === 200) {
            if (isFilterOrSearchActive) {
                yield put({
                    type: payments.GET_PAYOUTS_FILTER_DATA_SUCCESS,
                    payload: { filterData: response.data.data, filterCount: response.data.filterCount },
                });
            } else {
                yield put({ type: payments.GET_PAYOUTS_FILTER_DATA_SUCCESS, payload: response.data.data });
            }
        } else if ([400, 500].includes(response.data.code)) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
        }
    } catch (err) { }
    yield put({ type: loading.SET_ALL_PAYMENTS_LOADING, payload: false });
}

export function* getPoAssetsCategoriesDropdown(action) {
    let queryParams = { dropdown: true };
    try {
        const response = yield call(microservices.admin.getPoAssetsCategories, {}, {}, queryParams);
        if (response.data.code === 200) {
            let poassetcategories = response.data.data;
            poassetcategories = [];
            response.data.data.forEach((category) => {
                poassetcategories.push({ key: category.id, value: category.id, text: category.name, category, label: category.name });
            });
            yield put({ type: payments.GET_PO_ASSET_CATEGORIES_DROPDOWN_SUCCESS, payload: poassetcategories });
        }
    } catch (error) {
        yield put({ type: payments.GET_PO_ASSET_CATEGORIES_DROPDOWN_FAILURE, payload: { message: error } });
    }
}

export function* getTicketPODetails(action) {
    const { ticketId } = action.payload;
    try {
        yield put({ type: loading.IS_TICKET_PO_DETAILS_LOADING, payload: true });
        const response = yield call(lambdas.conversations.getConversationDetails, {}, { _id: ticketId }, { useConversationId: true, skipMedia: true });
        if (response.data.code === 200 && response.data.data?.ticket?.po) {
            yield put({ type: payments.GET_TICKET_PO_DETAILS_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message || 'No PO Data Found!' } });
        }
    } catch (err) {
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
    }
    yield put({ type: loading.IS_TICKET_PO_DETAILS_LOADING, payload: false });
}

export function* getPOCategories(action) {
    try {
        const response = yield call(microservices.payments.getPOCategories);
        if (response.data.code === 200) {
            yield put({ type: payments.GET_PO_CATEGORIES_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
        }
    } catch (err) {
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
    }
}

export function* exportCsvPayments(action) {
    const { module, tab, projectId, customerId, status = 'ALL', emails = [], download = true, type = 0, formName } = action.payload;
    const { filterPayload = {} } = yield select(getPaymentFilterData);
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        let queryParams = { module, tab, type, status };
        if (!['', 'all', undefined, null, 0].includes(projectId)) queryParams.projectId = projectId;
        if (!['', 'all', undefined, null, 0].includes(customerId)) queryParams.customerId = customerId;
        const response = yield call(lambdas.utils.exportCsv, {}, {}, queryParams, { emails, filterData: filterPayload });
        if (response.data.code === 200) {
            if (download && response.data.data.fileUrl && isURL(response.data.data.fileUrl)) {
                window.open(response.data.data.fileUrl, '_blank');
            }
            yield all([yield put({ type: modal.CLOSE_MODAL }), yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: response.data.data.message } })]);
        } else if ([400, 500].includes(response.data.code)) {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
            yield put({ type: modal.CLOSE_MODAL });
        }
    } catch (err) {
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* downloadZipPo(action) {
    const { customerId, emails, formName } = action.payload;
    const { filterPayload = {} } = yield select(getPaymentFilterData);
    try {
        const body = { emails, filterData: filterPayload };
        if (!['', undefined, null, 0].includes(customerId) && !isNaN(customerId) && parseInt(customerId) > 0) body.customerId = customerId;
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(microservices.payments.getPOZip, {}, {}, {}, body);
        if (response.data.code === 200) {
            yield all([yield put({ type: modal.CLOSE_MODAL }), yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: response.data.data.message } })]);
        } else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
        }
    } catch (error) {
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* getPoGstDetails(action) {
    try {
        const { ticketId, incentive = false, user_type = 'ALL', po_gst = true } = action.payload;
        yield put({ type: loading.IS_PO_GST_DETAILS_LOADING, payload: true });
        let response = yield call(microservices.tickets.ticketPayments, {}, { ticketId }, { incentive, user_type, po_gst });
        if (response.data.code === 200) {
            yield put({ type: payments.GET_PO_GST_DETAILS_SUCCESS, payload: response.data.data });
        } else {
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.data.message } });
            yield put({ type: payments.GET_PO_GST_DETAILS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: payments.GET_PO_GST_DETAILS_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.IS_PO_GST_DETAILS_LOADING, payload: false });
}

export default function* paymentSaga() {
    yield takeEvery(payments.GET_ALL_PAYMENTS_REQUEST, getPaymentsList);
    yield takeEvery(payments.GET_PO_LIST_REQUEST, getPaymentPos);
    yield takeEvery(payments.CANCEL_PO_REQUEST, cancelPoRequest);
    yield takeEvery(payments.DELETE_PO_REQUEST, deletePoRequest);
    yield takeEvery(payments.UPDATE_PO_INVOICE_REQUEST, updatePoInvoiceRequest);
    yield takeEvery(payments.UPDATE_PO_REQUEST, updatePo);
    yield takeEvery(payments.GET_OPEN_TICKET_PAYMENTS_REQUEST, getOpenTicketPaymentList);
    yield takeEvery(payments.GET_ALL_PAYOUTS_FILTERLIST_REQUEST, getAllPayoutsFilterList);
    yield takeEvery(payments.GET_ALL_REWARDS_AND_PENALTIES_FILTERLIST_REQUEST, getAllRewardsAndPenaltiesFilterList);
    yield takeEvery(payments.GET_ALL_PO_FILTERLIST_REQUEST, getAllPOFilterList);
    yield takeEvery(payments.GET_ALL_WITHDRAWAL_FILTERLIST_REQUEST, getAllWithdrwalFilterList);
    yield takeEvery(payments.GET_WALLET_WITHDRAWAL_TRANSACTIONS_REQUEST, getWalletWithdrawlTransactions);
    yield takeEvery(payments.GET_WALLET_REWARDS_TRANSACTIONS_REQUEST, getWalletRewardsTransactions);
    yield takeEvery(payments.PAY_PO_TAX_REQUEST, payPoTax);
    yield takeEvery(payments.GENERATE_INVOICE_REQUEST, generateInvoice);
    yield takeEvery(payments.GET_PAYOUTS_FILTER_DATA_REQUEST, getPayoutsFilterData);
    yield takeEvery(payments.GET_OPEN_TICKET_FILTER_DATA_REQUEST, getOpenTicketFilterData);
    yield takeEvery(payments.GET_REWARDS_FILTER_DATA_REQUEST, getRewardsFilterData);
    yield takeEvery(payments.GET_WITHDRAWAL_FILTER_DATA_REQUEST, getWithdrawlFilterData);
    yield takeEvery(payments.REFRESH_PO_PAGE, refreshPoPage);
    yield takeLatest(payments.GET_PO_FILTER_DATA_REQUEST, getPoFilterData);
    yield takeEvery(payments.GET_PO_ASSET_CATEGORIES_DROPDOWN_REQUEST, getPoAssetsCategoriesDropdown);
    yield takeEvery(payments.GET_TICKET_PO_DETAILS_REQUEST, getTicketPODetails);
    yield takeLatest(payments.GET_PO_CATEGORIES_REQUEST, getPOCategories);
    yield takeEvery(payments.EXPORT_CSV_PAYMENT_REQUEST, exportCsvPayments);
    yield takeEvery(payments.DOWNLOAD_ZIP_PO_REQUEST, downloadZipPo);
    yield takeLatest(payments.REFRESH_PAYOUTS_PAGE, refreshPayoutsPage);
    yield takeLatest(payments.GET_PO_GST_DETAILS_REQUEST, getPoGstDetails);
}

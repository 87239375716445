import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SkeltonCardOne } from '../../../common/SkeltonCardOne';
import { getTicketPayments, cleanupTicketPaymentDetails } from '../../../actions/tickets';
import GridTable from '../../../common/GridTable';
import { GetPaymentsListLogic } from '../PaymentController';
import moment from 'moment';
import OverflowTip from '../../../common/OverflowTip';
import { validateDateValue } from '../../../utils/common';
import { paymentType, transactionStatus } from '../../Wallet/walletController';
import StatusLabel from '../reusableComponents/StatusLabel';


const PaymentsDetails = ({ ticketId, user_type = 'ALL' }) => {
    const { isTicketPaymentLoading } = useSelector((state) => state.loading);
    const { ticketPayment, ticketBasePayment } = useSelector((state) => state.tickets);
    const { loggedUser } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [transformedPaymentData, setTransformedPaymentData] = useState([]);

    const isToShowBasePaymentColumnInTicketPaymentDetails = GetPaymentsListLogic('isToShowBasePaymentColumnInTicketPaymentDetails');

    useEffect(() => {
        if (!isNaN(ticketId) && ticketId > 0) {
            dispatch(getTicketPayments({ ticketId, incentive: true, user_type }));
        }
        return () => dispatch(cleanupTicketPaymentDetails());
    }, [ticketId, dispatch]);

    useEffect(() => {
        let data = [];
        if (!['', undefined, null].includes(ticketBasePayment) && isToShowBasePaymentColumnInTicketPaymentDetails) {
            let obj = {
                transactionNo: ticketBasePayment?.scogo_transaction_no ? ticketBasePayment?.scogo_transaction_no : '',
                user: `${ticketBasePayment?.vendor?.first_name ? ticketBasePayment.vendor.first_name : ''} ${ticketBasePayment?.vendor?.last_name ? ticketBasePayment.vendor.last_name : ''
                    } (partner)`,
                basePaymentDetails: 'BASE PAYMENT',
                orderAmount: `${ticketBasePayment?.amount ? ticketBasePayment?.amount : 0}`,
                status: ticketBasePayment?.is_request_raised === 4 ? 'Success' : ticketBasePayment?.is_request_raised === 3 ? 'Rejected' : 'Pending',
                payment_ratio: 1
            };
            data.push(obj);
        }

        Array.isArray(ticketPayment) &&
            ticketPayment.forEach((transaction) => {
                let currentTransaction = {
                    transactionNo: transaction.scogo_tx_no,
                    createdBy: transaction.createdBy,
                    paymentType: transaction.paymentType,
                    user: `${transaction?.user?.first_name ? transaction.user.first_name : ''} ${transaction?.user?.last_name ? transaction.user.last_name : ''}`,
                    paymentMode: transaction?.paymentMode,
                    reason: transaction.reason,
                    txMsg: transaction.txMsg,
                    orderAmount: transaction.orderAmount,
                    status: transaction.status,
                    processedByDetails: transaction.processedByDetails,
                    processed_at: transaction.processed_at,
                    recepient: transaction.recepient,
                    sender: transaction.sender,
                    fk_user_id: transaction.fk_user_id,
                    payment_ratio: transaction?.user?.payment_ratio ? transaction?.user?.payment_ratio : 1
                };
                data.push(currentTransaction);
            });
        setTransformedPaymentData(data);
    }, [ticketPayment, ticketBasePayment, isToShowBasePaymentColumnInTicketPaymentDetails]);

    const StatusBox = ({ status }) => {
        const classLookup = {
            Approved: 'scogo2e',
            Failure: 'scogoclosed',
            Scheduled: 'scogoorange',
            Pending: 'scogoorange',
            Rejected: 'scogoclosed',
        };
        return <StatusLabel content={status} color={classLookup[status]} />;
    };

    const getTransformedStatus = (status) => {
        let label = 'Pending';
        if (status === 'Success') {
            label = 'Approved';
        } else if (status === 'Failure') {
            label = 'Rejected';
        } else if (status === 'Scheduled') {
            label = 'Scheduled';
        }
        return label;
    };

    const isToShowPaymentStatus = (payment) => ['Scheduled', 'Success', 'Failure'].includes(payment.status) && payment.processedByDetails;
    const isValidPaymentProcessedDate = (date) => validateDateValue(date);

    const getOrderAmount = (payment) => {
        let amount = '';
        let isUsePaymentRatio = false;
        const isUserIsSender = payment.sender === payment.fk_user_id;
        const isUserIsReciever = payment.recepient === payment.fk_user_id;
        if (['SERVICE'].includes(payment.reason) && payment.status !== 'Success') {
            isUsePaymentRatio = true;
        }
        if (payment.paymentType === paymentType.incentive) {
            amount = `${payment.orderAmount}`;
        } else {
            if (payment.paymentMode === 'CUSTOMER') {
                amount = `${!isUserIsSender ? '-' : ''}${payment.orderAmount}`;
            } else {
                amount = `${!isUserIsReciever ? '-' : ''}${isUsePaymentRatio ? payment.orderAmount * payment.payment_ratio : payment.orderAmount}`;
            }
        }
        return amount;
    };

    const bluePrint = {
        table: [
            {
                head: {
                    render: (headName) => {
                        return headName;
                    },
                    headName: 'Transaction No',
                },
                body: {
                    render: (payment) => {
                        return (
                            <div>
                                <OverflowTip textClass={'text-scogoprimary font-medium cursor-pointer'} someLongText={payment.transactionNo} />
                                {payment.createdBy && payment.paymentType !== 'INCENTIVE' ? (
                                    <span className='w-full flex items-center gap-2'>
                                        <p className='text-medium'>Requested by:</p>
                                        <OverflowTip textClass='font-medium' someLongText={`${payment.createdBy.first_name} ${payment.createdBy.last_name}`} />
                                    </span>
                                ) : (
                                    <></>
                                )}
                            </div>
                        );
                    },
                },
            },
            {
                head: {
                    render: (headName) => {
                        return headName;
                    },
                    headName: 'User',
                },
                body: {
                    render: (payment) => {
                        return (
                            <div>
                                <OverflowTip someLongText={payment.user}></OverflowTip>
                                {payment.paymentMode ? <b>({payment.paymentMode.toLowerCase()})</b> : ''}
                            </div>
                        );
                    },
                },
            },
            {
                head: {
                    render: (headName) => {
                        return headName;
                    },
                    headName: 'Details',
                },
                body: {
                    render: (payment) => {
                        return <PaymentColumn payment={payment} />;
                    },
                },
            },
            {
                head: {
                    render: (headName) => {
                        return headName;
                    },
                    headName: 'Amount',
                },
                body: {
                    render: (payment, rowIndex) => {
                        return <p className='truncate w-full'>₹ {getOrderAmount(payment)}</p>;
                    },
                },
            },
            {
                head: {
                    render: (headName) => {
                        return headName;
                    },
                    headName: 'Status',
                },
                body: {
                    render: (payment, rowIndex) => {
                        let status = getTransformedStatus(payment?.status);
                        return (
                            <div>
                                <div className='flex'>
                                    <StatusBox status={status} />
                                </div>
                                {isToShowPaymentStatus(payment) && (
                                    <div className='pt-2'>
                                        <small>
                                            by <b>{`${payment?.processedByDetails?.first_name} ${payment?.processedByDetails?.last_name}`}</b>
                                        </small>
                                        {isValidPaymentProcessedDate(payment?.processed_at) && (
                                            <div className='truncate w-4/5'>
                                                <OverflowTip
                                                    someLongText={
                                                        <small>
                                                            Timestamp <b>{`${moment(payment?.processed_at).format('DD-MM-YYYY HH:mm:ss')}`}</b>
                                                        </small>
                                                    }
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        );
                    },
                },
            },
        ],
    };

    if (isTicketPaymentLoading) return <SkeltonCardOne />;

    return (
        <div className='px-7 pb-8 py-4'>
            <p className='text-medium text-md'>
                <b>*</b>All payments from Scogo are made post deduction of TDS and System Charges
            </p>
            <GridTable schema={bluePrint} rowData={transformedPaymentData} tableBodyHeight={'auto'} tableError='No Ticket Found' />
        </div>
    );
};

export default PaymentsDetails;

const PaymentColumn = ({ payment }) => {
    const { basePaymentDetails, paymentType } = payment;
    const isBasePayment = basePaymentDetails === 'BASE PAYMENT';
    const isIncentivePaymentType = paymentType === 'INCENTIVE';

    const commonColorCss = 'text-scogogray';

    const getIncentiveDetails = () => {
        return (
            <>
                {isIncentivePaymentType && <p className={commonColorCss}>INCENTIVE</p>}
                {!isIncentivePaymentType && (
                    <>
                        <span className='w-full flex items-center gap-2'>
                            <p className={commonColorCss}>Category:</p>
                            <OverflowTip someLongText={payment.reason} />
                        </span>
                        <span className='w-full flex items-center gap-2'>
                            <p className={commonColorCss}>Remark:</p>
                            <OverflowTip someLongText={payment.txMsg} />
                        </span>
                    </>
                )}
            </>
        );
    };
    return (
        <div className='truncate w-full'>
            {basePaymentDetails && <p className={commonColorCss}>{basePaymentDetails}</p>}
            {!isBasePayment && getIncentiveDetails()}
        </div>
    );
};

import { project } from '../types';
import { validateStringValue } from '../utils/common';

const INITIAL_STATE = {
    error: undefined,
    projectList: [],
    allProjectList: [],
    showAddProject: false,
    mainDiv: '',
    projectTeamLists: [],
    projectPosts: {},
    projectFilterData: {},
    projectPMs: {},
    projectsSlas: {}
};

const projectReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case project.GET_PROJECT_LIST_SUCCESS:
            return {
                ...state,
                projectList: action.payload,
            };
        case project.GET_ALL_PROJECT_DROPDOWN_LIST_SUCCESS:
            return {
                ...state,
                projectDropdownList: action.payload,
            };

        case project.GET_ALL_PROJECT_LIST_SUCCESS: {

            const projectFilterData = {
                contact_persons: [],
                contact_person_mobiles: [],
                contact_person_emails: [],
                min_ticket_time_duration_sec: {},
                max_ticket_time_duration_sec: {},
                mean_ticket_time_duration_sec: {},
            };

            const min_ticket_time_duration_sec = findMinMax({ list: action.payload, key: 'min_ticket_time_duration_sec' });
            const max_ticket_time_duration_sec = findMinMax({ list: action.payload, key: 'max_ticket_time_duration_sec' });
            const mean_ticket_time_duration_sec = findMinMax({ list: action.payload, key: 'mean_ticket_time_duration_sec' });

            projectFilterData.min_ticket_time_duration_sec = min_ticket_time_duration_sec
            projectFilterData.max_ticket_time_duration_sec = max_ticket_time_duration_sec
            projectFilterData.mean_ticket_time_duration_sec = mean_ticket_time_duration_sec

            if (Array.isArray(action.payload)) {
                action.payload.forEach((project) => {
                    let proj = {
                        id: project.id,
                        display: project.project_name
                    }
                    if (projectFilterData['projects']) {
                        let isAlreadyExit = projectFilterData['projects'].find((i) => i.id === project.id);
                        if (!isAlreadyExit && project.id) projectFilterData['projects'].push(proj);
                    } else projectFilterData['projects'] = [proj];

                    let customer = {
                        id: project.fk_customer_id,
                        display: project.customer_company_name
                    }
                    if (projectFilterData['customers']) {
                        let isAlreadyExit = projectFilterData['customers'].find((i) => i.id === project.fk_customer_id);
                        if (!isAlreadyExit && project.fk_customer_id) projectFilterData['customers'].push(customer);
                    } else projectFilterData['customers'] = [customer];

                    let enduser = {
                        id: project?.end_user_detail?.id,
                        display: project?.end_user_detail?.end_user_name
                    }
                    if (projectFilterData['endusers']) {
                        let isAlreadyExit = projectFilterData['endusers'].find((i) => i.id === project?.end_user_detail?.id);
                        if (!isAlreadyExit && project?.end_user_detail?.id) projectFilterData['endusers'].push(enduser);
                    } else projectFilterData['endusers'] = [enduser];


                    if (!projectFilterData['contact_persons'].includes(project.project_contact_person)) {
                        projectFilterData['contact_persons'].push(project.project_contact_person);
                    }

                    if (!projectFilterData['contact_person_mobiles'].includes(project.project_contact_person_mobile)) {
                        projectFilterData['contact_person_mobiles'].push(project.project_contact_person_mobile);
                    }

                    let service = {
                        id: project.service_type_id,
                        display: project.service_name
                    }

                    if (projectFilterData['service_names']) {
                        let isAlreadyExit = projectFilterData['service_names'].find((i) => i.id === project.service_type_id);
                        if (!isAlreadyExit && project.service_type_id) projectFilterData['service_names'].push(service);
                    } else projectFilterData['service_names'] = [service];

                    let usecase = {
                        id: project.use_case_id,
                        display: project.use_case_name
                    }

                    if (projectFilterData['use_case_names']) {
                        let isAlreadyExit = projectFilterData['use_case_names'].find((i) => i.id === project.use_case_id);
                        if (!isAlreadyExit && project.use_case_id) projectFilterData['use_case_names'].push(usecase);
                    } else projectFilterData['use_case_names'] = [usecase];

                })
            }

            return {
                ...state,
                projectList: action.payload,
                projectFilterData
            };
        }
        case project.PROJECTS_COUNT_SUCCESS:
            return {
                ...state,
                projectListCount: action.payload,
            };
        case project.CREATR_PROJECT_TEMPLATE_SUCCESS:
            const { data } = action.payload
            return {
                ...state,
                newTemplateData: { key: Math.random(), value: data.id, label: data.title },
            };
        case project.UPDATE_PM_DETAILS_FAILURE:
        case project.GET_PROJECT_LIST_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case project.GET_PROJECT_POST_LIST_SUCCESS: {
            const { projectId, postList } = action.payload;

            return {
                ...state,
                projectPosts: { ...state.projectPosts, [projectId]: postList },
            };
        }
        case project.CREATE_PROJECT_ATTACHMENT_POST_SUCCESS:
        case project.CREATE_PROJECT_TEXT_POST_SUCCESS: {
            const { projectId, data } = action.payload;

            let list = state.projectPosts;
            if (list[projectId]) {
                list[projectId].unshift(data);
            } else {
                list[projectId] = [data];
            }

            return {
                ...state,
                projectPosts: list,
            };
        }

        case project.PROJECT_POST_STATUS_SUCCESS: {
            const { status, projectId, postId } = action.payload;
            let posts = state.projectPosts;

            posts[projectId] = posts[projectId].map((post) => {
                if (post._id === postId) {
                    return { ...post, status };
                }
                return post;
            });

            return {
                ...state,
                projectPosts: posts,
            };
        }
        case project.GET_PROJECT_POST_LIST_FAILUIRE:
            return {
                ...state,
                error: action.payload,
            };
        case project.PROJECT_PREQUISITE_LIST_SUCCESS:
            return {
                ...state,
                sitePreRequisite: action.payload.sitePreRequisite,
            };

        case project.SHOW_TEMPLATE_LIST_SUCCESS:
            return {
                ...state,
                templates: action.payload,
            };

        case project.PROJECT_TEAM_LIST_SUCCESS:
            return {
                ...state,
                projectTeamLists: action.payload.data,
            };

        case project.IS_SHOW_ADD_PROJECT:
            const { showProjectDetails, mainDiv, isOpen } = action.payload;
            return {
                ...state,
                isToShowAddProject: isOpen,
                mainDiv: mainDiv,
                showProjectDetails: showProjectDetails,
            };

        case project.GET_SCOGO_DEALIDS_DROPDOWN_SUCCESS:
            const { list } = action.payload;
            return {
                ...state,
                dealList: list,
            };
        case project.CLEAR_DEAL_DROPDOWN_LIST_FROM_STORE:
            return {
                ...state,
                dealList: undefined,
            };

        case project.EDIT_PROJECT_SUCCESS: {
            const { formValues } = action.payload;
            const { project_id } = formValues;
            const findAndUpdate = ({ id, dataList, updatingValues }) => {
                let newList = dataList?.map((item) => {
                    if (item?.id === id) {
                        return Object.assign(item, updatingValues);
                    } else return item;
                });
                return newList;
            };
            const updatedprojectData = findAndUpdate({ id: project_id, dataList: state.projectList, updatingValues: formValues });
            return {
                ...state,
                projectList: updatedprojectData,
            };
        }

        case project.GET_LINKED_PROJECT_OF_USERS_SUCCESS: {
            return {
                ...state,
                linkedUserProjects: action.payload,
            };
        }

        case project.GET_LINKED_PROJECT_OF_TEAMS_SUCCESS: {
            return {
                ...state,
                linkedTeamsProjects: action.payload,
            };
        }
        case project.CREATR_WORKFLOW_SUCCESS: {
            const { projectId, fk_template_id } = action.payload;
            const projectList = state.projectList;
            const findProjectIndex = projectList.findIndex((project) => project.id === projectId);
            if (projectList[findProjectIndex]) {
                projectList[findProjectIndex].fk_template_id = fk_template_id;
            }
            return {
                ...state,
                projectList
            };
        }

        case project.GET_PROJECT_PM_SUCCESS: {
            const { projectId, projectPM } = action.payload
            return {
                ...state,
                projectPMs: {
                    ...state.projectPMs, [projectId]: projectPM
                }
            };
        }
        case project.GET_PROJECTS_SLA_SUCCESS: {
            const { slas, projectId } = action.payload;
            return {
                ...state,
                projectsSlas: {
                    ...state.projectsSlas, [projectId]: slas
                }
            };
        }

        case project.EDIT_PROJECT_PM_SUCCESS: {
            const { projectId, projectPM } = action.payload
            const projectList = state.projectList;
            const index = projectList.findIndex((project) => project.id === projectId);
            const first_pm = projectPM?.find((pm) => pm.order === 1);
            if (projectList[index] && first_pm && first_pm.user) {
                projectList[index].scogo_pm_mobile = first_pm.user.mobile;
                projectList[index].scogo_pm_name = validateStringValue(`${first_pm.user.first_name} ${first_pm.user.last_name}`);
                projectList[index].scogo_pm_id = first_pm.fk_user_id;
            }
            return {
                ...state,
                projectPMs: {
                    ...state.projectPMs, [projectId]: projectPM
                },
                projectList
            };
        }

        case project.GET_LINKED_PROJECT_OF_USERS_FAILURE:
        case project.GET_LINKED_PROJECT_OF_TEAMS_FAILURE:
        case project.GET_SCOGO_DEALIDS_DROPDOWN_FAILURE:
        case project.DOWNLOAD_PROJECT_ZIP_FAILURE:
        case project.CREATE_PROJECT_FAILURE:
        case project.SHOW_TEMPLATE_LIST_FAILURE:
        case project.PROJECT_PREQUISITE_LIST_FAILURE:
        case project.EDIT_PROJECT_FAILURE:
        case project.GET_PROJECT_POST_LIST_FAILUIRE:
            return {
                ...state,
                error: action.payload,
            };

        default:
            return state;
    }
};

export default projectReducer;


const findMinMax = ({ list, key }) => {
    let min = Infinity;
    let max = -Infinity;
    if (Array.isArray(list)) {
        list.forEach((elem) => {
            let lookFor = elem?.[key];
            if (lookFor > max && !isNaN(lookFor)) {
                max = lookFor;
            } else if (lookFor < min && !isNaN(lookFor)) {
                min = lookFor;
            }
        });
    }
    if (min === Infinity) min = -1;
    if (max === -Infinity) max = Infinity;
    return { min, max };
};

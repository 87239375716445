import React, { useState, useEffect, forwardRef } from 'react';

import { useDispatch } from 'react-redux';
import moment from 'moment';
import ButtonGreen from '../Buttons/ButtonGreen';
import { openChatElement, } from '../../actions/conversations';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Popover from '@mui/material/Popover';
import { Avatar, Skeleton } from '@mui/material';
import { enableRoom, getUserCallStatusAction, getUserProfileDetails } from '../../actions/users';
import { getRoleByLabelName } from '../../utils/role';

import NoResultFound from '../NoResultFound';
import { getMaskedString, isLogged, validateNumberValue } from '../../utils/common';
import RatingComponent from '../Rating';
import ButtonScogoPrimary from '../Buttons/ButtonScogoPrimary';
import { getSingleConvoIdFromUserIds } from '../../utils/utils';
import { websocketEvents } from '../../utils/websocket';
import { sendDataThroughWebsocket } from '../../actions/websocket';
import { generateRoomLink } from '../../pages/videomeet/components/constants';

const ShowSkeleton = () => {
    return (
        <>
            <div className='flex items-center gap-x-4'>
                <Skeleton variant='circular'>
                    <Avatar sx={{ height: '8rem', width: '9rem' }} />
                </Skeleton>
                <div>
                    <Skeleton sx={{ background: 'rgb(221,221,221)' }} variant='rectangular' width={140} height={20} animation='wave' />
                    <br />
                    <Skeleton sx={{ background: 'rgb(221,221,221)' }} variant='rectangular' width={140} height={20} animation='wave' />
                </div>
            </div>
            <div className='flex gap-x-2 mt-2'>
                <Skeleton sx={{ background: 'rgb(221,221,221)' }} variant='rectangular' width={120} height={20} animation='wave' />

                <Skeleton sx={{ background: 'rgb(221,221,221)' }} variant='rectangular' width={120} height={20} animation='wave' />
            </div>
            <div className='flex items-center gap-x-2 mt-3'>
                {[1, 2, 3].map(() => {
                    return (
                        <div>
                            <Skeleton sx={{ background: 'rgb(221,221,221)' }} variant='rectangular' width={79} height={20} animation='wave' />
                            <br />
                            <Skeleton sx={{ background: 'rgb(221,221,221)' }} variant='rectangular' width={79} height={20} animation='wave' />
                        </div>
                    );
                })}
            </div>
            <br />
            <Skeleton sx={{ background: 'rgb(221,221,221)' }} variant='rectangular' width={250} height={20} animation='wave' />
        </>
    );
};

const Popup = forwardRef(({ userId, from, close }, ref) => {
    const dispatch = useDispatch();

    const { userProfileDetails } = useSelector((state) => state.user);
    const { userProfileLoading } = useSelector((state) => state.loading);
    const { loggedUser } = useSelector((state) => state.auth);
    const { isEnableVCUserLoading } = useSelector((state) => state.loading);
    const { formButtonLoading } = useSelector(state => state.loading);
    const userDetails = userProfileDetails?.[userId]

    useEffect(() => {
        if (!userDetails) {
            dispatch(getUserProfileDetails({ userId, from }));
        }
    }, [userId, dispatch]);

    const showContacts = userDetails?.mobile || userDetails?.email;
    const showTicket = userDetails?.numTickets || userDetails?.numTickets;

    if (!userDetails && !userProfileLoading) {
        return <div className="py-4 px-4" >
            <NoResultFound message="User Details Not Found" paddingy="py-2" paddingx="px-2" />

        </div>
    }

    const addressProofOnClick = () => {
        if (userDetails.gstNumber) {
            return false
        } else if (userDetails.panNumber) {
            return false
        } else if (userDetails?.addressProofUrl) {
            return true
        } else {
            return false
        }
    }

    const singleConversationId = getSingleConvoIdFromUserIds(loggedUser?.id, userId);

    const handleOnSuccess = async ({ room_id, conversationId }) => {
        dispatch(getUserCallStatusAction({ userId: loggedUser?.id, status: [1, 2, 3, 4] }));
        dispatch(sendDataThroughWebsocket({ action: websocketEvents.chat.videoMeetInvite, data: { userIds: [userId], videoMeetDetails: { videoMeetLink: generateRoomLink(room_id, conversationId), room_id: room_id } } }));
        window.open(generateRoomLink(room_id, conversationId), "_blank");
        close();
    }

    const handleEnableRoom = () => {
        dispatch(enableRoom({ userId: loggedUser?.id, conversationId: singleConversationId, onSuccess: handleOnSuccess, buttonName: `popup-scogo-connect-${userId}` }));
    }

    let proofNumber = (userDetails?.addressProofNumber || userDetails?.gstNumber || userDetails?.panNumber);
    if (userDetails?.addressProofNumber) {
        proofNumber = loggedUser?.frontend_controller?.ViewMaskData ? userDetails?.addressProofNumber : getMaskedString(userDetails?.addressProofNumber);
    } else if (userDetails?.gstNumber) {
        proofNumber = userDetails.gstNumber
    } else if (userDetails?.panNumber) {
        proofNumber = loggedUser?.frontend_controller?.ViewMaskData ? userDetails?.panNumber : getMaskedString(userDetails?.panNumber);
    }

    const openChat = (userId, buttonName) => {
        dispatch(openChatElement({ source: 'teams', buttonName, userId }));
        // dispatch(openSingleChat({ userId, openInNewTab: true, navigate, formName: buttonName }))
    }
    let isLoading = formButtonLoading?.[`open_chat_${userId}`]
    return (
        <div className='bg-white shadow-lg rounded-md p-5 max-w-[32rem]' ref={ref}>
            {userProfileLoading ? (
                <ShowSkeleton />
            ) : (
                <>
                    <div className='flex gap-4'>
                        <div>

                            <img src={userDetails?.profilePic} className='w-[7rem] h-[7rem] shadow-md rounded-full mb-1 outline-2 object-cover shrink-0' alt='Profile' />

                            {userDetails?.rating > 0 ? <RatingComponent rating={userDetails?.rating} /> : <></>}

                        </div>
                        <div className='flex flex-col justify-center overflow-hidden'>
                            <span className='text-font16 whitespace-nowrap text-ellipsis overflow-hidden ' title={userDetails?.userName}>
                                {userDetails?.userName}
                            </span>
                            {(userDetails?.spName || userDetails?.companyName) && <div>
                                <span className='text-font11 text-scogogray'>{userDetails?.spName || userDetails?.companyName}</span>
                            </div>}
                            <div className='flex gap-5'>
                                <span className='text-font12 text-scogo21'>{getRoleByLabelName(userDetails?.role, userDetails?.type)}</span>
                                {(proofNumber) && (
                                    <div
                                        className='flex gap-1 items-center border-scogoprimary border-1 cursor-pointer px-2 py-0 rounded'
                                        onClick={() => {
                                            return addressProofOnClick() ? window.open(userDetails?.addressProofUrl, '_blank') : ""
                                        }}
                                    >
                                        <span className='material-icons text-font09 text-scogoorange m-0'>contact_mail</span>
                                        <span className='text-scogoprimary text-font09 m-0'>{proofNumber}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {showContacts && (
                        <div className='grid grid-cols-2 w-full gap-4 p-2 mt-4'>
                            {userDetails?.email && (
                                <div className='flex text-font12 gap-2 items-center'>
                                    <span className='material-icons text-font18'>email</span>
                                    <span className='whitespace-nowrap text-ellipsis overflow-hidden' title={userDetails?.email}>
                                        {userDetails?.email}
                                    </span>
                                </div>
                            )}
                            {userDetails?.mobile && (
                                <div className='flex text-font12 gap-2 items-center'>
                                    <span className='material-icons text-font18'>call</span>
                                    <span>{userDetails.mobile}</span>
                                </div>
                            )}
                        </div>
                    )}
                    <div className='grid grid-cols-3 w-full gap-4 p-2'>
                        <div className='flex flex-col gap-2'>
                            <span className='text-font13'>Joined on</span>
                            <span className='bg-scogoLogoGreen  text-white max-w-max text-font10 px-2 py-1 rounded-3px'>
                                {moment(userDetails?.joinedAt).format('DD MMM YYYY')}
                            </span>
                        </div>
                        {showTicket && (
                            <div className='flex flex-col gap-2'>
                                <span className='text-font13'>No. Tickets</span>
                                <span className='text-font12'>
                                    {userDetails?.numTicketsClosed} / {userDetails?.numTickets}
                                </span>
                            </div>
                        )}
                        {userDetails?.balance > 0 && (
                            <div className='flex flex-col gap-2'>
                                <span className='text-font13'>Balance</span>
                                <div className='flex gap-1 items-center'>
                                    <span className='material-icons text-font13'>currency_rupee</span>
                                    <span className='text-font12'>{userDetails.balance}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    {userDetails?.chatId && (
                        <div className='w-full flex gap-2 px-2'>
                            <ButtonGreen
                                textOrComponent={
                                    <div className='flex items-center gap-2'>
                                        <img src='/img/chat_colourful.png' alt='' className='w-6 cursor-pointer' />
                                        Chat
                                    </div>
                                }
                                buttonClass='w-1/2'
                                buttonPadding='px-4 py-2 mt-4 w-1/2'
                                onClick={() => {
                                    // dispatch(openSingleChat({ userId, chatId: userDetails.chatId, navigate, openInNewTab: true }));
                                    // close();

                                    openChat(userId, `open_chat_${userId}`)

                                }}
                                loading={isLoading}
                            />
                            <ButtonScogoPrimary
                                textOrComponent={
                                    <div className='flex items-center gap-2'>
                                        <div className="flex items-center w-7 h-7">
                                            <img src={process.env.PUBLIC_URL + '/img/scogo-connect-blue.svg'} alt="" className="w-full h-full object-contain hover:cursor-pointer" />
                                        </div>
                                        Connect
                                    </div>
                                }
                                loading={isEnableVCUserLoading}
                                buttonClass='w-1/2'
                                buttonPadding='px-4 py-2 mt-4 w-1/2'
                                onClick={() => {
                                    handleEnableRoom();
                                }}
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    );
});

const UserPopup = ({
    children,
    userId,
    from,
    anchorOrigin = {
        vertical: 'top',
        horizontal: 'center',
    },
    transformOrigin = {
        vertical: 'bottom',
        horizontal: 'center',
    },
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { loggedUser } = useSelector((state) => state.auth);
    if (!isLogged() || !loggedUser) {
        return <></>
    }
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const isValidUser = validateNumberValue(userId) && userId > 0;

    return (
        <>
            <div aria-describedby={id} onClick={handleClick} variant='contained' className='w-full hover:underline cursor-pointer inline'>
                {children}
            </div>
            {isValidUser ? <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={anchorOrigin} transformOrigin={transformOrigin}>
                <Popup userId={userId} from={from} showChat={userId !== loggedUser.id} close={handleClose} />
            </Popover> : <></>}
        </>
    );
};

export default UserPopup;

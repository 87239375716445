import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { RenderMapIcon } from './RenderChatIcon';
import { useSelector, useDispatch } from 'react-redux';
import { UserContact } from '../Payments/SideBarComponents/TicketDetails/ContactDetails';
import { getFirstAndLastNameFromFullName, GetQueryParam, isLogged, validateNumberValue, validateStringValue } from '../../utils/common';
import { useNavigate } from 'react-router-dom';
import { trackSupportTicket } from '../../router/roleWisePageRoute';
import { getTicketDetailForDetailPage } from '../../actions/tickets';
import { RibbonContainer } from '../../components/TicketExecution/RibbonContainer';

import MobileBasicInfo from './MobileBasicInfo';
import { Skeleton } from '@mui/material';
import ButtonScogoPrimary from '../../common/Buttons/ButtonScogoPrimary';
import { openCustomModal } from '../../actions/modal';
import { useForm } from 'react-hook-form';
import { loginWithOTPAction, removeOTPSuccessFromStore, requestOtpSupport } from '../../actions/auth';
import { sendToastMessage } from '../../actions/toast';
import config from '../../config';
import development from '../../config/env/development';
import Form from '../../common/Form';
import Input from '../../common/Form/Input';
import Progress from '../Payments/SideBarComponents/ProgressBar';
import { isCustomerGroup, isScm } from '../../utils/role';

export default function RenderTicketDetails({ ticketId, isLiveTrackingEnabled, isMobileView, showActions = false }) {
    const { loggedUser } = useSelector((state) => state.auth);
    const { ticketDetails, openTicketDetails } = useSelector((state) => state.tickets);
    const { isViewTicketByIdLoading } = useSelector((state) => state.loading);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const shared_by = GetQueryParam('shared_by', Number);

    const contactDetails = useMemo(() => {
        if (openTicketDetails) {
            return {
                pm_name: `${validateStringValue(openTicketDetails?.pm_detail?.first_name)} ${validateStringValue(openTicketDetails?.pm_detail?.last_name)}`,
                pm_id: openTicketDetails?.pm_detail.id,
                pm_mobile: openTicketDetails?.pm_detail?.mobile,
                fe_name: `${validateStringValue(openTicketDetails?.accepted_fe?.first_name)} ${validateStringValue(openTicketDetails?.accepted_fe?.last_name)}`,
                fe_id: openTicketDetails?.accepted_fe?.id,
                fe_mobile: openTicketDetails?.accepted_fe?.mobile,
                local_contact_name: `${validateStringValue(openTicketDetails.job_site_contact_name)}`,
                local_contact_id: 0,
                local_contact_mobile: openTicketDetails?.job_site_contact_mobile,
                shared_by_id: validateNumberValue(openTicketDetails?.sharedBy?.id),
                shared_by_name: `${validateStringValue(openTicketDetails?.sharedBy?.first_name)} ${validateStringValue(openTicketDetails?.sharedBy?.last_name)}`,
                shared_by_mobile: validateStringValue(openTicketDetails?.sharedBy?.mobile)
            }
        }
        if (ticketDetails) {
            const sharedBy = shared_by && ticketDetails && ticketDetails.shared_by_details.find((item) => item.id === shared_by);
            return {
                pm_name: `${validateStringValue(ticketDetails.pm_first_name)} ${validateStringValue(ticketDetails.pm_last_name)}`,
                pm_id: 0, // updated this code
                pm_mobile: ticketDetails.pm_mobile,
                fe_name: `${validateStringValue(ticketDetails.fe_first_name)} ${validateStringValue(ticketDetails.fe_last_name)}`,
                fe_id: ticketDetails.accepted_fe_id,
                fe_mobile: ticketDetails.fe_mobile,
                local_contact_name: `${validateStringValue(ticketDetails.job_site_contact_name)}`,
                local_contact_id: 0,
                local_contact_mobile: ticketDetails.job_site_contact_mobile,
                shared_by_id: validateNumberValue(sharedBy?.id),
                shared_by_name: `${validateStringValue(sharedBy?.first_name)} ${validateStringValue(openTicketDetails?.last_name)}`,
                shared_by_mobile: sharedBy?.mobile
            }
        }
        return {
            pm_name: '',
            pm_id: '',
            pm_mobile: '',
            fe_name: '',
            fe_id: 0,
            fe_mobile: '',
            local_contact_name: '',
            local_contact_id: 0,
            local_contact_mobile: '',
            shared_by_id: 0,
            shared_by_name: '',
            shared_by_mobile: ''
        }
    }, [ticketDetails, openTicketDetails, shared_by]);

    useEffect(() => {
        if (ticketId && !openTicketDetails && !loggedUser) {
            dispatch(getTicketDetailForDetailPage({ ticketId, shared_by }))
        }
    }, [ticketId, dispatch, openTicketDetails, shared_by, loggedUser]);

    const localContactDetails = {
        title: 'Local Contact',
        name: contactDetails.local_contact_name,
        mobile: contactDetails.local_contact_mobile,
        isCardVisible: contactDetails.local_contact_name,
    };

    const feDetails = {
        title: 'FE',
        id: contactDetails.fe_id,
        name: contactDetails.fe_name,
        mobile: contactDetails.fe_mobile,
        isCardVisible: contactDetails.fe_id > 0
    };

    const pmDetails = {
        title: 'PM',
        id: contactDetails.pm_id,
        name: contactDetails.pm_name,
        mobile: contactDetails.pm_mobile,
        isCardVisible: contactDetails.pm_id > 0
    };

    const sharedByDetails = {
        title: 'Link Shared By',
        id: contactDetails.shared_by_id,
        name: contactDetails.shared_by_name,
        mobile: contactDetails.shared_by_mobile,
        isCardVisible: contactDetails.shared_by_id > 0 && contactDetails.shared_by_id !== contactDetails.pm_id
    };

    const usersContact = [sharedByDetails, feDetails, localContactDetails, pmDetails].filter((e) => e.isCardVisible);

    const memoizedTicketDetails = useMemo(() => {
        let details = {};
        if (openTicketDetails) details = openTicketDetails;
        if (ticketDetails) {
            details = { ...ticketDetails, ...details };
        }
        return details;
    }, [openTicketDetails, ticketDetails]);

    const onNextClick = useCallback(() => {
        let path = `${trackSupportTicket.path}?ticketId=${ticketId}&redirectToChat=true`;
        if (shared_by) path = `${path}&shared_by=${shared_by}`;
        const modalWidth = isMobileView ? '90vw' : '30vw';
        if (loggedUser && isLogged()) {
            navigate(path);
        } else {
            dispatch(
                openCustomModal({
                    heading: `Login`,
                    modalWidth,
                    modalHeight: 'auto',
                    modalComponent: <LoginForm ticketId={ticketId} redirect_to={path} />,
                })
            )
        }
    }, [dispatch, ticketId, shared_by, loggedUser, navigate, isMobileView]);

    const showProgressAtTop = memoizedTicketDetails?.is_sign_off_request === 1 || isCustomerGroup(loggedUser?.role?.id, loggedUser?.type) || isScm(loggedUser?.role?.id, loggedUser?.type);

    return <div className="w-full flex flex-col items-center bg-scogolightblue  overflow-hidden">
        <div className='w-full overflow-y-auto overflow-x-hidden py-1 px-4 md:px-8' style={{ maxHeight: '90%' }}>


            <div className={`${isMobileView ? ' gap-4 flex flex-col' : ' flex gap-8'} py-4 h-full`} >

                {showProgressAtTop && <RenderProgress memoizedTicketDetails={memoizedTicketDetails} isLoading={isViewTicketByIdLoading} openCardDefault />}

                <RibbonContainer headerLabel={'Contact'}>
                    {isViewTicketByIdLoading ? (
                        <div className='flex flex-col items-center justify-center'>
                            <Skeleton sx={{ background: 'rgb(221,221,221)' }} variant='rectangular' width={300} height={90} animation='wave' />

                        </div>

                    ) :
                        <div className=' col-span-2 gap-3 grid grid-cols-2 justify-around mt-[0.5rem] '>
                            {usersContact?.map((user, i) => {
                                let lastIndex = i === usersContact.length - 1 ? i : 1;
                                let isToShowRanderComponent = usersContact.length % 2 === 0
                                let userLength = usersContact.length
                                return <UserContact userLength={userLength} user={user} lastIndex={lastIndex} i={i} isToShowRanderComponent={isToShowRanderComponent} loading={isViewTicketByIdLoading} />
                            })}
                        </div>
                    }
                </RibbonContainer>

                {!showProgressAtTop && <RenderProgress memoizedTicketDetails={memoizedTicketDetails} isLoading={isViewTicketByIdLoading} openCardDefault={!isMobileView} />}

                {memoizedTicketDetails && <MobileBasicInfo headerLabel={'Basic Info'} openTicketDetails={memoizedTicketDetails} status={openTicketDetails?.status} />}
                <RenderMapIcon hide={!isLiveTrackingEnabled} />
            </div>
        </div>
        {showActions && <div style={{ height: '10%' }} className='flex gap-2 justify-end py-4 px-4'>
            <ButtonScogoPrimary buttonPadding='px-10 py-2 text-font15 md:text-font12' textOrComponent={'Next'} onClick={onNextClick} />
        </div>}
    </div>
}


const requestOtp = 'Request OTP';
const submitText = 'Submit';

export const LoginForm = ({ ticketId, redirect_to }) => {
    const defaultValues = {}
    const methods = useForm({ defaultValues, mode: 'all' });
    const submitRef = useRef(null);
    const { requestOTPSuccess } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const { isRequestOtpButtonLoading, isOtpButtonLoading } = useSelector((state) => state.loading);
    const buttonName = requestOTPSuccess ? submitText : requestOtp;
    const { uiConfig } = useSelector(state => state.user);
    const navigate = useNavigate();

    useEffect(() => {
        return () => dispatch(removeOTPSuccessFromStore());
    }, [dispatch]);

    const onSubmit = (formValues) => {
        if (formValues.name.trim().length === 0) {
            dispatch(sendToastMessage({ status: 'warning', message: 'Please enter valid name.' }));
            return;
        }

        if (buttonName === requestOtp || !requestOTPSuccess) {
            const { first_name, last_name } = getFirstAndLastNameFromFullName(formValues.name)
            const data = { first_name, last_name, skip: true, mobile: formValues.mobile, logged_in_from: 'support_view' };
            if (ticketId) {
                data.ticketId = ticketId;
            } else if (uiConfig?.customer_id) {
                data.customer_id = uiConfig.customer_id;
            } else if (window.location.hostname === 'localhost' || config.name === development.name) {
                data.customer_id = 1;
            }
            dispatch(requestOtpSupport(data));
        } else {
            dispatch(loginWithOTPAction({ mobile: formValues.mobile, otp: formValues.otp, logged_in_from: 'support_view', redirect_to, navigate }))
        }
    };

    return <>
        <div className='overflow-y-auto w-full px-4'>
            <Form
                methods={methods}
                onSubmit={onSubmit}
                submitButtonText={'Update'}
                submitContainerClassName='py-4 px-4'
                showCancelButton={false}
                submitRef={submitRef}
                onEnterPressSubmit
            >
                <div className='grid grid-cols-1 rows-fr grid-flow-row-dense'>
                    <Input label='Name' name='name' type={'text'} required validate={(value) => value.trim().length > 0 || 'Invalid Name'} autofocus />
                    <Input label='Mobile' name='mobile' type={'number'} required validate={(value) => value.length === 10 || 'Invalid Mobile'} />
                    {requestOTPSuccess && <Input label='OTP' name='otp' type={'number'} required validate={(value) => value.length === 4 || 'Invalid OTP'} />
                    }
                </div>
            </Form>
        </div>
        <div className='pt-4 pb-20 w-full  '>
            <div className='flex justify-center w-full'>
                <ButtonScogoPrimary onClick={(e) => {
                    e.preventDefault();
                    submitRef.current.click();
                }}
                    buttonTextClass={'submitButtonSupportText'}
                    loading={isRequestOtpButtonLoading || isOtpButtonLoading}
                    textOrComponent={buttonName}
                />
            </div>
        </div>
    </>
}



const RenderProgress = ({ isLoading, memoizedTicketDetails, openCardDefault }) => {
    return <RibbonContainer isCollapsible headerLabel={'Progress'} childrenClass='justify-around mt-[2rem] w-full' openCardDefault={openCardDefault} >
        {isLoading ? (
            <div className='flex flex-col h-full items-center justify-center'>
                <Skeleton sx={{ background: 'rgb(221,221,221)' }} variant='rectangular' width={300} height={90} animation='wave' />

            </div>
        ) :
            <div >
                <Progress ticket={memoizedTicketDetails} />
            </div>
        }
    </RibbonContainer>
};
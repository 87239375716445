import { useDispatch } from 'react-redux';

import IconToolTip from '../../../../common/IconToolTip';
import LabelList from '../../../Modals/Inventory/LabelList';

import { openCustomModal } from '../../../../actions/modal';

const AssetDetails = ({ assetType, customerName, make, model, partcode, supplier, labelList, onClick = () => {} }) => {
    const dispatch = useDispatch();
    const openLabelListModal = () => {
        let modalWidth = '45em',
            modalHeight = '40em';
        let modalComponent = (
            <LabelList
                labelList={labelList}
                customerName={customerName}
                make={make}
                model={model}
                partcode={partcode}
                supplier={supplier}
                modalWidth={modalWidth}
                modalHeight={modalHeight}
            />
        );
        dispatch(openCustomModal({ modalComponent, heading: assetType, modalWidth, modalHeight }));
    };
    const viewAll = (
        <span className='text-font12 font-normal text-scogoorange cursor-pointer hover:text-scogobgsky' onClick={openLabelListModal}>
            View All
        </span>
    );

    return (
        <div className='flex flex-col'>
            {customerName && (
                <IconToolTip title={`Customer Name : ${customerName}`}>
                    <p>{customerName}</p>
                </IconToolTip>
            )}
            {make && (
                <IconToolTip title={`Make : ${make}`} placement='bottom'>
                    <span className='w-full truncate' onClick={(e) => onClick(e, make)}>
                        {make}
                    </span>
                </IconToolTip>
            )}
            {model && (
                <IconToolTip title={`Model : ${model}`} placement='bottom'>
                    <span className='w-full truncate' onClick={(e) => onClick(e, model)}>
                        {model}
                    </span>
                </IconToolTip>
            )}
            {partcode && (
                <IconToolTip title={`Partcode : ${partcode}`} placement='bottom'>
                    <span className='w-full truncate' onClick={(e) => onClick(e, partcode)}>
                        {partcode}
                    </span>
                </IconToolTip>
            )}
            {supplier && (
                <IconToolTip title={`Supplier : ${supplier}`} placement='bottom'>
                    <span className='w-full truncate' onClick={(e) => onClick(e, supplier)}>
                        {supplier}
                    </span>
                </IconToolTip>
            )}
            {Array.isArray(labelList) && labelList.length > 0 && viewAll}
        </div>
    );
};

export default AssetDetails;

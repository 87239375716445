export const REMOVE_FROM_INVENTORY_STORE_REQUEST = 'REMOVE_FROM_INVENTORY_STORE_REQUEST';

export const GET_ALL_SITES_REQUEST = 'GET_ALL_SITES_REQUEST';
export const GET_ALL_SITES_SUCCESS = 'GET_ALL_SITES_SUCCESS';
export const ALL_SITES_COUNT_SUCCESS = 'ALL_SITES_COUNT_SUCCESS';
export const GET_ALL_SITES_FAILURE = 'GET_ALL_SITES_FAILURE';

export const GET_ALL_FILTER_SITES_REQUEST = 'GET_ALL_FILTER_SITES_REQUEST';
export const GET_ALL_FILTER_SITES_SUCCESS = 'GET_ALL_FILTER_SITES_SUCCESS';
export const GET_ALL_FILTER_SITES_FAILURE = 'GET_ALL_FILTER_SITES_FAILURE';

export const GET_ALL_FILTER_SPAREASSET_REQUEST = 'GET_ALL_FILTER_SPAREASSET_REQUEST';
export const GET_ALL_FILTER_SPAREASSET_SUCCESS = 'GET_ALL_FILTER_SPAREASSET_SUCCESS';
export const GET_ALL_FILTER_SPAREASSET_FAILURE = 'GET_ALL_FILTER_SPAREASSET_FAILURE';

export const GET_ALL_FILTER_ASSETS_REQUEST = 'GET_ALL_FILTER_ASSETS_REQUEST';
export const GET_ALL_FILTER_ASSETS_SUCCESS = 'GET_ALL_FILTER_ASSETS_SUCCESS';
export const GET_ALL_FILTER_ASSETS_FAILURE = 'GET_ALL_FILTER_ASSETS_FAILURE';

export const GET_ALL_ASSETS_REQUEST = 'GET_ALL_ASSETS_REQUEST';
export const GET_ALL_ASSETS_SUCCESS = 'GET_ALL_ASSETS_SUCCESS';
export const GET_TAG_ASSETS_SUCCESS = 'GET_TAG_ASSETS_SUCCESS';
export const ALL_ASSETS_COUNT_SUCCESS = 'ALL_ASSETS_COUNT_SUCCESS';
export const GET_ALL_ASSETS_FAILURE = 'GET_ALL_ASSETS_FAILURE';

export const GET_ALL_WAREHOUSE_REQUEST = 'GET_ALL_WAREHOUSE_REQUEST';
export const GET_ALL_WAREHOUSE_SUCCESS = 'GET_ALL_WAREHOUSE_SUCCESS';
export const ALL_WAREHOUSES_COUNT_SUCCESS = 'ALL_WAREHOUSES_COUNT_SUCCESS';
export const GET_ALL_WAREHOUSE_FAILURE = 'GET_ALL_WAREHOUSE_FAILURE';

export const GET_ALL_WAREHOUSE_FOR_FILTER_REQUEST = 'GET_ALL_WAREHOUSE_FOR_FILTER_REQUEST';
export const GET_ALL_WAREHOUSE_FOR_FILTER_SUCCESS = 'GET_ALL_WAREHOUSE_FOR_FILTER_SUCCESS';
export const GET_ALL_WAREHOUSE_FOR_FILTER_FAILURE = 'GET_ALL_WAREHOUSE_FOR_FILTER_FAILURE';

export const GET_NEXT_WAREHOUSE_LIST_REQUEST = 'GET_NEXT_WAREHOUSE_LIST_REQUEST';
export const GET_NEXT_WAREHOUSE_LIST_SUCCESS = 'GET_NEXT_WAREHOUSE_LIST_SUCCESS';
export const GET_NEXT_WAREHOUSE_LIST_FAILURE = 'GET_NEXT_WAREHOUSE_LIST_FAILURE';

export const GET_ALL_SPARE_ASSETS_REQUEST = 'GET_ALL_SPARE_ASSETS_REQUEST';
export const GET_ALL_SPARE_ASSETS_SUCCESS = 'GET_ALL_SPARE_ASSETS_SUCCESS';
export const ALL_SPARE_ASSETS_COUNT_SUCCESS = 'ALL_SPARE_ASSETS_COUNT_SUCCESS';
export const GET_ALL_SPARE_ASSETS_FAILURE = 'GET_ALL_SPARE_ASSETS_FAILURE';

export const CREATE_INVENTORY_SITES_REQUEST = 'CREATE_INVENTORY_SITES_REQUEST';
export const CREATE_INVENTORY_SITES_SUCCESS = 'CREATE_INVENTORY_SITES_SUCCESS';
export const CREATE_INVENTORY_SITES_FAILURE = 'CREATE_INVENTORY_SITES_FAILURE';

export const CITIES_LIST_REQUEST = 'CITIES_LIST_REQUEST';
export const CITIES_LIST_SUCCESS = 'CITIES_LIST_SUCCESS';
export const CITIES_LIST_FAILURE = 'CITIES_LIST_FAILURE';

export const ENDUSERS_LIST_REQUEST = 'ENDUSERS_LIST_REQUEST';
export const ENDUSERS_LIST_SUCCESS = 'ENDUSERS_LIST_SUCCESS';
export const ENDUSERS_LIST_FAILURE = 'ENDUSERS_LIST_FAILURE';

export const GET_SITE_TYPE_REQUEST = 'GET_SITE_TYPE_REQUEST';
export const GET_SITE_TYPE_SUCCESS = 'GET_SITE_TYPE_SUCCESS';
export const GET_SITE_TYPE_FAILURE = 'GET_SITE_TYPE_FAILURE';

export const SLA_POLICIES_LIST_REQUEST = 'SLA_POLICIES_LIST_REQUEST';
export const SLA_POLICIES_LIST_SUCCESS = 'SLA_POLICIES_LIST_SUCCESS';
export const SLA_POLICIES_LIST_FAILURE = 'SLA_POLICIES_LIST_FAILURE';

export const GET_CITY_FROM_PINCODE_REQUEST = 'GET_CITY_FROM_PINCODE_REQUEST';
export const GET_CITY_FROM_PINCODE_SUCCESS = 'GET_CITY_FROM_PINCODE_SUCCESS';
export const GET_CITY_FROM_PINCODE_FAILURE = 'GET_CITY_FROM_PINCODE_FAILURE';

export const CREATE_INVENTORY_WAREHOUSES_REQUEST = 'CREATE_INVENTORY_WAREHOUSES_REQUEST';
export const CREATE_INVENTORY_WAREHOUSES_SUCCESS = 'CREATE_INVENTORY_WAREHOUSES_SUCCESS';
export const CREATE_INVENTORY_WAREHOUSES_FAILURE = 'CREATE_INVENTORY_WAREHOUSES_FAILURE';

export const GET_SPARE_CATEGORIES_REQUEST = 'GET_SPARE_CATEGORIES_REQUEST';
export const GET_SPARE_CATEGORIES_SUCCESS = 'GET_SPARE_CATEGORIES_SUCCESS';
export const GET_SPARE_CATEGORIES_FAILURE = 'GET_SPARE_CATEGORIES_FAILURE';

export const GET_INVENTORY_PARTCODES_REQUEST = 'GET_INVENTORY_PARTCODES_REQUEST';
export const GET_INVENTORY_PARTCODES_SUCCESS = 'GET_INVENTORY_PARTCODES_SUCCESS';
export const GET_INVENTORY_PARTCODES_FAILURE = 'GET_INVENTORY_PARTCODES_FAILURE';

export const GET_ASSETS_REQUEST = 'GET_ASSETS_REQUEST';
export const GET_ASSETS_SUCCESS = 'GET_ASSETS_SUCCESS';
export const GET_ASSETS_FAILURE = 'GET_ASSETS_FAILURE';

export const ADD_ASSETS_REQUEST = 'ADD_ASSETS_REQUEST';
export const ADD_ASSETS_SUCCESS = 'ADD_ASSETS_SUCCESS';
export const ADD_ASSETS_FAILURE = 'ADD_ASSETS_FAILURE';

export const FIND_SITE_BY_ID_REQUEST = 'FIND_SITE_BY_ID_REQUEST';
export const FIND_SITE_BY_ID_SUCCESS = 'FIND_SITE_BY_ID_SUCCESS';
export const FIND_SITE_BY_ID_LAST_TICKET_SUCCESS = 'FIND_SITE_BY_ID_LAST_TICKET_SUCCESS';
export const FIND_SITE_BY_ID_FAILURE = 'FIND_SITE_BY_ID_FAILURE';

export const CREATE_INVENTORY_ASSETS_REQUEST = 'CREATE_INVENTORY_ASSETS_REQUEST';
export const CREATE_INVENTORY_ASSETS_SUCCESS = 'CREATE_INVENTORY_ASSETS_SUCCESS';
export const CREATE_INVENTORY_ASSETS_FAILURE = 'CREATE_INVENTORY_ASSETS_FAILURE';

export const CREATE_INVENTORY_SPARE_ASSETS_REQUEST = 'CREATE_INVENTORY_SPARE_ASSETS_REQUEST';
export const CREATE_INVENTORY_SPARE_ASSETS_SUCCESS = 'CREATE_INVENTORY_SPARE_ASSETS_SUCCESS';
export const CREATE_INVENTORY_SPARE_ASSETS_FAILURE = 'CREATE_INVENTORY_SPARE_ASSETS_FAILURE';

export const DELETE_INVENTORY_SITE_REQUEST = 'DELETE_INVENTORY_SITE_REQUEST';
export const DELETE_INVENTORY_SITE_SUCCESS = 'DELETE_INVENTORY_SITE_SUCCESS';
export const DELETE_INVENTORY_SITE_FAILURE = 'DELETE_INVENTORY_SITE_FAILURE';

export const DELETE_INVENTORY_ASSET_REQUEST = 'DELETE_INVENTORY_ASSET_REQUEST';
export const DELETE_INVENTORY_ASSET_SUCCESS = 'DELETE_INVENTORY_ASSET_SUCCESS';
export const DELETE_INVENTORY_ASSET_FAILURE = 'DELETE_INVENTORY_ASSET_FAILURE';

export const DELETE_INVENTORY_WAREHOUSE_REQUEST = 'DELETE_INVENTORY_WAREHOUSE_REQUEST';
export const DELETE_INVENTORY_WAREHOUSE_SUCCESS = 'DELETE_INVENTORY_WAREHOUSE_SUCCESS';
export const DELETE_INVENTORY_WAREHOUSE_FAILURE = 'DELETE_INVENTORY_WAREHOUSE_FAILURE';

export const DELETE_INVENTORY_SPARE_ASSETS_REQUEST = 'DELETE_INVENTORY_SPARE_ASSETS_REQUEST';
export const DELETE_INVENTORY_SPARE_ASSETS_SUCCESS = 'DELETE_INVENTORY_SPARE_ASSETS_SUCCESS';
export const DELETE_INVENTORY_SPARE_ASSETS_FAILURE = 'DELETE_INVENTORY_SPARE_ASSETS_FAILURE';

export const UPDATE_INVENTORY_SITE_REQUEST = 'UPDATE_INVENTORY_SITE_REQUEST';
export const UPDATE_INVENTORY_SITE_SUCCESS = 'UPDATE_INVENTORY_SITE_SUCCESS';
export const UPDATE_INVENTORY_SITE_FAILURE = 'UPDATE_INVENTORY_SITE_FAILURE';

export const UPDATE_INVENTORY_ASSET_REQUEST = 'UPDATE_INVENTORY_ASSET_REQUEST';
export const UPDATE_INVENTORY_ASSET_SUCCESS = 'UPDATE_INVENTORY_ASSET_SUCCESS';
export const UPDATE_INVENTORY_ASSET_FAILURE = 'UPDATE_INVENTORY_ASSET_FAILURE';

export const UPDATE_INVENTORY_WAREHOUSE_REQUEST = 'UPDATE_INVENTORY_WAREHOUSE_REQUEST';
export const UPDATE_INVENTORY_WAREHOUSE_SUCCESS = 'UPDATE_INVENTORY_WAREHOUSE_SUCCESS';
export const UPDATE_INVENTORY_WAREHOUSE_FAILURE = 'UPDATE_INVENTORY_WAREHOUSE_FAILURE';

export const UPDATE_INVENTORY_SPARE_ASSETS_REQUEST = 'UPDATE_INVENTORY_SPARE_ASSETS_REQUEST';
export const UPDATE_INVENTORY_SPARE_ASSETS_SUCCESS = 'UPDATE_INVENTORY_SPARE_ASSETS_SUCCESS';
export const UPDATE_INVENTORY_SPARE_ASSETS_FAILURE = 'UPDATE_INVENTORY_SPARE_ASSETS_FAILURE';

export const MIGRATE_INVENTORY_ASSET_REQUEST = 'MIGRATE_INVENTORY_ASSET_REQUEST';
export const MIGRATE_INVENTORY_ASSET_SUCCESS = 'MIGRATE_INVENTORY_ASSET_SUCCESS';
export const MIGRATE_INVENTORY_ASSET_FAILURE = 'MIGRATE_INVENTORY_ASSET_FAILURE';

export const SCRAP_INVENTORY_ASSETS_REQUEST = 'SCRAP_INVENTORY_ASSETS_REQUEST';
export const SCRAP_INVENTORY_ASSETS_SUCCESS = 'SCRAP_INVENTORY_ASSETS_SUCCESS';
export const SCRAP_INVENTORY_ASSETS_FAILURE = 'SCRAP_INVENTORY_ASSETS_FAILURE';

export const REPLACE_INVENTORY_ASSET_REQUEST = 'REPLACE_INVENTORY_ASSET_REQUEST';
export const REPLACE_INVENTORY_ASSET_SUCCESS = 'REPLACE_INVENTORY_ASSET_SUCCESS';
export const REPLACE_INVENTORY_ASSET_FAILURE = 'REPLACE_INVENTORY_ASSET_FAILURE';

export const GENERATE_INVENTORY_ASSETS_BARCODE_REQUEST = 'GENERATE_INVENTORY_ASSETS_BARCODE_REQUEST';
export const GENERATE_INVENTORY_ASSETS_BARCODE_SUCCESS = 'GENERATE_INVENTORY_ASSETS_BARCODE_SUCCESS';
export const GENERATE_INVENTORY_ASSETS_BARCODE_FAILURE = 'GENERATE_INVENTORY_ASSETS_BARCODE_FAILURE';

export const BULK_UPLOAD_INVENTORY_SITES_REQUEST = 'BULK_UPLOAD_INVENTORY_SITES_REQUEST';
export const BULK_UPLOAD_INVENTORY_SITES_SUCCESS = 'BULK_UPLOAD_INVENTORY_SITES_SUCCESS';
export const BULK_UPLOAD_INVENTORY_SITES_FAILURE = 'BULK_UPLOAD_INVENTORY_SITES_FAILURE';

export const BULK_UPLOAD_INVENTORY_ASSETS_REQUEST = 'BULK_UPLOAD_INVENTORY_ASSETS_REQUEST';
export const BULK_UPLOAD_INVENTORY_ASSETS_SUCCESS = 'BULK_UPLOAD_INVENTORY_ASSETS_SUCCESS';
export const BULK_UPLOAD_INVENTORY_ASSETS_FAILURE = 'BULK_UPLOAD_INVENTORY_ASSETS_FAILURE';

export const BULK_UPLOAD_INVENTORY_SPARE_ASSETS_REQUEST = 'BULK_UPLOAD_INVENTORY_SPARE_ASSETS_REQUEST';
export const BULK_UPLOAD_INVENTORY_SPARE_ASSETS_SUCCESS = 'BULK_UPLOAD_INVENTORY_SPARE_ASSETS_SUCCESS';
export const BULK_UPLOAD_INVENTORY_SPARE_ASSETS_FAILURE = 'BULK_UPLOAD_INVENTORY_SPARE_ASSETS_FAILURE';

export const GET_WAREHOUSE_OWNER_DROPDOWN_REQUEST = 'GET_WAREHOUSE_OWNER_DROPDOWN_REQUEST';
export const GET_WAREHOUSE_OWNER_DROPDOWN_SUCCESS = 'GET_WAREHOUSE_OWNER_DROPDOWN_SUCCESS';
export const GET_WAREHOUSE_OWNER_DROPDOWN_FAILURE = 'GET_WAREHOUSE_OWNER_DROPDOWN_FAILURE';

export const FIND_ALL_WAREHOUSES_REQUEST = 'FIND_ALL_WAREHOUSES_REQUEST';
export const FIND_ALL_WAREHOUSES_SUCCESS = 'FIND_ALL_WAREHOUSES_SUCCESS';
export const FIND_ALL_WAREHOUSES_FAILURE = 'FIND_ALL_WAREHOUSES_FAILURE';

export const ALL_REPAIR_CENTERS_DROPDOWN_REQUEST = 'ALL_REPAIR_CENTERS_DROPDOWN_REQUEST';
export const ALL_REPAIR_CENTERS_DROPDOWN_SUCCESS = 'ALL_REPAIR_CENTERS_DROPDOWN_SUCCESS';
export const ALL_REPAIR_CENTERS_DROPDOWN_FAILURE = 'ALL_REPAIR_CENTERS_DROPDOWN_FAILURE';

export const GET_DROPDOWN_ALL_SITES_REQUEST = 'GET_ALL_DROPDOWN_SITES_REQUEST';
export const GET_DROPDOWN_ALL_SITES_SUCCESS = 'GET_ALL_DROPDOWN_SITES_SUCCESS';
export const GET_DROPDOWN_ALL_SITES_FAILURE = 'GET_ALL_DROPDOWN_SITES_FAILURE';

export const GET_DROPDOWN_REPLACE_ALL_ASSETS_REQUEST = 'GET_DROPDOWN_REPLACE_ALL_ASSETS_REQUEST';
export const GET_DROPDOWN_REPLACE_ALL_ASSETS_SUCCESS = 'GET_DROPDOWN_REPLACE_ALL_ASSETS_SUCCESS';
export const GET_DROPDOWN_REPLACE_ALL_ASSETS_FAILURE = 'GET_DROPDOWN_REPLACE_ALL_ASSETS_FAILURE';

export const REMOVE_ALL_SPARE_ASSET_DATA_FROM_STORE = 'REMOVE_ALL_SPARE_ASSET_DATA_FROM_STORE';
export const REMOVE_CITY_FROM_PINCODE_DATA_FROM_STORE = 'REMOVE_CITY_FROM_PINCODE_DATA_FROM_STORE';
export const REMOVE_SiTE_DATA_BY_ID_FROM_STORE = 'REMOVE_SiTE_DATA_BY_ID_FROM_STORE';

export const SET_INVENTORY_SIDEDRAWER = 'SET_INVENTORY_SIDEDRAWER';

export const FIND_WAREHOUSE_BY_ID_REQUEST = 'FIND_WAREHOUSE_BY_ID_REQUEST';
export const FIND_WAREHOUSE_BY_ID_SUCCESS = 'FIND_WAREHOUSE_BY_ID_SUCCESS';
export const FIND_WAREHOUSE_BY_ID_FAILURE = 'FIND_WAREHOUSE_BY_ID_FAILURE';

export const GET_ASSETS_HISTORY = 'GET_ASSETS_HISTORY';
export const GET_ASSETS_HISTORY_SUCCESS = 'GET_ASSETS_HISTORY_SUCCESS';
export const GET_ASSETS_HISTORY_FAILURE = 'GET_ASSETS_HISTORY_FAILURE';

export const GET_SITES_FILTER_LIST_REQUEST = 'GET_SITES_FILTER_LIST_REQUEST';
export const GET_SITES_FILTER_LIST_SUCCESS = 'GET_SITES_FILTER_LIST_SUCCESS';
export const GET_SITES_FILTER_LIST_FAILURE = 'GET_SITES_FILTER_LIST_FAILURE';

export const GET_ASSETS_FILTER_LIST_REQUEST = 'GET_ASSETS_FILTER_LIST_REQUEST';
export const GET_ASSETS_FILTER_LIST_SUCCESS = 'GET_ASSETS_FILTER_LIST_SUCCESS';
export const GET_ASSETS_FILTER_LIST_FAILURE = 'GET_ASSETS_FILTER_LIST_FAILURE';

export const GET_SPAREASSETS_FILTER_LIST_REQUEST = 'GET_SPAREASSETS_FILTER_LIST_REQUEST';
export const GET_SPAREASSETS_FILTER_LIST_SUCCESS = 'GET_SPAREASSETS_FILTER_LIST_SUCCESS';
export const GET_SPAREASSETS_FILTER_LIST_FAILURE = 'GET_SPAREASSETS_FILTER_LIST_FAILURE';

export const GET_WAREHOUSE_FILTER_LIST_REQUEST = 'GET_WAREHOUSE_FILTER_LIST_REQUEST';
export const GET_WAREHOUSE_FILTER_LIST_SUCCESS = 'GET_WAREHOUSE_FILTER_LIST_SUCCESS';
export const GET_WAREHOUSE_FILTER_LIST_FAILURE = 'GET_WAREHOUSE_FILTER_LIST_FAILURE';

export const SET_INVENTORY_LIMIT_OR_PAGE = 'SET_INVENTORY_LIMIT_OR_PAGE';

export const SET_INVENTORY_SEARCH_QUERY = 'SET_INVENTORY_SEARCH_QUERY';

export const CLEAR_SITE_TABLE_DATA_ON_UNMOUNT = 'CLEAR_SITE_TABLE_DATA_ON_UNMOUNT';

export const SET_INVENTORY_FILTER_AND_PAGINATION_DATA = 'SET_INVENTORY_FILTER_AND_PAGINATION_DATA';
export const SET_SITE_ID_IN_STORE = 'SET_SITE_ID_IN_STORE';
export const SET_WAREHOUSE_ID_IN_STORE = 'SET_WAREHOUSE_ID_IN_STORE';
export const CLEAR_SITE_OR_WAREHOUSE_ID = 'CLEAR_SITE_OR_WAREHOUSE_ID';
export const SET_IS_ARCHIVE_SELECTED_IN_STORE = 'SET_IS_ARCHIVE_SELECTED_IN_STORE';

export const REFRESH_SITE_PAGE = 'REFRESH_SITE_PAGE';
export const REFRESH_ASSET_PAGE = 'REFRESH_ASSET_PAGE';
export const REFRESH_WAREHOUSE_PAGE = 'REFRESH_WAREHOUSE_PAGE';
export const REFRESH_SPAREASSET_PAGE = 'REFRESH_SPAREASSET_PAGE';

export const GET_PROPERTIES_OF_SITE_REQUEST = 'GET_PROPERTIES_OF_SITE_REQUEST';
export const GET_PROPERTIES_OF_SITE_SUCCESS = 'GET_PROPERTIES_OF_SITE_SUCCESS';
export const GET_PROPERTIES_OF_SITE_FAILURE = 'GET_PROPERTIES_OF_SITE_FAILURE';

export const GET_PROPERTIES_OF_WAREHOUSE_REQUEST = 'GET_PROPERTIES_OF_WAREHOUSE_REQUEST';
export const GET_PROPERTIES_OF_WAREHOUSE_SUCCESS = 'GET_PROPERTIES_OF_WAREHOUSE_SUCCESS';
export const GET_PROPERTIES_OF_WAREHOUSE_FAILURE = 'GET_PROPERTIES_OF_WAREHOUSE_FAILURE';

export const GET_SITES_FILTERED_DATA_REQUEST = 'GET_SITES_FILTERED_DATA_REQUEST';
export const GET_SITES_FILTERED_DATA_SUCCESS = 'GET_SITES_FILTERED_DATA_SUCCESS';
export const GET_SITES_FILTERED_DATA_FAILURE = 'GET_SITES_FILTERED_DATA_FAILURE';

export const GET_ASSETS_FILTERED_DATA_REQUEST = 'GET_ASSETS_FILTERED_DATA_REQUEST';
export const GET_ASSETS_FILTERED_DATA_SUCCESS = 'GET_ASSETS_FILTERED_DATA_SUCCESS';
export const GET_ASSETS_FILTERED_DATA_FAILURE = 'GET_ASSETS_FILTERED_DATA_FAILURE';

export const GET_WAREHOUSE_FILTERED_DATA_REQUEST = 'GET_WAREHOUSE_FILTERED_DATA_REQUEST';
export const GET_WAREHOUSE_FILTERED_DATA_SUCCESS = 'GET_WAREHOUSE_FILTERED_DATA_SUCCESS';
export const GET_WAREHOUSE_FILTERED_DATA_FAILURE = 'GET_WAREHOUSE_FILTERED_DATA_FAILURE';

export const GET_SPAREASSET_FILTERED_DATA_REQUEST = 'GET_SPAREASSET_FILTERED_DATA_REQUEST';
export const GET_SPAREASSET_FILTERED_DATA_SUCCESS = 'GET_SPAREASSET_FILTERED_DATA_SUCCESS';
export const GET_SPAREASSET_FILTERED_DATA_FAILURE = 'GET_SPAREASSET_FILTERED_DATA_FAILURE';

export const LINK_RECONCILE_INVENTORY_ASSETS_REQUEST = 'LINK_RECONCILE_INVENTORY_ASSETS_REQUEST';
export const LINK_RECONCILE_INVENTORY_ASSETS_SUCCESS = 'LINK_RECONCILE_INVENTORY_ASSETS_SUCCESS';
export const LINK_RECONCILE_INVENTORY_ASSETS_FAILURE = 'LINK_RECONCILE_INVENTORY_ASSETS_FAILURE';

export const GET_SPAREASSET_WITH_ASSET_ID_REQUEST = 'GET_SPAREASSET_WITH_ASSET_ID_REQUEST';
export const GET_SPAREASSET_WITH_ASSET_ID_SUCCESS = 'GET_SPAREASSET_WITH_ASSET_ID_SUCCESS';
export const GET_SPAREASSET_WITH_ASSET_ID_FAILURE = 'GET_SPAREASSET_WITH_ASSET_ID_FAILURE';

export const CLEAR_SPARE_ASSET_DETAIL = 'CLEAR_SPARE_ASSET_DETAIL';

export const GET_SITE_LIST_FROM_PINCODE_REQUEST = 'GET_SITE_LIST_FROM_PINCODE_REQUEST';
export const GET_SITE_LIST_FROM_PINCODE_SUCCESS = 'GET_SITE_LIST_FROM_PINCODE_SUCCESS';
export const GET_SITE_LIST_FROM_PINCODE_FAILURE = 'GET_SITE_LIST_FROM_PINCODE_FAILURE';
export const REMOVE_CREATED_SITE_FROM_STORE = 'REMOVE_CREATED_SITE_FROM_STORE';

export const GET_ASSET_WITH_SERIAL_NUMBER_REQUEST = 'GET_ASSET_WITH_SERIAL_NUMBER_REQUEST';
export const GET_ASSET_WITH_SERIAL_NUMBER_SUCCESS = 'GET_ASSET_WITH_SERIAL_NUMBER_SUCCESS';
export const GET_ASSET_WITH_SERIAL_NUMBER_FAILURE = 'GET_ASSET_WITH_SERIAL_NUMBER_FAILURE';
export const REMOVE_ASSET_FROM_STORE = 'REMOVE_ASSET_FROM_STORE';
export const REMOVE_ASSET_NOT_FOUND_FROM_STORE = 'REMOVE_ASSET_NOT_FOUND_FROM_STORE';

export const SEARCH_SITE_REQUEST = 'SEARCH_SITE_REQUEST';
export const SEARCH_SITE_SUCCESS = 'SEARCH_SITE_SUCCESS';
export const SEARCH_SITE_FAILURE = 'SEARCH_SITE_FAILURE';
export const ADD_SITE_DROPDOWN_LIST = 'ADD_SITE_DROPDOWN_LIST';
export const CLEAR_ASSETS_AND_SPARES_LISTS = 'CLEAR_ASSETS_AND_SPARES_LISTS';
export const ADD_NEW_END_USER_IN_LIST = 'ADD_NEW_END_USER_IN_LIST';

export const CLEAR_SITES_DROPDOWN = 'CLEAR_SITES_DROPDOWN';
export const REMOVE_ADDED_ASSETS_TYPE_FROM_STORE = 'REMOVE_ADDED_ASSETS_TYPE_FROM_STORE';




export const EDIT_ASSET_DATE_REQUEST = 'EDIT_ASSET_DATE_REQUEST';
export const EDIT_ASSET_DATE_SUCCESS = 'EDIT_ASSET_DATE_SUCCESS';
export const EDIT_ASSET_DATE_FAILURE = 'EDIT_ASSET_DATE_FAILURE';

export const REMOVE_ENDUSER_LIST_FROM_STORE = 'REMOVE_ENDUSER_LIST_FROM_STORE';

export const GET_ASSET_TAG_DETAILS_REQUEST = 'GET_ASSET_TAG_DETAILS_REQUEST';
export const GET_ASSET_TAG_DETAILS_SUCCESS = 'GET_ASSET_TAG_DETAILS_SUCCESS';
export const GET_ASSET_TAG_DETAILS_FAILURE = 'GET_ASSET_TAG_DETAILS_FAILURE';

export const EDIT_ASSET_UPLOAD_ATTACHMENT_REQUEST = 'EDIT_ASSET_UPLOAD_ATTACHMENT_REQUEST';
export const EDIT_ASSET_UPLOAD_ATTACHMENT_SUCCESS = 'EDIT_ASSET_UPLOAD_ATTACHMENT_SUCCESS';
export const EDIT_ASSET_UPLOAD_ATTACHMENT_FAILURE = 'EDIT_ASSET_UPLOAD_ATTACHMENT_FAILURE';

export const EDIT_SPARE_ASSET_UPLOAD_ATTACHMENT_SUCCESS = 'EDIT_SPARE_ASSET_UPLOAD_ATTACHMENT_SUCCESS';

export const GET_SITE_POS_LIST_REQUEST = 'GET_SITE_POS_LIST_REQUEST';
export const GET_SITE_POS_LIST_SUCCESS = 'GET_SITE_POS_LIST_SUCCESS';
export const GET_SITE_POS_LIST_FAILURE = 'GET_SITE_POS_LIST_FAILURE';
export const GET_ENDUSER_BY_SITE_POS_LIST_SUCCESS = 'GET_ENDUSER_BY_SITE_POS_LIST_SUCCESS';
export const GET_SCANNED_ASSET_SUCCESS = 'GET_SCANNED_ASSET_SUCCESS';
export const GET_WALLET_PAYMENTS_STATUS_REQUEST = 'GET_WALLET_PAYMENTS_STATUS_REQUEST';

export const GET_WALLET_DETAILS_REQUEST = 'GET_WALLET_DETAILS_REQUEST';
export const GET_WALLET_DETAILS_SUCCESS = 'GET_WALLET_DETAILS_SUCCESS';
export const GET_WALLET_DETAILS_FAILURE = 'GET_WALLET_DETAILS_FAILURE';

export const WITHDRAW_WALLET_AMOUNT_REQUEST = 'WITHDRAW_WALLET_AMOUNT_REQUEST';
export const WITHDRAW_WALLET_AMOUNT_SUCCESS = 'WITHDRAW_WALLET_AMOUNT_SUCCESS';
export const WITHDRAW_WALLET_AMOUNT_FAILURE = 'WITHDRAW_WALLET_AMOUNT_FAILURE';

export const GET_PROMISED_AMOUNT_REQUEST = 'GET_PROMISED_AMOUNT_REQUEST';
export const GET_PROMISED_AMOUNT_SUCCESS = 'GET_PROMISED_AMOUNT_SUCCESS';
export const GET_PROMISED_AMOUNT_FAILURE = 'GET_PROMISED_AMOUNT_FAILURE';

export const GET_WALLET_TRANSACTIONS_REQUEST = 'GET_WALLET_TRANSACTIONS_REQUEST';
export const GET_WALLET_TRANSACTIONS_SUCCESS = 'GET_WALLET_TRANSACTIONS_SUCCESS';
export const GET_WALLET_TRANSACTIONS_FAILURE = 'GET_WALLET_TRANSACTIONS_FAILURE';

export const GET_WALLET_NEXT_TRANSACTIONS_REQUEST = 'GET_WALLET_NEXT_TRANSACTIONS_REQUEST';
export const GET_WALLET_NEXT_TRANSACTIONS_SUCCESS = 'GET_WALLET_NEXT_TRANSACTIONS_SUCCESS';
export const GET_WALLET_NEXT_TRANSACTIONS_FAILURE = 'GET_WALLET_NEXT_TRANSACTIONS_FAILURE';

export const ADD_AMOUNT_TO_WALLET_REQUEST = 'ADD_AMOUNT_TO_WALLET_REQUEST';
export const ADD_AMOUNT_TO_WALLET_SUCCESS = 'ADD_AMOUNT_TO_WALLET_SUCCESS';
export const ADD_AMOUNT_TO_WALLET_FAILURE = 'ADD_AMOUNT_TO_WALLET_FAILURE';

export const PAY_PO_REQUEST = 'PAY_PO_REQUEST';
export const PAY_PO_SUCCESS = 'PAY_PO_SUCCESS';
export const PAY_PO_FAILURE = 'PAY_PO_FAILURE';

export const PAY_PO_MANUALLY_REQUEST = 'PAY_PO_MANUALLY_REQUEST';
export const PAY_PO_MANUALLY_SUCCESS = 'PAY_PO_MANUALLY_SUCCESS';
export const PAY_PO_MANUALLY_FAILURE = 'PAY_PO_MANUALLY_FAILURE';

export const REQUEST_STATEMENT_REQUEST = 'REQUEST_STATEMENT_REQUEST';
export const REQUEST_STATEMENT_SUCCESS = 'REQUEST_STATEMENT_SUCCESS';
export const REQUEST_STATEMENT_FAILURE = 'REQUEST_STATEMENT_FAILURE';

export const GET_RECEPIENTS_FILTERED_DATA_REQUEST = 'GET_RECEPIENTS_FILTERED_DATA_REQUEST';
export const GET_RECEPIENTS_FILTERED_DATA_SUCCESS = 'GET_RECEPIENTS_FILTERED_DATA_SUCCESS';
export const GET_RECEPIENTS_FILTERED_DATA_FAILURE = 'GET_RECEPIENTS_FILTERED_DATA_FAILURE';

export const UPDATE_WALLET_AFTER_PAYMENT_GATEWAY_SUCCESS_REQUEST = 'UPDATE_WALLET_AFTER_PAYMENT_GATEWAY_SUCCESS_REQUEST';
export const UPDATE_WALLET_AFTER_PAYMENT_GATEWAY_SUCCESS_SUCCESS = 'UPDATE_WALLET_AFTER_PAYMENT_GATEWAY_SUCCESS_SUCCESS';
export const UPDATE_WALLET_AFTER_PAYMENT_GATEWAY_SUCCESS_FAILURE = 'UPDATE_WALLET_AFTER_PAYMENT_GATEWAY_SUCCESS_FAILURE';

export const CLEAR_ONGOING_TRANSACTIONS_FROM_STORE = 'CLEAR_ONGOING_TRANSACTIONS_FROM_STORE';

export const APPROVE_WALLET_PAYMENT_REQUEST = 'APPROVE_WALLET_PAYMENT_REQUEST';
export const APPROVE_WALLET_PAYMENT_SUCCESS = 'APPROVE_WALLET_PAYMENT_SUCCESS';
export const APPROVE_WALLET_PAYMENT_FAILURE = 'APPROVE_WALLET_PAYMENT_FAILURE';

export const GET_WALLET_TRANSACTIONS_DETAILS_REQUEST = 'GET_WALLET_TRANSACTIONS_DETAILS_REQUEST';
export const GET_WALLET_TRANSACTIONS_DETAILS_SUCCESS = 'GET_WALLET_TRANSACTIONS_DETAILS_SUCCESS';
export const GET_WALLET_TRANSACTIONS_DETAILS_FAILURE = 'GET_WALLET_TRANSACTIONS_DETAILS_FAILURE';

export const SET_IS_WITHDRAWAL_AMOUNT_CONFIRMED = 'SET_IS_WITHDRAWAL_AMOUNT_CONFIRMED';

export const WITHDRAW_WALLET_AMOUNT_WEBHOOK_SUCCESS = 'WITHDRAW_WALLET_AMOUNT_WEBHOOK_SUCCESS';

export const PAY_SECURITY_DEPOSIT_REQUEST = 'PAY_SECURITY_DEPOSIT_REQUEST';
export const PAY_SECURITY_DEPOSIT_SUCCESS = 'PAY_SECURITY_DEPOSIT_SUCCESS';
export const PAY_SECURITY_DEPOSIT_FAILURE = 'PAY_SECURITY_DEPOSIT_FAILURE';

export const CLEAR_ONGOING_SECURITY_DEPOSIT_TRANSACTIONS_FROM_STORE = 'CLEAR_ONGOING_SECURITY_DEPOSIT_TRANSACTIONS_FROM_STORE';

export const GET_WALLET_DETAILS_BY_ID_REQUEST = 'GET_WALLET_DETAILS_BY_ID_REQUEST';
export const GET_WALLET_DETAILS_BY_ID_SUCCESS = 'GET_WALLET_DETAILS_BY_ID_SUCCESS';
export const GET_WALLET_DETAILS_BY_ID_FAILURE = 'GET_WALLET_DETAILS_BY_ID_FAILURE';
export const REMOVE_WALLET_DETAILS_FROM_STORE = 'REMOVE_WALLET_DETAILS_FROM_STORE';

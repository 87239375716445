import React, { useCallback } from 'react';
import { DateComponent } from '../../../common/TableReusableColumns';
import { getTimeDuration, formatSlaTime, validateDateValue } from '../../../utils/common';
import { useDispatch, useSelector } from 'react-redux';
import { openCustomModal } from '../../../actions/modal';
import HoldTicket from '../Modal/HoldTicket';
import ResumeTicket from '../Modal/ResumeTicket';
import IconToolTip from '../../../common/IconToolTip';
import HardCloseTicket from '../Modal/HardCloseTicket';
import UpdateSignOffNotes from '../Modal/updateSignOffNote';
import { isShoHardCloseIcon, isToShowHoldIcon, isToShowResumeIcon, progressStatusList } from '../TicketController';
import StatusLabel from '../../Payments/reusableComponents/StatusLabel';
import moment from 'moment';
import { isFe, isSp } from '../../../utils/role';

export const RenderRemark = ({ ticket, onClick }) => {
    const { signOffRequest, job_sign_off_notes, pm_rejection_message, signOffReject } = ticket;
    const { loggedUser } = useSelector((state) => state.auth)
    const role = loggedUser.role.id
    const type = loggedUser.type

    let hideEditButton = false;
    let remark = '';
    let color = '';
    if (signOffRequest === 1) {
        remark = job_sign_off_notes;
        color = 'scogosuccess';
    } else if (signOffReject === 1) {
        remark = pm_rejection_message;
        hideEditButton = true;
        color = 'scogoclosed';
    }

    return (
        <div className='flex gap-x-3 w-full'>
            {remark && (
                <div className='max-w-8/8'>
                    <StatusLabel titleText='Signoff Remark' content={remark} color={color} />
                </div>
            )}
            {!hideEditButton && (!isSp(role, type) && !isFe(role, type)) && (
                <IconToolTip title='Edit Signoff Remark'>
                    <span className='material-icons cursor-pointer font-medium rounded-full text-font13 p-1 bg-scogobgsky text-white ' onClick={onClick}>
                        edit
                    </span>
                </IconToolTip>
            )}
        </div>
    );
};

export const getResolutionTime = (ticket) => {
    let resolution_time = ``;
    let startDate = validateDateValue(ticket?.created_at) || validateDateValue(ticket?.execution_date) || validateDateValue(ticket?.planned_date)
    let endDate = validateDateValue(ticket?.site_completed_on) || validateDateValue(ticket?.job_sign_off_date);

    if (startDate && endDate) {
        resolution_time = getTimeDuration(startDate, endDate);
    }
    return resolution_time;
};

const getTicketBroadCastSince = (broadcast_timestamp) => {
    let date = moment(broadcast_timestamp);
    let text = ''
    if (moment().isSame(date, 'day')) {
        let hours = moment(new Date()).diff(date, 'hours');
        text = `${hours} hrs ago`;
    } else {
        let days = moment(new Date()).diff(date, 'days');
        text = `${days} days ago`;
    }
    return text
}

export const ProgressBar = React.memo(({ ticket }) => {
    const { loggedUser } = useSelector((state) => state.auth);
    const role = loggedUser.role.id;
    const type = loggedUser.type;

    const dispatch = useDispatch();

    const updateSignOffNotesModal = () => {
        let heading = <span className='text-black'>Update SignOff Notes </span>;
        let modalComponent = <UpdateSignOffNotes ticket={ticket} />;
        let modalHeight = 'auto',
            modalWidth = '50rem';
        dispatch(openCustomModal({ modalComponent, heading, modalHeight, modalWidth }));
    };

    let daysLeft = getResolutionTime(ticket);

    const progressStatus = progressStatusList.find((progress) => progress.check(ticket, { role, type }));
    let statusComplete = 20;
    let statusColor = '';
    let statusContent = '';
    let progressColor = `bg-scogoclosed`;
    if (progressStatus) {
        if (typeof progressStatus.statusComplete === 'function') {
            statusComplete = progressStatus.statusComplete(ticket);
        } else statusComplete = progressStatus.statusComplete;
    }

    if (statusComplete >= 20) progressColor = 'bg-scogoorange';
    if (statusComplete === 100 || ticket.closedByNoc > 0) progressColor = 'bg-scogogoing';
    if (ticket.isOnHold !== 0 || ticket.isEscalated !== 0) progressColor = `bg-scogoclosed`;

    if (progressStatus) {
        if (typeof progressStatus.color === 'function') {
            statusColor = progressStatus.color(ticket);
        } else statusColor = progressStatus.color;
    }

    if (progressStatus) {
        if (typeof progressStatus.label === 'function') {
            statusContent = progressStatus.label(ticket, { onClick: updateSignOffNotesModal });
        } else statusContent = progressStatus.label;
    }

    let slaTime = '';
    if (ticket.sla_details) {
        slaTime = `${ticket.sla_details.resolve} ${ticket.sla_details.resolve_unit}`;
    }

    let showPauseIcon = isToShowHoldIcon(ticket);
    let showPlayIcon = isToShowResumeIcon(ticket, { role, type });
    let showHardIcon = isShoHardCloseIcon(ticket, { role, type });

    const openHoldTicketModal = () => {
        let heading = <span className='text-black'>Hold Ticket</span>;
        let modalComponent = <HoldTicket ticket={ticket} isBulk={false} />;
        let modalHeight = 'auto',
            modalWidth = '60rem';
        dispatch(openCustomModal({ modalComponent, heading, modalHeight, modalWidth }));
    };
    const openResumeTicketModal = () => {
        let heading = <span className='text-black'>Resume Ticket</span>;
        let modalComponent = <ResumeTicket ticket={ticket} />;
        let modalHeight = 'auto',
            modalWidth = '50rem';
        dispatch(openCustomModal({ modalComponent, heading, modalHeight, modalWidth }));
    };

    const hardCloseTicketModal = () => {
        let heading = <span className='text-black'>Hard Close Ticket</span>;
        let modalComponent = <HardCloseTicket ticket={ticket} />;
        let modalHeight = 'auto',
            modalWidth = '50rem';
        dispatch(openCustomModal({ modalComponent, heading, modalHeight, modalWidth }));
    };

    let startDate = validateDateValue(ticket?.created_at) || validateDateValue(ticket?.execution_date) || validateDateValue(ticket?.planned_date)
    let endDate = validateDateValue(ticket?.site_completed_on) || validateDateValue(ticket?.job_sign_off_date);

    const broadCastTime = validateDateValue(ticket.broadcast_timestamp) && getTicketBroadCastSince(ticket.broadcast_timestamp);

    return (
        <div className='w-full text-font11'>
            <p className='td_p_light_primary md:mt-2 mt-0 md:mr-0 mr-2 md:hidden block'>Progress :</p>
            <div>
                <p className='flex items-center gap-1'>
                    <span className='text-scogogray font-medium'> Created: </span> <DateComponent date={ticket.created_at} timeFormat='DD MMM YYYY' />
                </p>
            </div>
            <div className='flex mt-1 justify-between items-end '>
                <div className='flex gap-2 items-center'>
                    {startDate && moment(startDate).isValid() && <DateComponent date={startDate} timeFormat='DD MMM YYYY' />}
                    {broadCastTime && <IconToolTip title='Broadcast Time'>
                        <span className='text-font09 text-scogogray flex items-start'>
                            (BR - {broadCastTime})
                        </span>
                    </IconToolTip>}
                </div>
                <div>
                    <p className='td_p_light mx-auto'>{daysLeft}</p>
                </div>
                <div className=''>{endDate && moment(endDate).isValid() && <DateComponent date={endDate} timeFormat='DD MMM YYYY' />}</div>
            </div>
            <div className='bg-scogoeee w-full relative h-2.5 rounded-md mt-1'>
                <div style={{ width: `${statusComplete}%` }} className={`${progressColor} absolute top-0 h-2.5 z-0  rounded-md`}></div>
            </div>

            <div className='mt-1 flex items-center w-full'>
                <div className='mr-2 w-full'>
                    <div className='w-full flex items-center'>
                        <div className='w-4/5 pr-2 pt-2 flex gap-x-2 mb-1'>
                            {showPauseIcon && (
                                <IconToolTip title='Hold Ticket'>
                                    <span
                                        className='material-icons cursor-pointer rounded-full text-font13 p-1 bg-scogoprimary text-white'
                                        onClick={() => {
                                            openHoldTicketModal();
                                        }}
                                    >
                                        pause
                                    </span>
                                </IconToolTip>
                            )}
                            {showPlayIcon && (
                                <IconToolTip title='Resume Ticket'>
                                    <span
                                        className='material-icons cursor-pointer rounded-full text-font13 p-1  bg-scogoorange text-white'
                                        onClick={() => {
                                            openResumeTicketModal();
                                        }}
                                    >
                                        play_arrow
                                    </span>
                                </IconToolTip>
                            )}

                            {showHardIcon && (
                                <IconToolTip title='Hard Close'>
                                    <span
                                        className='material-icons cursor-pointer font-medium rounded-full text-font13 p-1 bg-scogoclosed text-white'
                                        onClick={() => {
                                            hardCloseTicketModal();
                                        }}
                                    >
                                        not_interested
                                    </span>
                                </IconToolTip>
                            )}

                            {progressStatus?.label &&
                                (typeof progressStatus.label === 'function' ? (
                                    progressStatus.label(ticket, { onClick: updateSignOffNotesModal })
                                ) : (
                                    <div className='truncate'>
                                        <StatusLabel titleText='Status' content={statusContent} color={statusColor} />
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
            {ticket.project_sla ? <RenderSLA milliseconds={ticket.project_sla} /> : <></>}
        </div>
    );
});



const RenderSLA = ({ milliseconds }) => {
    return <div className='flex'>
        <p className='td_p_dark'>
            <span className='td_p_light'> SLA: </span> {`${formatSlaTime(milliseconds)}`}
        </p>
    </div>
}
import _groupBy from 'lodash/groupBy';
import { getLookupWitHDefaultValue, isValidJSON } from '../../../../utils/common';
import { paymentMode, additionalPaymentStatus, requestRaised, taxDeductionPercentage } from '../../PaymentController';
import { v4 as uuidv4 } from 'uuid';
import { validateDateValue } from '../../../../utils/common';
import moment from 'moment';

export const remarkType = [
    { key: 1, value: `MATERIAL`, label: `Material` },
    { key: 2, value: `SERVICE`, label: `Services` },
    { key: 3, value: `COURIER`, label: `Courier` },
    { key: 4, value: `TRAVEL`, label: `Travel` },
    { key: 5, value: `INCENTIVE`, label: `Incentive` },
    { key: 6, value: `GST`, label: `Gst` },
];

export const statusLookup = getLookupWitHDefaultValue({
    lookup: {
        isApproved: { statusColor: 'scogo2e', statusContent: 'Approved' },
        isRejected: { statusColor: 'scogoclosed', statusContent: 'Rejected' },
        isScheduled: { statusColor: 'scogoAmber', statusContent: 'Scheduled' },
        isSignoffPending: { statusColor: 'scogoAmber', statusContent: 'Approval Pending' },
        isPendingProcessed: { statusColor: 'scogoorange', statusContent: 'Processed' },
        isRequested: { statusColor: 'scogobgsky', statusContent: 'Requested' },
    },
    defaultValue: { statusColor: '', statusContent: '' },
});

export const getGroupByAdditonalData = ({ ticketPayment, ticketDetails, setDisableApproveSignoffButton = () => { }, approvalPermission }) => {
    let result = [];
    if (Array.isArray(ticketPayment) && ticketPayment.length > 0 && ticketDetails) {
        const paymentGroups = _groupBy(ticketPayment, 'referenceId');
        Object.keys(paymentGroups).map((referenceId, index) => {
            const paymentDetails = paymentGroups[referenceId][0];
            const payments = paymentGroups[referenceId];
            if (Array.isArray(payments) && payments.length > 0) {
                const spPayment = paymentGroups[referenceId].find((payment) => payment.paymentMode === paymentMode.partner && payment.fk_user_id === ticketDetails?.accepted_sp_id);
                const customerPayment = paymentGroups[referenceId].find(
                    (payment) => payment.paymentMode === paymentMode.customer && payment.fk_user_id === ticketDetails?.customer_details?.fk_user_id
                );
                const clusterPayment = paymentGroups[referenceId].find(
                    (payment) => payment.paymentMode === paymentMode.cluster && payment.fk_user_id === ticketDetails?.cluster_id
                );
                const pspPayment = paymentGroups[referenceId].find((payment) => payment.paymentMode === paymentMode.psp && payment.fk_user_id === ticketDetails?.psp_id);
                const supervisorPayment = paymentGroups[referenceId].find(
                    (payment) => payment.paymentMode === paymentMode.supervisor && payment.fk_user_id === ticketDetails?.supervisor_id
                );
                const warehousePayment = paymentGroups[referenceId].find(
                    (payment) => payment.paymentMode === paymentMode.warehouseOwner && payment.fk_user_id === ticketDetails?.warehouse_owner
                );
                const paymentsData = paymentGroups[referenceId].find((payment) => payment);
                const isPendingProcessed = paymentGroups[referenceId].some(
                    (payment) => payment.status === additionalPaymentStatus.pending && !['', undefined, null].includes(payment.processed_at)
                );
                const isPending = paymentGroups[referenceId].some((payment) => payment.status === additionalPaymentStatus.pending);
                const isScheduled = paymentGroups[referenceId].some((payment) => payment.status === additionalPaymentStatus.scheduled);
                const isApproved = paymentGroups[referenceId].every((payment) => payment.status === additionalPaymentStatus.success);
                const isRejected = paymentGroups[referenceId].some((payment) => payment.status === additionalPaymentStatus.failure);
                let additionalData = {
                    type: 'Additional Payment',
                    isEditable: false,
                    proof: '',
                    customer_details: 0,
                    partner_details: 0,
                    cluster_details: 0,
                    psp_details: 0,
                    supervisor_details: 0,
                    warehouse_details: 0,
                    customer_beneficiary_transaction_id: 0,
                    partner_beneficiary_transaction_id: 0,
                    cluster_beneficiary_transaction_id: 0,
                    psp_beneficiary_transaction_id: 0,
                    supervisor_beneficiary_transaction_id: 0,
                    warehouse_owner_beneficiary_transaction_id: 0,
                };

                if (spPayment) {
                    additionalData.spPayment = spPayment;
                }

                additionalData.referenceId = referenceId;

                if (paymentsData?.reason) {
                    let category;
                    remarkType.map((remark) => {
                        if (remark.value === paymentsData?.reason) category = remark;
                    });
                    additionalData.category = category;
                }

                let partnerAdditionalExpenses = getPartnerAdditionalExpenses({ ticketDetails });
                if (partnerAdditionalExpenses) {
                    additionalData.partnerAdditionalExpenses = partnerAdditionalExpenses;
                }

                if (customerPayment && [additionalPaymentStatus.pending, additionalPaymentStatus.scheduled, additionalPaymentStatus.success].includes(customerPayment.status)) {
                    additionalData.customer_beneficiary_transaction_id = customerPayment.id;
                    if (customerPayment.sender === ticketDetails?.customer_details.fk_user_id) {
                        additionalData.customer_details = customerPayment.orderAmount;
                    } else if (customerPayment.recepient === ticketDetails?.customer_details.fk_user_id) {
                        additionalData.customer_details = parseFloat(`-${customerPayment.orderAmount}`);
                    } else if (customerPayment.orderAmount && customerPayment.status === additionalPaymentStatus.success) {
                        additionalData.customer_details = customerPayment.orderAmount;
                    }
                }

                if (
                    spPayment &&
                    [additionalPaymentStatus.pending, additionalPaymentStatus.scheduled, additionalPaymentStatus.success, additionalPaymentStatus.failure].includes(
                        spPayment.status
                    )
                ) {
                    if (spPayment.status === additionalPaymentStatus.pending) setDisableApproveSignoffButton(true);
                    additionalData.partner_beneficiary_transaction_id = spPayment.id;
                    if (spPayment.recepient === ticketDetails?.accepted_sp_id) {
                        additionalData.partner_details = spPayment.orderAmount;
                    } else if (spPayment.recepient !== ticketDetails?.accepted_sp_id) {
                        additionalData.partner_details = parseFloat(`-${spPayment.orderAmount}`);
                    } else if (spPayment?.orderAmount && [additionalPaymentStatus.success, additionalPaymentStatus.failure].includes(spPayment.status)) {
                        additionalData.partner_details = spPayment.orderAmount;
                    }
                }
                if (
                    clusterPayment &&
                    [additionalPaymentStatus.pending, additionalPaymentStatus.scheduled, additionalPaymentStatus.success, additionalPaymentStatus.failure].includes(
                        clusterPayment.status
                    )
                ) {
                    if (clusterPayment.status === additionalPaymentStatus.pending) setDisableApproveSignoffButton(true);
                    additionalData.cluster_beneficiary_transaction_id = clusterPayment.id;
                    if (clusterPayment.recepient === ticketDetails?.cluster_id) {
                        additionalData.cluster_details = clusterPayment.orderAmount;
                    } else if (clusterPayment.recepient !== ticketDetails?.cluster_id) {
                        additionalData.cluster_details = parseFloat(`-${clusterPayment.orderAmount}`);
                    } else if (clusterPayment?.orderAmount && [additionalPaymentStatus.success, additionalPaymentStatus.failure].includes(clusterPayment.status)) {
                        additionalData.cluster_details = clusterPayment.orderAmount;
                    }
                }

                if (
                    pspPayment &&
                    [additionalPaymentStatus.pending, additionalPaymentStatus.scheduled, additionalPaymentStatus.success, additionalPaymentStatus.failure].includes(
                        pspPayment.status
                    )
                ) {
                    if (pspPayment.status === additionalPaymentStatus.pending) setDisableApproveSignoffButton(true);
                    additionalData.psp_beneficiary_transaction_id = pspPayment.id;
                    if (pspPayment.recepient === ticketDetails?.psp_id) {
                        additionalData.psp_details = pspPayment.orderAmount;
                    } else if (pspPayment.recepient !== ticketDetails?.psp_id) {
                        additionalData.psp_details = parseFloat(`-${pspPayment.orderAmount}`);
                    } else if (pspPayment?.orderAmount && [additionalPaymentStatus.success, additionalPaymentStatus.failure].includes(pspPayment.status)) {
                        additionalData.psp_details = pspPayment.orderAmount;
                    }
                }

                if (
                    supervisorPayment &&
                    [additionalPaymentStatus.pending, additionalPaymentStatus.scheduled, additionalPaymentStatus.success, additionalPaymentStatus.failure].includes(
                        supervisorPayment.status
                    )
                ) {
                    if (supervisorPayment.status === additionalPaymentStatus.pending) setDisableApproveSignoffButton(true);
                    additionalData.supervisor_beneficiary_transaction_id = supervisorPayment.id;
                    if (supervisorPayment.recepient === ticketDetails?.supervisor_id) {
                        additionalData.supervisor_details = supervisorPayment.orderAmount;
                    } else if (supervisorPayment.recepient !== ticketDetails?.supervisor_id) {
                        additionalData.supervisor_details = parseFloat(`-${supervisorPayment.orderAmount}`);
                    } else if (supervisorPayment?.orderAmount && [additionalPaymentStatus.success, additionalPaymentStatus.failure].includes(supervisorPayment.status)) {
                        additionalData.supervisor_details = supervisorPayment.orderAmount;
                    }
                }

                if (
                    warehousePayment &&
                    [additionalPaymentStatus.pending, additionalPaymentStatus.scheduled, additionalPaymentStatus.success, additionalPaymentStatus.failure].includes(
                        warehousePayment.status
                    )
                ) {
                    if (warehousePayment.status === additionalPaymentStatus.pending) setDisableApproveSignoffButton(true);
                    additionalData.warehouse_owner_beneficiary_transaction_id = warehousePayment.id;
                    if (warehousePayment.recepient === ticketDetails?.warehouse_owner) {
                        additionalData.warehouse_details = warehousePayment.orderAmount;
                    } else if (warehousePayment.recepient !== ticketDetails?.warehouse_owner) {
                        additionalData.warehouse_details = parseFloat(`-${warehousePayment.orderAmount}`);
                    } else if (warehousePayment?.orderAmount && [additionalPaymentStatus.success, additionalPaymentStatus.failure].includes(warehousePayment.status)) {
                        additionalData.warehouse_details = warehousePayment.orderAmount;
                    }
                }
                if (paymentDetails.isNew) {
                    setDisableApproveSignoffButton(true);
                }
                if (paymentDetails?.processedByDetails) {
                    additionalData.processedByDetails = paymentDetails?.processedByDetails;
                }
                if (paymentDetails?.createdBy) {
                    additionalData.createdBy = paymentDetails?.createdBy;
                }
                if (paymentDetails?.processed_at) {
                    additionalData.processed_at = paymentDetails?.processed_at;
                }

                if (paymentsData.paymentLink) {
                    additionalData.proof = paymentDetails.paymentLink;
                }

                if (paymentsData.txMsg) {
                    additionalData.remark = paymentsData.txMsg;
                }
                if (paymentsData.scheduled_on) {
                    let scheduled_on = new Date(paymentsData.scheduled_on);
                    additionalData.scheduled_on = scheduled_on;
                }

                if (isPending && !approvalPermission) {
                    additionalData.status = 'isRequested';
                }

                if (!isPendingProcessed && isPending && approvalPermission) {
                    additionalData.status = 'isPendingProcessed';
                }
                if (isApproved) {
                    additionalData.status = 'isApproved';
                }
                if (isRejected) {
                    additionalData.status = 'isRejected';
                }
                if (isScheduled) {
                    additionalData.status = 'isScheduled';
                }

                if (paymentDetails?.createdBy) {
                    additionalData.createdBy = paymentDetails.createdBy;
                }
                additionalData.processed_remark = paymentDetails.processed_remark;
                if (ticketDetails?.additional_user_payments && isValidJSON(ticketDetails?.additional_user_payments)) {
                    const additional_users = JSON.parse(ticketDetails.additional_user_payments).filter(u => u.id !== ticketDetails.accepted_sp_id);
                    additionalData.custom_amounts = Object.assign({}, ...additional_users.filter(u => {
                        return paymentGroups[referenceId].find((payment) => payment.paymentMode === paymentMode.partner && payment.fk_user_id === u.id);
                    }).map(u => {
                        const uPayment = paymentGroups[referenceId].find((payment) => payment.paymentMode === paymentMode.partner && payment.fk_user_id === u.id);
                        const entry = {}
                        if (uPayment.status === additionalPaymentStatus.pending) setDisableApproveSignoffButton(true);
                        entry.beneficiary_transaction_id = uPayment.id;
                        if (uPayment.recepient === u.id) {
                            entry.amount = uPayment.orderAmount;
                        } else if (uPayment.recepient !== u.id) {
                            entry.amount = parseFloat(`-${uPayment.orderAmount}`);
                        } else if (uPayment?.orderAmount && [additionalPaymentStatus.success, additionalPaymentStatus.failure].includes(uPayment.status)) {
                            entry.amount = uPayment.orderAmount;
                        }
                        return {
                            [u.id]: entry
                        }
                    }))
                }
                if (ticketDetails.accepted_sp_id) {
                    additionalData.partner_id = ticketDetails.accepted_sp_id;
                }
                if (ticketDetails.cl_details) {
                    additionalData.cluster_id = ticketDetails.cl_details.id;
                }
                if (ticketDetails.supervisor_details) {
                    additionalData.supervisor_id = ticketDetails.supervisor_details.id;
                }
                if (ticketDetails.psp_details) {
                    additionalData.psp_id = ticketDetails.psp_details.id;
                }
                if (ticketDetails.warehouse_owner) {
                    additionalData.warehouse_owner = ticketDetails.warehouse_owner;
                }
                result.push(additionalData);
            }
        });
    }

    return result;
};

const getBasePaymentStatus = ({ ticketDetails }) => {
    let data = 'isScheduled';
    if (ticketDetails?.is_signoff_accept === 0) {
        data = 'isSignoffPending';
    } else if (ticketDetails?.is_signoff_accept === 1 && ticketDetails?.payment_transaction && ticketDetails?.payment_transaction?.is_request_raised === requestRaised.success) {
        data = 'isApproved';
    } else if (ticketDetails?.payment_transaction?.is_request_raised === requestRaised.rejected) {
        data = 'isRejected';
    }
    return data;
};

const getCustomerBasePrice = ({ ticketDetails }) => {
    if (ticketDetails?.customer_base_price > 0) return ticketDetails?.customer_base_price;
    else return 0;
};

const getPartnerAdditionalExpenses = ({ ticketDetails }) => {
    if (ticketDetails?.additional_expenses > 0) return ticketDetails?.additional_expenses;
    return 0;
};

const getPspBaseIncentive = ({ ticketDetails }) => {
    if (ticketDetails?.psp_id === 0 && ticketDetails?.psp_base_payment > 0) return ticketDetails?.psp_base_payment;
    return 0;
};

const getPartnerBasePrice = ({ ticketDetails }) => {
    if (ticketDetails?.payment_transaction) {
        return ticketDetails.payment_transaction.amount;
    }
    let pspBaseIncentive = getPspBaseIncentive({ ticketDetails });
    let partnerAdditionalExpenses = getPartnerAdditionalExpenses({ ticketDetails });
    const is_pancard_linked_with_aadhaar = ticketDetails?.accepted_s_p_company_detail?.is_pancard_linked_with_aadhaar;
    if (
        (!['', undefined, null].includes(ticketDetails?.partner_base_payment) && ticketDetails?.partner_base_payment > 0) ||
        pspBaseIncentive > 0 ||
        partnerAdditionalExpenses > 0
    ) {
        if (ticketDetails?.accepted_s_p_company_detail) {
            if (ticketDetails?.accepted_s_p_company_detail && ticketDetails?.accepted_s_p_company_detail.employee_type === 'EMP') {
                return (ticketDetails?.partner_base_payment + pspBaseIncentive + partnerAdditionalExpenses) * ticketDetails?.accepted_s_p_company_detail.payment_ratio;
            } else {
                return (
                    (ticketDetails?.partner_base_payment +
                        pspBaseIncentive +
                        partnerAdditionalExpenses -
                        (ticketDetails?.partner_base_payment + pspBaseIncentive + partnerAdditionalExpenses) * taxDeductionPercentage({ is_pancard_linked_with_aadhaar })) *
                    ticketDetails?.accepted_s_p_company_detail.payment_ratio
                );
            }
        } else
            return (
                ticketDetails?.partner_base_payment +
                pspBaseIncentive +
                partnerAdditionalExpenses -
                (ticketDetails?.partner_base_payment + pspBaseIncentive + partnerAdditionalExpenses) * taxDeductionPercentage({ is_pancard_linked_with_aadhaar })
            );
    }
    return 0;
};

const getClusterBasePrice = ({ ticketDetails }) => {
    const is_pancard_linked_with_aadhaar = ticketDetails?.cl_details?.is_pancard_linked_with_aadhaar;
    if (!['', undefined, null].includes(ticketDetails?.cluster_base_payment) && ticketDetails?.cluster_base_payment > 0) {
        if (ticketDetails?.cl_details && ticketDetails?.cl_details.employee_type === 'EMP') {
            return ticketDetails?.cluster_base_payment;
        } else {
            return ticketDetails?.cluster_base_payment - ticketDetails?.cluster_base_payment * taxDeductionPercentage({ is_pancard_linked_with_aadhaar });
        }
    }
    return 0;
};

const getPspBasePrice = ({ ticketDetails }) => {
    const is_pancard_linked_with_aadhaar = ticketDetails?.psp_details?.is_pancard_linked_with_aadhaar;
    if (!['', undefined, null].includes(ticketDetails?.psp_base_payment) && ticketDetails?.psp_base_payment > 0) {
        if (ticketDetails?.psp_details && ticketDetails?.psp_details.employee_type === 'EMP') {
            return ticketDetails?.psp_base_payment;
        } else {
            return ticketDetails?.psp_base_payment - ticketDetails?.psp_base_payment * taxDeductionPercentage({ is_pancard_linked_with_aadhaar });
        }
    }
    return 0;
};

const getWarehouseOwnerBasePayment = ({ ticketDetails }) => {
    const is_pancard_linked_with_aadhaar = ticketDetails?.warehouse_owner_details?.is_pancard_linked_with_aadhaar;
    if (!['', undefined, null].includes(ticketDetails?.warehouse_owner_base_payment) && ticketDetails?.warehouse_owner_base_payment > 0) {
        if (ticketDetails?.warehouse_owner_details && ticketDetails?.warehouse_owner_details.employee_type === 'EMP') {
            return ticketDetails?.warehouse_owner_base_payment;
        } else {
            return ticketDetails?.warehouse_owner_base_payment - ticketDetails?.warehouse_owner_base_payment * taxDeductionPercentage({ is_pancard_linked_with_aadhaar });
        }
    }
    return 0;
};

const getSupervisorBasePayment = ({ ticketDetails }) => {
    const is_pancard_linked_with_aadhaar = ticketDetails?.supervisor_details?.is_pancard_linked_with_aadhaar;
    if (!['', undefined, null].includes(ticketDetails?.supervisor_base_payment) && ticketDetails?.supervisor_base_payment > 0) {
        if (ticketDetails?.supervisor_details && ticketDetails?.supervisor_details.employee_type === 'EMP') {
            return ticketDetails?.supervisor_base_payment;
        } else {
            return ticketDetails?.supervisor_base_payment - ticketDetails?.supervisor_base_payment * taxDeductionPercentage({ is_pancard_linked_with_aadhaar });
        }
    }
    return 0;
};

export const getBasePaymentData = ({ ticketDetails, ticketBasePayment, setBasePaymentScheduledOnDate = () => { } }) => {
    let data = {
        type: 'Base Payment',
        scheduled_date: 'test',
        isEditable: false,
        customer_details: 0,
        partner_details: 0,
        cluster_details: 0,
        psp_details: 0,
        supervisor_details: 0,
        warehouse_details: 0,
        scheduled_on: new Date(),
    };

    const customer_details = getCustomerBasePrice({ ticketDetails });
    data.customer_details = customer_details;
    const partner_details = getPartnerBasePrice({ ticketDetails });
    data.partner_details = partner_details;

    if (ticketDetails?.cluster_id > 0) {
        const cluster_details = getClusterBasePrice({ ticketDetails });
        data.cluster_details = cluster_details;
    }
    if (ticketDetails?.psp_id > 0) {
        const psp_details = getPspBasePrice({ ticketDetails });
        data.psp_details = psp_details;
    }
    if (ticketDetails?.supervisor_id > 0) {
        const supervisor_details = getSupervisorBasePayment({ ticketDetails });
        data.supervisor_details = supervisor_details;
    }
    if (ticketDetails?.is_rma_raised === 1 && ticketDetails?.warehouse_owner > 0) {
        const warehouse_details = getWarehouseOwnerBasePayment({ ticketDetails });
        data.warehouse_details = warehouse_details;
    }
    if (ticketBasePayment?.due_date) {
        let date = new Date(ticketBasePayment?.due_date);
        data.scheduled_on = date;
        setBasePaymentScheduledOnDate(date);
    } else { data.isToHidedate = true }
    data.status = getBasePaymentStatus({ ticketDetails });
    data.referenceId = uuidv4();
    return data;
};

import { takeEvery, takeLatest, call, put, all } from 'redux-saga/effects';
import { customer, toast, modal, user, loading, utils, partners, auth } from '../types';
import lambdas from '../lambdas/apis';

import api from '../apis';

export function* getCustomerAddonsList(action) {
    const { customerId = 0, dropdown = false } = action.payload;
    try {
        yield put({ type: customer.CUSTOMERS_ADDONS_LIST_LOADING, payload: true });
        const response = yield call(api.SP.getCustomersAddons, {}, { customerId });
        if (response.data.code === 200) {
            let addons = response.data.data.addon_users;
            if (dropdown) {
                addons = [];
                const isDataAvailable = Array.isArray(response.data.data.addon_users);
                isDataAvailable &&
                    response.data.data.addon_users.forEach((item) => {
                        if (item.user) {
                            addons.push({
                                key: `addons_${item.id}_${item.fk_user_id}`,
                                value: item.fk_user_id,
                                label: `${item.user.first_name} ${item.user.last_name}`,
                            });
                        }
                    });
            }
            yield put({ type: customer.CUSTOMERS_ADDONS_LIST_SUCCESS, payload: addons });
            const list = addons.filter((item) => item.user).map((item) => item.user);
            yield put({ type: partners.GET_GROUP_USER_LIST_SUCCESS, payload: { data: list } });
        } else {
            yield all([
                yield put({ type: customer.CUSTOMERS_ADDONS_LIST_SUCCESS, payload: [] }),
                yield put({ type: customer.CUSTOMERS_ADDONS_LIST_FAILURE, payload: { message: 'Oops Something went wrong' } }),
            ]);
        }
    } catch (error) {
        yield put({ type: customer.CUSTOMERS_ADDONS_LIST_FAILURE, payload: { message: error } });
    }
    yield put({ type: customer.CUSTOMERS_ADDONS_LIST_LOADING, payload: false });
}

export function* getCustomerAddonsListDropDown(action) {
    const { customerId } = action.payload;
    try {
        yield put({ type: customer.CUSTOMERS_ADDONS_DROPDOWN_LIST_LOADING, payload: true });
        const response = yield call(api.SP.getCustomersAddons, {}, { customerId });
        if (response.data.code === 200) {
            let addons = [];
            const isDataAvailable = Array.isArray(response.data.data.addon_users);
            isDataAvailable &&
                response.data.data.addon_users.forEach((item) => {
                    if (item.user) {
                        addons.push({
                            key: `addons_${item.id}_${item.fk_user_id}`,
                            value: item.fk_user_id,
                            label: `${item.user.first_name} ${item.user.last_name}`,
                            user: item?.user,
                            is_enabled: item?.user?.is_enabled
                        });
                    }
                });
            yield put({ type: customer.CUSTOMERS_ADDONS_DROPDOWN_LIST_SUCCESS, payload: addons });
        } else {
            yield put({ type: customer.CUSTOMERS_ADDONS_DROPDOWN_LIST_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: customer.CUSTOMERS_ADDONS_DROPDOWN_LIST_FAILURE, payload: { message: error } });
    }
    yield put({ type: customer.CUSTOMERS_ADDONS_DROPDOWN_LIST_LOADING, payload: false });
}

export function* getCustomerVendorsData(action) {
    const { customer_id } = action.payload;
    try {
        yield put({ type: loading.GET_CUSTOMER_VENDORS_LIST_LOADING, payload: true });
        const response = yield call(api.customers.listAllVendors, {}, { customer_id }, {}, {});
        if (response.data.code === 200) {
            yield put({ type: customer.GET_CUSTOMER_VENDORS_LIST_SUCCESS, payload: response.data.data.service_partner });
            const updatedData = response.data.data.service_partner.map((vendor) => {
                return {
                    label: `${vendor?.user?.first_name} ${vendor?.user?.last_name}`,
                    value: vendor.fk_user_id,
                    key: vendor.fk_user_id,
                    vendor: vendor.fk_user_id,
                    user: vendor,
                    address: vendor.sp_address_line_1 || '',
                    sp_name: vendor.sp_name,
                    pancard_number: vendor.sp_pan_card_number,
                    customer_gst_number: vendor.sp_gst_no,
                    gst_pancard_number: vendor.sp_gst_no ? vendor.sp_gst_no : vendor.sp_pan_card_number,
                };
            });
            yield put({ type: customer.RAISE_PO_VENDORS_LIST, payload: updatedData });
        } else {
            yield put({ type: customer.GET_CUSTOMER_VENDORS_LIST_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: customer.GET_CUSTOMER_VENDORS_LIST_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.GET_CUSTOMER_VENDORS_LIST_LOADING, payload: false });
}

export function* getCustomerTeams(action) {
    const { agentsInfo = true, customerId, limit, page = 1, dropdown = false, count = false, query, projectId, type, toAppendInternalTeams } = action.payload;
    let queryParams = { customerId, limit, page, dropdown, count, agentsInfo, projectId, toAppendInternalTeams };

    if (!['', undefined, null].includes(query)) queryParams.query = query;
    if (!['', undefined, null].includes(type)) queryParams.type = type;

    try {
        yield put({ type: customer.CUSTOMERS_TEAMS_LIST_LOADING, payload: true });
        const response = yield call(lambdas.superdesk.getCustomerTeams, {}, {}, queryParams);
        if (response.data.code === 200) {
            let teams = response.data.data;
            if (dropdown) {
                teams = [];
                response.data.data.forEach((item) => {
                    teams.push({
                        key: `teams_${item?.id}`,
                        value: item?.id,
                        label: `${item?.name}`,
                        default: item?.default,
                        type: item?.type,
                    });
                });
                yield put({ type: customer.CUSTOMERS_TEAMS_DROPDOWN_LIST_SUCCESS, payload: { data: teams } });
            } else {
                yield put({ type: customer.CUSTOMERS_TEAMS_LIST_SUCCESS, payload: { data: teams, count: response.data.count } });
            }
        } else {
            yield put({ type: customer.CUSTOMERS_TEAMS_LIST_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: customer.CUSTOMERS_TEAMS_LIST_FAILURE, payload: { message: error } });
    }
    yield put({ type: customer.CUSTOMERS_TEAMS_LIST_LOADING, payload: false });
}

export function* getCustomerTeamsByCustomerID(action) {
    const { agentsInfo = true, customerId, limit, page = 1, dropdown = false, count = false, query, projectId, type } = action.payload;
    let queryParams = { customerId, limit, page, dropdown, count, agentsInfo, projectId, type };

    if (!['', undefined, null].includes(query)) queryParams.query = query;
    try {
        yield put({ type: customer.CUSTOMERS_TEAMS_LIST_LOADING, payload: true });
        const response = yield call(lambdas.superdesk.getCustomerTeams, {}, {}, queryParams);
        if (response.data.code === 200) {
            let teams = response.data.data;
            yield put({ type: customer.CUSTOMERS_TEAMS_BY_CUSTOMERID_LIST_SUCCESS, payload: { data: teams, count: response.data.count } });
        } else {
            yield put({ type: customer.CUSTOMERS_TEAMS_BY_CUSTOMERID_LIST_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: customer.CUSTOMERS_TEAMS_BY_CUSTOMERID_LIST_FAILURE, payload: { message: error } });
    }
    yield put({ type: customer.CUSTOMERS_TEAMS_LIST_LOADING, payload: false });
}

export function* getNextCustomerTeams(action) {
    const { customerId, limit, page = 1, dropdown = false, count, query, type } = action.payload;
    let queryParams = { type, customerId, limit, page, dropdown, count, agentsInfo: true };

    if (!['', undefined, null].includes(query)) queryParams.query = query;
    try {
        yield put({ type: customer.CUSTOMERS_NEXT_TEAMS_LIST_LOADING, payload: true });
        const response = yield call(lambdas.superdesk.getCustomerTeams, {}, {}, queryParams);
        if (response.data.code === 200) {
            let teams = response.data.data;
            yield put({ type: customer.CUSTOMERS_NEXT_TEAMS_LIST_SUCCESS, payload: { data: teams, count: response.data.count } });
        } else {
            yield put({ type: customer.CUSTOMERS_NEXT_TEAMS_LIST_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: customer.CUSTOMERS_NEXT_TEAMS_LIST_FAILURE, payload: { message: error } });
    }
    yield put({ type: customer.CUSTOMERS_NEXT_TEAMS_LIST_LOADING, payload: false });
}

export function* getprojectListName(action) {
    const { customerId } = action.payload;
    let queryParams = {};
    if (customerId > 0) queryParams.customer_id = customerId;
    try {
        yield put({ type: customer.PROJECT_LIST_NAME_LOADING, payload: true });
        const response = yield call(lambdas.superdesk.getprojectListName, {}, {}, queryParams);
        if (response.data.code === 200) {
            let projectNameList = response.data.data;
            projectNameList = response.data.data.map((project) => {
                return {
                    label: project.project_name,
                    value: project.id,
                    key: `project_${project.id}`,
                    is_boq_required: project.is_boq_required,
                    fk_customer_id: project.fk_customer_id,
                    endUser: project.endUser,
                    use_case_id: project.use_case_id,
                    service_type_id: project.service_type_id,
                    self_pickup_required: project.self_pickup_required,
                    is_rma_eligible: project.is_rma_eligible,
                    auto_broadcast_tickets: project.auto_broadcast_tickets,
                };
            });
            yield put({ type: customer.PROJECT_LIST_NAME_SUCCESS, payload: projectNameList });
        } else {
            yield put({ type: customer.PROJECT_LIST_NAME_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: customer.PROJECT_LIST_NAME_FAILURE, payload: { message: error } });
    }
    yield put({ type: customer.PROJECT_LIST_NAME_LOADING, payload: false });
}

export function* getCustomersList(action) {
    try {
        yield put({ type: loading.CUSTOMERS_LIST_LOADING, payload: true });
        const response = yield call(api.customers.listAll, {});
        if (response.data.code === 200) {
            let customers = [];
            response.data.data.map((customer) => {
                return customers.push({
                    key: `${customer.customer_id}`,
                    value: customer.customer_id,
                    label: customer.customer_company_name,
                    customer_id: customer.customer_id,
                    is_rma_eligible: customer.is_rma_eligible,
                    is_feasibility_eligible: customer.is_feasibility_eligible,
                    check_serial_number: customer.check_serial_number,
                    fk_user_id: customer.fk_user_id,
                    id: customer.id,
                });
            });
            yield put({ type: customer.CUSTOMERS_LIST_SUCCESS, payload: customers });
        } else {
            yield put({ type: customer.CUSTOMERS_LIST_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: customer.CUSTOMERS_LIST_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.CUSTOMERS_LIST_LOADING, payload: false });
}

export function* inviteCustomersEndUsers(action) {
    const { form, selectedTab, formName } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(api.invitations.inviteCustomersEndUsers, {}, {}, {}, form);

        if (response.data.code === 201) {
            yield put({ type: utils.SET_INITIAL_FORM, payload: Date.now() });
            if (selectedTab === 'send_invites') {
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'Enduser Invited Successfully' } });
            } else {
                yield put({ type: modal.CLOSE_MODAL });
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'Customer Assigned Succesfully' } });
            }
        } else {
            let errors = response.data.message;
            for (const message in errors) {
                let toastMessage = errors[message].toString();
                yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: toastMessage } });
            }
            yield all([yield put({ type: modal.CLOSE_MODAL }), yield put({ type: customer.INVITE_CUSTOMERS_ENDUSERS_FAILURE, payload: { message: 'Oops Something went wrong' } })]);
        }
    } catch (error) {
        yield put({ type: customer.INVITE_CUSTOMERS_ENDUSERS_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* customerInviteCSPUser(action) {
    const { form, formName } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(api.invitations.adminSendCSPInvite, {}, {}, {}, form);
        if (response.data.code === 201) {
            let toastList = [];
            let errorsList = [];
            response.data.data.map((invitation, index) => {
                if (invitation.code === 201) {
                    return toastList.push({
                        index: index,
                        status: 'success',
                        message: 'User Invited Successfully',
                    });
                } else {
                    return errorsList.push({
                        index: index,
                        status: 'danger',
                        message: `${invitation.message.email ? invitation.message.email[0] : invitation.message.mobile ? invitation.message.mobile[0] : 'User Detail already exits'
                            }`,
                    });
                }
            });

            if (toastList.length > 0) {
                yield put({ type: toast.SEND_TOAST, payload: { status: toastList[0].status, message: toastList[0].message } });
            }
            if (errorsList.length > 0) {
                yield put({ type: toast.SEND_TOAST, payload: { status: errorsList[0].status, message: errorsList[0].message } });
            }
            if (errorsList.length === 0) {
                yield put({ type: utils.SET_INITIAL_FORM, payload: Date.now() });
            }
        } else {
            yield put({ type: user.CUSTOMER_SEND_SP_INVITES_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: user.CUSTOMER_SEND_SP_INVITES_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* customerInviteAdminPMUser(action) {
    const { form, formName } = action.payload;
    try {
        yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
        const response = yield call(api.invitations.customerSendInvites, {}, {}, {}, form);
        if (response.data.code === 201 || response.data.code === 400) {
            let message = '';
            let status = '';
            if (response.data.code === 201) {
                yield put({ type: utils.SET_INITIAL_FORM, payload: Date.now() });
                status = 'success';
                message = 'User Invited Successfully';
            } else {
                status = 'danger';
                message = 'User details already exists';
            }
            yield put({ type: toast.SEND_TOAST, payload: { status, message } });
        } else {
            yield put({ type: user.CUSTOMER_SEND_ADMIN_PM_INVITES_FAILURE, payload: { message: 'Oops Something went wrong' } });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: user.CUSTOMER_SEND_ADMIN_PM_INVITES_FAILURE, payload: { message: error } });
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong' } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* getCustomerProfileDetails(action) {
    const { customerId } = action.payload;
    yield put({ type: loading.FIND_CUSTOMER_PROFILR_LOADING, payload: true });
    try {
        let response = yield call(api.customers.getProfile, {}, { customerId });
        if (response.data.code === 200) {
            yield put({ type: customer.GET_CUSTOMER_PROFILE_DETAILS_SUCCESS, payload: response.data.data.customer });
        } else {
            yield put({ type: customer.GET_CUSTOMER_PROFILE_DETAILS_FAILURE, payload: { message: 'Oops Something went wrong' } });
        }
    } catch (error) {
        yield put({ type: customer.GET_CUSTOMER_PROFILE_DETAILS_FAILURE, payload: { message: error } });
    }
    yield put({ type: loading.FIND_CUSTOMER_PROFILR_LOADING, payload: false });
}

export function* downloadISPFeasibityReport(action) {
    const { emails, formName, closeModalOnSuccess } = action.payload;
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    const queryParams = { type: 0, module: 'PARTNERS', tab: 'isp', status: 'FEASIBILITY' };
    const body = {
        emails,
        filterData: {},
        data: { download: true, rate: 'both', act: 'company', categorise: 'both', archive: 'both', range: 'all', assetswise: 'all' },
    };
    try {
        let response = yield call(lambdas.utils.exportCsv, {}, {}, queryParams, body);
        if (response.data.code === 200) {
            yield put({ type: partners.DOWNLOAD_ISP_FEASIBILITY_REPORT_SUCCESS, payload: response.data.data });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: response.data.data.message } });
            if (closeModalOnSuccess) {
                yield put({ type: modal.CLOSE_MODAL });
            }
        } else {
            yield put({ type: partners.DOWNLOAD_ISP_FEASIBILITY_REPORT_FAILURE, payload: { message: 'Oops Something went wrong' } });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data?.data?.message || 'Something went wrong!' } });
        }
    } catch (error) {
        yield put({ type: partners.DOWNLOAD_ISP_FEASIBILITY_REPORT_FAILURE, payload: { message: error } });
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: error?.message || 'Something went wrong!' } });
    }

    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* updateCustomerDetails(action) {
    const { form, formName } = action.payload;
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: true } });
    try {
        if (form?.net_provider || form?.net_plan) {
            yield put({ type: auth.UPDATE_PROFILE_REQUEST, payload: { net_provider: form?.net_provider, net_plan: form?.net_plan } });
        }
        const response = yield call(api.customers.updateDetails, {}, {}, {}, form);
        if (response.data.code === 201) {
            yield all([
                yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: 'Details Updated Successfully' } }),
                yield put({ type: customer.UPDATE_CUSTOMER_PROFILE_DETAILS_SUCCESS, payload: response.data.data }),
                yield put({ type: modal.CLOSE_MODAL }),
            ]);
        } else {
            yield put({ type: customer.UPDATE_CUSTOMER_PROFILE_DETAILS_FAILURE, payload: { message: 'Oops Something went wrong' } });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: response.data.message } });
        }
    } catch (error) {
        yield put({ type: customer.UPDATE_CUSTOMER_PROFILE_DETAILS_FAILURE, payload: { message: error } });
        yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: error?.message || 'Something went wrong!' } });
    }
    yield put({ type: loading.FORM_BUTTON_LOADING, payload: { formName, isLoading: false } });
}

export function* uploadCustomerLogo(action) {
    const { form } = action.payload;
    try {
        let response = yield call(api.customers.uploadLogo, {}, {}, {}, form);
        if (response.data.code === 201) {
            yield put({ type: customer.UPLOAD_CUSTOMER_LOGO_SUCCESS, payload: response.data.data });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'success', message: response.data.data.message } });
        } else {
            yield put({ type: customer.UPLOAD_CUSTOMER_LOGO_FAILURE, payload: { message: 'Oops Something went wrong' } });
            yield put({ type: toast.SEND_TOAST, payload: { status: 'danger', message: 'Something went wrong!' } });
        }
    } catch (error) {
        yield put({ type: customer.UPLOAD_CUSTOMER_LOGO_FAILURE, payload: { message: error } });
    }
}

export default function* customerSaga() {
    yield takeEvery(customer.CUSTOMERS_ADDONS_LIST_REQUEST, getCustomerAddonsList);
    yield takeEvery(customer.CUSTOMERS_ADDONS_DROPDOWN_LIST_REQUEST, getCustomerAddonsListDropDown);
    yield takeEvery(customer.CUSTOMERS_TEAMS_LIST_REQUEST, getCustomerTeams);
    yield takeLatest(customer.GET_CUSTOMER_VENDORS_LIST_REQUEST, getCustomerVendorsData);
    yield takeEvery(customer.CUSTOMERS_NEXT_TEAMS_LIST_REQUEST, getNextCustomerTeams);
    yield takeEvery(customer.CUSTOMERS_TEAMS_BY_CUSTOMERID_LIST_REQUEST, getCustomerTeamsByCustomerID);
    yield takeEvery(customer.PROJECT_LIST_NAME_REQUEST, getprojectListName);
    yield takeEvery(customer.CUSTOMERS_LIST_REQUEST, getCustomersList);
    yield takeEvery(customer.INVITE_CUSTOMERS_ENDUSERS_REQUEST, inviteCustomersEndUsers);
    yield takeLatest(user.CUSTOMER_SEND_SP_INVITES_REQUEST, customerInviteCSPUser);
    yield takeLatest(user.CUSTOMER_SEND_ADMIN_PM_INVITES_REQUEST, customerInviteAdminPMUser);
    yield takeEvery(customer.GET_CUSTOMER_PROFILE_DETAILS_REQUEST, getCustomerProfileDetails);
    yield takeEvery(partners.DOWNLOAD_ISP_FEASIBILITY_REPORT_REQUEST, downloadISPFeasibityReport);
    yield takeLatest(customer.UPDATE_CUSTOMER_PROFILE_DETAILS_REQUEST, updateCustomerDetails);
    yield takeLatest(customer.UPLOAD_CUSTOMER_LOGO_REQUEST, uploadCustomerLogo);
}
